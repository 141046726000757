import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Card, CardActions, CardContent, IconButton, Typography } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { InformationCardProps } from './InformationCard.definitions';

function InformationCard({
  subtitle,
  title,
  bodyText,
  buttonText,
  navigatePath,
  isDeletable = false,
  deleteEntity,
  children,
}: InformationCardProps): FunctionComponentElement<InformationCardProps> {
  const navigate = useNavigate();

  return (
    <Card variant="outlined">
      <CardContent>
        {subtitle && (
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {subtitle}
          </Typography>
        )}
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" component="div">
          {bodyText}
        </Typography>
        {children}
      </CardContent>
      <CardActions sx={{ justifyContent: 'space-between' }}>
        <Button size="small" onClick={() => navigate(navigatePath)}>
          {buttonText}
        </Button>

        {deleteEntity && (
          <IconButton
            disabled={isDeletable}
            sx={{ color: (theme) => theme.palette.primary.main }}
            onClick={deleteEntity}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </CardActions>
    </Card>
  );
}

export default InformationCard;
