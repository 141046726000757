import {
  GridRenderCellParams,
  GridRowModel,
  GridRowsProp,
  GridValidRowModel,
  GridValueSetterParams,
} from '@mui/x-data-grid-premium';
import { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  ReviewState,
  TaxFormEntryModel,
  TaxFormEntryType,
  TaxFormEntryValueModel,
  TaxFormSection,
  TaxFormsService,
} from 'api/taxCalculation';

import { numberFormatter } from 'shared/utils/invoice.helper';

import RenderBasicEditCell from 'components/BdoDataGrid/Cells/BasicEditCell';

import TaxFormSectionType from 'enums/TaxFormSectionType';
import { TaxFormData } from 'models/TaxFormData';

export const taxFormEntryTypes = Object.values(TaxFormEntryType).filter(
  (value) => typeof value === 'number',
);

export const taxFormSectionTypes: string[] = Object.values(TaxFormSectionType);

export const entryTypesWithoutAmounts: number[] = [
  // TaxFormEntryType._0,
  TaxFormEntryType._1,
  TaxFormEntryType._9,
  TaxFormEntryType._12,
  TaxFormEntryType._18,
];

export const entryTypeWithAmounts: number[] = [
  TaxFormEntryType._2,
  TaxFormEntryType._3,
  TaxFormEntryType._4,
  TaxFormEntryType._5,
  TaxFormEntryType._6,
  TaxFormEntryType._7,
  TaxFormEntryType._8,
  TaxFormEntryType._10,
  TaxFormEntryType._11,
  TaxFormEntryType._13,
  TaxFormEntryType._14,
  TaxFormEntryType._15,
  TaxFormEntryType._16,
  TaxFormEntryType._17,
  TaxFormEntryType._19,
  TaxFormEntryType._20,
];

export const readOnlyEntriesKeyFigures: string[] = [
  'FillingPeriod.ReportingYear',
  'FillingPeriod.ReportingMonth',
];

export async function postOverwriting(entry: TaxFormEntryModel): Promise<TaxFormEntryModel> {
  const taxFormEntry = await TaxFormsService.postApiTaxFormsApplyOverwriting(
    entry.id,
    entry.overwriting,
  );
  return taxFormEntry;
}

export async function revertOverwriting(entryId: string): Promise<TaxFormEntryModel> {
  const response = await TaxFormsService.postApiTaxFormsReverseOverwriting(entryId);
  return response;
}

export async function updateReviewState(
  entryId: string,
  reviewState: ReviewState,
): Promise<TaxFormEntryModel> {
  const response = await TaxFormsService.postApiTaxFormsChangeReviewState(entryId, reviewState);
  return response;
}

export function sortEntries(entries: TaxFormEntryModel[]): TaxFormEntryModel[] {
  entries.sort((a, b) => (a.configuration?.sortOrder || 0) - (b.configuration?.sortOrder || 0));
  return entries;
}

export function groupRowsByFormEntryGroup(
  rows: GridRowsProp<TaxFormEntryModel>,
): Map<number, GridRowsProp<TaxFormEntryModel>> {
  return rows.reduce(
    (
      groupedMap: Map<number, GridRowsProp<TaxFormEntryModel>>,
      currentRow: GridRowModel<TaxFormEntryModel>,
    ): Map<number, GridRowsProp<TaxFormEntryModel>> => {
      const { configuration } = currentRow;

      if (!configuration || configuration.formEntryGroup === undefined) return groupedMap;

      // Ignore frontend headers i.e General, Tax
      if (configuration.id && taxFormSectionTypes.includes(configuration.id)) {
        return groupedMap;
      }

      const updatedGroupArray = [
        ...(groupedMap.get(configuration.formEntryGroup) || []),
        currentRow,
      ];

      return groupedMap.set(configuration.formEntryGroup, updatedGroupArray);
    },
    new Map<number, GridRowsProp<TaxFormEntryModel>>(),
  );
}

export function groupRowsByTaxFormSection(
  rows: GridRowsProp<TaxFormEntryModel>,
): Map<number, GridRowsProp<TaxFormEntryModel>> {
  return rows.reduce(
    (
      groupedMap: Map<number, GridRowsProp<TaxFormEntryModel>>,
      currentRow: GridRowModel<TaxFormEntryModel>,
    ): Map<number, GridRowsProp<TaxFormEntryModel>> => {
      const { configuration } = currentRow;

      if (!configuration || configuration.taxFormSection === undefined) return groupedMap;

      // Ignore frontend headers i.e General, Tax
      if (configuration.id && taxFormSectionTypes.includes(configuration.id)) {
        return groupedMap;
      }

      const updatedGroupArray = [
        ...(groupedMap.get(configuration.taxFormSection) || []),
        currentRow,
      ];

      return groupedMap.set(configuration.taxFormSection, updatedGroupArray);
    },
    new Map<number, GridRowsProp<TaxFormEntryModel>>(),
  );
}

export function addHeaderRow(
  rows: GridRowsProp<TaxFormEntryModel>,
  headerType: TaxFormSectionType,
): GridRowsProp<TaxFormEntryModel> {
  return rows.reduce(
    (result: TaxFormEntryModel[], currentRow: TaxFormEntryModel, index: number) => {
      result.push(currentRow);

      const nextRow = rows[index + 1];
      if (
        currentRow.configuration?.taxFormEntryType === TaxFormEntryType._0 &&
        nextRow &&
        nextRow.configuration?.taxFormEntryType !== TaxFormEntryType._0
      ) {
        result.push({
          id: uuidv4(),
          configuration: {
            id: headerType,
          },
        });
      }

      return result;
    },
    [],
  );
}

export function updateRowsWithHeaders(
  rows: GridRowsProp<TaxFormEntryModel>,
): GridRowsProp<TaxFormEntryModel> {
  const rowsWithHeaders: TaxFormEntryModel[] = [];
  const groupedRows = groupRowsByFormEntryGroup(rows);

  groupedRows.forEach((group) => {
    const groupHasAmountRow = group.some(
      (row: TaxFormEntryModel) =>
        row.configuration?.taxFormEntryType !== undefined &&
        entryTypeWithAmounts.includes(row.configuration.taxFormEntryType),
    );

    if (!groupHasAmountRow) {
      // rows with General header
      const groupWithHeader = addHeaderRow(group, TaxFormSectionType.General);
      rowsWithHeaders.push(...groupWithHeader);
    } else {
      // rows with Tax header
      const groupWithHeader = addHeaderRow(group, TaxFormSectionType.Tax);
      rowsWithHeaders.push(...groupWithHeader);
    }
  });

  return rowsWithHeaders;
}

export function initializeRows(data: TaxFormData): GridRowsProp<TaxFormEntryModel> {
  const rows: TaxFormEntryModel[] = [];
  const { generalFormEntries, entrepreneurFormEntries, taxFormEntries } = data;

  if (generalFormEntries) {
    const generalEntries = sortEntries([...generalFormEntries]);
    const entriesWithHeaders = updateRowsWithHeaders(generalEntries);
    rows.push(...entriesWithHeaders);
  }

  if (entrepreneurFormEntries) {
    const enterpreunerEntries = sortEntries([...entrepreneurFormEntries]);

    const entriesWithHeaders = updateRowsWithHeaders(enterpreunerEntries);
    rows.push(...entriesWithHeaders);
  }

  if (taxFormEntries) {
    const taxEntries = sortEntries([...taxFormEntries]);
    const entriesWithHeaders = updateRowsWithHeaders(taxEntries);
    rows.push(...entriesWithHeaders);
  }

  return rows;
}

function isGeneralSection(group: readonly TaxFormEntryModel[]): boolean {
  return group.every(
    (row: TaxFormEntryModel) => row.configuration?.taxFormSection === TaxFormSection._0,
  );
}

function isEnterpreneurSection(group: readonly TaxFormEntryModel[]): boolean {
  return group.every(
    (row: TaxFormEntryModel) => row.configuration?.taxFormSection === TaxFormSection._1,
  );
}

function isAmountAndNonAmountSubGroup(subGroup: readonly TaxFormEntryModel[]): boolean {
  return (
    subGroup.some(
      (row: TaxFormEntryModel) =>
        row.configuration?.taxFormEntryType !== undefined &&
        entryTypeWithAmounts.includes(row.configuration?.taxFormEntryType),
    ) &&
    subGroup.some(
      (row: TaxFormEntryModel) =>
        row.configuration?.taxFormEntryType !== undefined &&
        entryTypesWithoutAmounts.includes(row.configuration?.taxFormEntryType),
    )
  );
}

function isAmountOnlySubGroup(subGroup: readonly TaxFormEntryModel[]): boolean {
  return (
    subGroup.some(
      (row: TaxFormEntryModel) =>
        row.configuration?.taxFormEntryType !== undefined &&
        entryTypeWithAmounts.includes(row.configuration?.taxFormEntryType),
    ) &&
    subGroup.some(
      (row: TaxFormEntryModel) =>
        row.configuration?.taxFormEntryType !== undefined &&
        !entryTypesWithoutAmounts.includes(row.configuration?.taxFormEntryType),
    )
  );
}

function getValueRowsForAmountAndNonAmountSubGroup(
  subGroup: readonly TaxFormEntryModel[],
): TaxFormEntryModel[] {
  const selectedRows: TaxFormEntryModel[] = [];
  let descRows: TaxFormEntryModel[] = [];
  let isDescRowAdded = false;
  subGroup.forEach((row: TaxFormEntryModel) => {
    // desc
    if (
      row.configuration?.taxFormEntryType !== undefined &&
      row.configuration?.taxFormEntryType === TaxFormEntryType._0
    ) {
      descRows.push(row);
      isDescRowAdded = false;
    }
    // non-amount
    else if (
      row.configuration?.taxFormEntryType !== undefined &&
      entryTypesWithoutAmounts.includes(row.configuration?.taxFormEntryType)
    ) {
      if (isDescRowAdded) {
        selectedRows.push(row);
      } else {
        selectedRows.push(...descRows);
        selectedRows.push(row);
        isDescRowAdded = true;
        descRows = [];
      }
    } else {
      // amount
      const { configuration, calculation, overwriting } = row;
      if (!configuration || !calculation) {
        return;
      }
      if (overwriting) {
        if (
          (overwriting.amount && overwriting.amount !== 0) ||
          (overwriting.taxAmount && overwriting.taxAmount !== 0) ||
          (calculation.numberOfCalculatedInvoices && calculation.numberOfCalculatedInvoices !== 0)
        ) {
          if (isDescRowAdded) {
            selectedRows.push(row);
          } else {
            selectedRows.push(...descRows);
            selectedRows.push(row);
            isDescRowAdded = true;
          }
        }
      } else if (
        (calculation.amount && calculation.amount !== 0) ||
        (calculation.taxAmount && calculation.taxAmount !== 0) ||
        (calculation.numberOfCalculatedInvoices && calculation.numberOfCalculatedInvoices !== 0)
      ) {
        if (isDescRowAdded) {
          selectedRows.push(row);
        } else {
          selectedRows.push(...descRows);
          selectedRows.push(row);
          isDescRowAdded = true;
        }
      }
    }
  });
  return selectedRows;
}

function getValueRowsForAmountOnlySubGroup(
  subGroup: readonly TaxFormEntryModel[],
): TaxFormEntryModel[] {
  const selectedRows: TaxFormEntryModel[] = [];
  const descRows: TaxFormEntryModel[] = [];
  let isDescRowAdded = false;
  subGroup.forEach((row: TaxFormEntryModel) => {
    if (
      row.configuration?.taxFormEntryType !== undefined &&
      row.configuration?.taxFormEntryType === TaxFormEntryType._0
    ) {
      // desc
      descRows.push(row);
      isDescRowAdded = false;
    } else {
      // amount
      const { configuration, calculation, overwriting } = row;
      if (!configuration || !calculation) {
        return;
      }
      if (overwriting) {
        if (
          (overwriting.amount && overwriting.amount !== 0) ||
          (overwriting.taxAmount && overwriting.taxAmount !== 0) ||
          (calculation.numberOfCalculatedInvoices && calculation.numberOfCalculatedInvoices !== 0)
        ) {
          if (isDescRowAdded) {
            selectedRows.push(row);
          } else {
            selectedRows.push(...descRows);
            selectedRows.push(row);
            isDescRowAdded = true;
          }
        }
      } else if (
        (calculation.amount && calculation.amount !== 0) ||
        (calculation.taxAmount && calculation.taxAmount !== 0) ||
        (calculation.numberOfCalculatedInvoices && calculation.numberOfCalculatedInvoices !== 0)
      ) {
        if (isDescRowAdded) {
          selectedRows.push(row);
        } else {
          selectedRows.push(...descRows);
          selectedRows.push(row);
          isDescRowAdded = true;
        }
      }
    }
  });

  return selectedRows;
}

export function getRowsWithValues(
  rows: GridRowsProp<TaxFormEntryModel>,
): GridRowsProp<TaxFormEntryModel> {
  const groupedRows = groupRowsByTaxFormSection(rows);
  const rowsWithValue: GridRowModel[] = [];

  groupedRows.forEach((group) => {
    // general and enterpreneur section
    if (isGeneralSection(group) || isEnterpreneurSection(group)) {
      const rowsWithHeaders = updateRowsWithHeaders(group);
      rowsWithValue.push(...rowsWithHeaders);
    } else {
      // tax section
      const taxGroup: TaxFormEntryModel[] = [...group];
      const taxSubGroup = groupRowsByFormEntryGroup(taxGroup);

      taxSubGroup.forEach((subGroup) => {
        // desc, amount and non-amount subgroup
        if (isAmountAndNonAmountSubGroup(subGroup)) {
          const selectedRows = getValueRowsForAmountAndNonAmountSubGroup(subGroup);
          const subGroupWithHeaders = updateRowsWithHeaders(selectedRows);
          rowsWithValue.push(...subGroupWithHeaders);
        }
        // desc and amount subgroup
        else if (isAmountOnlySubGroup(subGroup)) {
          const selectedRows = getValueRowsForAmountOnlySubGroup(subGroup);
          const subGroupWithHeaders = updateRowsWithHeaders(selectedRows);
          rowsWithValue.push(...subGroupWithHeaders);
        } else {
          // non-amount subgroup
          const subGroupWithHeaders = updateRowsWithHeaders(subGroup);
          rowsWithValue.push(...subGroupWithHeaders);
        }
      });
    }
  });

  return rowsWithValue;
}

export function updateProperty<T>(
  object: TaxFormEntryValueModel,
  property: keyof TaxFormEntryValueModel,
  value: T,
): TaxFormEntryValueModel {
  return {
    ...object,
    [property]: value,
  };
}

export function updateRowWithOverwriting<T>(
  params: GridValueSetterParams,
  propertyToBeUpdated: keyof TaxFormEntryValueModel,
  valueToBeupdated: T,
): GridValidRowModel {
  const {
    row: { overwriting },
  } = params;

  // overwriting exists
  if (overwriting) {
    const updatedOverwriting = updateProperty(
      { ...params.row.overwriting },
      propertyToBeUpdated,
      valueToBeupdated,
    );
    return { ...params.row, overwriting: updatedOverwriting };
  }

  const createdOverwriting = {} as TaxFormEntryValueModel;
  const updatedOverwriting = updateProperty(
    createdOverwriting,
    propertyToBeUpdated,
    valueToBeupdated,
  );

  return { ...params.row, overwriting: updatedOverwriting };
}

export function isValueChanged(
  params: GridValueSetterParams,
  propertyToBeVerified: keyof TaxFormEntryValueModel,
): boolean {
  const {
    row: { overwriting, calculation },
  } = params;

  if (overwriting) {
    if (overwriting[propertyToBeVerified] === params.value || '') {
      return false;
    }
  } else if (calculation[propertyToBeVerified] === params.value || '') {
    return false;
  }

  return true;
}

export function isBooleanValueChanged(
  params: GridValueSetterParams,
  propertyToBeVerified: keyof TaxFormEntryValueModel,
  valueToBeUpdated: string,
): boolean {
  const {
    row: { overwriting, calculation },
  } = params;

  if (overwriting) {
    if (!overwriting[propertyToBeVerified] && valueToBeUpdated === '0') {
      return false;
    }
    if (overwriting[propertyToBeVerified] === valueToBeUpdated) {
      return false;
    }
  } else {
    if (!calculation[propertyToBeVerified] && valueToBeUpdated === '0') {
      return false;
    }
    if (calculation[propertyToBeVerified] === valueToBeUpdated) {
      return false;
    }
  }
  return true;
}

export const renderAmountCell = (params: GridRenderCellParams): ReactNode => {
  const {
    row: { configuration },
  } = params;

  if (!configuration) {
    return null;
  }

  // Viewable for headers
  if (configuration.id === TaxFormSectionType.Tax) {
    return <div>{params.value}</div>;
  }

  // Viewable only for types with amounts
  if (entryTypeWithAmounts.includes(configuration.taxFormEntryType)) {
    // return <div>{currencyFormatter.format(params.value || 0)}</div>;
    return <div>{numberFormatter.format(params.value || 0)}</div>;
  }

  return null;
};

export const renderEditAmountCell = (params: GridRenderCellParams): ReactNode => {
  const {
    row: { configuration },
  } = params;

  if (!configuration) {
    return null;
  }

  // Header
  if (configuration.id === TaxFormSectionType.Tax) {
    return <div>{params.value}</div>;
  }

  // Editable only for types with amounts
  if (entryTypeWithAmounts.includes(configuration.taxFormEntryType)) {
    return RenderBasicEditCell(params);
  }

  return null;
};
