import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { FunctionComponentElement } from 'react';

import { DrawerHeaderProps } from './DrawerHeader.definitions';

function DrawerHeader({
  title,
  hasCloseButton = false,
  onClose,
}: DrawerHeaderProps): FunctionComponentElement<DrawerHeaderProps> {
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
          {title}
        </Typography>
        {hasCloseButton && onClose && (
          <IconButton
            aria-label="close-drawer"
            sx={{ display: 'flex', justifyContent: 'end', height: '40px', width: '40px' }}
            onClick={onClose}
          >
            <ArrowForwardIcon />
          </IconButton>
        )}
      </Box>
      <Divider />
    </>
  );
}

export default DrawerHeader;
