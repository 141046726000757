import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import { FilesService } from 'api/files';

import Loader from 'components/Loader/Loader';
import PdfViewer from 'components/PdfViewer/PdfViewer';

import { SubmissionFileViewProps } from './SubmissionFileView.definitions';

function SubmissionFileView({ submissionFiles, t }: SubmissionFileViewProps) {
  const [selectedFile, setSelectedFile] = useState<string>(
    submissionFiles !== undefined ? submissionFiles[0].id : '',
  );
  const [fileContent, setFileContent] = useState<string | null | undefined>(null);
  const [filename, setFilename] = useState<string>('');

  useEffect(() => {
    const fetchFileContent = async (fileId: string) => {
      const file = await FilesService.getApiFilesGetFileContent(fileId);
      setFileContent(file.fileContent);
      setFilename(file.name);
    };

    if (selectedFile) {
      fetchFileContent(selectedFile);
    }
  }, [selectedFile]);

  if (!submissionFiles || submissionFiles.length === 0) {
    return (
      <Typography variant="body1" sx={{ margin: '16px 0' }}>
        {t('submissionMessages:noResults')}
      </Typography>
    );
  }

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedFile(event.target.value as string);
    setFileContent(null);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <FormControl sx={{ marginBottom: '1rem', minWidth: '60%' }}>
          <InputLabel>{t('submissionMessages:selectFile')}</InputLabel>
          <Select
            value={selectedFile}
            onChange={handleChange}
            label={t('submissionMessages:selectFile')}
          >
            {submissionFiles.map((file) => (
              <MenuItem key={file.id} value={file.id}>
                {file.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div>
          {fileContent ? (
            <Link
              href={`data:application/pdf;base64,${fileContent}`}
              target="_blank"
              download={filename}
              style={{ marginLeft: '0.5rem' }}
            >
              <DownloadForOfflineIcon fontSize="large" />
            </Link>
          ) : (
            <CircularProgress sx={{ marginLeft: '0.5rem' }} />
          )}
        </div>
      </div>
      {fileContent ? <PdfViewer file={fileContent} /> : <Loader />}
    </Box>
  );
}

export default withTranslation('submissionMessages')(SubmissionFileView);
