import { Box, List } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import useStore from 'context/Store/Store.hooks';

import FallbackComponent from 'components/FallbackComponent/FallbackComponent';
import DrawerHeader from 'components/Layouts/DrawerHeader/DrawerHeader';
import Loader from 'components/Loader/Loader';

import CollapsibleNavListItem from '../CollapsibleNavListItem/CollapsibleNavListItem';
import { getParentPath, initialPadding } from '../Navigation.helper';
import NormalNavListItem from '../NormalNavListItem/NormalNavListItem';

import { NavigationListProps } from './NavigationList.definitions';

function NavigationList({ t }: NavigationListProps): FunctionComponentElement<NavigationListProps> {
  const { navigationStore } = useStore();
  const { loadNavigation, navigationList } = navigationStore;

  const { isFetching, error } = useQuery({
    queryKey: ['navigation'],
    queryFn: loadNavigation,
  });

  if (error) {
    return <FallbackComponent error={error} />;
  }

  if (isFetching) {
    return (
      <Box sx={{ margin: 'auto' }}>
        <Loader />
      </Box>
    );
  }

  return (
    <Box sx={{ py: 2, overflow: 'auto' }}>
      <DrawerHeader title={t('navigation')} />
      <List sx={{ py: 0 }}>
        {navigationList.map((navigationItem) => (
          <React.Fragment key={`${navigationItem.value}-${uuidv4()}${navigationItem.postfix}`}>
            {navigationItem.childs && navigationItem.childs.length > 0 ? (
              <CollapsibleNavListItem
                item={navigationItem}
                path={getParentPath(navigationItem)}
                paddingLeft={initialPadding}
              />
            ) : (
              <NormalNavListItem
                item={navigationItem}
                path={getParentPath(navigationItem)}
                paddingLeft={initialPadding}
              />
            )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
}

export default withTranslation('navigation')(observer(NavigationList));
