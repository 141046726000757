import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Fragment, FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import LoadingButton from 'components/Buttons/LoadingButton/LoadingButton';
import CardWrapper from 'components/CardWrapper/CardWrapper';
import FileView from 'components/FileView/FileView';
import FormFooter from 'components/Layouts/FormFooter/FormFooter';
import SummaryCardHeader from 'components/SummaryCardHeader/SummaryCardHeader';

import getSheetDescription from '../ImportInvoicesStepper.helper';

import { SummaryAndImportStepProps } from './SummaryAndImportStep.definitions';

function SummaryAndImportStep({
  selectedFile,
  sheets,
  isSaving = false,
  goToNextStep,
  goToPrevStep,
  t,
}: SummaryAndImportStepProps): FunctionComponentElement<SummaryAndImportStepProps> {
  const handleImportClick = () => {
    goToNextStep();
  };

  const handlePrevClick = () => {
    goToPrevStep();
  };

  return (
    <div className="formContent">
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <CardWrapper>
          <SummaryCardHeader header={t('fileSummary')} />
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t('file')}</TableCell>
                  <TableCell>{t('sheetsToBeImported')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell sx={{ verticalAlign: 'top' }}>
                    {selectedFile && (
                      <FileView fileName={selectedFile.name} fileSize={selectedFile.size} />
                    )}
                  </TableCell>
                  <TableCell>
                    {sheets.map((sheet) => (
                      <Fragment key={uuidv4()}>
                        {sheet.isSelected && <p>{getSheetDescription(sheet)}</p>}
                      </Fragment>
                    ))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardWrapper>
      </Box>
      <FormFooter>
        <Stack direction="row" spacing={3}>
          <Button variant="contained" disabled={isSaving} onClick={handlePrevClick}>
            {t('common:back')}
          </Button>
          <LoadingButton
            title={t('common:import')}
            isLoading={isSaving}
            onClick={handleImportClick}
          />
        </Stack>
      </FormFooter>
    </div>
  );
}

export default withTranslation('importInvoicesStepper')(SummaryAndImportStep);
