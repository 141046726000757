import { Typography } from '@mui/material';
import { FunctionComponentElement } from 'react';

import { SummaryCardHeaderProps } from './SummaryCardHeader.definitions';

function SummaryCardHeader({
  header,
}: SummaryCardHeaderProps): FunctionComponentElement<SummaryCardHeaderProps> {
  return (
    <Typography variant="h5" component="h3">
      {header}
    </Typography>
  );
}

export default SummaryCardHeader;
