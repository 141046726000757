import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Link, Typography } from '@mui/material';
import { GridColDef, GridPaginationModel } from '@mui/x-data-grid-premium';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { ExchangeRateModel, ExchangeRatesService } from 'api/masterdata';

import BdoDataGrid from 'components/BdoDataGrid/BdoDataGrid';
import { DataGridRow } from 'components/BdoDataGrid/Types/DataGridRow.definitions';

import { ExchangeRatesProps } from './ExchangeRates.definitions';

function ExchangeRates({ t }: ExchangeRatesProps): FunctionComponentElement<ExchangeRatesProps> {
  const columns: GridColDef[] = [
    { field: 'id', headerName: t('exchangeRateFields:id'), type: 'string', width: 150 },
    {
      field: 'year',
      headerName: `${t('exchangeRateFields:year')}`,
      type: 'string',
      editable: true,
      width: 100,
    },
    {
      field: 'targetCountryName',
      headerName: t('exchangeRateFields:targetCountryName'),
      type: 'string',
      editable: true,
      width: 150,
    },
    {
      field: 'sourceCurrencyCode',
      headerName: `${t('exchangeRateFields:sourceCurrencyCode')}`,
      type: 'string',
      editable: true,
      width: 150,
    },
    {
      field: 'targetCurrencyCode',
      headerName: `${t('exchangeRateFields:targetCurrencyCode')}`,
      type: 'string',
      editable: true,
      width: 200,
    },
    {
      field: 'januaryExchangeRate',
      headerName: t('exchangeRateFields:januaryExchangeRate'),
      type: 'number',
      editable: true,
      width: 100,
    },
    {
      field: 'februaryExchangeRate',
      headerName: t('exchangeRateFields:februaryExchangeRatee'),
      type: 'number',
      editable: true,
      width: 100,
    },
    {
      field: 'marchExchangeRate',
      headerName: t('exchangeRateFields:marchExchangeRate'),
      type: 'number',
      editable: true,
      width: 100,
    },
    {
      field: 'aprilExchangeRate',
      headerName: t('exchangeRateFields:aprilExchangeRate'),
      type: 'number',
      editable: true,
      width: 100,
    },
    {
      field: 'mayExchangeRate',
      headerName: t('exchangeRateFields:mayExchangeRate'),
      type: 'number',
      editable: true,
      width: 100,
    },
    {
      field: 'juneExchangeRate',
      headerName: t('exchangeRateFields:juneExchangeRate'),
      type: 'number',
      editable: true,
      width: 100,
    },
    {
      field: 'julyExchangeRate',
      headerName: t('exchangeRateFields:julyExchangeRate'),
      type: 'number',
      editable: true,
      width: 100,
    },
    {
      field: 'augustExchangeRate',
      headerName: t('exchangeRateFields:augustExchangeRate'),
      type: 'number',
      editable: true,
      width: 100,
    },
    {
      field: 'septemberExchangeRate',
      headerName: t('exchangeRateFields:septemberExchangeRate'),
      type: 'number',
      editable: true,
      width: 100,
    },
    {
      field: 'octoberExchangeRate',
      headerName: t('exchangeRateFields:octoberExchangeRate'),
      type: 'number',
      editable: true,
      width: 100,
    },
    {
      field: 'novemberExchangeRate',
      headerName: t('exchangeRateFields:novemberExchangeRate'),
      type: 'number',
      editable: true,
      width: 100,
    },
    {
      field: 'decemberExchangeRate',
      headerName: t('exchangeRateFields:decemberExchangeRate'),
      type: 'number',
      editable: true,
      width: 100,
    },
  ];

  const processRowDelete = async (row: DataGridRow): Promise<boolean> =>
    ExchangeRatesService.deleteApiExchangeRatesDeleteRow(row as ExchangeRateModel);

  const getRows = (pagination: GridPaginationModel) =>
    ExchangeRatesService.getApiExchangeRatesGetRows(pagination.page, pagination.pageSize);

  const getRowCount = () => ExchangeRatesService.getApiExchangeRatesGetRowCount();

  return (
    <div>
      <Typography
        variant="h6"
        sx={{ display: 'flex', px: 2, paddingTop: 1, paddingBottom: 0, fontWeight: 'medium' }}
      >
        {t('navigation:exchange-rates')}
      </Typography>
      <Typography variant="body2" sx={{ px: 2, py: 0 }}>
        <Link
          href={t('exchangeRateFields:sourceHref')}
          target="_blank"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {t('exchangeRateFields:sourceText')}{' '}
          <OpenInNewIcon sx={{ width: '1rem', marginLeft: '0.25rem' }} />
        </Link>
      </Typography>
      <Box sx={{ p: '1rem' }}>
        <BdoDataGrid
          columns={columns}
          processRowUpdate={async (row) => row}
          processRowDelete={processRowDelete}
          getRows={getRows}
          getRowCount={getRowCount}
          exportFileName={t('exchangeRateFields:exportFileName')}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                actions: false,
              },
            },
            sorting: {
              sortModel: [{ field: 'targetCountryName', sort: 'asc' }],
            },
            rowGrouping: {
              model: ['year'],
            },
          }}
        />
      </Box>
    </div>
  );
}

export default withTranslation(['exchangeRateFields', 'gridTooltips', 'navigation'])(ExchangeRates);
