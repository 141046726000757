import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AdvanceVatReturnSubmissionService } from 'api/submission';
import { AdvanceVatReturnsService } from 'api/taxCalculation';

import BdoTaxFormDataGrid from 'components/BdoTaxFormDataGrid/BdoTaxFormDataGrid';

import { AdvanceVatReturnProps } from './AdvanceVatReturn.definitions';

function AdvanceVatReturn({
  t,
}: AdvanceVatReturnProps): FunctionComponentElement<AdvanceVatReturnProps> {
  const { advanceVatReturnId, fillingPeriodId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const basePath = location.pathname.substring(0, location.pathname.indexOf('filling-period') - 1);

  const { mutate: createCorrection } = useMutation({
    mutationFn: (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      return AdvanceVatReturnSubmissionService.postApiAdvanceVatReturnSubmissionCreateCorrection(
        fillingPeriodId,
      );
    },
    onSuccess: (copiedPeriod) => {
      if (copiedPeriod) {
        queryClient.invalidateQueries({ queryKey: ['navigation'] });
        navigate(`${basePath}/filling-period/${copiedPeriod.id}`);
      }
    },
  });

  return advanceVatReturnId ? (
    <BdoTaxFormDataGrid
      title={t('navigation:advance-vat-return')}
      dataId={advanceVatReturnId}
      getData={() => AdvanceVatReturnsService.getApiAdvanceVatReturns(advanceVatReturnId)}
      getInvoices={(taxFormEntryId) =>
        AdvanceVatReturnsService.getApiAdvanceVatReturnsGetUsedInvoicesForCalculation(
          taxFormEntryId,
        )
      }
      exportFileName={t('gridForms:exportFileAdvanceVatReturn')}
      validateTaxForm={() =>
        AdvanceVatReturnSubmissionService.postApiAdvanceVatReturnSubmissionValidate(
          advanceVatReturnId,
        )
      }
      submitTaxForm={() =>
        AdvanceVatReturnSubmissionService.postApiAdvanceVatReturnSubmissionSubmit(
          advanceVatReturnId,
        )
      }
      createCorrection={createCorrection}
    />
  ) : (
    <div />
  );
}

export default withTranslation(['navigation', 'gridForms'])(AdvanceVatReturn);
