/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EcSalesListModel } from '../models/EcSalesListModel';
import type { InvoiceModel } from '../models/InvoiceModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EcSalesListsService {
  /**
   * Retrieves the EC Sales List for a given EC Sales List ID.
   * @param ecSalesListId The unique identifier of the EC Sales List to retrieve.
   * @returns EcSalesListModel Success
   * @throws ApiError
   */
  public static getApiEcSalesLists(ecSalesListId?: string): CancelablePromise<EcSalesListModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/EcSalesLists',
      query: {
        ecSalesListId: ecSalesListId,
      },
    });
  }

  /**
   * Retrieves a list of invoices used in the VAT return calculation for a specific tax form entry.
   * @param taxFormEntryId The ID of the tax form entry for which the invoices are to be retrieved.
   * @returns InvoiceModel Success
   * @throws ApiError
   */
  public static getApiEcSalesListsGetUsedInvoicesForCalculation(
    taxFormEntryId?: string,
  ): CancelablePromise<Array<InvoiceModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/EcSalesLists/GetUsedInvoicesForCalculation',
      query: {
        taxFormEntryId: taxFormEntryId,
      },
    });
  }
}
