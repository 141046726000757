/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientCreationForm } from '../models/ClientCreationForm';
import type { ClientModel } from '../models/ClientModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ClientsService {
  /**
   * Retrieves a list of clients associated with a specific user.
   * @param userId The unique identifier of the user.
   * @returns ClientModel Success
   * @throws ApiError
   */
  public static getApiClientsGetClientsForUser(
    userId?: string,
  ): CancelablePromise<Array<ClientModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Clients/GetClientsForUser',
      query: {
        userId: userId,
      },
    });
  }

  /**
   * Creates a new client based on the provided form data.
   * @param requestBody The form containing the data needed to create a new client.
   * @returns any The client was successfully created.
   * @throws ApiError
   */
  public static postApiClients(requestBody?: ClientCreationForm): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Clients',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `The provided form data contains validation errors.`,
      },
    });
  }

  /**
   * Retrieves paginated rows of clients.
   * @param page The page number of the data set to retrieve.
   * @param pageSize The size of the page to retrieve.
   * @returns ClientModel Success
   * @throws ApiError
   */
  public static getApiClientsGetRows(
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<ClientModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Clients/GetRows',
      query: {
        page: page,
        pageSize: pageSize,
      },
    });
  }

  /**
   * Retrieves the total count of clients
   * @returns number Success
   * @throws ApiError
   */
  public static getApiClientsGetRowCount(): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Clients/GetRowCount',
    });
  }
}
