/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TaxRuleModel } from '../models/TaxRuleModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaxRulesService {
  /**
   * Gets the list of all tax rules.
   * @returns TaxRuleModel Success
   * @throws ApiError
   */
  public static getApiTaxRules(): CancelablePromise<Array<TaxRuleModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxRules',
    });
  }

  /**
   * Retrieves paginated rows of tax rules.
   * @param page The page number for the data set to retrieve.
   * @param pageSize The size of the page to retrieve.
   * @returns TaxRuleModel Success
   * @throws ApiError
   */
  public static getApiTaxRulesGetRows(
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<TaxRuleModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxRules/GetRows',
      query: {
        page: page,
        pageSize: pageSize,
      },
    });
  }

  /**
   * Retrieves the total count of tax rules.
   * @returns number Success
   * @throws ApiError
   */
  public static getApiTaxRulesGetRowCount(): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxRules/GetRowCount',
    });
  }

  /**
   * Adds a new tax rule row.
   * @param requestBody The TaxRuleModel object representing the new tax rule to add.
   * @returns TaxRuleModel Success
   * @throws ApiError
   */
  public static postApiTaxRulesAddRow(requestBody?: TaxRuleModel): CancelablePromise<TaxRuleModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/TaxRules/AddRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Updates an existing tax rule row.
   * @param requestBody The TaxRuleModel object with updated information.
   * @returns TaxRuleModel Success
   * @throws ApiError
   */
  public static putApiTaxRulesGetRow(requestBody?: TaxRuleModel): CancelablePromise<TaxRuleModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/TaxRules/GetRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Deletes a tax rule if it's not referenced anymore.
   * @param requestBody The tax rule model to delete.
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteApiTaxRulesDeleteRow(requestBody?: TaxRuleModel): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/TaxRules/DeleteRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
