import { TFunction } from 'i18next';

import { NavigationEntry } from 'api/system';

import NavListTypeToRoute from 'enums/NavListTypeToRoute';

export const initialPadding = 2;

export const getValueByKeyForStringEnum = (value: string) =>
  Object.entries(NavListTypeToRoute).find(([key, val]) => key === value)?.[1];

export const getRouteFromItemType = (item: NavigationEntry): string => {
  const { type } = item;
  return type ? getValueByKeyForStringEnum(type) || '' : '';
};

export const getChildPath = (parentPath: string, child: NavigationEntry): string => {
  const { type } = child;
  const childRoute = type ? getValueByKeyForStringEnum(type) || '' : '';
  const pathWithParentPath = childRoute ? `${parentPath}${childRoute}` : `${parentPath}`;
  const pathWithId = child.id ? `${pathWithParentPath}/${child.id}` : pathWithParentPath;

  return pathWithId;
};

export const getParentPath = (parent: NavigationEntry): string => {
  const { type } = parent;
  const parentRoute = type ? getValueByKeyForStringEnum(type) || '' : '';
  const pathWithId = parent.id ? `${parentRoute}/${parent.id}` : `${parentRoute}`;
  return pathWithId;
};

export const getDisplayText = (
  item: NavigationEntry,
  t: TFunction<'translation', undefined>,
): string =>
  `${item.prefix && t(item.prefix)} ${item.value && t(item.value)} ${
    item.postfix && t(item.postfix)
  }`;
