import { Button, ButtonProps, Grid, createSvgIcon } from '@mui/material';
import {
  GridCsvGetRowsToExportParams,
  GridExcelExportOptions,
  GridRowModelUpdate,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  gridFilteredSortedRowIdsSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { useQueryClient } from '@tanstack/react-query';
import { FunctionComponentElement, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import AddRecordToolbarEntry from './AddRecordToolbarEntry';
import { BdoDataGridToolbarProps } from './BdoDataGridToolbarProps.definitions';

const getFilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
  gridFilteredSortedRowIdsSelector(apiRef);

const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  'SaveAlt',
);

function BdoDataGridToolbar({
  addRecordToolbarEntryProps,
  excelExportProps,
  t,
}: BdoDataGridToolbarProps): FunctionComponentElement<BdoDataGridToolbarProps> {
  const apiRef = useGridApiContext();
  const queryClient = useQueryClient();

  const handleExport = async (options: GridExcelExportOptions) => {
    await queryClient.refetchQueries({ queryKey: ['getGridRows'], type: 'active' });

    const data = queryClient.getQueriesData({ queryKey: ['getGridRows'] });
    // eslint-disable-next-line no-console
    console.log(data);
    if (data[0] && data[0][1]) {
      const updatedRows = data[0][1] as GridRowModelUpdate[];

      apiRef.current.updateRows(updatedRows);
      apiRef.current.forceUpdate();
    }

    apiRef.current.exportDataAsExcel(options);
  };

  const buttonBaseProps: ButtonProps = {
    color: 'primary',
    size: 'small',
    startIcon: <ExportIcon />,
  };

  const [newRecordEnabled, setNewRecordEnabled] = useState(
    addRecordToolbarEntryProps?.addDefaultRecord !== undefined,
  );

  useEffect(() => {
    setNewRecordEnabled(
      addRecordToolbarEntryProps?.addDefaultRecord !== undefined ||
        addRecordToolbarEntryProps?.addDefaultRecord != null,
    );
  }, [addRecordToolbarEntryProps]);

  return (
    <GridToolbarContainer>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs="auto">
          {newRecordEnabled && addRecordToolbarEntryProps ? (
            <AddRecordToolbarEntry {...addRecordToolbarEntryProps} />
          ) : (
            <div />
          )}
          <Button
            {...buttonBaseProps}
            onClick={() => handleExport({ ...excelExportProps, getRowsToExport: getFilteredRows })}
          >
            {t('export')}
          </Button>
        </Grid>
        <Grid item xs={2}>
          <GridToolbarQuickFilter />
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}

export default withTranslation('bdoDataGrid')(BdoDataGridToolbar);
