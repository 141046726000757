import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Box, Button, ButtonGroup, CircularProgress, Tooltip } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { FunctionComponentElement, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { ReviewState } from 'api/taxCalculation';

import useStore from 'context/Store/Store.hooks';

import Severity from 'enums/Severity';

import { GridReviewStateButtonGroupProps } from './GridReviewStateButtonGroup.definitions';

function GridReviewStateButtonGroup({
  rowId,
  reviewState,
  rows,
  updateRows,
  updateReviewStateAsync,
  isDisabled = false,
  t,
}: GridReviewStateButtonGroupProps): FunctionComponentElement<GridReviewStateButtonGroupProps> {
  const [isMutating, setIsMutating] = useState(false);
  const [selectedReviewState, setSelectedReviewState] = useState<ReviewState>(reviewState);
  const { toastStore } = useStore();

  useEffect(() => setSelectedReviewState(reviewState), [reviewState]);

  const { mutate } = useMutation({
    mutationFn: async () => {
      const response = await updateReviewStateAsync(rowId, selectedReviewState);
      return response;
    },
    onSuccess: (updatedRow) => {
      toastStore.addToast({
        id: uuidv4(),
        title: t('notifications:notification-row-entry-updated'),
        severity: Severity.Info,
      });
      const updatedRows = rows.map((row) => (row.id === updatedRow.id ? updatedRow : row));
      updateRows(updatedRows);
    },
    onMutate: () => setIsMutating(true),
    onSettled: () => setIsMutating(false),
    mutationKey: ['changeReviewState'],
  });

  const handleReviewStateClick = async (selected: ReviewState): Promise<void> => {
    if (reviewState === selected) {
      return;
    }
    setSelectedReviewState(selected);
    mutate();
  };

  return (
    <ButtonGroup
      variant="contained"
      aria-label="Basic button group"
      disabled={isMutating || isDisabled}
    >
      <Tooltip title={t('gridForms:pending')} placement="bottom">
        <Box sx={{ width: 'inherit', height: 'inherit' }}>
          <Button
            variant={selectedReviewState === 0 ? 'contained' : 'outlined'}
            sx={{
              height: '100%',
              backgroundColor: (theme) =>
                selectedReviewState === 0 ? theme.palette.primary.main : 'transparent',
              padding: 0.5,
            }}
            onClick={() => handleReviewStateClick(ReviewState._0)}
          >
            {selectedReviewState === 0 && isMutating ? (
              <CircularProgress sx={{ color: (theme) => theme.palette.primary.main }} size="1rem" />
            ) : (
              <ScheduleIcon />
            )}
          </Button>
        </Box>
      </Tooltip>
      <Tooltip title={t('gridForms:approved')} placement="bottom">
        <Box sx={{ width: 'inherit', height: 'inherit' }}>
          <Button
            variant={selectedReviewState === 1 ? 'contained' : 'outlined'}
            sx={{
              height: '100%',
              backgroundColor: (theme) =>
                selectedReviewState === 1 ? theme.palette.primary.main : 'transparent',
              padding: 0.5,
            }}
            onClick={() => handleReviewStateClick(ReviewState._1)}
          >
            {selectedReviewState === 1 && isMutating ? (
              <CircularProgress sx={{ color: (theme) => theme.palette.primary.main }} size="1rem" />
            ) : (
              <CheckCircleOutlineIcon />
            )}
          </Button>
        </Box>
      </Tooltip>
      <Tooltip title={t('gridForms:rejected')} placement="bottom">
        <Box sx={{ width: 'inherit', height: 'inherit' }}>
          <Button
            variant={selectedReviewState === 2 ? 'contained' : 'outlined'}
            sx={{
              height: '100%',
              backgroundColor: (theme) =>
                selectedReviewState === 2 ? theme.palette.primary.main : 'transparent',
              padding: 0.5,
            }}
            onClick={() => handleReviewStateClick(ReviewState._2)}
          >
            {selectedReviewState === 2 && isMutating ? (
              <CircularProgress sx={{ color: (theme) => theme.palette.primary.main }} size="1rem" />
            ) : (
              <HighlightOffIcon />
            )}
          </Button>
        </Box>
      </Tooltip>
    </ButtonGroup>
  );
}

export default withTranslation('gridForms')(GridReviewStateButtonGroup);
