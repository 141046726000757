import { Box } from '@mui/system';
import { FunctionComponentElement, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { InvoicesService } from 'api/invoices';

import InvoicesGrid from 'components/InvoicesGrid/InvoicesGrid';

import { CompanyInvoicesProps } from './CompanyInvoices.definitions';

function CompanyInvoices({
  company,
  t,
}: CompanyInvoicesProps): FunctionComponentElement<CompanyInvoicesProps> {
  const { companyId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getRowCountQuery = async () => {
      const response = await InvoicesService.getApiInvoicesGetRowCount(companyId);

      if (response === 0) {
        navigate('import-invoices');
      }
    };

    getRowCountQuery();
  }, [companyId, navigate]);

  return (
    <Box sx={{ p: '1rem' }}>
      <InvoicesGrid key={companyId} companyId={companyId} />
    </Box>
  );
}

export default withTranslation([
  'navigation',
  'invoice',
  'businessPartner',
  'gridTooltips',
  'notifications',
  'companyFields',
])(CompanyInvoices);
