/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TaxCodeModel } from '../models/TaxCodeModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaxCodesService {
  /**
   * Gets the list of all tax codes.
   * @returns TaxCodeModel Success
   * @throws ApiError
   */
  public static getApiTaxCodes(): CancelablePromise<Array<TaxCodeModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxCodes',
    });
  }

  /**
   * Retrieves paginated rows of tax codes.
   * @param page The page number of the data set to retrieve.
   * @param pageSize The size of the page to retrieve.
   * @returns TaxCodeModel Success
   * @throws ApiError
   */
  public static getApiTaxCodesGetRows(
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<TaxCodeModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxCodes/GetRows',
      query: {
        page: page,
        pageSize: pageSize,
      },
    });
  }

  /**
   * Retrieves the total count of tax code rows.
   * @returns number Success
   * @throws ApiError
   */
  public static getApiTaxCodesGetRowCount(): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxCodes/GetRowCount',
    });
  }

  /**
   * Adds a new tax code row.
   * @param requestBody The tax code model to add.
   * @returns TaxCodeModel Success
   * @throws ApiError
   */
  public static postApiTaxCodesAddRow(requestBody?: TaxCodeModel): CancelablePromise<TaxCodeModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/TaxCodes/AddRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Updates an existing tax code row.
   * @param requestBody The tax code model with updated information.
   * @returns TaxCodeModel Success
   * @throws ApiError
   */
  public static putApiTaxCodesUpdateRow(
    requestBody?: TaxCodeModel,
  ): CancelablePromise<TaxCodeModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/TaxCodes/UpdateRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Deletes a tax code if it's not referenced anymore.
   * @param requestBody The tax code model to delete.
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteApiTaxCodesDeleteRow(requestBody?: TaxCodeModel): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/TaxCodes/DeleteRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
