enum NamedApprovalStatus {
  InEditing = 0,
  FirstSupervisorApprovalRequested = 1,
  SecondSupervisorApprovalRequested = 2,
  ClientApprovalRequested = 3,
  ApprovalCompleted = 4,
  CorrectionCreated = 5,
}

export default NamedApprovalStatus;
