import { Box, Typography } from '@mui/material';
import {
  GridColDef,
  GridPaginationModel,
  GridPreProcessEditCellProps,
  GridRowModel,
} from '@mui/x-data-grid-premium';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { CountriesService, CountryModel } from 'api/masterdata';

import GridFieldsValidator from 'shared/utils/validators/GridFieldsValidator';

import BdoDataGrid from 'components/BdoDataGrid/BdoDataGrid';
import RenderBasicEditCell from 'components/BdoDataGrid/Cells/BasicEditCell';
import { DataGridRow } from 'components/BdoDataGrid/Types/DataGridRow.definitions';

import { CountriesProps } from './Countries.definitions';

function Countries({ t }: CountriesProps): FunctionComponentElement<CountriesProps> {
  const columns: GridColDef[] = [
    { field: 'id', headerName: t('countryFields:id'), type: 'string', width: 150 },
    {
      field: 'name',
      headerName: `${t('countryFields:name')} *`,
      type: 'string',
      editable: true,
      width: 200,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'isoAlpha2Code',
      headerName: `${t('countryFields:isoAlpha2Code')} *`,
      type: 'string',
      editable: true,
      width: 200,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) =>
        GridFieldsValidator.validateExactLength(params, 2),
    },
    {
      field: 'isoAlpha3Code',
      headerName: t('countryFields:isoAlpha3Code'),
      type: 'string',
      editable: true,
      width: 200,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) =>
        GridFieldsValidator.validateMaxLength(params, 3),
    },
    {
      field: 'isoNumericCode',
      headerName: `${t('countryFields:isoNumericCode')} *`,
      type: 'string',
      editable: true,
      width: 200,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) =>
        GridFieldsValidator.validateNumberRange(params, 10, 999),
    },
  ];

  const addDefaultRecord = (id: string): GridRowModel => {
    const country: CountryModel = {
      id,
      name: '',
      isoAlpha2Code: '',
      isoAlpha3Code: '',
      isoNumericCode: undefined,
    };

    return country;
  };

  const processRowUpdate = async (row: DataGridRow): Promise<DataGridRow> => {
    let resultRow;

    if (row.isNew) {
      resultRow = await CountriesService.postApiCountries(row as unknown as CountryModel);
    } else {
      resultRow = await CountriesService.putApiCountriesUpdateRow(row as unknown as CountryModel);
    }

    return resultRow as unknown as DataGridRow;
  };

  const processRowDelete = async (row: DataGridRow): Promise<boolean> =>
    CountriesService.deleteApiCountriesDeleteRow(row as unknown as CountryModel);

  const getRows = (pagination: GridPaginationModel) =>
    CountriesService.getApiCountriesGetRows(pagination.page, pagination.pageSize);

  const getRowCount = () => CountriesService.getApiCountriesGetRowCount();

  return (
    <div>
      <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
        {t('navigation:countries')}
      </Typography>
      <Box sx={{ p: '1rem' }}>
        <BdoDataGrid
          columns={columns}
          getRows={getRows}
          getRowCount={getRowCount}
          processRowUpdate={processRowUpdate}
          processRowDelete={processRowDelete}
          addDefaultRecord={addDefaultRecord}
          exportFileName={t('countryFields:exportFileName')}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
          }}
        />
      </Box>
    </div>
  );
}
export default withTranslation(['countryFields', 'gridTooltips', 'navigation'])(Countries);
