import { observer } from 'mobx-react-lite';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import useStore from 'context/Store/Store.hooks';

import FillingPeriodActions from 'features/FillingPeriodActions/FillingPeriodActions';

import NoDrawerContent from 'components/NoDrawerContent/NoDrawerContent';

import { SpecialPrepaymentDrawerProps } from './SpecialPrepaymentDrawer.definitions';

function SpecialPrepaymentDrawer({
  t,
}: SpecialPrepaymentDrawerProps): FunctionComponentElement<SpecialPrepaymentDrawerProps> {
  const {
    rightDrawerStore: { isActionsDrawer, isCommentsDrawer },
  } = useStore();

  return (
    <>
      {isActionsDrawer && <FillingPeriodActions />}
      {isCommentsDrawer && <NoDrawerContent text={t('noCommentsAvailable')} />}
    </>
  );
}

export default withTranslation('common')(observer(SpecialPrepaymentDrawer));
