import { Box } from '@mui/material';
import { FunctionComponentElement } from 'react';

import { FormFooterProps } from './FormFooter.definitions';

function FormFooter({ children }: FormFooterProps): FunctionComponentElement<FormFooterProps> {
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'end',
      }}
    >
      {children}
    </Box>
  );
}

export default FormFooter;
