/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FieldMappingModel } from '../models/FieldMappingModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FieldMappingsService {
  /**
   * Retrieves a paginated list of field mappings for a specific import setting.
   * @param importSettingsId The unique identifier of the import setting.
   * @param page The page number of the result set to retrieve.
   * @param pageSize The number of items per page.
   * @returns FieldMappingModel Success
   * @throws ApiError
   */
  public static getApiFieldMappingsGetRows(
    importSettingsId?: string,
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<FieldMappingModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/FieldMappings/GetRows',
      query: {
        importSettingsId: importSettingsId,
        page: page,
        pageSize: pageSize,
      },
    });
  }

  /**
   * Gets the total number of field mappings for a specific import setting.
   * @param importSettingsId The unique identifier of the import setting.
   * @returns number Success
   * @throws ApiError
   */
  public static getApiFieldMappingsGetRowCount(
    importSettingsId?: string,
  ): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/FieldMappings/GetRowCount',
      query: {
        importSettingsId: importSettingsId,
      },
    });
  }

  /**
   * Adds a new field mapping.
   * @param requestBody The field mapping model to add.
   * @returns FieldMappingModel Success
   * @throws ApiError
   */
  public static postApiFieldMappingsAddRow(
    requestBody?: FieldMappingModel,
  ): CancelablePromise<FieldMappingModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/FieldMappings/AddRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Updates an existing field mapping and every copied field mapping.
   * @param requestBody The field mapping model with updated information.
   * @returns FieldMappingModel Success
   * @throws ApiError
   */
  public static putApiFieldMappingsUpdateRow(
    requestBody?: FieldMappingModel,
  ): CancelablePromise<FieldMappingModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/FieldMappings/UpdateRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Deletes a field mapping.
   * @param requestBody The field mapping model to delete.
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteApiFieldMappingsDeleteRow(
    requestBody?: FieldMappingModel,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/FieldMappings/DeleteRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
