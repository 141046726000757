import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  List,
  ListItemButton,
  ListSubheader,
  Stack,
} from '@mui/material';
import { FunctionComponentElement } from 'react';
import { Controller, get, useFormContext } from 'react-hook-form';
import { withTranslation } from 'react-i18next';

import { UserRoleSelectionProps } from './UserRoleSelection.definitions';

function UserRoleSelection({
  fieldName,
  availableRoles,
  isRequired = false,
  isDisabled = false,
  t,
}: UserRoleSelectionProps): FunctionComponentElement<UserRoleSelectionProps> {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Stack>
      <List
        sx={{
          width: '100%',
          maxWidth: 360,
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'auto',
          maxHeight: 260,
          border: '1px solid',
          borderColor: (theme) => theme.palette.grey[400],
        }}
        subheader={
          <>
            <ListSubheader
              component="div"
              id="available-roles"
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              {t('userFields:selectRoles')}
            </ListSubheader>
            <Divider sx={{ position: 'sticky' }} />
          </>
        }
      >
        <FormControl
          component="fieldset"
          required={isRequired}
          error={!!(errors && get(errors, fieldName))}
          sx={{ width: '100%' }}
          disabled={isDisabled}
        >
          <FormGroup>
            <Controller
              name={fieldName}
              control={control}
              rules={{
                required: {
                  value: isRequired,
                  message: t('fieldsValidation:rolesHelperText'),
                },
              }}
              render={({ field }) => (
                <div>
                  {availableRoles.map((role) => (
                    <ListItemButton key={role} dense>
                      <FormControlLabel
                        {...field}
                        key={role}
                        label={t(`keycloakRoles:${role}`)}
                        control={
                          <Checkbox
                            checked={field.value?.includes(role)}
                            onChange={() => {
                              if (!field.value?.includes(role)) {
                                field.onChange([...field.value, role]);
                                return;
                              }
                              const newRoles = field.value.filter((item: string) => item !== role);
                              field.onChange(newRoles);
                            }}
                          />
                        }
                      />
                    </ListItemButton>
                  ))}
                </div>
              )}
            />
          </FormGroup>
        </FormControl>
      </List>
      <FormHelperText
        sx={{ mx: 2 }}
        required={isRequired}
        error={!!(errors && get(errors, fieldName))}
      >
        {errors && get(errors, fieldName)?.message}
      </FormHelperText>
    </Stack>
  );
}

export default withTranslation(['userFields', 'keycloakRoles', 'fieldsValidation'])(
  UserRoleSelection,
);
