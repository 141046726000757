import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useState } from 'react';
import { withTranslation } from 'react-i18next';

import { DeletionApprovalDialogProps } from './DeletionApprovalDialog.definitions';

function DeletionApprovalDialog({
  open,
  title,
  message,
  switchLabel,
  handleClose,
  handleFormSubmit,
  t,
}: DeletionApprovalDialogProps) {
  const [approveDeletion, setApproveDeletion] = useState<boolean>(false);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApproveDeletion(event.target.checked);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: handleFormSubmit,
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>

        {switchLabel && (
          <FormControlLabel
            control={<Switch checked={approveDeletion} onChange={handleSwitchChange} />}
            label={switchLabel}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('dialogs:dialog-btn-cancel')}</Button>
        <Button disabled={switchLabel !== undefined && !approveDeletion} type="submit">
          {t('dialogs:dialog-btn-delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withTranslation()(DeletionApprovalDialog);
