import { Description } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import {
  DataGridPremium,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowClassNameParams,
  GridRowModel,
  GridRowsProp,
  GridValueGetterParams,
  gridClasses,
} from '@mui/x-data-grid-premium';
import { useQuery } from '@tanstack/react-query';
import { ChangeEvent, FunctionComponentElement, Suspense, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { withTranslation } from 'react-i18next';
import DataGridBox from 'styled-components/DataGridBox';
import { v4 as uuidv4 } from 'uuid';

import { FileModel, FilesService } from 'api/files';
import { TaxFormEntryModel, TaxFormEntryType } from 'api/taxCalculation';

import useStore from 'context/Store/Store.hooks';
import { dateReplacer } from 'shared/utils/date.helper';
import { logError } from 'shared/utils/error.helper';

import GridRevertAndInvoicesButtonGroup from 'features/GridRevertAndInvoicesButtonGroup/GridRevertAndInvoicesButtonGroup';
import GridReviewStateButtonGroup from 'features/GridReviewStateButtonGroup/GridReviewStateButtonGroup';
import InvoiceListView from 'features/InvoiceListView/InvoiceListView';
import SubmissionFileView from 'features/SubmissionFileView/SubmissionFileView';
import SubmissionLogView from 'features/SubmissionLogView/SubmissionLogView';
import SubmissionValidationsView from 'features/SubmissionValidationsView/SubmissionValidationsView';

import BdoDataGridToolbar from 'components/BdoDataGrid/Toolbar/BdoDataGridToolbar';
import ApprovalWorkflowMenu from 'components/Buttons/ApprovalWorkflowMenu/ApprovalWorkflowMenu';
import RecalculationDropdownButton from 'components/Buttons/RecalculationDropdownButton/RecalculationDropdownButton';
import ValidationWorkflowButton from 'components/Buttons/ValidationWorkflowButton/ValidationWorkflowButton';
import CardWrapper from 'components/CardWrapper/CardWrapper';
import CenteredLoader from 'components/CenteredLoader/CenteredLoader';
import FallbackComponent from 'components/FallbackComponent/FallbackComponent';
import Loader from 'components/Loader/Loader';
import PopupDataDisplayIcon from 'components/PopupDataDisplayIcon/PopupDataDisplayIcon';
import PopupModal from 'components/PopupModal/PopupModal';
import ValidationStateIcon from 'components/ValidationStateIcon/ValidationStateIcon';

import NamedApprovalStatus from 'enums/NamedApprovalStatus';
import NamedEditStatus from 'enums/NamedEditStatus';
import Severity from 'enums/Severity';
import TaxFormSectionType from 'enums/TaxFormSectionType';

import getColumns from './BdoTaxFormDataGrid.columns';
import { BdoTaxFormDataGridProps } from './BdoTaxFormDataGrid.definitions';
import {
  entryTypeWithAmounts,
  getRowsWithValues,
  initializeRows,
  postOverwriting,
  revertOverwriting,
  taxFormSectionTypes,
  updateReviewState,
} from './BdoTaxFormDataGrid.helper';

function BdoTaxFormDataGrid({
  title,
  dataId,
  getData,
  getInvoices,
  exportFileName,
  validateTaxForm,
  submitTaxForm,
  createCorrection,
  t,
}: BdoTaxFormDataGridProps): FunctionComponentElement<BdoTaxFormDataGridProps> {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [rowsWithValue, setRowsWithValue] = useState<GridRowsProp>([]);
  const [showRowsWithValue, setShowRowsWithValue] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [logsModalOpen, setLogsModalOpen] = useState<boolean>(false);
  const [submissionValidationsViewModalOpen, setSubmissionValidationsViewModalOpen] =
    useState<boolean>(false);
  const [pdfModalOpen, setPdfModalOpen] = useState<boolean>(false);
  const [invoicesEntryId, setInvoicesEntryId] = useState<string>('');
  const [isReadOnlyMode, setIsReadOnlyMode] = useState<boolean>(false);
  const [isReviewStatusReadOnly, setIsReviewStatusReadOnly] = useState<boolean>(false);

  const { toastStore } = useStore();
  const baseColumns = getColumns(isReadOnlyMode);

  const [submissionResultFiles, setSubmissionResultFiles] = useState<FileModel[]>([]);

  const { isFetching, data } = useQuery({
    queryKey: ['BdoFormDataGrid', dataId],
    queryFn: async () => {
      const response = await getData();

      return response;
    },
  });

  useEffect(() => {
    if (data) {
      const allRows = initializeRows(data);
      setRows(allRows);
      setIsReadOnlyMode(Number(data?.approvalStatus) !== NamedApprovalStatus.InEditing);
      setIsReviewStatusReadOnly(Number(data?.editStatus) !== NamedEditStatus.Open);
    }
  }, [data]);

  useEffect(() => {
    const valueRows = getRowsWithValues(rows);
    setRowsWithValue(valueRows);
  }, [rows]);

  useEffect(() => {
    const fetchFiles = async () => {
      if (data?.submissionResults) {
        const filesPromises = data.submissionResults.map((result) =>
          FilesService.getApiFilesGetFilesByReference(result.id),
        );
        const filesArray = await Promise.all(filesPromises);
        setSubmissionResultFiles(filesArray.flat());
      }
    };

    fetchFiles();
  }, [data?.submissionResults]);

  const handleProcessRowUpdate = async (
    updatedRow: GridRowModel,
    oldRow?: GridRowModel,
  ): Promise<GridRowModel> => {
    if (
      oldRow &&
      JSON.stringify(oldRow, dateReplacer) === JSON.stringify(updatedRow, dateReplacer)
    ) {
      return updatedRow;
    }

    const processedRow = await postOverwriting(updatedRow);

    toastStore.addToast({
      id: uuidv4(),
      title: t('notifications:notification-row-entry-updated'),
      severity: Severity.Info,
    });

    const updatedRows = rows.map((row) => (row.id === updatedRow.id ? processedRow : row));
    setRows(updatedRows);
    return processedRow;
  };

  const handleModalOpen = (entryId: string): void => {
    setModalOpen(true);
    setInvoicesEntryId(entryId);
  };

  const handleSwitch = (event: ChangeEvent<HTMLInputElement>): void => {
    const { checked } = event.target;
    setShowRowsWithValue(checked);
  };

  const columns: GridColDef[] = [
    ...baseColumns,
    {
      field: 'reviewState',
      width: 180,
      align: 'center',
      disableExport: true,
      renderCell: (params: GridRenderCellParams<TaxFormEntryModel>) => {
        const {
          row: { id, configuration },
        } = params;

        if (!id || !configuration) {
          return null;
        }

        if (configuration.id === TaxFormSectionType.Tax) {
          return <div>{params.value}</div>;
        }

        //  Viewable only for amount types
        if (
          configuration.taxFormEntryType !== undefined &&
          entryTypeWithAmounts.includes(configuration.taxFormEntryType)
        ) {
          return (
            <GridReviewStateButtonGroup
              key={id}
              rowId={id}
              reviewState={params.value}
              rows={rows}
              updateRows={(updatedRows: GridRowsProp) => setRows(updatedRows)}
              updateReviewStateAsync={updateReviewState}
              isDisabled={isReviewStatusReadOnly}
            />
          );
        }

        return null;
      },
      valueGetter: (params: GridValueGetterParams<TaxFormEntryModel>) => {
        const {
          row: { configuration, reviewState },
        } = params;

        if (!configuration) {
          return null;
        }

        if (configuration.id === TaxFormSectionType.Tax) {
          return t('gridForms:reviewState');
        }

        return reviewState;
      },
    },
    {
      field: 'revertOriginalAndShowInvoices',
      width: 120,
      align: 'center',
      disableExport: true,
      renderCell: (params: GridRenderCellParams<TaxFormEntryModel>) => {
        const {
          row: { id, configuration },
        } = params;

        if (!id || !configuration) {
          return null;
        }

        if (configuration.id === TaxFormSectionType.Tax) {
          return <div>{t('gridForms:actions')}</div>;
        }

        // Viewable only for amount types
        if (
          configuration.taxFormEntryType !== undefined &&
          entryTypeWithAmounts.includes(configuration.taxFormEntryType)
        ) {
          return (
            <GridRevertAndInvoicesButtonGroup
              key={id}
              params={params}
              rows={rows}
              updateRows={(updatedRows: GridRowsProp) => setRows(updatedRows)}
              handleShowInvoicesClick={handleModalOpen}
              revertOverwritingAsync={revertOverwriting}
              isDisabled={isReadOnlyMode}
            />
          );
        }

        return null;
      },
    },
  ];

  return (
    <>
      {isFetching ? (
        <CenteredLoader />
      ) : (
        <Suspense fallback={<CenteredLoader />}>
          {columns && rows.length > 0 && (
            <Box sx={{ p: '1rem' }}>
              <CardWrapper>
                <Stack direction="row" spacing={2} sx={{ mb: 2, justifyContent: 'space-between' }}>
                  <Typography variant="h6">{title}</Typography>

                  <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                    sx={{ maxHeight: '2rem' }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          inputProps={{ 'aria-label': 'ShowNullValues' }}
                          checked={showRowsWithValue}
                          onChange={handleSwitch}
                        />
                      }
                      label={t('gridForms:emptyValuesToggle')}
                    />

                    <RecalculationDropdownButton
                      submissionId={dataId}
                      approvalStatus={Number(data?.approvalStatus)}
                    />

                    <ValidationWorkflowButton
                      submissionId={dataId}
                      approvalStatus={Number(data?.approvalStatus)}
                      validateTaxForm={validateTaxForm}
                    />
                    <ApprovalWorkflowMenu
                      submissionId={dataId}
                      approvalStatus={Number(data?.approvalStatus)}
                      validationState={Number(data?.validationState)}
                      submitTaxForm={submitTaxForm}
                      validateTaxForm={validateTaxForm}
                      createCorrection={createCorrection}
                    />
                    <PopupDataDisplayIcon
                      handleClick={() => setPdfModalOpen(true)}
                      activeTitle={t('submissions:tooltip-pdf-active')}
                      inactiveTitle={t('submissions:tooltip-pdf-inactive')}
                      hasData={!!(submissionResultFiles && submissionResultFiles.length > 0)}
                    >
                      <Description />
                    </PopupDataDisplayIcon>
                    <PopupDataDisplayIcon
                      handleClick={() => setSubmissionValidationsViewModalOpen(true)}
                      activeTitle={t('submissions:tooltip-validation-messages-active')}
                      inactiveTitle={t('submissions:tooltip-validation-messages-inactive')}
                      hasData={!!(data?.submissionResults && data.submissionResults.length > 0)}
                    >
                      <ValidationStateIcon
                        validationState={data?.validationState}
                        submissionStatus={data?.submissionStatus}
                      />
                    </PopupDataDisplayIcon>
                  </Stack>
                </Stack>

                <DataGridBox sx={{ height: '100%', width: '100%' }}>
                  <DataGridPremium
                    loading={isFetching}
                    rows={showRowsWithValue ? rowsWithValue : rows}
                    columns={columns}
                    processRowUpdate={handleProcessRowUpdate}
                    hideFooter
                    columnHeaderHeight={0}
                    autoHeight
                    autosizeOptions={{
                      columns: ['keyFigureForAmount'],
                      includeOutliers: true,
                      includeHeaders: false,
                    }}
                    autosizeOnMount
                    slots={{
                      toolbar: BdoDataGridToolbar,
                    }}
                    slotProps={{
                      toolbar: {
                        excelExportProps: {
                          fileName: exportFileName,
                        },
                        showQuickFilter: true,
                      },
                    }}
                    getRowHeight={() => 'auto'}
                    isCellEditable={(params: GridCellParams<TaxFormEntryModel>) =>
                      !isReadOnlyMode &&
                      !(
                        params.row.calculation?.description === 'Berichtige Anmeldung' &&
                        data?.isCorrection
                      )
                    }
                    getRowClassName={(params: GridRowClassNameParams<TaxFormEntryModel>) => {
                      const {
                        row: { configuration },
                      } = params;

                      if (!configuration) {
                        return '';
                      }

                      // Section header rows
                      if (configuration.taxFormEntryType === TaxFormEntryType._0) {
                        return 'sectionHeaderRow';
                      }

                      // Group header rows
                      if (configuration.id && taxFormSectionTypes.includes(configuration.id)) {
                        return 'groupHeaderRow';
                      }

                      // Other rows
                      return 'greyRow';
                    }}
                    sx={{
                      [`& .${gridClasses.cell}`]: {
                        py: 1,
                      },
                      '& .MuiDataGrid-cell:focus': {
                        outline: 'none',
                      },
                    }}
                  />
                </DataGridBox>
              </CardWrapper>
            </Box>
          )}
        </Suspense>
      )}
      {getInvoices && (
        <ErrorBoundary onError={logError} FallbackComponent={FallbackComponent}>
          <Suspense fallback={<Loader />}>
            <PopupModal
              key={invoicesEntryId}
              title={t('invoice:invoices')}
              open={modalOpen}
              handleClose={() => setModalOpen(false)}
            >
              <InvoiceListView taxFormEntryModelId={invoicesEntryId} getInvoices={getInvoices} />
            </PopupModal>
          </Suspense>
        </ErrorBoundary>
      )}
      {data?.submissionResults && (
        <>
          <PopupModal
            title={t('submissionMessages:logTitle')}
            open={logsModalOpen}
            handleClose={() => setLogsModalOpen(false)}
          >
            <SubmissionLogView submissionResults={data.submissionResults} />
          </PopupModal>
          <PopupModal
            title={t('submissionMessages:validationTitle')}
            open={submissionValidationsViewModalOpen}
            handleClose={() => setSubmissionValidationsViewModalOpen(false)}
          >
            <SubmissionValidationsView submissionResults={data.submissionResults} />
          </PopupModal>
        </>
      )}
      {submissionResultFiles && (
        <PopupModal
          title={t('submissionMessages:pdf')}
          open={pdfModalOpen}
          handleClose={() => setPdfModalOpen(false)}
        >
          <SubmissionFileView submissionFiles={submissionResultFiles} />
        </PopupModal>
      )}
    </>
  );
}

export default withTranslation(['gridForms', 'invoice', 'submissionLog', 'submissionMessages'])(
  BdoTaxFormDataGrid,
);
