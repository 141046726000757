import { RouteObject } from 'react-router-dom';

import PathConstants from 'pages/PathConstants';

import HomeDrawer from '../HomeDrawer/HomeDrawer';

const ClientRouterConfig: RouteObject[] = [
  {
    path: PathConstants.CLIENT_SETTINGS,
    children: [
      {
        index: true,
        element: <HomeDrawer />,
      },
      {
        path: PathConstants.CLIENT_MASTERDATA,
        element: <HomeDrawer />,
      },
      {
        path: PathConstants.TAX_CODES,
        element: <HomeDrawer />,
      },
      {
        path: PathConstants.JOBS,
        element: <HomeDrawer />,
      },
      {
        path: PathConstants.VAT_GROUP_SETTINGS,
        children: [
          {
            index: true,
            element: <HomeDrawer />,
          },
          {
            path: PathConstants.COMPANY_SETTINGS,
            element: <HomeDrawer />,
          },
        ],
      },
      {
        path: PathConstants.IMPORT_SETTINGS,
        element: <HomeDrawer />,
      },
      {
        path: PathConstants.CULTURE_SETTINGS,
        element: <HomeDrawer />,
      },
      {
        path: PathConstants.USER_MANAGEMENT,
        element: <HomeDrawer />,
      },
      {
        path: PathConstants.ROLE_MANAGEMENT,
        element: <HomeDrawer />,
      },
    ],
  },
];

export default ClientRouterConfig;
