import { Typography } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { ClientSettingsProps } from './ClientSettings.definitions';

function ClientSettings({ t }: ClientSettingsProps): FunctionComponentElement<ClientSettingsProps> {
  return (
    <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
      {t('client-settings')}
    </Typography>
  );
}

export default withTranslation('navigation')(ClientSettings);
