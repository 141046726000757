/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TaxRuleMappingModel } from '../models/TaxRuleMappingModel';
import type { TaxRuleModel } from '../models/TaxRuleModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaxRuleMappingsService {
  /**
   * Retrieves paginated rows of tax rule mappings.
   * @param page The page number for the data set to retrieve.
   * @param pageSize The size of the page to retrieve.
   * @returns TaxRuleModel Success
   * @throws ApiError
   */
  public static getApiTaxRuleMappingsGetRows(
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<TaxRuleModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxRuleMappings/GetRows',
      query: {
        page: page,
        pageSize: pageSize,
      },
    });
  }

  /**
   * Retrieves the total count of tax rule mappings.
   * @returns number Success
   * @throws ApiError
   */
  public static getApiTaxRuleMappingsGetRowCount(): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxRuleMappings/GetRowCount',
    });
  }

  /**
   * Adds a new tax rule mapping row.
   * @param requestBody The tax rule mapping model representing the new tax rule mapping to add.
   * @returns TaxRuleMappingModel Success
   * @throws ApiError
   */
  public static postApiTaxRuleMappingsAddRow(
    requestBody?: TaxRuleMappingModel,
  ): CancelablePromise<TaxRuleMappingModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/TaxRuleMappings/AddRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Updates an existing tax rule mapping row.
   * @param requestBody The tax rule mapping model with updated information.
   * @returns TaxRuleMappingModel Success
   * @throws ApiError
   */
  public static putApiTaxRuleMappingsUpdateRow(
    requestBody?: TaxRuleMappingModel,
  ): CancelablePromise<TaxRuleMappingModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/TaxRuleMappings/UpdateRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Deletes a tax rule mapping if it's not referenced anymore.
   * @param requestBody The tax rule mapping model to delete.
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteApiTaxRuleMappingsDeleteRow(
    requestBody?: TaxRuleMappingModel,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/TaxRuleMappings/DeleteRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
