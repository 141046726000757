import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FunctionComponentElement, useState } from 'react';
import { FormProvider, Path, useForm } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import { TaxOfficeModel } from 'api/client';
import { TaxOfficesService } from 'api/masterdata';

import useStore from 'context/Store/Store.hooks';
import { getNewTaxOffice } from 'shared/utils/formFields.helper';

import LoadingButton from 'components/Buttons/LoadingButton/LoadingButton';
import TaxOfficeFields from 'components/FormFields/TaxOfficeFields/TaxOfficeFields';
import FormFooter from 'components/Layouts/FormFooter/FormFooter';

import Severity from 'enums/Severity';

import { TaxOfficeFormDialogProps } from './TaxOfficeFormDialog.definitions';

function TaxOfficeFormDialog({
  open = false,
  onClose,
  t,
}: TaxOfficeFormDialogProps): FunctionComponentElement<TaxOfficeFormDialogProps> {
  const [isMutating, setIsMutating] = useState(false);
  const queryClient = useQueryClient();
  const { notificationStore, toastStore } = useStore();
  const nameField: Path<TaxOfficeModel> = 'name';
  const addressField: Path<TaxOfficeModel> = 'address';
  const contactInfoField: Path<TaxOfficeModel> = 'contactInfo';
  const contactField: Path<TaxOfficeModel> = 'contact';
  const notesField: Path<TaxOfficeModel> = 'notes';
  const taxOfficeNumberField: Path<TaxOfficeModel> = 'taxOfficeNumber';

  const methods = useForm<TaxOfficeModel>({
    defaultValues: { ...getNewTaxOffice(), id: v4() },
    mode: 'onChange',
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods;

  const postTaxOfficeQuery = async (newData: TaxOfficeModel) => {
    const response = await TaxOfficesService.postApiTaxOffices(newData);
    return response;
  };

  const { mutate } = useMutation({
    mutationFn: postTaxOfficeQuery,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['getApiTaxOffices'] });
      reset();
      onClose();
      toastStore.addToast({
        id: v4(),
        title: t('notifications:tax-office-created'),
        severity: Severity.Info,
      });
    },
    onError: (postApiError) => {
      // eslint-disable-next-line no-console
      console.error(postApiError);
      notificationStore.sendNotification(
        t('notifications:notification-save-failed-title'),
        Severity.Error,
        t('notifications:notification-save-failed-description'),
      );
    },
    onMutate: () => setIsMutating(true),
    onSettled: () => setIsMutating(false),
    mutationKey: ['postTaxOffice'],
  });

  const handleSaveClick = async (data: TaxOfficeModel) => {
    let newData = { ...data };

    if (!data.contactInfo?.phoneNumber) {
      newData = { ...newData, contactInfo: undefined };
    }

    if (!data.contact?.firstName) {
      newData = { ...newData, contact: undefined };
    } else {
      let newContact = { ...data.contact };
      if (!data.contact.contactInfo?.phoneNumber) {
        newContact = { ...newContact, contactInfo: undefined };
        newData = { ...newData, contact: newContact };
      }
      if (!data.contact.address?.street) {
        newContact = { ...newContact, address: undefined };
        newData = { ...newData, contact: newContact };
      }
    }

    mutate(newData);
  };

  const handleCancelClick = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{t('common:createTaxOffice')}</DialogTitle>
      <form
        onSubmit={(event) => {
          event.stopPropagation();
          handleSubmit(handleSaveClick)(event);
        }}
      >
        <DialogContent dividers>
          <FormProvider {...methods}>
            <TaxOfficeFields
              nameField={nameField}
              addressField={addressField}
              contactInfoField={contactInfoField}
              contactField={contactField}
              notesField={notesField}
              taxOfficeNumberField={taxOfficeNumberField}
            />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <FormFooter>
            <Stack direction="row" spacing={3}>
              <Button variant="contained" onClick={handleCancelClick}>
                {t('common:cancel')}
              </Button>
              <LoadingButton title={t('common:save')} isLoading={isMutating} disabled={!isValid} />
            </Stack>
          </FormFooter>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default withTranslation(['taxOfficeFields', 'common', 'notifications'])(TaxOfficeFormDialog);
