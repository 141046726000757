import { Box, Typography } from '@mui/material';
import { GridColDef, GridPaginationModel, GridRowModel } from '@mui/x-data-grid-premium';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { TaxIssueModel, TaxIssuesService } from 'api/taxRule';

import GridFieldsValidator from 'shared/utils/validators/GridFieldsValidator';

import BdoDataGrid from 'components/BdoDataGrid/BdoDataGrid';
import RenderBasicEditCell from 'components/BdoDataGrid/Cells/BasicEditCell';
import { DataGridRow } from 'components/BdoDataGrid/Types/DataGridRow.definitions';

import { TaxIssuesProps } from './TaxIssues.definitions';

function TaxIssues({ t }: TaxIssuesProps): FunctionComponentElement<TaxIssuesProps> {
  const columns: GridColDef[] = [
    { field: 'id', headerName: t('taxIssueFields:id'), type: 'string', width: 150 },
    {
      field: 'name',
      headerName: `${t('taxIssueFields:name')} *`,
      type: 'string',
      editable: true,
      width: 300,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'description',
      headerName: t('taxIssueFields:description'),
      type: 'string',
      editable: true,
      width: 300,
    },
    {
      field: 'relevantFor',
      headerName: `${t('taxIssueFields:relevantFor')} *`,
      type: 'singleSelect',
      editable: true,
      width: 150,
      valueOptions: [
        { value: 1, label: t('taxIssueFields:incoming') },
        { value: 2, label: t('taxIssueFields:outgoing') },
        { value: 3, label: t('taxIssueFields:both') },
      ],
      valueGetter: (params) => params.row.relevantFor || '',
      groupingValueGetter: ({ value }) => {
        if (value === 1) {
          return t('taxIssueFields:incoming');
        }
        if (value === 2) {
          return t('taxIssueFields:outgoing');
        }
        return t('taxIssueFields:both');
      },
    },
  ];

  const addDefaultRecord = (id: string): GridRowModel => {
    const taxIssue: TaxIssueModel = {
      id,
      name: '',
      description: '',
    };

    return taxIssue;
  };

  const processRowUpdate = async (row: DataGridRow): Promise<DataGridRow> => {
    let resultRow;

    if (row.isNew) {
      resultRow = await TaxIssuesService.postApiTaxIssuesAddRow(row as TaxIssueModel);
    } else {
      resultRow = await TaxIssuesService.putApiTaxIssuesUpdateRow(row as TaxIssueModel);
    }

    return resultRow as DataGridRow;
  };

  const processRowDelete = async (row: DataGridRow): Promise<boolean> =>
    TaxIssuesService.deleteApiTaxIssuesDeleteRow(row as TaxIssueModel);

  const getRows = (pagination: GridPaginationModel) =>
    TaxIssuesService.getApiTaxIssuesGetRows(pagination.page, pagination.pageSize);

  const getRowCount = () => TaxIssuesService.getApiTaxIssuesGetRowCount();

  return (
    <div>
      <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
        {t('navigation:tax-issues')}
      </Typography>
      <Box sx={{ p: '1rem' }}>
        <BdoDataGrid
          columns={columns}
          getRows={getRows}
          getRowCount={getRowCount}
          processRowUpdate={processRowUpdate}
          processRowDelete={processRowDelete}
          addDefaultRecord={addDefaultRecord}
          exportFileName={t('taxIssueFields:exportFileName')}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
          }}
        />
      </Box>
    </div>
  );
}

export default withTranslation(['gridTooltips', 'navigation', 'taxIssueFields'])(TaxIssues);
