/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditorOfficeModel } from '../models/AuditorOfficeModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuditorOfficesService {
  /**
   * Gets the list of all auditor offices.
   * @returns AuditorOfficeModel Success
   * @throws ApiError
   */
  public static getApiAuditorOffices(): CancelablePromise<Array<AuditorOfficeModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/AuditorOffices',
    });
  }

  /**
   * Creates a new auditor office.
   * @param requestBody The auditor office model containing the details of the auditor office to be created.
   * @returns AuditorOfficeModel Success
   * @throws ApiError
   */
  public static postApiAuditorOffices(
    requestBody?: AuditorOfficeModel,
  ): CancelablePromise<AuditorOfficeModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/AuditorOffices',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Retrieves a paginated list of auditor office rows.
   * @param page The page number of the data set to retrieve.
   * @param pageSize The size of the page to retrieve.
   * @returns AuditorOfficeModel Success
   * @throws ApiError
   */
  public static getApiAuditorOfficesGetRows(
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<AuditorOfficeModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/AuditorOffices/GetRows',
      query: {
        page: page,
        pageSize: pageSize,
      },
    });
  }

  /**
   * Retrieves the total count of auditor offices rows.
   * @returns number Success
   * @throws ApiError
   */
  public static getApiAuditorOfficesGetRowCount(): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/AuditorOffices/GetRowCount',
    });
  }

  /**
   * Adds a new auditor office row.
   * @param requestBody The auditor office model to add.
   * @returns AuditorOfficeModel Success
   * @throws ApiError
   */
  public static postApiAuditorOfficesAddRow(
    requestBody?: AuditorOfficeModel,
  ): CancelablePromise<AuditorOfficeModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/AuditorOffices/AddRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Updates an exitsing auditor office row.
   * @param requestBody The auditor office model with updated information.
   * @returns AuditorOfficeModel Success
   * @throws ApiError
   */
  public static putApiAuditorOfficesUpdateRow(
    requestBody?: AuditorOfficeModel,
  ): CancelablePromise<AuditorOfficeModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/AuditorOffices/UpdateRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Deletes an auditor office if it's not referenced anymore.
   * @param requestBody The auditor office model to delete.
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteApiAuditorOfficesDeleteRow(
    requestBody?: AuditorOfficeModel,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/AuditorOffices/DeleteRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
