import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import i18n from 'configs/i18n';

import CollapsibleListItem from '../CollapsibleListItem/CollapsibleListItem';
import { getChildPath, getDisplayText, initialPadding } from '../Navigation.helper';
import NormalNavListItem from '../NormalNavListItem/NormalNavListItem';

import { CollapsibleNavListItemProps } from './CollapsibleNavListItem.definitions';

function CollapsibleNavListItem({
  item,
  path,
  paddingLeft,
  t,
}: CollapsibleNavListItemProps): FunctionComponentElement<CollapsibleNavListItemProps> {
  return (
    <CollapsibleListItem
      listItem={item}
      navigatePath={path}
      primaryText={getDisplayText(item, t)}
      paddingLeft={paddingLeft}
    >
      {item.childs &&
        item.childs.length > 0 &&
        item.childs.map((child) => (
          <div key={`${child.value}${child.postfix}`}>
            {child.childs && child.childs.length > 0 ? (
              <CollapsibleNavListItem
                item={child}
                path={getChildPath(path, child)}
                paddingLeft={paddingLeft + initialPadding}
                t={t}
                i18n={i18n}
                tReady
              />
            ) : (
              <NormalNavListItem
                item={child}
                path={getChildPath(path, child)}
                paddingLeft={paddingLeft + initialPadding}
              />
            )}
          </div>
        ))}
    </CollapsibleListItem>
  );
}

export default withTranslation('navigation')(CollapsibleNavListItem);
