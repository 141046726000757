import { Box, CssBaseline, Toolbar } from '@mui/material';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { FunctionComponentElement, Suspense, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { withTranslation } from 'react-i18next';

import { logError } from 'shared/utils/error.helper';

import FallbackComponent from 'components/FallbackComponent/FallbackComponent';
import ActionMenu from 'components/Layouts/ActionMenu/ActionMenu';
import Header from 'components/Layouts/Header/Header';
import Sidebar from 'components/Layouts/Sidebar/Sidebar';
import Loader from 'components/Loader/Loader';
import Router from 'components/Router/Router';

import { HomeProps } from './Home.definitions';
import HomeRouterConfig from './HomeRouterConfig';

const sidebarWidth = 300;

function Home({ t, children }: HomeProps): FunctionComponentElement<HomeProps> {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleSidebarToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <CssBaseline />

      {/* header */}
      <Header title={t('title')} handleDrawerToggle={handleSidebarToggle} />

      {/* sidebar */}
      <Sidebar width={sidebarWidth} isOpen={mobileOpen} handleDrawerToggle={handleSidebarToggle} />

      {/* main content view */}
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          flexGrow: 1,
          width: { sm: `calc(100% - ${sidebarWidth}px)` },
        }}
      >
        <Toolbar />

        {/* content */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            p: 2,
            pr: 7,
          }}
        >
          <QueryErrorResetBoundary>
            {({ reset }) => (
              <ErrorBoundary
                onReset={reset}
                onError={logError}
                FallbackComponent={FallbackComponent}
              >
                <Suspense fallback={<Loader />}>
                  <Router routes={HomeRouterConfig} />
                </Suspense>
              </ErrorBoundary>
            )}
          </QueryErrorResetBoundary>
        </Box>
      </Box>

      {/* action menu with right drawer */}
      <Box sx={{ display: 'block', position: 'fixed', top: '45%', right: 0, zIndex: 1 }}>
        <ActionMenu />
      </Box>
    </Box>
  );
}

export default withTranslation('common')(observer(Home));
