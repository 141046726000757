import { Typography } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { AdministrationsProps } from './Administrations.definitions';

function Administrations({
  t,
}: AdministrationsProps): FunctionComponentElement<AdministrationsProps> {
  return (
    <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
      {t('administrations')}
    </Typography>
  );
}

export default withTranslation('navigation')(Administrations);
