import { Typography } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { AnnualVatReturnFormProps } from './AnnualVatReturnForm.definitions';

function AnnualVatReturnForm({
  t,
}: AnnualVatReturnFormProps): FunctionComponentElement<AnnualVatReturnFormProps> {
  return (
    <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
      {t('annual-vat-return-form')}
    </Typography>
  );
}

export default withTranslation('navigation')(AnnualVatReturnForm);
