import { Box, Drawer, Toolbar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FunctionComponentElement, Suspense } from 'react';
import { withTranslation } from 'react-i18next';

import useStore from 'context/Store/Store.hooks';

import MainDrawer from 'pages/drawer/MainDrawer/MainDrawer';

import DrawerHeader from 'components/Layouts/DrawerHeader/DrawerHeader';
import Loader from 'components/Loader/Loader';
import NotificationList from 'components/Notification/NotificationList/NotificationList';

import { RightDrawerProps } from './RightDrawer.definitions';

function RightDrawer({
  open = false,
  drawerWidth,
  handleCloseClick,
  t,
}: RightDrawerProps): FunctionComponentElement<RightDrawerProps> {
  const {
    rightDrawerStore: { activeContent, isNotificationsDrawer },
  } = useStore();

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={open}
      PaperProps={{ elevation: 3 }}
      sx={{
        display: open ? 'contents' : 'none',
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          background: (theme) => theme.sidebarColor?.main,
        },
      }}
    >
      <Toolbar />
      <Box
        sx={{ py: 2, overflow: 'auto', display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <DrawerHeader hasCloseButton title={t(activeContent)} onClose={handleCloseClick} />
        <Suspense
          fallback={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                position: 'absolute',
                width: '100%',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              <Loader />
            </Box>
          }
        >
          {isNotificationsDrawer ? <NotificationList /> : <MainDrawer />}
        </Suspense>
      </Box>
    </Drawer>
  );
}

export default withTranslation(['actionMenu', 'common'])(observer(RightDrawer));
