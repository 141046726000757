import { ListItemButton, Tooltip } from '@mui/material';
import { FunctionComponentElement } from 'react';

import { ActionMenuButtonProps } from './ActionMenuButton.definitions';

function ActionMenuButton({
  handleClick,
  icon,
  tooltipText,
  selected,
}: ActionMenuButtonProps): FunctionComponentElement<ActionMenuButtonProps> {
  return (
    <Tooltip title={tooltipText} placement="left">
      <ListItemButton
        selected={selected}
        onClick={handleClick}
        sx={{
          p: 2,
          justifyContent: 'center',
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: (theme) => theme.palette.primary.main,
          },
          '&:hover': {
            backgroundColor: (theme) => theme.palette.secondary.dark,
          },
        }}
        disableRipple
      >
        {icon}
      </ListItemButton>
    </Tooltip>
  );
}

export default ActionMenuButton;
