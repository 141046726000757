import { Box, Typography } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { AddressViewProps } from './AddressView.definitions';

function AddressView({
  address,
  showHeader = true,
  t,
}: AddressViewProps): FunctionComponentElement<AddressViewProps> {
  if (!address) {
    return (
      <Typography variant="subtitle1" gutterBottom>
        -
      </Typography>
    );
  }

  return (
    <Box>
      {showHeader && (
        <Typography variant="h6" component="div">
          {t('companyFields:contactInfo')}
        </Typography>
      )}
      <Typography variant="subtitle1" component="div" gutterBottom>
        <div>
          {address.street} {address.number}
        </div>
        <div>
          {address.postal} {address.city}
        </div>
        <div>
          {address.region} {address.country}
        </div>
        <div>{address.notes}</div>
      </Typography>
    </Box>
  );
}

export default withTranslation('companyFields')(AddressView);
