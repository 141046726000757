/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaxValidationsService {
  /**
   * Enqueues validations for a specified Tax Folder.
   * @param taxFolderId The unique identifier of the Tax Folder to validate.
   * @returns any Success
   * @throws ApiError
   */
  public static getApiTaxValidationsEnqueueTaxFolderValidations(
    taxFolderId?: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxValidations/EnqueueTaxFolderValidations',
      query: {
        taxFolderId: taxFolderId,
      },
    });
  }

  /**
   * Enqueues validations for a specified Tax Folder.
   * @param countryId The unique identifier of the Country to validate.
   * @param taxYearId The unique identifier of the Tax Year to validate.
   * @returns any Success
   * @throws ApiError
   */
  public static getApiTaxValidationsEnqueueTaxFolderValidationsByCountryAndTaxYear(
    countryId?: string,
    taxYearId?: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxValidations/EnqueueTaxFolderValidationsByCountryAndTaxYear',
      query: {
        countryId: countryId,
        taxYearId: taxYearId,
      },
    });
  }

  /**
   * Enqueues validations for a specified Filing Period.
   * @param filligPeriodId The unique identifier of the Filing Period to validate.
   * @returns any Success
   * @throws ApiError
   */
  public static getApiTaxValidationsEnqueueFillingPeriodValidations(
    filligPeriodId?: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxValidations/EnqueueFillingPeriodValidations',
      query: {
        filligPeriodId: filligPeriodId,
      },
    });
  }

  /**
   * Enqueues validations for a specified Company.
   * @param companyId The unique identifier of the Company to validate.
   * @returns any Success
   * @throws ApiError
   */
  public static getApiTaxValidationsEnqueueCompanyValidations(
    companyId?: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxValidations/EnqueueCompanyValidations',
      query: {
        companyId: companyId,
      },
    });
  }

  /**
   * Enqueues validations for a specified Invoice.
   * @param invoiceId The unique identifier of the Invoice to validate.
   * @returns any Success
   * @throws ApiError
   */
  public static getApiTaxValidationsEnqueueInvoiceValidations(
    invoiceId?: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxValidations/EnqueueInvoiceValidations',
      query: {
        invoiceId: invoiceId,
      },
    });
  }
}
