import { Button, CircularProgress } from '@mui/material';
import { FunctionComponentElement } from 'react';

import { LoadingButtonProps } from './LoadingButton.definitions';

function LoadingButton({
  title,
  isLoading = false,
  disabled = false,
  onClick,
}: LoadingButtonProps): FunctionComponentElement<LoadingButtonProps> {
  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <Button
      type="submit"
      variant="contained"
      disabled={disabled || isLoading}
      onClick={handleClick}
    >
      {title}
      {isLoading && (
        <CircularProgress
          sx={{ ml: 1, color: (theme) => theme.palette.common.white }}
          size="1rem"
        />
      )}
    </Button>
  );
}

export default LoadingButton;
