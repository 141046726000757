import { Alert, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FunctionComponentElement, useEffect, useState } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { FileModel } from 'api/files';
import { InvoiceImportModel } from 'api/import';
import { ImportService } from 'api/import/services/ImportService';

import useInvoicesExist from 'shared/api-hooks/useInvoicesExist';
import useUnfinishedImportExist from 'shared/api-hooks/useUnfinishedImportExist';

import Loader from 'components/Loader/Loader';

import SelectFilesStep from '../../../components/StepperComponents/SelectFilesStep/SelectFilesStep';
import SelectWorksheetsStep from '../SelectWorksheetsStep/SelectWorksheetsStep';
import SummaryAndImportStep from '../SummaryAndImportStep/SummaryAndImportStep';

import {
  ImportInvoicesStepperProps,
  SheetModelSelection,
} from './ImportInvoicesStepper.definitions';

function ImportInvoicesStepper({
  t,
}: ImportInvoicesStepperProps): FunctionComponentElement<ImportInvoicesStepperProps> {
  const [selectedFile, setSelectedFile] = useState<FileModel | undefined>(undefined);
  const [sheets, setSheets] = useState<SheetModelSelection[]>([]);
  const [isMutating, setIsMutating] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const { countryId, taxYearId, fillingPeriodId, companyId } = useParams();
  const queryClient = useQueryClient();

  const getSheetsQuery = async () => {
    const response = await ImportService.getApiImportGetSheets(selectedFile?.id);
    const sheetsWithSelection = response.map(
      (sheet): SheetModelSelection => ({
        sheetName: sheet.sheetName,
        position: sheet.position,
        usedRows: sheet.usedRows,
        isSelected: false,
      }),
    );
    setSheets(sheetsWithSelection);
    return response;
  };

  const postImportQuery = async () => {
    const selectedSheets = sheets.filter((sheet) => sheet.isSelected);
    const selectedSheetNames: string[] = selectedSheets.map((sheet) => sheet.sheetName || '');
    const invoiceImport: InvoiceImportModel = {
      fileId: selectedFile?.id,
      companyId,
      selectedSheets: selectedSheetNames,
    };
    const response = await ImportService.postApiImportEnqueue(invoiceImport);
    return response;
  };

  const { isFetching: isFetchingInvoicesExist, data: hasInvoices } = useInvoicesExist(companyId);

  const { isFetching: isFetchingUnfinishedImport, data: hasUnfinishedImport } =
    useUnfinishedImportExist(companyId);

  const {
    isFetching: isFetchingSheets,
    error,
    refetch,
  } = useQuery({
    queryKey: ['getSheets'],
    queryFn: getSheetsQuery,
    enabled: false, // fetch sheets only when file exists from useEffect
  });

  const { mutate } = useMutation({
    mutationFn: postImportQuery,
    onSuccess: async () => {
      navigate(
        `/country/${countryId}/tax-year/${taxYearId}/filling-period/${fillingPeriodId}/value-added-tax-group/company/${companyId}`,
      );
      await queryClient.invalidateQueries({ queryKey: ['getInvoicesExist', companyId] });
      await queryClient.invalidateQueries({ queryKey: ['getUnfinishedImportExist', companyId] });
    },
    onMutate: () => setIsMutating(true),
    onSettled: () => setIsMutating(false),
    mutationKey: ['postExcelImport'],
  });

  // refetch sheets when file is changed
  useEffect(() => {
    if (!selectedFile) return;
    refetch();
  }, [refetch, selectedFile]);

  const steps = [t('selectFiles'), t('selectWorksheetsToImport'), t('summaryAndImportStart')];

  const isLastStep = activeStep === steps.length - 1;

  const handleNextClick = () => {
    if (isLastStep) {
      mutate();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handlePrevClick = () => {
    setActiveStep(activeStep - 1);
  };

  const renderStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <SelectFilesStep
            selectedFile={selectedFile}
            updateSelectedFile={setSelectedFile}
            goToNextStep={handleNextClick}
          />
        );
      case 1:
        return (
          <SelectWorksheetsStep
            isFetchingSheets={isFetchingSheets}
            error={error}
            sheets={sheets}
            updateSheets={setSheets}
            goToPrevStep={handlePrevClick}
            goToNextStep={handleNextClick}
          />
        );
      case 2:
        return (
          <SummaryAndImportStep
            selectedFile={selectedFile}
            sheets={sheets}
            isSaving={isMutating}
            goToPrevStep={handlePrevClick}
            goToNextStep={handleNextClick}
          />
        );
      default:
        return <div>Not Found</div>;
    }
  };

  if (isFetchingInvoicesExist || isFetchingUnfinishedImport) {
    return <Loader />;
  }

  return (
    <>
      <Typography component="h1" variant="h6">
        {t('common:startInvoiceImport')}{' '}
        {hasInvoices && !hasUnfinishedImport && (
          <Alert severity="error">{t('notifications:import-invoices-already-exist')}</Alert>
        )}
        {hasUnfinishedImport && (
          <Alert sx={{ mb: 2 }} severity="warning">
            <span>
              <Trans
                i18nKey={t('notifications:notification-unfinished-imports-link')}
                components={{
                  linkCompany: (
                    <Link
                      to={`/country/${countryId}/tax-year/${taxYearId}/filling-period/${fillingPeriodId}/value-added-tax-group/company/${companyId}`}
                    />
                  ),
                }}
              />
            </span>
          </Alert>
        )}
      </Typography>
      <Stepper activeStep={activeStep} sx={{ py: 2 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {renderStepContent(activeStep)}
    </>
  );
}

export default withTranslation(['importInvoicesStepper', 'common', 'notifications'])(
  ImportInvoicesStepper,
);
