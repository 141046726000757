import {
  CountryModel,
  FillingPeriodDeadlines,
  Interval,
  TaxFolderCreationForm,
  TaxFolderModel,
  TaxFoldersService,
  TaxYearModel,
} from 'api/invoices';
import { CountriesService } from 'api/masterdata';
import { FillingPeriodsService } from 'api/taxCalculation';

import { TaxFolderStepperForm } from './CreateTaxFolderStepper.definitions';

export const getCountriesQuery = async (): Promise<CountryModel[]> => {
  const response = await CountriesService.getApiCountries();
  return response;
};

export const getOpenTaxYearsQuery = async (countryId: string): Promise<TaxYearModel[]> => {
  const response = await TaxFoldersService.getApiTaxFoldersGetOpenTaxYearsByCountry(countryId);
  return response;
};

export const getFillingPeriodDeadlinesQuery = async (
  year: number,
  interval: string,
  extension: boolean,
): Promise<FillingPeriodDeadlines> => {
  if (year === 0) {
    throw new Error('Invalid year');
  }
  const calculatedDeadlines = await FillingPeriodsService.getApiFillingPeriodsDeadlines(
    year,
    interval as unknown as number as Interval,
    extension,
  );
  return calculatedDeadlines;
};

export const postTaxFolderQuery = async (
  taxFolderStepperForm: TaxFolderStepperForm,
): Promise<TaxFolderModel> => {
  const taxFolderCreationForm: TaxFolderCreationForm = {
    country: taxFolderStepperForm.selectedCountry!,
    taxYear: taxFolderStepperForm.selectedOpenTaxYear!,
    deadlines: taxFolderStepperForm.calculatedDeadlines!,
  };
  const createdTaxFolder = await TaxFoldersService.postApiTaxFolders(taxFolderCreationForm);
  return createdTaxFolder;
};
