import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import SubmissionDialog from 'components/SubmissionDialog/SubmissionDialog';

import { TaxOfficeSubmissionDialogProps } from './TaxOfficeSubmissionDialog.definitions';

function TaxOfficeSubmissionDialog({
  open,
  showClientWarning,
  onClose,
  onFormSubmit,
  t,
}: TaxOfficeSubmissionDialogProps): FunctionComponentElement<TaxOfficeSubmissionDialogProps> {
  return (
    <SubmissionDialog
      open={open}
      dialogTitle={t('taxOfficeSubmissions:dialog-title')}
      switchLabel={t('taxOfficeSubmissions:dialog-switch-label')}
      cancelButtonText={t('taxOfficeSubmissions:btn-cancel')}
      submitButtonText={t('taxOfficeSubmissions:btn-submit')}
      onClose={onClose}
      onFormSubmit={onFormSubmit}
      showWarning={showClientWarning}
      warningText={t('taxOfficeSubmissions:dialog-client-warning')}
    />
  );
}

export default withTranslation('taxOfficeSubmissions')(TaxOfficeSubmissionDialog);
