import CloseIcon from '@mui/icons-material/Close';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import {
  Avatar,
  Card,
  CardActions,
  CardHeader,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { forwardRef, useCallback } from 'react';

import theme from 'themes/theme';

import { getSnackbarColor } from 'shared/utils/notification.helper';

import { BdoSnackbarProps } from './BdoSnackbar.definitions';

const StyledSnackbarContent = styled('div')({
  '@media (min-width:600px)': {
    minWidth: '344px !important',
  },
});

const BdoSnackbar = forwardRef<HTMLDivElement, BdoSnackbarProps>((props, ref) => {
  const { id, message, severity, icon } = props;
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <StyledSnackbarContent ref={ref}>
      <Card sx={{ width: '100%', bgcolor: getSnackbarColor(severity) }}>
        <CardHeader
          sx={{ p: 1 }}
          avatar={
            <Avatar
              sx={{
                bgcolor: getSnackbarColor(severity),
                border: '1.5px solid',
                borderColor: theme.palette.common.white,
              }}
            >
              {icon || <NotificationsOutlinedIcon />}
            </Avatar>
          }
          action={
            <CardActions>
              <IconButton
                aria-label="delete"
                size="small"
                sx={{ color: theme.palette.common.white }}
                onClick={handleDismiss}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </CardActions>
          }
          title={
            <Typography variant="body2" sx={{ color: theme.palette.common.white }}>
              {message}
            </Typography>
          }
        />
      </Card>
    </StyledSnackbarContent>
  );
});

export default BdoSnackbar;
