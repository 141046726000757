import { Box, Typography } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import AddressView from 'components/AddressView/AddressView';
import ContactInfoView from 'components/ContactInfoView/ContactInfoView';

import { ContactViewProps } from './ContactView.definitions';

function ContactView({
  contact,
  showHeader = true,
  t,
}: ContactViewProps): FunctionComponentElement<ContactViewProps> {
  if (!contact) {
    return (
      <Typography variant="subtitle1" gutterBottom>
        -
      </Typography>
    );
  }

  return (
    <Box>
      {showHeader && (
        <Typography variant="h6" component="div">
          {t('companyFields:contactInfo')}
        </Typography>
      )}
      <Typography variant="subtitle1" component="div" gutterBottom>
        <div>
          {contact?.firstName} {contact?.lastName}
        </div>
        <ContactInfoView contactInfo={contact?.contactInfo} showHeader={false} />
        <div>{contact?.notes}</div>
        <AddressView address={contact?.address} showHeader={false} />
      </Typography>
    </Box>
  );
}

export default withTranslation('companyFields')(ContactView);
