/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InvoiceModel } from '../models/InvoiceModel';
import type { InvoiceRowModel } from '../models/InvoiceRowModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InvoicesService {
  /**
   * Checks if there are any invoices for the specified company within the filling period.
   * @param companyId The unique identifier of the company.
   * @returns boolean Success
   * @throws ApiError
   */
  public static getApiInvoicesDoesInvoicesAlreadyExist(
    companyId?: string,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Invoices/DoesInvoicesAlreadyExist',
      query: {
        companyId: companyId,
      },
    });
  }

  /**
   * Retrieves a list of invoices for the specified company.
   * @param companyId The unique identifier of the company for which to retrieve invoices.
   * @returns InvoiceModel Success
   * @throws ApiError
   */
  public static getApiInvoices(companyId?: string): CancelablePromise<Array<InvoiceModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Invoices',
      query: {
        companyId: companyId,
      },
    });
  }

  /**
   * Retrieves paginated rows of invoices for the given company.
   * @param companyId The unique identifier of the company for which to retrieve invoices.
   * @param page The page number of the data set to retrieve.
   * @param pageSize The size of the page to retrieve.
   * @returns InvoiceRowModel Success
   * @throws ApiError
   */
  public static getApiInvoicesGetRows(
    companyId?: string,
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<InvoiceRowModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Invoices/GetRows',
      query: {
        companyId: companyId,
        page: page,
        pageSize: pageSize,
      },
    });
  }

  /**
   * Retrieves the total count of invoices for the given company.
   * @param companyId The unique identifier of the company for which to retrieve invoices.
   * @returns number Success
   * @throws ApiError
   */
  public static getApiInvoicesGetRowCount(companyId?: string): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Invoices/GetRowCount',
      query: {
        companyId: companyId,
      },
    });
  }

  /**
   * Adds a new invoice row.
   * @param requestBody The invoice model to add.
   * @returns InvoiceRowModel Success
   * @throws ApiError
   */
  public static postApiInvoicesAddRow(
    requestBody?: InvoiceModel,
  ): CancelablePromise<InvoiceRowModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Invoices/AddRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Updates an existing invoice row.
   * @param requestBody The invoice model with updated information.
   * @returns InvoiceRowModel Success
   * @throws ApiError
   */
  public static putApiInvoicesUpdateRow(
    requestBody?: InvoiceModel,
  ): CancelablePromise<InvoiceRowModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Invoices/UpdateRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Moves an invoice to the previous filling period if possible
   * @param requestBody The invoice model with updated information.
   * @returns InvoiceRowModel Success
   * @throws ApiError
   */
  public static putApiInvoicesMoveInvoiceToPreviousPeriod(
    requestBody?: InvoiceModel,
  ): CancelablePromise<InvoiceRowModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Invoices/MoveInvoiceToPreviousPeriod',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Moves an invoice to the next filling period if possible
   * @param requestBody The invoice model with updated information.
   * @returns InvoiceRowModel Success
   * @throws ApiError
   */
  public static putApiInvoicesMoveInvoiceToNextPeriod(
    requestBody?: InvoiceModel,
  ): CancelablePromise<InvoiceRowModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Invoices/MoveInvoiceToNextPeriod',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Deletes an invoice.
   * @param requestBody The invoice model to delete.
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteApiInvoicesDeleteRow(requestBody?: InvoiceModel): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/Invoices/DeleteRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
