/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FillingPeriodDeadlines } from '../models/FillingPeriodDeadlines';
import type { FillingPeriodModel } from '../models/FillingPeriodModel';
import type { Interval } from '../models/Interval';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FillingPeriodsService {
  /**
   * Calculates the filling period deadlines based on the provided parameters.
   * @param year The year for which the filling period deadlines are to be calculated.
   * @param interval The interval (e.g., Monthly, Quarterly) for the filling period. Default is Monthly.
   * @param permanentPeriodExtension Indicates whether a permanent period extension is applied. Default is false.
   * @returns FillingPeriodDeadlines Success
   * @throws ApiError
   */
  public static getApiFillingPeriodsDeadlines(
    year?: number,
    interval?: Interval,
    permanentPeriodExtension: boolean = false,
  ): CancelablePromise<FillingPeriodDeadlines> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/FillingPeriods/Deadlines',
      query: {
        year: year,
        interval: interval,
        permanentPeriodExtension: permanentPeriodExtension,
      },
    });
  }

  /**
   * Retrieves a filling period by its unique identifier.
   * @param id The unique identifier of the filling period.
   * @returns FillingPeriodModel Success
   * @throws ApiError
   */
  public static getApiFillingPeriodsGetFillingPeriod(
    id?: string,
  ): CancelablePromise<FillingPeriodModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/FillingPeriods/GetFillingPeriod',
      query: {
        id: id,
      },
    });
  }

  /**
   * Deletes a filling period.
   * @param id The unique identifier of the filling period.
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteApiFillingPeriodsDelete(id?: string): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/FillingPeriods/Delete',
      query: {
        id: id,
      },
    });
  }
}
