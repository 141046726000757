import { Headers } from 'form-data';

import { OpenAPI as ClientOpenAPI } from 'api/client';
import { OpenAPI as FilesOpenAPI } from 'api/files';
import { OpenAPI as ImportOpenAPI } from 'api/import';
import { OpenAPI as InvoicesOpenAPI } from 'api/invoices';
import { OpenAPI as MasterdataOpenAPI } from 'api/masterdata';
import { OpenAPI as SubmissionOpenAPI } from 'api/submission';
import { OpenAPI as SystemOpenAPI } from 'api/system';
import { OpenAPI as TaxCalculationOpenAPI } from 'api/taxCalculation';
import { OpenAPI as TaxRuleOpenAPI } from 'api/taxRule';

const initializeApiUrls = (): void => {
  ClientOpenAPI.BASE = process.env.REACT_APP_CLIENT_API_URL || '';
  SystemOpenAPI.BASE = process.env.REACT_APP_SYSTEM_API_URL || '';
  MasterdataOpenAPI.BASE = process.env.REACT_APP_MASTERDATA_API_URL || '';
  InvoicesOpenAPI.BASE = process.env.REACT_APP_INVOICES_API_URL || '';
  TaxCalculationOpenAPI.BASE = process.env.REACT_APP_TAXCALCULATION_API_URL || '';
  FilesOpenAPI.BASE = process.env.REACT_APP_FILES_API_URL || '';
  ImportOpenAPI.BASE = process.env.REACT_APP_IMPORT_API_URL || '';
  TaxRuleOpenAPI.BASE = process.env.REACT_APP_TAXRULE_API_URL || '';
  SubmissionOpenAPI.BASE = process.env.REACT_APP_SUBMISSION_API_URL || '';
};

const initializeTokens = (resolveToken: () => Promise<string>): void => {
  ClientOpenAPI.TOKEN = resolveToken;
  SystemOpenAPI.TOKEN = resolveToken;
  MasterdataOpenAPI.TOKEN = resolveToken;
  InvoicesOpenAPI.TOKEN = resolveToken;
  TaxCalculationOpenAPI.TOKEN = resolveToken;
  FilesOpenAPI.TOKEN = resolveToken;
  ImportOpenAPI.TOKEN = resolveToken;
  TaxRuleOpenAPI.TOKEN = resolveToken;
  SubmissionOpenAPI.TOKEN = resolveToken;
};

const initializeHeader = (resolveHeader: () => Promise<Headers>): void => {
  ClientOpenAPI.HEADERS = resolveHeader;
  SystemOpenAPI.HEADERS = resolveHeader;
  MasterdataOpenAPI.HEADERS = resolveHeader;
  InvoicesOpenAPI.HEADERS = resolveHeader;
  TaxCalculationOpenAPI.HEADERS = resolveHeader;
  FilesOpenAPI.HEADERS = resolveHeader;
  ImportOpenAPI.HEADERS = resolveHeader;
  TaxRuleOpenAPI.HEADERS = resolveHeader;
  SubmissionOpenAPI.HEADERS = resolveHeader;
};

const initializeOpenApi = (
  resolveToken: () => Promise<string>,
  resolveHeader: () => Promise<Headers>,
): void => {
  initializeApiUrls();
  initializeTokens(resolveToken);
  initializeHeader(resolveHeader);
};

export default initializeOpenApi;
