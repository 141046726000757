import { RouteObject } from 'react-router-dom';

import PathConstants from 'pages/PathConstants';

import DashboardDrawer from '../DashboardDrawer/DashboardDrawer';
import HomeDrawer from '../HomeDrawer/HomeDrawer';

import AdminRouterConfig from './AdminRouterConfig';
import ClientRouterConfig from './ClientRouterConfig';
import SystemRouterConfig from './SystemRouterConfig';
import TaxRouterConfig from './TaxRouterConfig';

const DrawerRouterConfig: RouteObject[] = [
  { path: '/', element: <HomeDrawer /> },
  {
    path: PathConstants.DASHBOARD,
    element: <DashboardDrawer />,
  },
  ...TaxRouterConfig,
  ...ClientRouterConfig,
  ...SystemRouterConfig,
  ...AdminRouterConfig,
];

export default DrawerRouterConfig;
