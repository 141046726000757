import { useQuery } from '@tanstack/react-query';
import { FunctionComponentElement } from 'react';

import CenteredLoader from 'components/CenteredLoader/CenteredLoader';
import InvoiceTable from 'components/InvoiceTable/InvoiceTable';

import { InvoiceListViewProps } from './InvoiceListView.definitions';

function InvoiceListView({
  taxFormEntryModelId,
  getInvoices,
}: InvoiceListViewProps): FunctionComponentElement<InvoiceListViewProps> {
  const { isFetching, data } = useQuery({
    queryKey: ['UsedInvoices', taxFormEntryModelId],
    queryFn: async () => {
      const response = await getInvoices(taxFormEntryModelId);
      return response;
    },
  });

  if (isFetching) {
    return <CenteredLoader />;
  }

  if (!data || data.length === 0) {
    return <div>No invoices exist</div>;
  }

  return <InvoiceTable invoices={data} />;
}

export default InvoiceListView;
