import Roles from 'configs/keycloak-roles';

export const auditorRoles: string[] = [
  Roles.AuditorAdministrator,
  Roles.AuditorEmployee,
  Roles.AuditorSupervisor,
];
export const clientRoles: string[] = [Roles.ClientEmployee, Roles.ClientSupervisor];

export const adminRoles: string[] = [Roles.Administrator, Roles.AuditorAdministrator];

export const auditorEmployeeRoles: string[] = [Roles.AuditorEmployee];

export const auditorSupervisorRoles: string[] = [Roles.AuditorSupervisor];

export const isAdminRole = (roles: string[]): boolean =>
  roles.some((role) => adminRoles.includes(role));

export const isAuditorEmployeeRole = (roles: string[]): boolean =>
  roles.some((role) => auditorEmployeeRoles.includes(role));

export const isAuditorSupervisorRole = (roles: string[]): boolean =>
  roles.some((role) => auditorSupervisorRoles.includes(role));

export const isClientRole = (roles: string[]): boolean =>
  roles.some((role) => clientRoles.includes(role));
