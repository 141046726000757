// BDO primary palette
export const red = '#e81a3b';
export const charcoal = '#333333';
export const slate = '#5b6e7f';
export const burgundy = '#98002e';
export const paleCharcoal = '#e7e7e7';

// BDO secondary palette
export const gold = '#d67900';
export const jade = '#009966';
export const ocean = '#008fd2';

// BDO Font
export const proximaNova = 'Proxima Nova, sans-serif';
