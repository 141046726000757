import { Box } from '@mui/material';
import { GridEditInputCell, GridRenderEditCellParams } from '@mui/x-data-grid-premium';

import ErrorTooltip from '../Tooltips/ErrorTooltip';

function EditInputCell({ params }: { params: GridRenderEditCellParams }) {
  const { error, errormessage = '' } = params;

  return (
    <ErrorTooltip
      open={error}
      title={errormessage}
      PopperProps={{
        disablePortal: true,
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              enabled: false,
              options: {
                altBoundary: false,
                rootBoundary: 'document',
                padding: 8,
              },
            },
            {
              name: 'preventOverflow',
              enabled: false,
              options: {
                altAxis: false,
                altBoundary: false,
                tether: false,
                rootBoundary: 'document',
                padding: 8,
              },
            },
          ],
        },
      }}
    >
      <Box sx={{ width: '100%', height: '100%' }}>
        <GridEditInputCell {...params} />
      </Box>
    </ErrorTooltip>
  );
}

export default function RenderBasicEditCell(params: GridRenderEditCellParams) {
  return <EditInputCell params={params} />;
}
