import i18n from 'configs/i18n';

import { SheetModelSelection } from './ImportInvoicesStepper/ImportInvoicesStepper.definitions';

const getSheetDescription = (sheet: SheetModelSelection): string =>
  `${i18n.t('importInvoicesStepper:worksheet')} ${sheet.sheetName} ${i18n.t(
    'importInvoicesStepper:with',
  )} ${sheet.usedRows} ${i18n.t('importInvoicesStepper:rows')}`;

export default getSheetDescription;
