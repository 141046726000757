/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JobModel } from '../models/JobModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class JobsService {
  /**
   * Retrieves paginated rows of jobs.
   * @param page The page number of the data set to retrieve.
   * @param pageSize The size of the page to retrieve.
   * @returns JobModel Success
   * @throws ApiError
   */
  public static getApiJobsGetRows(
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<JobModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Jobs/GetRows',
      query: {
        page: page,
        pageSize: pageSize,
      },
    });
  }

  /**
   * Retrieves the total count of job rows.
   * @returns number Success
   * @throws ApiError
   */
  public static getApiJobsGetRowCount(): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Jobs/GetRowCount',
    });
  }

  /**
   * Adds a new job row.
   * @param requestBody The job model to add.
   * @returns JobModel Success
   * @throws ApiError
   */
  public static postApiJobsAddRow(requestBody?: JobModel): CancelablePromise<JobModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Jobs/AddRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Updates an existing job row.
   * @param requestBody The job model with updated information.
   * @returns JobModel Success
   * @throws ApiError
   */
  public static putApiJobsUpdateRow(requestBody?: JobModel): CancelablePromise<JobModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Jobs/UpdateRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
