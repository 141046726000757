import { Box, Stack, Typography } from '@mui/material';
import React, { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import useStore from 'context/Store/Store.hooks';

import { ActiveClientDisplayProps } from './ActiveClientDisplay.definition';

function ActiveClientDisplay({
  t,
}: ActiveClientDisplayProps): FunctionComponentElement<ActiveClientDisplayProps> {
  const { clientStore } = useStore();
  const client = clientStore.getClient();

  return (
    <Box
      sx={{
        ml: '0.5rem',
        mr: '0.5rem',
      }}
    >
      <Stack>
        <Typography variant="overline" display="block" sx={{ lineHeight: 'inherit' }}>
          {client?.name}
        </Typography>
        <Typography variant="caption" display="block">
          {`Id: ${client?.id}`}
        </Typography>
      </Stack>
    </Box>
  );
}

export default withTranslation('activeClientDisplay')(ActiveClientDisplay);
