import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FunctionComponentElement } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { TaxSummaryService } from 'api/invoices';

import CenteredLoader from 'components/CenteredLoader/CenteredLoader';
import CompanyInformationCards from 'components/CompanyInformationCards/CompanyInformationCards';
import InformationCard from 'components/InformationCard/InformationCard';

import { FillingPeriodProps } from './FillingPeriod.definitions';

function FillingPeriod({ t }: FillingPeriodProps): FunctionComponentElement<FillingPeriodProps> {
  const { fillingPeriodId } = useParams();
  const location = useLocation();

  const { data: fillingPeriodSummary, isFetching } = useQuery({
    queryKey: ['getFillingPeriodSummary', fillingPeriodId],
    queryFn: async () => {
      const fillingPeriodSummaryData =
        await TaxSummaryService.getApiTaxSummaryGetFillingPeriodSummary(fillingPeriodId);

      return fillingPeriodSummaryData;
    },
  });

  if (isFetching) {
    return <CenteredLoader />;
  }

  return (
    <div style={{ height: '100%' }}>
      <Grid container spacing={2}>
        {fillingPeriodSummary?.advanceVatReturnId && (
          <Grid item xs={12} lg={4} key={fillingPeriodSummary?.advanceVatReturnId}>
            <InformationCard
              subtitle={t('fillingPeriod:filling-period-subtitle', {
                prefix: fillingPeriodSummary?.fillingPeriodPrefix,
              })}
              title={t('fillingPeriod:advance-vat-return-title')}
              bodyText={
                <Trans i18nKey="fillingPeriod">
                  {t('fillingPeriod:advance-vat-return-body', {
                    date: new Date(
                      fillingPeriodSummary?.advanceVatReturnDueDate || '',
                    ).toLocaleDateString('de-DE'),
                  })}
                </Trans>
              }
              buttonText={t('fillingPeriod:cta-button-submission')}
              navigatePath={`${location.pathname}/advance-vat-return/${fillingPeriodSummary.advanceVatReturnId}`}
            />
          </Grid>
        )}
        {fillingPeriodSummary?.ecSalesListId && (
          <Grid item xs={12} lg={4} key={fillingPeriodSummary?.ecSalesListId}>
            <InformationCard
              subtitle={t('fillingPeriod:filling-period-subtitle', {
                prefix: fillingPeriodSummary?.fillingPeriodPrefix,
              })}
              title={t('fillingPeriod:ec-sales-list-title')}
              bodyText={
                <Trans i18nKey="fillingPeriod">
                  {t('fillingPeriod:ec-sales-list-body', {
                    date: new Date(
                      fillingPeriodSummary?.ecSalesListDueDate || '',
                    ).toLocaleDateString('de-DE'),
                  })}
                </Trans>
              }
              buttonText={t('fillingPeriod:cta-button-submission')}
              navigatePath={`${location.pathname}/ec-sales-list/${fillingPeriodSummary.ecSalesListId}`}
            />
          </Grid>
        )}
        {fillingPeriodSummary?.specialPrepaymentId && (
          <Grid item xs={12} lg={4} key={fillingPeriodSummary?.specialPrepaymentId}>
            <InformationCard
              subtitle={t('fillingPeriod:filling-period-subtitle', {
                prefix: fillingPeriodSummary?.fillingPeriodPrefix,
              })}
              title={t('fillingPeriod:special-prepayment-title')}
              bodyText={
                <Trans i18nKey="fillingPeriod">
                  {t('fillingPeriod:special-prepayment-body', {
                    date: new Date(
                      fillingPeriodSummary?.specialPrepaymentDueDate || '',
                    ).toLocaleDateString('de-DE'),
                  })}
                </Trans>
              }
              buttonText={t('fillingPeriod:cta-button-submission')}
              navigatePath={`${location.pathname}/special-prepayment/${fillingPeriodSummary.specialPrepaymentId}`}
            />
          </Grid>
        )}

        {fillingPeriodSummary?.companies && (
          <CompanyInformationCards
            companies={fillingPeriodSummary.companies}
            navigationBasePath={`${location.pathname}/value-added-tax-group/company`}
            hasDelete
          />
        )}
      </Grid>
    </div>
  );
}

export default withTranslation(['navigation', 'fillingPeriod'])(FillingPeriod);
