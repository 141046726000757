/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApprovalStatus } from '../models/ApprovalStatus';
import type { SubmissionModel } from '../models/SubmissionModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApprovalWorkflowService {
  /**
   * Changes the submission status of a submission.
   * @param submissionId The unique identifier of the advanced VAT return.
   * @param nextStatus The next submission status to be set.
   * @returns SubmissionModel Success
   * @throws ApiError
   */
  public static putApiApprovalWorkflowChangeApprovalStatus(
    submissionId?: string,
    nextStatus?: ApprovalStatus,
  ): CancelablePromise<SubmissionModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/ApprovalWorkflow/ChangeApprovalStatus',
      query: {
        submissionId: submissionId,
        nextStatus: nextStatus,
      },
    });
  }
}
