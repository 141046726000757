import { RouteObject } from 'react-router-dom';

import PathConstants from 'pages/PathConstants';

import HomeDrawer from '../HomeDrawer/HomeDrawer';

const AdminRouterConfig: RouteObject[] = [
  {
    path: PathConstants.ADMINISTRATIONS,
    children: [
      {
        index: true,
        element: <HomeDrawer />,
      },
      {
        path: PathConstants.CLIENTS,
        children: [
          {
            index: true,
            element: <HomeDrawer />,
          },
          {
            path: PathConstants.ADD,
            element: <HomeDrawer />,
          },
        ],
      },
      {
        path: PathConstants.SYSTEM_ADMIN_SETTINGS,
        element: <HomeDrawer />,
      },
    ],
  },
];

export default AdminRouterConfig;
