import Box from '@mui/material/Box';
import { FunctionComponentElement } from 'react';

import ShowInfo from 'components/ShowInfo/ShowInfo';

import { NoDrawerContentProps } from './NoDrawerContent.definitions';

function NoDrawerContent({
  text,
}: NoDrawerContentProps): FunctionComponentElement<NoDrawerContentProps> {
  return (
    <Box sx={{ px: 2, py: 1 }}>
      <ShowInfo text={text} />
    </Box>
  );
}

export default NoDrawerContent;
