import { FunctionComponentElement } from 'react';
import { useRoutes } from 'react-router-dom';

import { RouterProps } from 'components/Router/Router.definitions';

function Router({ routes, children }: RouterProps): FunctionComponentElement<RouterProps> {
  const element = useRoutes(routes);
  return (
    <>
      {children}
      {element}
    </>
  );
}

export default Router;
