import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';
import { FunctionComponentElement, useCallback, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import theme from 'themes/theme';

import useStore from 'context/Store/Store.hooks';
import { getNotificationIcon } from 'shared/utils/notification.helper';

import { Toast } from 'models/Toast';

import { ToastNotifierProps } from './ToastNotifier.defintions';

function ToastNotifier({ t }: ToastNotifierProps): FunctionComponentElement<ToastNotifierProps> {
  const { toastStore } = useStore();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const action: SnackbarAction = useCallback(
    (key: SnackbarKey) => (
      <IconButton
        aria-label="delete"
        size="small"
        sx={{ color: theme.palette.common.white }}
        onClick={() => closeSnackbar(key)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    ),
    [closeSnackbar],
  );

  useEffect(() => {
    autorun(() => {
      toastStore.toasts.forEach((toast: Toast) => {
        const { id, title, severity, type } = toast;

        // Do nothing if toast is already displayed
        if (toastStore.displayedIds.includes(id)) {
          return;
        }

        // Display toast using notistack
        enqueueSnackbar(t(title), {
          action,
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          autoHideDuration: 6000,
          disableWindowBlurListener: true,
          variant: 'bdoSnackbar',
          severity,
          icon: getNotificationIcon(type),
        });

        // Keep track of toasts that we've displayed
        toastStore.addToDisplayedIds(id);

        // Dispatch action to remove toast from toast store
        toastStore.deleteToast(toast);
      });
    });
  }, [action, enqueueSnackbar, t, toastStore]);

  return <div />;
}

ToastNotifier.displayName = 'ToastNotifier';
ToastNotifier.defaultProps = {};

export default withTranslation('notifications')(observer(ToastNotifier));
