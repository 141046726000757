import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { SubmissionMessageViewProps } from './SubmissionValidationsView.definitions';

function SubmissionValidationsView({
  submissionResults,
  t,
}: SubmissionMessageViewProps): FunctionComponentElement<SubmissionMessageViewProps> {
  // Check if submissionResults is undefined or empty and display a default message
  if (!submissionResults || submissionResults.length === 0) {
    return (
      <Typography variant="body1" sx={{ margin: '16px 0' }}>
        {t('submissionMessages:noResults')}
      </Typography>
    );
  }

  return (
    <>
      {submissionResults!.map((result) => (
        <>
          <Typography variant="body1" sx={{ paddingTop: '1rem' }}>
            {t(`submissionMessages:submissionType${result.submissionType}`)} -{' '}
            {new Date(result.timeStamp ?? '').toLocaleString('de-DE')} - {result.ericResponseCode}:
          </Typography>
          {result?.submissionResultMessages && result.submissionResultMessages.length > 0 ? (
            <TableContainer
              key={result.id}
              sx={{ height: '100%', overflow: 'unset', marginBottom: '16px' }}
            >
              <Table sx={{ minWidth: 650 }} size="medium">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('submissionMessages:messageType')}</TableCell>
                    <TableCell>{t('submissionMessages:message')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {result?.submissionResultMessages?.map((message) => (
                    <TableRow
                      key={message.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>
                        {t(`${message?.messageType}` ?? '', 'defaultMessageType')}
                      </TableCell>
                      <TableCell>{message.message}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body1" sx={{ fontStyle: 'italic', paddingBottom: '1rem' }}>
              {t('submissionMessages:noResults')}
            </Typography>
          )}
          <Divider />
        </>
      ))}
    </>
  );
}

export default withTranslation('submissionMessages')(SubmissionValidationsView);
