import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import React, { FunctionComponentElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import theme from 'themes/theme';

import NavListTypeToRoute from 'enums/NavListTypeToRoute';

import { CollapsibleListItemProps } from './CollapsibleListItem.definitions';

function CollapsibleListItem({
  navigatePath,
  primaryText,
  paddingLeft,
  children,
}: CollapsibleListItemProps): FunctionComponentElement<CollapsibleListItemProps> {
  const navigate = useNavigate();
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    navigate(navigatePath);
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(!!location.pathname.includes(navigatePath));
  }, [location.pathname, navigatePath]);

  const isActiveBlock = () => {
    let rootPath = '';
    if (navigatePath.startsWith(NavListTypeToRoute.Country)) {
      rootPath = navigatePath.substring(0, 15);
    } else if (navigatePath.startsWith(NavListTypeToRoute.ClientSettings)) {
      rootPath = NavListTypeToRoute.ClientSettings;
    } else if (navigatePath.startsWith(NavListTypeToRoute.SystemSettings)) {
      rootPath = NavListTypeToRoute.SystemSettings;
    } else if (navigatePath.startsWith(NavListTypeToRoute.Administrations)) {
      rootPath = NavListTypeToRoute.Administrations;
    }
    return location.pathname.startsWith(rootPath);
  };

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{
          pl: paddingLeft,
          backgroundColor: isActiveBlock() ? theme.sidebarColor.dark : theme.sidebarColor.main,
        }}
        selected={location.pathname.endsWith(navigatePath)}
      >
        <ListItemText primary={primaryText} />
        {expanded ? <ExpandMore /> : <ExpandLess />}
      </ListItemButton>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        sx={{
          backgroundColor: isActiveBlock() ? theme.sidebarColor.dark : theme.sidebarColor.main,
        }}
      >
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
}

export default CollapsibleListItem;
