import { Typography } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { SystemSettingsProps } from './SystemSettings.definitions';

function SystemSettings({ t }: SystemSettingsProps): FunctionComponentElement<SystemSettingsProps> {
  return (
    <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
      {t('system-settings')}
    </Typography>
  );
}

export default withTranslation('navigation')(SystemSettings);
