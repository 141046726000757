import { Box, Typography } from '@mui/material';
import {
  GridColDef,
  GridPaginationModel,
  GridRowModel,
  ValueOptions,
} from '@mui/x-data-grid-premium';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { AuditorOfficeModel, BaseVatGroupsService, CompanyModel, TaxOfficeModel } from 'api/client';
import { AuditorOfficesService, TaxOfficesService } from 'api/masterdata';

import GridFieldsValidator from 'shared/utils/validators/GridFieldsValidator';

import BdoDataGrid from 'components/BdoDataGrid/BdoDataGrid';
import RenderBasicEditCell from 'components/BdoDataGrid/Cells/BasicEditCell';
import { DataGridRow } from 'components/BdoDataGrid/Types/DataGridRow.definitions';
import FallbackComponent from 'components/FallbackComponent/FallbackComponent';

import { ValueAddedTaxGroupSettingsProps } from './ValueAddedTaxGroupSettings.definitions';

function ValueAddedTaxGroupSettings({
  t,
}: ValueAddedTaxGroupSettingsProps): FunctionComponentElement<ValueAddedTaxGroupSettingsProps> {
  const queryClient = useQueryClient();

  const results = useQueries({
    queries: [
      {
        queryKey: ['setTaxOffices'],
        queryFn: async () => {
          const response = await TaxOfficesService.getApiTaxOffices();
          return response;
        },
      },
      {
        queryKey: ['setAuditorOffices'],
        queryFn: async () => {
          const response = await AuditorOfficesService.getApiAuditorOffices();
          return response;
        },
      },
    ],
  });

  const [
    { isError: isTaxOfficeError, data: taxOffices },
    { isError: isAuditorOfficeError, data: auditorOffices },
  ] = results;

  const columns: GridColDef[] = [
    { field: 'id', headerName: t('id'), type: 'string', width: 150 },
    {
      field: 'name',
      headerName: `${t('companyFields:name')} *`,
      type: 'string',
      editable: true,
      width: 250,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'isControllingCompany',
      headerName: t('companyFields:isControllingCompany'),
      type: 'boolean',
      editable: false,
      width: 150,
    },
    {
      field: 'legalForm',
      headerName: t('companyFields:legalForm'),
      type: 'string',
      editable: true,
      width: 150,
    },
    {
      field: 'taxNumber',
      headerName: `${t('companyFields:taxNumber')} *`,
      type: 'string',
      editable: true,
      width: 150,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'vatTaxNumber',
      headerName: t('companyFields:vatTaxNumber'),
      type: 'string',
      editable: true,
      width: 150,
    },
    {
      field: 'taxOffice',
      headerName: `${t('companyFields:taxOffice')} *`,
      type: 'singleSelect',
      editable: true,
      width: 200,
      valueOptions: taxOffices,
      getOptionValue: (value: ValueOptions) => value && (value as TaxOfficeModel).id,
      getOptionLabel: (value: ValueOptions) => (value && (value as TaxOfficeModel).name) || '',
      valueGetter: (params) => params?.row?.taxOffice?.id || '',
      renderCell: (params) => {
        if (isTaxOfficeError) {
          return <FallbackComponent errorMessage={t('common:errorOccured')} />;
        }
        const data = params?.row?.taxOffice;
        return data ? data.name : '';
      },
      valueSetter: (params) => {
        const { value, row } = params;
        const taxOffice = taxOffices?.find((x) => x.id === value);

        return { ...row, taxOffice };
      },
      groupingValueGetter: (params) => params.value.name,
    },
    {
      field: 'auditorOffice',
      headerName: `${t('companyFields:auditorOffice')} *`,
      type: 'singleSelect',
      editable: true,
      width: 200,
      valueOptions: auditorOffices,
      getOptionValue: (value: ValueOptions) => value && (value as AuditorOfficeModel).id,
      getOptionLabel: (value: ValueOptions) => (value && (value as AuditorOfficeModel).name) || '',
      valueGetter: (params) => params?.row?.auditorOffice?.id || '',
      renderCell: (params) => {
        if (isAuditorOfficeError) {
          return <FallbackComponent errorMessage={t('common:errorOccured')} />;
        }
        const data = params?.row?.auditorOffice;
        return data ? data.name : '';
      },
      valueSetter: (params) => {
        const { value, row } = params;
        const auditorOffice = auditorOffices?.find((x) => x.id === value);

        return { ...row, auditorOffice };
      },
      groupingValueGetter: (params) => params.value.name,
    },
    {
      field: 'email',
      headerName: t('contactInfoFields:email'),
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.contactInfo?.email,
      valueSetter: (params) => ({
        ...params.row,
        contactInfo: { ...params.row.contactInfo, email: params.value },
      }),
    },
    {
      field: 'phoneNumber',
      headerName: t('contactInfoFields:phoneNumber'),
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.contactInfo?.phoneNumber,
      valueSetter: (params) => ({
        ...params.row,
        contactInfo: { ...params.row.contactInfo, phoneNumber: params.value },
      }),
    },
    {
      field: 'street',
      headerName: t('addressFields:street'),
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.address?.street,
      valueSetter: (params) => ({
        ...params.row,
        address: { ...params.row.address, street: params.value },
      }),
    },
    {
      field: 'number',
      headerName: t('addressFields:number'),
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.address?.number,
      valueSetter: (params) => ({
        ...params.row,
        address: { ...params.row.address, number: params.value },
      }),
    },
    {
      field: 'postal',
      headerName: t('addressFields:postal'),
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.address?.postal,
      valueSetter: (params) => ({
        ...params.row,
        address: { ...params.row.address, postal: params.value },
      }),
    },
    {
      field: 'city',
      headerName: t('addressFields:city'),
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.address?.city,
      valueSetter: (params) => ({
        ...params.row,
        address: { ...params.row.address, city: params.value },
      }),
    },
    {
      field: 'region',
      headerName: t('addressFields:region'),
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.address?.region,
      valueSetter: (params) => ({
        ...params.row,
        address: { ...params.row.address, region: params.value },
      }),
    },
    {
      field: 'country',
      headerName: t('addressFields:country'),
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.address?.country,
      valueSetter: (params) => ({
        ...params.row,
        address: { ...params.row.address, country: params.value },
      }),
    },
    {
      field: 'addressNotes',
      headerName: t('addressFields:addressNotes'),
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.address?.addressNotes,
      valueSetter: (params) => ({
        ...params.row,
        address: { ...params.row.address, addressNotes: params.value },
      }),
    },
  ];

  const addDefaultRecord = (id: string): GridRowModel => {
    const company: CompanyModel = {
      id,
      isControllingCompany: false,
      taxOffice: taxOffices && taxOffices.length > 0 ? taxOffices[0] : undefined,
      auditorOffice: auditorOffices && auditorOffices.length > 0 ? auditorOffices[0] : undefined,
    };

    return company;
  };

  const processRowUpdate = async (row: DataGridRow): Promise<DataGridRow> => {
    let resultRow;

    if (row.isNew) {
      resultRow = await BaseVatGroupsService.postApiBaseVatGroupsAddRow(row as CompanyModel);
    } else {
      resultRow = await BaseVatGroupsService.putApiBaseVatGroupsUpdateRow(row as CompanyModel);
    }
    await queryClient.invalidateQueries({ queryKey: ['navigation'] });

    return resultRow as DataGridRow;
  };

  const processRowDelete = async (row: DataGridRow): Promise<boolean> => {
    const result = await BaseVatGroupsService.deleteApiBaseVatGroupsDeleteRow(row as CompanyModel);
    await queryClient.invalidateQueries({ queryKey: ['navigation'] });

    return result;
  };

  const getRows = (pagination: GridPaginationModel) =>
    BaseVatGroupsService.getApiBaseVatGroupsGetRows(pagination.page, pagination.pageSize);

  const getRowCount = () => BaseVatGroupsService.getApiBaseVatGroupsGetRowCount();

  return (
    <div>
      <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
        {t('navigation:value-added-tax-group-settings')}
      </Typography>
      <Box sx={{ p: '1rem' }}>
        <BdoDataGrid
          columns={columns}
          processRowUpdate={processRowUpdate}
          processRowDelete={processRowDelete}
          getRows={getRows}
          getRowCount={getRowCount}
          addDefaultRecord={addDefaultRecord}
          exportFileName={t('valueAddedTaxGroupSettings:exportFileName')}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                addressNotes: false,
              },
            },
            sorting: {
              sortModel: [{ field: 'isControllingCompany', sort: 'desc' }],
            },
          }}
        />
      </Box>
    </div>
  );
}

export default withTranslation([
  'addressFields',
  'companyFields',
  'contactInfoFields',
  'gridTooltips',
  'valueAddedTaxGroupSettings',
  'navigation',
])(ValueAddedTaxGroupSettings);
