import { Box, Typography } from '@mui/material';
import { GridColDef, GridPaginationModel, GridRowModel } from '@mui/x-data-grid-premium';
import { useQuery } from '@tanstack/react-query';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { SystemSettingModel, SystemSettingsService } from 'api/system';

import BdoDataGrid from 'components/BdoDataGrid/BdoDataGrid';
import FallbackComponent from 'components/FallbackComponent/FallbackComponent';

import { DataGridRow } from '../../../../components/BdoDataGrid/Types/DataGridRow.definitions';

import { SystemAdminSettingsProps } from './SystemAdminSettings.definitions';

function SystemAdminSettings({
  t,
}: SystemAdminSettingsProps): FunctionComponentElement<SystemAdminSettingsProps> {
  const { isError: isErrorSystemSettingsKeys, data: systemSettingKeys } = useQuery({
    queryKey: ['setSystemSettingKeys'],
    queryFn: async () => {
      const systemSettingKeysData = await SystemSettingsService.getApiSystemSettingsGetAllKeys();
      return systemSettingKeysData;
    },
  });

  const columns: GridColDef[] = [
    { field: 'id', headerName: t('systemSettingFields:id'), type: 'string', width: 150 },
    {
      field: 'key',
      headerName: `${t('systemSettingFields:key')} *`,
      type: 'singleSelect',
      width: 200,
      editable: true,
      valueOptions: systemSettingKeys,
      renderCell: (params) => {
        if (isErrorSystemSettingsKeys) {
          return <FallbackComponent errorMessage={t('common:errorOccured')} />;
        }
        const data = params?.row?.key;
        return data || '';
      },
    },
    {
      field: 'value',
      headerName: `${t('systemSettingFields:value')} *`,
      type: 'string',
      width: 400,
      editable: true,
    },
  ];

  const addDefaultRecord = (id: string): GridRowModel => {
    const systemSetting: SystemSettingModel = {
      id,
      key: '',
      value: '',
    };

    return systemSetting;
  };

  const processRowUpdate = async (row: DataGridRow): Promise<DataGridRow> => {
    let resultRow;

    if (row.isNew) {
      resultRow = await SystemSettingsService.postApiSystemSettingsAddRow(
        row as SystemSettingModel,
      );
    } else {
      resultRow = await SystemSettingsService.putApiSystemSettingsUpdateRow(
        row as SystemSettingModel,
      );
    }

    return resultRow as DataGridRow;
  };

  const processRowDelete = async (row: DataGridRow): Promise<boolean> =>
    SystemSettingsService.deleteApiSystemSettingsDeleteRow(row as SystemSettingModel);

  const getRows = (pagination: GridPaginationModel) =>
    SystemSettingsService.getApiSystemSettingsGetRows(pagination.page, pagination.pageSize);

  const getRowCount = () => SystemSettingsService.getApiSystemSettingsGetRowCount();

  return (
    <div>
      <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
        {t('navigation:system-admin-settings')}
      </Typography>
      <Box sx={{ p: '1rem' }}>
        <BdoDataGrid
          columns={columns}
          getRows={getRows}
          getRowCount={getRowCount}
          processRowUpdate={processRowUpdate}
          processRowDelete={processRowDelete}
          addDefaultRecord={addDefaultRecord}
          exportFileName={t('systemSettingFields:exportFileName')}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
            sorting: {
              sortModel: [{ field: 'value', sort: 'asc' }],
            },
          }}
        />
      </Box>
    </div>
  );
}

export default withTranslation(['navigation', 'systemSettingFields'])(SystemAdminSettings);
