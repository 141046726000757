import { action, computed, observable } from 'mobx';

import { NavigationEntry, NavigationModel, NavigationService } from 'api/system';

import NavListType from 'enums/NavListType';

import { INavigationStore } from './INavigationStore';

const sortTaxEntries = (entries: NavigationEntry[]) => {
  if (!entries || !Array.isArray(entries)) return;

  if (entries.some((entry) => entry.type === NavListType.Country)) {
    entries.sort((a, b) => {
      const aValue = a.value || '';
      const bValue = b.value || '';

      if (aValue === 'Germany') return -1;
      if (bValue === 'Germany') return 1;

      return aValue.localeCompare(bValue);
    });
  }
};

const sortCompanies = (entries: NavigationEntry[]) => {
  if (!entries || !Array.isArray(entries)) return;

  if (
    entries.some(
      (entry) => entry.type === NavListType.Company || entry.type === NavListType.CompanySettings,
    )
  ) {
    entries.sort((a, b) => {
      const aValue = a.value || '';
      const bValue = b.value || '';

      if (a.postfix === 'controlling-company-postfix') return -1;
      if (b.postfix === 'controlling-company-postfix') return 1;

      return aValue.localeCompare(bValue);
    });
  }

  entries.forEach((entry) => {
    if (entry.childs) {
      sortCompanies(entry.childs);
    }
  });
};

class NavigationStore implements INavigationStore {
  @observable navigation = {} as NavigationModel;

  @computed get navigationList(): NavigationEntry[] {
    const {
      dashboard,
      taxEntries,
      clientSettingEntries,
      systemSettingEntries,
      administrationEntries,
    } = this.navigation;

    const list: NavigationEntry[] = [];

    if (dashboard) {
      list.push(dashboard);
    }

    if (taxEntries && taxEntries.length > 0) {
      sortTaxEntries(taxEntries);
      sortCompanies(taxEntries);
      list.push(...taxEntries);
    }

    if (clientSettingEntries && clientSettingEntries.childs) {
      sortCompanies(clientSettingEntries.childs || []);
      list.push(clientSettingEntries);
    }

    if (systemSettingEntries) {
      list.push(systemSettingEntries);
    }

    if (administrationEntries) {
      list.push(administrationEntries);
    }

    return list;
  }

  @action
  loadNavigation = async (): Promise<NavigationModel> => {
    const navigation = await NavigationService.getApiNavigation();
    this.navigation = navigation;
    return navigation;
  };
}

export default NavigationStore;
