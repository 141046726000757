import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
  styled,
} from '@mui/material';
import { FunctionComponentElement, useRef, useState } from 'react';

import { DropdownMenuProps } from './DropdownMenu.definitions';

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  fontSize: '0.65rem',
  '& .MuiButtonGroup-lastButton': {
    borderLeft: 'solid 1px',
    borderLeftColor: theme.palette.grey[300],
  },
}));

function DropdownMenu({
  menuDisabled,
  defaultButton,
  menuItems,
  isLoading,
}: DropdownMenuProps): FunctionComponentElement<DropdownMenuProps> {
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpenMenu(false);
  };

  return (
    <>
      <StyledButtonGroup
        size="small"
        variant="contained"
        ref={anchorRef}
        aria-label="approval status menu"
        sx={{ fontSize: '0.65rem' }}
      >
        {defaultButton}
        <Button
          size="small"
          aria-controls={openMenu ? 'split-button-menu' : undefined}
          aria-expanded={openMenu ? 'true' : undefined}
          aria-label="select approval"
          aria-haspopup="menu"
          sx={{ fontSize: '0.65rem' }}
          disabled={menuDisabled}
          onClick={handleToggle}
        >
          {isLoading ? <CircularProgress size={25} /> : <ArrowDropDownIcon />}
        </Button>
      </StyledButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={openMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList sx={{ fontSize: '0.65rem' }} id="split-button-menu" autoFocusItem>
                  {menuItems}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default DropdownMenu;
