/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ApprovalStatus {
  '_0' = 0,
  '_1' = 1,
  '_2' = 2,
  '_3' = 3,
  '_4' = 4,
  '_5' = 5,
}
