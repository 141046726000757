import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';

import { FormCheckboxProps } from './FormCheckbox.defintions';

function FormCheckbox<T extends FieldValues>({
  name,
  label,
  isDisabled = false,
}: FormCheckboxProps<T>): FunctionComponentElement<FormCheckboxProps<T>> {
  const { control } = useFormContext();

  return (
    <FormControl>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            label={label}
            labelPlacement="end"
            control={
              <Checkbox
                {...field}
                checked={field.value}
                disabled={isDisabled}
                inputProps={{
                  'aria-label': 'vatGroupSelection',
                }}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
              />
            }
          />
        )}
      />
    </FormControl>
  );
}

export default FormCheckbox;
