import { Box, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useStore from 'context/Store/Store.hooks';

import { SystemSettingsProps } from 'pages/main/System/SystemSettings/SystemSettings.definitions';

import NoDrawerContent from 'components/NoDrawerContent/NoDrawerContent';

function SystemSettingsDrawer({
  t,
}: SystemSettingsProps): FunctionComponentElement<SystemSettingsProps> {
  const navigate = useNavigate();
  const {
    rightDrawerStore: { setOpen, isActionsDrawer, isCommentsDrawer },
  } = useStore();

  return (
    <>
      {isActionsDrawer && (
        <Box sx={{ p: 1, display: 'flex', justifyContent: 'center' }}>
          <Button
            fullWidth
            variant="contained"
            sx={{ textTransform: 'none' }}
            onClick={() => {
              setOpen(false);
              navigate('clients/add');
            }}
          >
            {t('createClient')}
          </Button>
        </Box>
      )}
      {isCommentsDrawer && <NoDrawerContent text={t('noCommentsAvailable')} />}
    </>
  );
}

export default withTranslation('common')(observer(SystemSettingsDrawer));
