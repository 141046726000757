import { observer } from 'mobx-react-lite';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useStore from 'context/Store/Store.hooks';

import TaxFolderActions from 'features/TaxFolderActions/TaxFolderActions';

import CommentSection from 'components/CommentSection/CommentSection';
import NoDrawerContent from 'components/NoDrawerContent/NoDrawerContent';

import CommentEntityType from 'enums/CommentEntityType';

import { TaxYearDrawerProps } from './TaxYearDrawer.definitions';

function TaxYearDrawer({ t }: TaxYearDrawerProps): FunctionComponentElement<TaxYearDrawerProps> {
  const {
    rightDrawerStore: { isActionsDrawer, isCommentsDrawer },
  } = useStore();
  const { taxYearId } = useParams();

  return (
    <>
      {isActionsDrawer && <TaxFolderActions />}
      {isCommentsDrawer &&
        (taxYearId ? (
          <CommentSection entityType={CommentEntityType.TaxYear} referenceId={taxYearId} />
        ) : (
          <NoDrawerContent text={t('noCommentsAvailable')} />
        ))}
    </>
  );
}

export default withTranslation('common')(observer(TaxYearDrawer));
