import { Box, Typography } from '@mui/material';
import {
  GridColDef,
  GridPaginationModel,
  GridPreProcessEditCellProps,
  GridRowModel,
} from '@mui/x-data-grid-premium';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { TaxYearModel, TaxYearsService } from 'api/masterdata';

import useStore from 'context/Store/Store.hooks';

import BdoDataGrid from 'components/BdoDataGrid/BdoDataGrid';
import { DataGridRow } from 'components/BdoDataGrid/Types/DataGridRow.definitions';

import Severity from 'enums/Severity';

import { TaxYearsProps } from './TaxYears.definitions';

function TaxYears({ t }: TaxYearsProps): FunctionComponentElement<TaxYearsProps> {
  const { notificationStore } = useStore();

  const columns: GridColDef[] = [
    { field: 'id', headerName: t('id'), type: 'string', width: 150 },
    {
      field: 'year',
      headerName: `${t('taxYear:year')} *`,
      type: 'string',
      editable: true,
      width: 300,
      preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
        const yearValue = params.props.value as string;
        const isFourDigitYear = /^\d{4}$/.test(yearValue);
        const hasError = yearValue.length !== 4 || !isFourDigitYear;

        if (!hasError) {
          const rows = await TaxYearsService.getApiTaxYearsGetRowCount();
          const existingRows = await TaxYearsService.getApiTaxYearsGetRows(0, rows);
          const isDuplicateYear = existingRows.some(
            (existingRow) => existingRow.year?.toString() === yearValue,
          );

          if (isDuplicateYear) {
            notificationStore.sendNotification(
              t('notifications:notification-row-entry-error-duplicate'),
              Severity.Error,
            );
            return { ...params.props, error: true };
          }
        }

        return { ...params.props, error: hasError };
      },
    },
  ];

  const addDefaultRecord = (id: string): GridRowModel => {
    const taxYears: TaxYearModel = {
      id,
      year: 0,
    };

    return taxYears;
  };

  const processRowUpdate = async (row: DataGridRow): Promise<DataGridRow> => {
    let resultRow;

    if (row.isNew) {
      resultRow = await TaxYearsService.postApiTaxYearsAddRow(row as TaxYearModel);
    } else {
      resultRow = await TaxYearsService.putApiTaxYearsUpdateRow(row as TaxYearModel);
    }

    return resultRow as DataGridRow;
  };

  const processRowDelete = async (row: DataGridRow): Promise<boolean> =>
    TaxYearsService.deleteApiTaxYearsDeleteRow(row as TaxYearModel);

  const getRows = (pagination: GridPaginationModel) =>
    TaxYearsService.getApiTaxYearsGetRows(pagination.page, pagination.pageSize);

  const getRowCount = () => TaxYearsService.getApiTaxYearsGetRowCount();

  return (
    <div>
      <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
        {t('navigation:tax-years')}
      </Typography>
      <Box sx={{ p: '1rem' }}>
        <BdoDataGrid
          columns={columns}
          processRowUpdate={processRowUpdate}
          processRowDelete={processRowDelete}
          getRows={getRows}
          getRowCount={getRowCount}
          addDefaultRecord={addDefaultRecord}
          exportFileName={t('taxYear:exportFileName')}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                addressNotes: false,
              },
            },
            sorting: {
              sortModel: [{ field: 'year', sort: 'desc' }],
            },
          }}
        />
      </Box>
    </div>
  );
}

export default withTranslation(['gridTooltips', 'navigation', 'notifications', 'taxYear'])(
  TaxYears,
);
