import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { TaxSummaryService, VatGroupsService } from 'api/invoices';

import CreateVatGroupsStepper from 'features/CreateVatGroupsStepper/CreateVatGroupsStepper/CreateVatGroupsStepper';

import CenteredLoader from 'components/CenteredLoader/CenteredLoader';
import CompanyInformationCards from 'components/CompanyInformationCards/CompanyInformationCards';

import { TaxGroupProps } from './TaxGroup.definitions';

function TaxGroup({ t }: TaxGroupProps): FunctionComponentElement<TaxGroupProps> {
  const { fillingPeriodId } = useParams();
  const location = useLocation();

  const { isFetching, data: vatGroups } = useQuery({
    queryKey: ['getVatGroups', fillingPeriodId],
    queryFn: async () => {
      const response =
        await VatGroupsService.getApiVatGroupsGetVatGroupByFillingPeriod(fillingPeriodId);

      return response;
    },
    refetchOnMount: true,
  });

  const { data: companies, isFetching: isFetchingCompanies } = useQuery({
    queryKey: ['getCompanies', fillingPeriodId],
    queryFn: async () => {
      const companiesData =
        await TaxSummaryService.getApiTaxSummaryGetCompanySummaries(fillingPeriodId);

      return companiesData;
    },
  });

  if (isFetching || isFetchingCompanies || !vatGroups) {
    return <CenteredLoader />;
  }

  if (vatGroups && vatGroups.length === 0) {
    return <CreateVatGroupsStepper />;
  }

  return (
    <div style={{ height: '100% ' }}>
      {companies && (
        <Grid container spacing={2}>
          <CompanyInformationCards
            companies={companies}
            navigationBasePath={`${location.pathname}/company`}
            hasDelete
          />
        </Grid>
      )}
    </div>
  );
}

export default withTranslation(['navigation', 'fillingPeriod'])(TaxGroup);
