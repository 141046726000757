import { Box, Switch } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { ChangeEvent, FunctionComponentElement, useState } from 'react';
import { get, useFormContext } from 'react-hook-form';
import { withTranslation } from 'react-i18next';

import FormHeader from 'components/Layouts/FormHeader/FormHeader';

import FieldWidth from 'enums/FieldWidth';

import { AddressFieldsProps } from './AddressFields.definitions';

function AddressFields({
  streetField,
  numberField,
  postalField,
  cityField,
  regionField,
  countryField,
  notesField,
  showHeader = false,
  isContactAddress = false,
  t,
}: AddressFieldsProps): FunctionComponentElement<AddressFieldsProps> {
  const [showAddressFields, setShowAddressFields] = useState(!isContactAddress);

  const {
    register,
    trigger,
    resetField,
    formState: { errors },
  } = useFormContext();

  const handleSwitch = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setShowAddressFields((prev) => !prev);
    if (trigger && checked) {
      trigger([streetField, numberField, postalField, cityField, countryField]);
    }
    if (trigger && resetField && !checked) {
      trigger();
      resetField(streetField);
      resetField(numberField);
      resetField(postalField);
      resetField(cityField);
      resetField(countryField);
      resetField(regionField);
      resetField(notesField);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {showHeader && <FormHeader header={t(isContactAddress ? 'contactAddress' : 'address')} />}
        {isContactAddress && (
          <Switch
            sx={{ mb: 2 }}
            inputProps={{ 'aria-label': 'switch_contactAddress' }}
            checked={showAddressFields}
            onChange={handleSwitch}
          />
        )}
      </Box>
      <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
        <TextField
          id="street"
          label={t('street')}
          variant="outlined"
          {...register(streetField, {
            disabled: !showAddressFields,
            required: t('fieldsValidation:requiredHelperText', { fieldName: t('street') }),
          })}
          required
          sx={{ width: FieldWidth.ThreeQuarters }}
          error={!!(errors && get(errors, streetField))}
          helperText={get(errors, streetField) && get(errors, streetField).message}
          inputProps={{ 'data-testid': 'input_street' }}
        />
        <TextField
          id="number"
          label={t('number')}
          variant="outlined"
          {...register(numberField, {
            disabled: !showAddressFields,
            required: t('fieldsValidation:requiredHelperText', { fieldName: t('number') }),
          })}
          required
          sx={{ width: FieldWidth.Quarter }}
          error={!!(errors && get(errors, numberField))}
          helperText={get(errors, numberField) && get(errors, numberField).message}
          inputProps={{ 'data-testid': 'input_number' }}
        />
      </Stack>
      <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
        <TextField
          id="postal"
          label={t('postal')}
          variant="outlined"
          {...register(postalField, {
            disabled: !showAddressFields,
            required: t('fieldsValidation:requiredHelperText', { fieldName: t('postal') }),
            pattern: {
              value: /^[0-9]+$/,
              message: t('fieldsValidation:onlyNumbersHelperText'),
            },
            minLength: {
              value: 4,
              message: t('fieldsValidation:postalHelperText'),
            },
          })}
          required
          sx={{ width: FieldWidth.Half }}
          error={!!(errors && get(errors, postalField))}
          helperText={get(errors, postalField) && get(errors, postalField).message}
          inputProps={{ 'data-testid': 'input_postal' }}
        />
        <TextField
          id="city"
          label={t('city')}
          variant="outlined"
          {...register(cityField, {
            disabled: !showAddressFields,
            required: t('fieldsValidation:requiredHelperText', { fieldName: t('city') }),
          })}
          required
          sx={{ width: FieldWidth.Half }}
          error={!!(errors && get(errors, cityField))}
          helperText={get(errors, cityField) && get(errors, cityField).message}
          inputProps={{ 'data-testid': 'input_city' }}
        />
      </Stack>
      <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
        <TextField
          id="region"
          label={t('region')}
          variant="outlined"
          sx={{ width: FieldWidth.Half }}
          {...register(regionField, {
            disabled: !showAddressFields,
          })}
          inputProps={{ 'data-testid': 'input_region' }}
        />
        <TextField
          id="country"
          label={t('country')}
          variant="outlined"
          sx={{ width: FieldWidth.Half }}
          {...register(countryField, {
            disabled: !showAddressFields,
          })}
          inputProps={{ 'data-testid': 'input_country' }}
        />
      </Stack>
      <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
        <TextField
          id="notes"
          label={t('notes')}
          variant="outlined"
          multiline
          rows={3}
          sx={{ width: FieldWidth.Full }}
          {...register(notesField, {
            disabled: !showAddressFields,
          })}
          inputProps={{ 'data-testid': 'input_notes_address' }}
        />
      </Stack>
    </>
  );
}

export default withTranslation(['addressFields', 'fieldsValidation'])(AddressFields);
