import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import LoadingButton from 'components/Buttons/LoadingButton/LoadingButton';
import CardWrapper from 'components/CardWrapper/CardWrapper';
import FileView from 'components/FileView/FileView';
import FormFooter from 'components/Layouts/FormFooter/FormFooter';
import SummaryCardHeader from 'components/SummaryCardHeader/SummaryCardHeader';

import { ExchangeRatesSummaryStepProps } from './ExchangeRatesSummaryStep.definitions';

function ExchangeRatesSummaryStep({
  selectedFile,
  taxYear,
  isSaving,
  goToPrevStep,
  goToNextStep,
  t,
}: ExchangeRatesSummaryStepProps): FunctionComponentElement<ExchangeRatesSummaryStepProps> {
  const handleImportClick = () => {
    goToNextStep();
  };

  const handlePrevClick = () => {
    goToPrevStep();
  };

  return (
    <div className="formContent">
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <CardWrapper>
          <SummaryCardHeader header={t('fileSummary')} />
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t('file')}</TableCell>
                  <TableCell>{t('taxYear')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell sx={{ verticalAlign: 'top' }}>
                    {selectedFile && (
                      <FileView fileName={selectedFile.name} fileSize={selectedFile.size} />
                    )}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'top' }}>
                    <p>{taxYear?.year}</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardWrapper>
      </Box>
      <FormFooter>
        <Stack direction="row" spacing={3}>
          <Button variant="contained" disabled={isSaving} onClick={handlePrevClick}>
            {t('common:back')}
          </Button>
          <LoadingButton
            title={t('common:import')}
            isLoading={isSaving}
            onClick={handleImportClick}
          />
        </Stack>
      </FormFooter>
    </div>
  );
}

export default withTranslation('importExchangeRatesStepper')(ExchangeRatesSummaryStep);
