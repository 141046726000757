import { RouteObject } from 'react-router-dom';

import PathConstants from 'pages/PathConstants';

import HomeDrawer from '../HomeDrawer/HomeDrawer';
import AdvanceVatReturnDrawer from '../TaxDrawer/AdvanceVatReturnDrawer/AdvanceVatReturnDrawer';
import AnnualVatReturnDrawer from '../TaxDrawer/AnnualVatReturnDrawer/AnnualVatReturnDrawer';
import CompanyDrawer from '../TaxDrawer/CompanyDrawer/CompanyDrawer';
import CountryDrawer from '../TaxDrawer/CountryDrawer/CountryDrawer';
import EcSalesListDrawer from '../TaxDrawer/EcSalesListDrawer/EcSalesListDrawer';
import FillingPeriodDrawer from '../TaxDrawer/FillingPeriodDrawer/FillingPeriodDrawer';
import SpecialPrepaymentDrawer from '../TaxDrawer/SpecialPrepaymentDrawer/SpecialPrepaymentDrawer';
import TaxGroupDrawer from '../TaxDrawer/TaxGroupDrawer/TaxGroupDrawer';
import TaxYearDrawer from '../TaxDrawer/TaxYearDrawer/TaxYearDrawer';

const TaxRouterConfig: RouteObject[] = [
  {
    path: PathConstants.COUNTRY,
    children: [
      {
        index: true,
        element: <CountryDrawer />,
      },
      {
        path: PathConstants.TAX_YEAR,
        children: [
          {
            index: true,
            element: <TaxYearDrawer />,
          },
          {
            path: PathConstants.ANNUAL_VAT_RETURN,
            element: <AnnualVatReturnDrawer />,
          },
          {
            path: PathConstants.FILLING_PERIOD,
            children: [
              {
                index: true,
                element: <FillingPeriodDrawer />,
              },
              {
                path: PathConstants.ADVANCE_VAT_RETURN,
                element: <AdvanceVatReturnDrawer />,
              },
              {
                path: PathConstants.EC_SALES_LIST,
                element: <EcSalesListDrawer />,
              },
              {
                path: PathConstants.SPECIAL_PREPAYMENT,
                element: <SpecialPrepaymentDrawer />,
              },
              {
                path: PathConstants.VAT_GROUP,
                children: [
                  {
                    index: true,
                    element: <TaxGroupDrawer />,
                  },
                  {
                    path: PathConstants.ADD,
                    element: <HomeDrawer />,
                  },
                  {
                    path: PathConstants.COMPANY,
                    children: [
                      {
                        index: true,
                        element: <CompanyDrawer />,
                      },
                      {
                        path: PathConstants.IMPORT_INVOICES,
                        element: <HomeDrawer />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/create-tax-folder/',
    element: <HomeDrawer />,
  },
];

export default TaxRouterConfig;
