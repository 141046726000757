import { Alert, Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { FunctionComponentElement } from 'react';
import { useParams } from 'react-router-dom';

import { CompaniesService } from 'api/invoices';

import useInvoicesExist from 'shared/api-hooks/useInvoicesExist';
import useUnfinishedImportExist from 'shared/api-hooks/useUnfinishedImportExist';

import ImportInvoicesStepper from 'features/ImportInvoicesStepper/ImportInvoicesStepper/ImportInvoicesStepper';

import CompanyHeader from 'components/CompanyHeader/CompanyHeader';

import { CompanyProps } from './Company.definitions';
import CompanyInvoices from './CompanyInvoices/CompanyInvoices';

function Company(): FunctionComponentElement<CompanyProps> {
  const { companyId } = useParams();

  const { isFetching: isFetchingInvoicesExist, data: hasInvoices } = useInvoicesExist(companyId);

  const { isFetching: isFetchingUnfinishedImport, data: hasUnfinishedImport } =
    useUnfinishedImportExist(companyId);

  const { isFetching: isFetchingCompany, data: company } = useQuery({
    queryKey: ['getCompany', companyId],
    queryFn: async () => {
      const companyData = await CompaniesService.getApiCompaniesGetCompany(companyId);
      return companyData;
    },
  });

  if (isFetchingInvoicesExist || isFetchingUnfinishedImport || isFetchingCompany) {
    return (
      <Box sx={{ p: '1rem', width: '100%' }}>
        {/* <CompanyHeader name={company?.name} isControllingCompany={company?.isControllingCompany} /> */}
        {/* <CenteredLoader /> */}
      </Box>
    );
  }

  if (!hasInvoices && !hasUnfinishedImport) {
    return (
      <Box sx={{ width: '100%' }}>
        <ImportInvoicesStepper />
      </Box>
    );
  }

  if (hasInvoices && company) {
    return (
      <Box sx={{ width: '100%' }}>
        <CompanyHeader name={company?.name} isControllingCompany={company?.isControllingCompany} />
        <CompanyInvoices company={company} />
      </Box>
    );
  }

  if (!hasInvoices && hasUnfinishedImport) {
    return (
      <Box sx={{ width: '100%' }}>
        <CompanyHeader name={company?.name} isControllingCompany={company?.isControllingCompany} />
        <Alert sx={{ mb: 2 }} severity="warning">
          {t('notifications:notification-unfinished-imports')}
        </Alert>
      </Box>
    );
  }

  return <div />;
}

export default Company;
