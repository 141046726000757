import { Box, Pagination } from '@mui/material';
import { FunctionComponentElement, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import { PdfViewerProps } from './PdfViewer.definitions';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
const options = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
};

function PdfViewer({ file }: PdfViewerProps): FunctionComponentElement<PdfViewerProps> {
  const [pages, setPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setPages(numPages);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ border: '2px solid grey', marginBottom: '1rem' }}
      >
        <Document
          file={`data:application/pdf;base64,${file}`}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
        >
          <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false} />
        </Document>
      </Box>
      <Pagination count={pages} shape="rounded" onChange={handleChange} />
    </>
  );
}

export default PdfViewer;
