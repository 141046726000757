import { Box, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FunctionComponentElement, ReactNode } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { TaxValidationsService } from 'api/taxRule';

import useStore from 'context/Store/Store.hooks';
import useFillingPeriod from 'shared/api-hooks/useFillingPeriod';

import CommentSection from 'components/CommentSection/CommentSection';
import NoDrawerContent from 'components/NoDrawerContent/NoDrawerContent';

import CommentEntityType from 'enums/CommentEntityType';

import { CompanyDrawerProps } from './CompanyDrawer.definitions';

function CompanyDrawer({ t }: CompanyDrawerProps): FunctionComponentElement<CompanyDrawerProps> {
  const navigate = useNavigate();
  const { companyId, fillingPeriodId } = useParams();
  const {
    rightDrawerStore: { isActionsDrawer, isCommentsDrawer, setOpen, commentsDrawerGridContext },
  } = useStore();
  const { isReadonly } = useFillingPeriod(fillingPeriodId);

  const handleTriggerValidation = async () => {
    setOpen(false);
    await TaxValidationsService.getApiTaxValidationsEnqueueCompanyValidations(companyId);
  };

  const renderComments = (): ReactNode => {
    if (!companyId) {
      return <NoDrawerContent text={t('noCommentsAvailable')} />;
    }
    if (commentsDrawerGridContext.entityType && commentsDrawerGridContext.referenceId) {
      const { entityType, referenceId } = commentsDrawerGridContext;
      return <CommentSection key={referenceId} entityType={entityType} referenceId={referenceId} />;
    }
    return (
      <CommentSection
        key={companyId}
        entityType={CommentEntityType.Company}
        referenceId={companyId}
      />
    );
  };

  return (
    <>
      {isActionsDrawer && (
        <Box sx={{ p: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Button
            fullWidth
            variant="contained"
            sx={{ mb: '10px', textTransform: 'none' }}
            onClick={() => {
              setOpen(false);
              navigate('import-invoices');
            }}
            disabled={isReadonly}
          >
            {t('startInvoiceImport')}
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{ mb: '10px', textTransform: 'none' }}
            onClick={handleTriggerValidation}
            disabled={isReadonly}
          >
            {t('triggerValidation')}
          </Button>
        </Box>
      )}
      {isCommentsDrawer && renderComments()}
    </>
  );
}

export default withTranslation('common')(observer(CompanyDrawer));
