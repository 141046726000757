/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanySummaryModel } from '../models/CompanySummaryModel';
import type { CountrySummaryModel } from '../models/CountrySummaryModel';
import type { FillingPeriodSummaryModel } from '../models/FillingPeriodSummaryModel';
import type { TaxYearSummaryModel } from '../models/TaxYearSummaryModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaxSummaryService {
  /**
   * Retrieves tax information for a specific country.
   * @param countryId The ID of the country for which tax information is requested.
   * @returns CountrySummaryModel Success
   * @throws ApiError
   */
  public static getApiTaxSummaryGetCountrySummary(
    countryId?: string,
  ): CancelablePromise<CountrySummaryModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxSummary/GetCountrySummary',
      query: {
        countryId: countryId,
      },
    });
  }

  /**
   * Retrieves the summary of a filling period based on the specified filling period identifier.
   * @param fillingPeriodId The unique identifier of the filling period.
   * @returns FillingPeriodSummaryModel Success
   * @throws ApiError
   */
  public static getApiTaxSummaryGetFillingPeriodSummary(
    fillingPeriodId?: string,
  ): CancelablePromise<FillingPeriodSummaryModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxSummary/GetFillingPeriodSummary',
      query: {
        fillingPeriodId: fillingPeriodId,
      },
    });
  }

  /**
   * Retrieves the companies belonging to the value-added tax group within the specified filling period.
   * @param fillingPeriodId The unique identifier of the filling period.
   * @returns CompanySummaryModel Success
   * @throws ApiError
   */
  public static getApiTaxSummaryGetCompanySummaries(
    fillingPeriodId?: string,
  ): CancelablePromise<Array<CompanySummaryModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxSummary/GetCompanySummaries',
      query: {
        fillingPeriodId: fillingPeriodId,
      },
    });
  }

  /**
   * Retrieves tax year information for a specific country and tax year.
   * @param countryId The ID of the country for which tax information is requested.
   * @param taxYearId The ID of the tax year for which information is requested.
   * @returns TaxYearSummaryModel Success
   * @throws ApiError
   */
  public static getApiTaxSummaryGetTaxYearSummaries(
    countryId?: string,
    taxYearId?: string,
  ): CancelablePromise<Array<TaxYearSummaryModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxSummary/GetTaxYearSummaries',
      query: {
        countryId: countryId,
        taxYearId: taxYearId,
      },
    });
  }
}
