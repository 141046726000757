/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyModel } from '../models/CompanyModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CompaniesService {
  /**
   * Retrieves information about a specific company based on its unique identifier.
   * @param companyId The unique identifier of the company to retrieve.
   * @returns CompanyModel Success
   * @throws ApiError
   */
  public static getApiCompaniesGetCompany(companyId?: string): CancelablePromise<CompanyModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Companies/GetCompany',
      query: {
        companyId: companyId,
      },
    });
  }

  /**
   * Deletes a company inside of a filling period.
   * @param companyId The id of the company to delete.
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteApiCompaniesDeleteCompany(companyId?: string): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/Companies/DeleteCompany',
      query: {
        companyId: companyId,
      },
    });
  }
}
