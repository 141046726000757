import {
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { dateFormat } from 'shared/utils/date.helper';

import CardWrapper from 'components/CardWrapper/CardWrapper';

import { DisplayDeadlinesProps } from './DisplayDeadlines.definitions';

function DisplayDeadlines({
  deadlines,
  t,
}: DisplayDeadlinesProps): FunctionComponentElement<DisplayDeadlinesProps> {
  if (!deadlines) {
    return (
      <Stack direction="row" spacing={2} sx={{ margin: 4 }}>
        <Typography variant="h6" component="h3">
          {t('noDeadlinesProvided')}
        </Typography>
      </Stack>
    );
  }

  return (
    <>
      <CardWrapper>
        <Typography variant="h5" component="h3">
          {t('annualVatReturnDeadline')}
        </Typography>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small" aria-label={t('annualVatReturnDeadline')}>
            <TableHead>
              <TableRow>
                <TableCell align="center">{t('forYear')}</TableCell>
                <TableCell align="center">{t('forPeriodAndInterval')}</TableCell>
                <TableCell align="center">{t('dueDate')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key={deadlines?.annualVatReturnDeadline?.dueDate}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="center">{deadlines?.annualVatReturnDeadline?.forYear}</TableCell>
                <TableCell align="center">
                  {deadlines?.annualVatReturnDeadline?.forPeriod} /{' '}
                  {deadlines?.annualVatReturnDeadline?.forInterval}
                </TableCell>
                <TableCell align="center">
                  {deadlines?.annualVatReturnDeadline?.dueDate
                    ? new Date(deadlines.annualVatReturnDeadline.dueDate).toLocaleDateString(
                        'de-DE',
                        dateFormat,
                      )
                    : ''}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardWrapper>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CardWrapper>
            <Typography variant="h5" component="h3">
              {t('advanceVatReturnDeadlines')}
            </Typography>
            <TableContainer>
              <Table size="small" aria-label={t('advanceVatReturnDeadlines')}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t('forYear')}</TableCell>
                    <TableCell align="center">{t('forPeriodAndInterval')}</TableCell>
                    <TableCell align="center">{t('dueDate')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deadlines?.advanceVatReturnDeadlines?.map((deadline) => (
                    <TableRow
                      key={deadline.dueDate}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="center">{deadline.forYear}</TableCell>
                      <TableCell align="center">
                        {deadline.forPeriod} / {deadline.forInterval}
                      </TableCell>
                      <TableCell align="center">
                        {deadline.dueDate
                          ? new Date(deadline.dueDate).toLocaleDateString('de-DE', dateFormat)
                          : ''}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardWrapper>
        </Grid>

        <Grid item xs={6}>
          <CardWrapper>
            <Typography variant="h5" component="h3">
              {t('ecSalesListDeadlines')}
            </Typography>
            <TableContainer>
              <Table size="small" aria-label={t('ecSalesListDeadlines')}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t('forYear')}</TableCell>
                    <TableCell align="center">{t('forPeriodAndInterval')}</TableCell>
                    <TableCell align="center">{t('dueDate')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deadlines?.ecSalesListDeadlines?.map((deadline) => (
                    <TableRow
                      key={deadline.dueDate}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="center">{deadline.forYear}</TableCell>
                      <TableCell align="center">
                        {deadline.forPeriod} / {deadline.forInterval}
                      </TableCell>
                      <TableCell align="center">
                        {deadline.dueDate
                          ? new Date(deadline.dueDate).toLocaleDateString('de-DE', dateFormat)
                          : ''}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardWrapper>
        </Grid>
      </Grid>
    </>
  );
}

export default withTranslation(['displayDeadlines'])(DisplayDeadlines);
