import { withTranslation } from 'react-i18next';

import SubmissionDialog from 'components/SubmissionDialog/SubmissionDialog';

import { SubmissionCorrectionDialogProps } from './SubmissionCorrectionDialog.definitions';

function SubmissionCorrectionDialog({
  open,
  onClose,
  onSubmit,
  t,
}: SubmissionCorrectionDialogProps) {
  return (
    <SubmissionDialog
      open={open}
      dialogTitle={t('submissions:correction-dialog-title')}
      switchLabel={t('submissions:correction-dialog-switch-label')}
      warningText={t('submissions:correction-warning-text')}
      cancelButtonText={t('submissions:btn-correction-cancel')}
      submitButtonText={t('submissions:btn-correction-approve')}
      onClose={onClose}
      onFormSubmit={onSubmit}
      showWarning
    />
  );
}

export default withTranslation('submissions')(SubmissionCorrectionDialog);
