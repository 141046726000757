import { Grid } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FunctionComponentElement, useCallback, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import { CountrySummaryModel, TaxFoldersService, TaxSummaryService } from 'api/invoices';
import { ApiError } from 'api/taxCalculation';

import useStore from 'context/Store/Store.hooks';

import CenteredLoader from 'components/CenteredLoader/CenteredLoader';
import DeletionApprovalDialog from 'components/DeletionApprovalDialog/DeletionApprovalDialog';
import InformationCard from 'components/InformationCard/InformationCard';

import NamedApprovalStatus from 'enums/NamedApprovalStatus';
import Severity from 'enums/Severity';

import { CountryProps } from './Country.definitions';

function Country({ t }: CountryProps): FunctionComponentElement<CountryProps> {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedYear, setSelectedYear] = useState<string>('');
  const [isMutating, setIsMutating] = useState<boolean>(false);

  const { fillingPeriodId } = useParams();
  const { countryId } = useParams();
  const { toastStore } = useStore();

  const queryClient = useQueryClient();

  const { data: countryTaxInformation, isFetching } = useQuery({
    queryKey: ['getCountryTaxInformation', countryId],
    queryFn: async () => {
      const countryTaxInformationData =
        await TaxSummaryService.getApiTaxSummaryGetCountrySummary(countryId);

      return countryTaxInformationData;
    },
  });

  const handleOpen = (yearId: string) => {
    setSelectedYear(yearId);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const sendMessage = useCallback(
    (success: boolean, message?: string) => {
      if (success) {
        toastStore.addToast({
          id: v4(),
          title: t('notifications:notification-row-entry-deleted'),
          severity: Severity.Info,
        });
      } else {
        toastStore.addToast({
          id: v4(),
          title: t(`notifications:${message}`),
          severity: Severity.Error,
        });
      }
    },
    [t, toastStore],
  );

  const { mutate: deleteTaxYear } = useMutation({
    mutationFn: async (yearId: string) => {
      const response = await TaxFoldersService.deleteApiTaxFoldersDeleteTaxFolder(yearId);
      return response;
    },
    onSuccess: async () => {
      queryClient.setQueryData(
        ['getCountryTaxInformation', countryId],
        (oldData: CountrySummaryModel) => {
          const updatedData = {
            ...oldData,
            taxYearSummaries: oldData?.taxYearSummaries?.filter(
              (year) => year.yearId !== selectedYear,
            ),
          };
          return updatedData;
        },
      );
      setSelectedYear('');
      sendMessage(true);
      await queryClient.invalidateQueries({ queryKey: ['navigation'] });
    },
    onMutate: () => setIsMutating(true),
    onSettled: () => setIsMutating(false),
    onError: (error) => {
      const apiError = error as ApiError;
      sendMessage(false, apiError.body);
    },
    mutationKey: ['deleteCompany', fillingPeriodId],
  });

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    deleteTaxYear(selectedYear);
    setOpen(false);
  };

  if (isFetching || isMutating) {
    return <CenteredLoader />;
  }

  return (
    <div style={{ height: '100%' }}>
      <Grid container spacing={2}>
        {countryTaxInformation?.taxYearSummaries?.map((summary) => (
          <Grid item xs={6} lg={4} key={summary.yearId}>
            <InformationCard
              subtitle={t(`navigation:${countryTaxInformation?.countryName}`)}
              title={t('countryTaxInformationFields:tax-year', { year: summary.year })}
              bodyText={t('countryTaxInformationFields:tax-information', { ...summary })}
              buttonText={t('countryTaxInformationFields:cta-button')}
              navigatePath={`/country/${countryId}/tax-year/${summary.yearId}`}
              deleteEntity={() => handleOpen(summary.yearId ?? '')}
            >
              <Grid container spacing={0} fontSize="small" paddingTop="0.5rem">
                {summary.annualVatReturnApprovalStatus !== undefined && (
                  <>
                    <Grid item xs={5}>
                      {t('submissions:AnnualVatReturnApprovalStatus')}
                    </Grid>
                    <Grid item xs={7}>
                      {t(
                        `submissions:${
                          NamedApprovalStatus[Number(summary.annualVatReturnApprovalStatus)]
                        }`,
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </InformationCard>
          </Grid>
        ))}
      </Grid>
      <DeletionApprovalDialog
        open={open}
        title={t('countryFields:deletion-dialog-delete-title')}
        message={t('countryFields:dialog-delete-warning')}
        switchLabel={t('countryFields:dialog-switch-label-title')}
        handleClose={handleClose}
        handleFormSubmit={handleFormSubmit}
      />
    </div>
  );
}

export default withTranslation([
  'navigation',
  'countryFields',
  'countryTaxInformationFields',
  'dialogs',
  'submissions',
])(Country);
