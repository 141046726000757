import { Stack } from '@mui/material';

import Loader from 'components/Loader/Loader';

function CenteredLoader() {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ width: 1, height: '100%' }}
    >
      <Loader />
    </Stack>
  );
}

export default CenteredLoader;
