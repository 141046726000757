import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { SubmissionLogViewProps } from './SubmissionLogView.definitions';

function SubmissionLogView({
  submissionResults,
  t,
}: SubmissionLogViewProps): FunctionComponentElement<SubmissionLogViewProps> {
  // Check if submissionResults is undefined or empty and display a default message
  if (!submissionResults || submissionResults.length === 0) {
    return (
      <Typography variant="body1" sx={{ margin: '16px 0' }}>
        {t('submissionMessages:noResults')}
      </Typography>
    );
  }

  return (
    <>
      {submissionResults!.map((result) => (
        <TableContainer
          key={result.id}
          sx={{ height: '100%', overflow: 'unset', marginBottom: '16px' }}
        >
          <Table sx={{ minWidth: 650 }} size="medium">
            <TableHead>
              <TableRow>
                <TableCell>{t('submissionMessages:severity')}</TableCell>
                <TableCell>{t('submissionMessages:message')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {result?.submissionResultLogs?.map((message) => (
                <TableRow
                  key={message.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{t(`${message?.severity}` ?? '')}</TableCell>
                  <TableCell>{message.message}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ))}
    </>
  );
}

export default withTranslation('submissionMessages')(SubmissionLogView);
