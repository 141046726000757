import { action } from 'mobx';

import { ClientModel } from 'api/client';

import { IClientStore } from './IClientStore';

class ClientStore implements IClientStore {
  private readonly clientKey = 'clientData';

  @action
  getClient = () => {
    const storedClientData = localStorage.getItem(this.clientKey);
    return storedClientData ? JSON.parse(storedClientData) : undefined;
  };

  @action
  setClient = (client: ClientModel) => {
    localStorage.setItem(this.clientKey, JSON.stringify(client));
  };

  @action
  logout = () => {
    localStorage.removeItem(this.clientKey);
  };
}

export default ClientStore;
