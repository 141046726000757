import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { SpecialPrepaymentsService } from 'api/taxCalculation';

import BdoTaxFormDataGrid from 'components/BdoTaxFormDataGrid/BdoTaxFormDataGrid';

import { SubmissionModel } from '../../../../api/taxCalculation/models/SubmissionModel';

import { SpecialPrepaymentProps } from './SpecialPrepayment.definitions';

function SpecialPrepayment({
  t,
}: SpecialPrepaymentProps): FunctionComponentElement<SpecialPrepaymentProps> {
  const { specialPrepaymentId } = useParams();

  return specialPrepaymentId ? (
    <BdoTaxFormDataGrid
      title={t('special-prepayment')}
      dataId={specialPrepaymentId}
      getData={() => SpecialPrepaymentsService.getApiSpecialPrepayments(specialPrepaymentId)}
      validateTaxForm={
        () => Promise.resolve({} as SubmissionModel)
        // SpecialPrepaymentSubmissionService.postApiSpecialPrepaymentSubmissionValidate(
        //   specialPrepaymentId,
        // )
      }
      submitTaxForm={
        () => Promise.resolve({} as SubmissionModel)
        // SpecialPrepaymentSubmissionService.postApiSpecialPrepaymentSubmissionSubmit(
        //   specialPrepaymentId,
        // )
      }
      createCorrection={() => null}
    />
  ) : (
    <div />
  );
}

export default withTranslation('navigation')(SpecialPrepayment);
