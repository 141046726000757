import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { FunctionComponentElement, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import removeWhitespaces from 'shared/utils/string.helper';

import CardWrapper from 'components/CardWrapper/CardWrapper';
import FallbackComponent from 'components/FallbackComponent/FallbackComponent';
import FormFooter from 'components/Layouts/FormFooter/FormFooter';
import Loader from 'components/Loader/Loader';

import FieldWidth from 'enums/FieldWidth';

import { TaxFolderStepperForm } from '../CreateTaxFolderStepper/CreateTaxFolderStepper.definitions';
import {
  getCountriesQuery,
  getOpenTaxYearsQuery,
} from '../CreateTaxFolderStepper/CreateTaxFolderStepper.helper';

import { TaxFolderStepProps } from './TaxFolderStep.definitions';

function TaxFolderStep({
  taxFolderStepperForm,
  updateTaxFolderStepperForm,
  goToNextStep,
  t,
}: TaxFolderStepProps): FunctionComponentElement<TaxFolderStepProps> {
  const { allCountries, allOpenTaxYears, selectedCountry } = taxFolderStepperForm;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm({ defaultValues: taxFolderStepperForm, mode: 'onChange' });

  const selectedCountryId = useWatch({
    control,
    name: 'selectedCountry.id',
  });

  const {
    isFetching: isFetchingCountries,
    data: countries,
    error: countriesError,
  } = useQuery({
    queryKey: ['countries'],
    queryFn: getCountriesQuery,
    enabled: !allCountries,
  });

  const {
    isFetching: isFetchingTaxYears,
    data: openTaxYears,
    error: taxYearsError,
    refetch,
  } = useQuery({
    queryKey: ['openTaxYears'],
    queryFn: () => getOpenTaxYearsQuery(selectedCountryId),
    enabled: !allOpenTaxYears && !!selectedCountryId,
  });

  const handleCountryChange = async (countryId: string) => {
    const country = countries?.find((x) => x.id === countryId);
    if (country) {
      if (country === taxFolderStepperForm.selectedCountry) {
        // same country selected again
        return;
      }
      // different country selected
      setValue('selectedCountry', country, { shouldValidate: true });
      setValue(`selectedOpenTaxYear`, undefined, { shouldValidate: true });
      setValue(`selectedOpenTaxYear.id`, '', { shouldValidate: true });
    } else {
      setValue(`selectedCountry`, undefined, { shouldValidate: true });
      setValue(`selectedOpenTaxYear`, undefined, { shouldValidate: true });
    }
  };

  const handleOpenTaxYearChange = async (taxYearId: string) => {
    const selectedOpenTaxYear = openTaxYears?.find((x) => x.id === taxYearId);
    if (selectedOpenTaxYear) {
      setValue('selectedOpenTaxYear', selectedOpenTaxYear, { shouldValidate: true });
    } else {
      setValue(`selectedOpenTaxYear`, undefined, { shouldValidate: true });
    }
  };

  const handleNextClick = (data: TaxFolderStepperForm) => {
    const updatedInfo = {
      ...taxFolderStepperForm,
      allCountries: countries,
      allOpenTaxYears: openTaxYears,
      selectedCountry: data.selectedCountry,
      selectedOpenTaxYear: data.selectedOpenTaxYear,
      selectedInterval: data.selectedInterval,
      permanentPeriodExtension: data.permanentPeriodExtension,
    };
    updateTaxFolderStepperForm(updatedInfo);
    goToNextStep();
  };

  // refetch tax years when country is changed
  useEffect(() => {
    if (!selectedCountryId || selectedCountryId === selectedCountry?.id) return;
    refetch();
  }, [refetch, selectedCountry, selectedCountryId]);

  if (countriesError) {
    return <FallbackComponent error={countriesError} />;
  }

  if (taxYearsError) {
    return <FallbackComponent error={taxYearsError} />;
  }

  if (isFetchingCountries) {
    return (
      <Box sx={{ margin: 'auto' }}>
        <Loader />
      </Box>
    );
  }

  return (
    <form onSubmit={handleSubmit(handleNextClick)} className="formContent">
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <CardWrapper>
          <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
            <FormControl required sx={{ width: FieldWidth.Full }}>
              <Controller
                name="selectedCountry"
                control={control}
                rules={{ required: true }}
                render={({ field: { value } }) => (
                  <>
                    <InputLabel id="country">{t('navigation:country')}</InputLabel>
                    <Select
                      labelId="country"
                      id="country"
                      label={t('navigation:country')}
                      value={value?.id || ''}
                      onChange={(event) => handleCountryChange(event.target.value)}
                      inputProps={{ MenuProps: { disableScrollLock: true } }}
                    >
                      {countries?.map((country) => (
                        <MenuItem key={country.id} value={country.id}>
                          {t(`navigation:${removeWhitespaces(country.name)}`, country.name)}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              />
            </FormControl>
          </Stack>
          <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
            {isFetchingTaxYears ? (
              <Box sx={{ margin: 'auto' }}>
                <Loader />
              </Box>
            ) : (
              <FormControl required sx={{ width: FieldWidth.Full }}>
                <Controller
                  name="selectedOpenTaxYear"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value } }) => (
                    <>
                      <InputLabel id="opentaxYears">{t('opentaxYears')}</InputLabel>
                      <Select
                        labelId="opentaxYears"
                        id="opentaxYears"
                        label={t('opentaxYears')}
                        value={value?.id || ''}
                        onChange={(event) => handleOpenTaxYearChange(event.target.value)}
                        inputProps={{ MenuProps: { disableScrollLock: true } }}
                        disabled={!openTaxYears}
                      >
                        {openTaxYears ? (
                          openTaxYears.map((taxYear) => (
                            <MenuItem key={taxYear.id || uuidv4()} value={taxYear.id || ''}>
                              {taxYear.year}
                            </MenuItem>
                          ))
                        ) : (
                          <div />
                        )}
                      </Select>
                    </>
                  )}
                />
              </FormControl>
            )}
          </Stack>
          <Stack direction="row" spacing={2} sx={{ marginBottom: 4 }}>
            <FormControl required sx={{ width: FieldWidth.Half }}>
              <Controller
                name="selectedInterval"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <>
                    <InputLabel id="interval">{t('common:interval')}</InputLabel>
                    <Select
                      labelId="interval"
                      id="interval"
                      label={t('common:interval')}
                      value={value || '12'}
                      onChange={onChange}
                      inputProps={{ MenuProps: { disableScrollLock: true } }}
                    >
                      <MenuItem key="1" value="1">
                        {t(`common:annual`, 'Annual')}
                      </MenuItem>
                      <MenuItem key="4" value="4">
                        {t(`common:quarterly`, 'Quarterly')}
                      </MenuItem>
                      <MenuItem key="12" value="12">
                        {t(`common:monthly`, 'Monthly')}
                      </MenuItem>
                    </Select>
                  </>
                )}
              />
            </FormControl>

            <FormControl
              sx={{
                width: FieldWidth.Half,
                border: '1px solid #ccc',
                borderRadius: '4px',
                paddingTop: '2px',
                paddingLeft: '15px',
              }}
            >
              <FormGroup>
                <FormControlLabel
                  label={t('permanentPeriodExtension')}
                  labelPlacement="end"
                  control={
                    <Controller
                      name="permanentPeriodExtension"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          inputProps={{
                            'aria-label': t('permanentPeriodExtension'),
                          }}
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                        />
                      )}
                    />
                  }
                />
              </FormGroup>
            </FormControl>
          </Stack>
        </CardWrapper>
      </Box>
      <FormFooter>
        <Button type="submit" variant="contained" disabled={!isValid}>
          {t('common:next')}
        </Button>
      </FormFooter>
    </form>
  );
}

export default withTranslation([
  'createTaxFolderStepper',
  'fieldsValidation',
  'common',
  'navigation',
])(observer(TaxFolderStep));
