import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';

import keycloak from 'configs/keycloak-config';
import 'configs/i18n';
import theme from 'themes/theme';

import App from 'app/App';
import './index.scss';
import StoreProvider from 'context/Store/Store';
import RootStore from 'shared/stores/RootStore/RootStore';

import BdoSnackbar from 'components/BdoSnackbar/BdoSnackbar';
import ToastNotifier from 'components/ToastNotifier/ToastNotifier';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const rootStore = new RootStore();

root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{
      onLoad: 'login-required',
      checkLoginIframe: false,
    }}
  >
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <StoreProvider rootStore={rootStore}>
          <SnackbarProvider
            maxSnack={5}
            Components={{
              bdoSnackbar: BdoSnackbar,
            }}
          >
            <App />
            <ToastNotifier />
          </SnackbarProvider>
        </StoreProvider>
      </ThemeProvider>
    </React.StrictMode>
  </ReactKeycloakProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
