import { Button } from '@mui/material';
import { useState } from 'react';
import { withTranslation } from 'react-i18next';

import SubmissionCorrectionDialog from 'components/SubmissionCorrectionDialog/SubmissionCorrectionDialog';

import { CorrectionButtonProps } from './CorrectionButton.definitions';

function CorrectionButton({
  createCorrection,
  isCreated,
  handleMutation,
  t,
}: CorrectionButtonProps) {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setDialogOpen(true)}
        sx={{ fontSize: '0.8rem' }}
        disabled={isCreated}
      >
        {isCreated ? t('btn-correction-created') : t('btn-correct-submission')}
      </Button>
      <SubmissionCorrectionDialog
        onSubmit={(e) => {
          setDialogOpen(false);
          handleMutation(true);
          createCorrection(e);
          handleMutation(false);
        }}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
}

export default withTranslation('submissions')(CorrectionButton);
