import { observer } from 'mobx-react-lite';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useStore from 'context/Store/Store.hooks';
import useSubmissionFormData from 'shared/api-hooks/useSubmissionData';

import FillingPeriodActions from 'features/FillingPeriodActions/FillingPeriodActions';

import CommentSection from 'components/CommentSection/CommentSection';
import NoDrawerContent from 'components/NoDrawerContent/NoDrawerContent';

import CommentEntityType from 'enums/CommentEntityType';

import { AdvanceVatReturnDrawerProps } from './AdvanceVatReturnDrawer.definitions';

function AdvanceVatReturnDrawer({
  t,
}: AdvanceVatReturnDrawerProps): FunctionComponentElement<AdvanceVatReturnDrawerProps> {
  const {
    rightDrawerStore: { isActionsDrawer, isCommentsDrawer },
  } = useStore();
  const { advanceVatReturnId } = useParams();
  const { isDisabled } = useSubmissionFormData(advanceVatReturnId);

  return (
    <>
      {isActionsDrawer && <FillingPeriodActions isDisabled={isDisabled} />}
      {isCommentsDrawer &&
        (advanceVatReturnId ? (
          <CommentSection
            entityType={CommentEntityType.Submission}
            referenceId={advanceVatReturnId}
          />
        ) : (
          <NoDrawerContent text={t('noCommentsAvailable')} />
        ))}
    </>
  );
}

export default withTranslation('common')(observer(AdvanceVatReturnDrawer));
