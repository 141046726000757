import { RouteObject } from 'react-router-dom';

import PathConstants from 'pages/PathConstants';

import ClientMasterdata from '../Client/ClientMasterdata/ClientMasterdata';
import ClientSettings from '../Client/ClientSettings/ClientSettings';
import CompanySettings from '../Client/CompanySettings/CompanySettings';
import CultureSettings from '../Client/CultureSettings/CultureSettings';
import ImportLogs from '../Client/ImportLogs/ImportLogs';
import ImportSettings from '../Client/ImportSettings/ImportSettings';
import Jobs from '../Client/Jobs/Jobs';
import RoleManagement from '../Client/RoleManagement/RoleManagement';
import TaxCodes from '../Client/TaxCodes/TaxCodes';
import UserManagement from '../Client/UserManagement/UserManagement';
import ValueAddedTaxGroupSettings from '../Client/ValueAddedTaxGroupSettings/ValueAddedTaxGroupSettings';

const ClientRouterConfig: RouteObject[] = [
  {
    path: PathConstants.CLIENT_SETTINGS,
    children: [
      {
        index: true,
        element: <ClientSettings />,
      },
      {
        path: PathConstants.CLIENT_MASTERDATA,
        element: <ClientMasterdata />,
      },
      {
        path: PathConstants.TAX_CODES,
        element: <TaxCodes />,
      },
      {
        path: PathConstants.JOBS,
        element: <Jobs />,
      },
      {
        path: PathConstants.IMPORT_LOGS,
        element: <ImportLogs />,
      },
      {
        path: PathConstants.VAT_GROUP_SETTINGS,
        children: [
          {
            index: true,
            element: <ValueAddedTaxGroupSettings />,
          },
          {
            path: PathConstants.COMPANY_SETTINGS,
            element: <CompanySettings />,
          },
        ],
      },
      {
        path: PathConstants.IMPORT_SETTINGS,
        element: <ImportSettings />,
      },
      {
        path: PathConstants.CULTURE_SETTINGS,
        element: <CultureSettings />,
      },
      {
        path: PathConstants.USER_MANAGEMENT,
        element: <UserManagement />,
      },
      {
        path: PathConstants.ROLE_MANAGEMENT,
        element: <RoleManagement />,
      },
    ],
  },
];

export default ClientRouterConfig;
