import { Box, Typography } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { ContactInfoViewProps } from './ContactInfoView.definitions';

function ContactInfoView({
  contactInfo,
  showHeader = true,
  t,
}: ContactInfoViewProps): FunctionComponentElement<ContactInfoViewProps> {
  if (!contactInfo) {
    return (
      <Typography variant="subtitle1" gutterBottom>
        -
      </Typography>
    );
  }

  return (
    <Box>
      {showHeader && (
        <Typography variant="h6" component="div">
          {t('companyFields:contactInfo')}
        </Typography>
      )}
      <Typography variant="subtitle1" component="div" gutterBottom>
        {contactInfo.email} <br />
        {contactInfo.phoneNumber}
      </Typography>
    </Box>
  );
}

export default withTranslation('companyFields')(ContactInfoView);
