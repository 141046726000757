import { observer } from 'mobx-react-lite';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useStore from 'context/Store/Store.hooks';
import useSubmissionFormData from 'shared/api-hooks/useSubmissionData';

import TaxFolderActions from 'features/TaxFolderActions/TaxFolderActions';

import CommentSection from 'components/CommentSection/CommentSection';
import NoDrawerContent from 'components/NoDrawerContent/NoDrawerContent';

import CommentEntityType from 'enums/CommentEntityType';

import { AnnualVatReturnDrawerProps } from './AnnualVatReturnDrawer.definitions';

function AnnualVatReturnDrawer({
  t,
}: AnnualVatReturnDrawerProps): FunctionComponentElement<AnnualVatReturnDrawerProps> {
  const {
    rightDrawerStore: { isActionsDrawer, isCommentsDrawer },
  } = useStore();
  const { annualVatReturnId } = useParams();
  const { isDisabled } = useSubmissionFormData(annualVatReturnId);

  return (
    <>
      {isActionsDrawer && <TaxFolderActions isDisabled={isDisabled} />}
      {isCommentsDrawer &&
        (annualVatReturnId ? (
          <CommentSection
            entityType={CommentEntityType.Submission}
            referenceId={annualVatReturnId}
          />
        ) : (
          <NoDrawerContent text={t('noCommentsAvailable')} />
        ))}
    </>
  );
}

export default withTranslation('common')(observer(AnnualVatReturnDrawer));
