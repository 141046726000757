/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdvanceVatReturnModel } from '../models/AdvanceVatReturnModel';
import type { FillingPeriodModel } from '../models/FillingPeriodModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdvanceVatReturnSubmissionService {
  /**
   * Validates the specified advance VAT return.
   * @param advanceVatReturnId The ID of the advance VAT return.
   * @returns AdvanceVatReturnModel Success
   * @throws ApiError
   */
  public static postApiAdvanceVatReturnSubmissionValidate(
    advanceVatReturnId?: string,
  ): CancelablePromise<AdvanceVatReturnModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/AdvanceVatReturnSubmission/Validate',
      query: {
        advanceVatReturnId: advanceVatReturnId,
      },
    });
  }

  /**
   * Submits the specified advance VAT return.
   * @param advanceVatReturnId The ID of the advance VAT return.
   * @returns AdvanceVatReturnModel Success
   * @throws ApiError
   */
  public static postApiAdvanceVatReturnSubmissionSubmit(
    advanceVatReturnId?: string,
  ): CancelablePromise<AdvanceVatReturnModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/AdvanceVatReturnSubmission/Submit',
      query: {
        advanceVatReturnId: advanceVatReturnId,
      },
    });
  }

  /**
   * Creates a correction for the specified filling period.
   * @param fillingPeriodId The ID of the filling period to create a correction for.
   * @returns FillingPeriodModel Success
   * @throws ApiError
   */
  public static postApiAdvanceVatReturnSubmissionCreateCorrection(
    fillingPeriodId?: string,
  ): CancelablePromise<FillingPeriodModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/AdvanceVatReturnSubmission/CreateCorrection',
      query: {
        fillingPeriodId: fillingPeriodId,
      },
    });
  }
}
