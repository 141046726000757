import { RouteObject } from 'react-router-dom';

import PathConstants from 'pages/PathConstants';

import CreateClientStepper from 'features/CreateClientStepper/CreateClientStepper/CreateClientStepper';
import ImportExchangeRatesStepper from 'features/ImportExchangeRatesStepper/ImportExchangeRatesStepper/ImportExchangeRatesStepper';

import AdvanceVatReturnForm from '../System/AdvanceVatReturnForm/AdvanceVatReturnForm';
import AnnualVatReturnForm from '../System/AnnualVatReturnForm/AnnualVatReturnForm';
import AuditorOffices from '../System/AuditorOffices/AuditorOffices';
import Contacts from '../System/Contacts/Contacts';
import Countries from '../System/Countries/Countries';
import EcSalesListForm from '../System/EcSalesListForm/EcSalesListForm';
import ExchangeRates from '../System/ExchangeRates/ExchangeRates';
import SystemSettings from '../System/SystemSettings/SystemSettings';
import TaxIssues from '../System/TaxIssues/TaxIssues';
import TaxOffices from '../System/TaxOffices/TaxOffices';
import TaxRuleMappings from '../System/TaxRuleMappings/TaxRuleMappings';
import TaxRules from '../System/TaxRules/TaxRules';
import TaxYears from '../System/TaxYears/TaxYears';
import ValueAddedTaxForms from '../System/ValueAddedTaxForms/ValueAddedTaxForms';
import ValueAddedTaxFormYear from '../System/ValueAddedTaxFormYear/ValueAddedTaxFormYear';

const SystemRouterConfig: RouteObject[] = [
  {
    path: PathConstants.SYSTEM_SETTINGS,
    children: [
      {
        index: true,
        element: <SystemSettings />,
      },
      {
        path: `${PathConstants.CLIENTS}/${PathConstants.ADD}`,
        element: <CreateClientStepper />,
      },
      {
        path: PathConstants.VAT_FORMS,
        children: [
          {
            index: true,
            element: <ValueAddedTaxForms />,
          },
          {
            path: PathConstants.VAT_FORM_YEAR,
            children: [
              {
                index: true,
                element: <ValueAddedTaxFormYear />,
              },
              {
                path: PathConstants.ANNUAL_VAT_RETURN_FORM,
                element: <AnnualVatReturnForm />,
              },
              {
                path: PathConstants.ADVANCE_VAT_RETURN_FORM,
                element: <AdvanceVatReturnForm />,
              },
              {
                path: PathConstants.EC_SALES_LIST_FORM,
                element: <EcSalesListForm />,
              },
            ],
          },
        ],
      },
      {
        path: PathConstants.TAX_OFFICES,
        element: <TaxOffices />,
      },
      {
        path: PathConstants.AUDITOR_OFFICES,
        element: <AuditorOffices />,
      },
      {
        path: PathConstants.TAX_YEARS,
        element: <TaxYears />,
      },
      {
        path: PathConstants.CONTACTS,
        element: <Contacts />,
      },
      {
        path: PathConstants.EXCHANGE_RATES,
        children: [
          {
            index: true,
            element: <ExchangeRates />,
          },
          {
            path: PathConstants.IMPORT_EXCHANGE_RATES,
            element: <ImportExchangeRatesStepper />,
          },
        ],
      },
      {
        path: PathConstants.COUNTRIES,
        element: <Countries />,
      },
      {
        path: PathConstants.TAX_ISSUES,
        element: <TaxIssues />,
      },
      {
        path: PathConstants.TAX_RULES,
        element: <TaxRules />,
      },
      {
        path: PathConstants.TAX_RULE_MAPPINGS,
        element: <TaxRuleMappings />,
      },
    ],
  },
];

export default SystemRouterConfig;
