import { Typography } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { AdvanceVatReturnFormProps } from './AdvanceVatReturnForm.definitions';

function AdvanceVatReturnForm({
  t,
}: AdvanceVatReturnFormProps): FunctionComponentElement<AdvanceVatReturnFormProps> {
  return (
    <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
      {t('advance-vat-return-form')}
    </Typography>
  );
}

export default withTranslation('navigation')(AdvanceVatReturnForm);
