import { Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { FunctionComponentElement, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ClientCreationForm } from 'api/client';

import { getNewClientFormInfo } from 'shared/utils/formFields.helper';

import ClientStep from '../ClientStep/ClientStep';
import ClientUsersStep from '../ClientUsersStep/ClientUsersStep';
import SummaryStep from '../SummaryStep/SummaryStep';
import ValueAddedTaxStep from '../ValueAddedTaxStep/ValueAddedTaxStep';

import { CreateClientStepperProps } from './CreateClientStepper.definitions';
import { postClientFormQuery } from './CreateClientStepper.helper';

function CreateClientStepper({
  t,
}: CreateClientStepperProps): FunctionComponentElement<CreateClientStepperProps> {
  const [clientFormInfo, setClientFormInfo] = useState<ClientCreationForm>(getNewClientFormInfo());
  const [activeStep, setActiveStep] = useState(0);
  const [isMutating, setIsMutating] = useState(false);
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: () => postClientFormQuery(clientFormInfo),
    onSuccess: async () => {
      navigate('/system-settings/clients');
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    },
    onMutate: () => setIsMutating(true),
    onSettled: () => setIsMutating(false),
    mutationKey: ['postClientCreationForm'],
  });

  const steps = [
    t('addClientDetails'),
    t('addCompanyDetails'),
    // t('addExistingUsers'),
    t('addNewUsers'),
    t('common:reviewAndSave'),
  ];

  const isLastStep = activeStep === steps.length - 1;

  const handleNextClick = () => {
    if (isLastStep) {
      mutate();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handlePrevClick = () => {
    setActiveStep(activeStep - 1);
  };

  const renderStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <ClientStep
            clientFormInfo={clientFormInfo}
            updateClientFormInfo={setClientFormInfo}
            goToNextStep={handleNextClick}
          />
        );
      case 1:
        return (
          <ValueAddedTaxStep
            clientFormInfo={clientFormInfo}
            updateClientFormInfo={setClientFormInfo}
            goToNextStep={handleNextClick}
            goToPrevStep={handlePrevClick}
          />
        );
      // case 2:
      //   return (
      //     <ExistingUserStep
      //       clientFormInfo={clientFormInfo}
      //       updateClientFormInfo={setClientFormInfo}
      //       goToNextStep={handleNextClick}
      //       goToPrevStep={handlePrevClick}
      //     />
      //   );
      case 2:
        return (
          <ClientUsersStep
            clientFormInfo={clientFormInfo}
            updateClientFormInfo={setClientFormInfo}
            goToNextStep={handleNextClick}
            goToPrevStep={handlePrevClick}
          />
        );
      case 3:
        return (
          <SummaryStep
            clientFormInfo={clientFormInfo}
            isSaving={isMutating}
            goToNextStep={handleNextClick}
            goToPrevStep={handlePrevClick}
          />
        );
      default:
        return <div>Not Found</div>;
    }
  };

  return (
    <>
      <Typography component="h1" variant="h6">
        {t('common:createClient')}
      </Typography>
      <Stepper activeStep={activeStep} sx={{ py: 2 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {renderStepContent(activeStep)}
    </>
  );
}

export default withTranslation(['createClientStepper', 'common'])(CreateClientStepper);
