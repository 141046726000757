/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditLogModel } from '../models/AuditLogModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuditLogsService {
  /**
   * Retrieves a paginated list of audit log entries.
   * @param page The page number of entries to retrieve.
   * @param pageSize The number of entries per page.
   * @returns AuditLogModel Success
   * @throws ApiError
   */
  public static getApiAuditLogsGetRows(
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<AuditLogModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/AuditLogs/GetRows',
      query: {
        page: page,
        pageSize: pageSize,
      },
    });
  }

  /**
   * Gets the total count of audit log entries in the database.
   * @returns number Success
   * @throws ApiError
   */
  public static getApiAuditLogsGetRowCount(): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/AuditLogs/GetRowCount',
    });
  }
}
