/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TaxOfficeModel } from '../models/TaxOfficeModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaxOfficesService {
  /**
   * Gets the list of all tax offices.
   * @returns TaxOfficeModel Success
   * @throws ApiError
   */
  public static getApiTaxOffices(): CancelablePromise<Array<TaxOfficeModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxOffices',
    });
  }

  /**
   * Creates a new tax office.
   * @param requestBody The tax office model containing the details of the tax office to be created.
   * @returns TaxOfficeModel Success
   * @throws ApiError
   */
  public static postApiTaxOffices(requestBody?: TaxOfficeModel): CancelablePromise<TaxOfficeModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/TaxOffices',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Retrieves a paginated list of tax office rows.
   * @param page The page number of the data set to retrieve.
   * @param pageSize The size of the page to retrieve.
   * @returns TaxOfficeModel Success
   * @throws ApiError
   */
  public static getApiTaxOfficesGetRows(
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<TaxOfficeModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxOffices/GetRows',
      query: {
        page: page,
        pageSize: pageSize,
      },
    });
  }

  /**
   * Retrieves the total count of tax office rows.
   * @returns number Success
   * @throws ApiError
   */
  public static getApiTaxOfficesGetRowCount(): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxOffices/GetRowCount',
    });
  }

  /**
   * Adds a new tax office row.
   * @param requestBody The tax office model to add.
   * @returns TaxOfficeModel Success
   * @throws ApiError
   */
  public static postApiTaxOfficesAddRow(
    requestBody?: TaxOfficeModel,
  ): CancelablePromise<TaxOfficeModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/TaxOffices/AddRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Updates an existing tax office row.
   * @param requestBody The tax office model with updated information.
   * @returns TaxOfficeModel Success
   * @throws ApiError
   */
  public static putApiTaxOfficesUpdateRow(
    requestBody?: TaxOfficeModel,
  ): CancelablePromise<TaxOfficeModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/TaxOffices/UpdateRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Deletes an tax office if it's not referenced anymore.
   * @param requestBody The tax office model to delete.
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteApiTaxOfficesDeleteRow(
    requestBody?: TaxOfficeModel,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/TaxOffices/DeleteRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
