import { v4 as uuidv4 } from 'uuid';

import {
  AddressModel,
  AuditorOfficeModel,
  ClientCreationForm,
  CompanyModel,
  ContactInfoModel,
  ContactModel,
  TaxOfficeModel,
  UserModel,
} from 'api/client';

export const getNewAddress = (): AddressModel => ({
  street: '',
  number: '',
  city: '',
  country: '',
  postal: '',
});

export const getNewContactInfo = (): ContactInfoModel => ({
  phoneNumber: '',
  email: '',
});

export const getNewContact = (): ContactModel => ({
  id: uuidv4(),
  firstName: '',
  lastName: '',
  notes: '',
  address: getNewAddress(),
  contactInfo: getNewContactInfo(),
});

export const getNewTaxOffice = (): TaxOfficeModel => ({
  id: '',
  name: '',
  taxOfficeNumber: 0,
  address: getNewAddress(),
});

export const getNewAuditorOffice = (): AuditorOfficeModel => ({
  id: '',
  name: '',
  address: getNewAddress(),
});

export const getNewCompany = (): CompanyModel => ({
  id: uuidv4(),
  name: '',
  taxNumber: '',
  address: getNewAddress(),
  isControllingCompany: false,
  taxOffice: getNewTaxOffice(),
  auditorOffice: getNewAuditorOffice(),
});

export const getNewUser = (): UserModel => ({
  id: uuidv4(),
  firstName: '',
  lastName: '',
  email: '',
  isAuditorUser: false,
  roles: [],
  username: '',
});

export const getNewClientFormInfo = (): ClientCreationForm => {
  const clientFormInfo: ClientCreationForm = {
    client: {
      id: uuidv4(),
      name: '',
    },
    valueAddedTaxGroup: [],
    clientUsers: [],
  };

  return clientFormInfo;
};
