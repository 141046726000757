import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import {
  GridColDef,
  GridPaginationModel,
  GridPreProcessEditCellProps,
} from '@mui/x-data-grid-premium';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FunctionComponentElement, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { BaseVatGroupsService, CompanyModel, FieldMappingModel } from 'api/client';
import { FieldMappingsService, ImportSettingsService } from 'api/import';

import AddressView from 'components/AddressView/AddressView';
import BdoDataGrid from 'components/BdoDataGrid/BdoDataGrid';
import { DataGridRow } from 'components/BdoDataGrid/Types/DataGridRow.definitions';
import CardWrapper from 'components/CardWrapper/CardWrapper';
import ContactInfoView from 'components/ContactInfoView/ContactInfoView';
import ContactView from 'components/ContactView/ContactView';
import Loader from 'components/Loader/Loader';
import SummaryCardHeader from 'components/SummaryCardHeader/SummaryCardHeader';

import { CompanySettingsProps } from './CompanySettings.definitions';

function CompanySettings({
  t,
}: CompanySettingsProps): FunctionComponentElement<CompanySettingsProps> {
  const { companyId } = useParams();
  const [cultureIdentifier, setCultureIdentifier] = useState('de-DE');
  const [importSettingsId, setImportSettingsId] = useState('');

  const formatOptions = [
    { value: 0, label: t('companySettings:percentage-format-none') },
    { value: 1, label: (0.19).toLocaleString(cultureIdentifier) },
    { value: 2, label: (19).toLocaleString(cultureIdentifier, { minimumFractionDigits: 2 }) },
    { value: 3, label: '19 %' },
  ];

  const getFormatLabel = (value: number) => {
    const option = formatOptions.find((x) => x.value === value);
    return option ? option.label : '';
  };

  const { isFetching, data: company } = useQuery({
    queryKey: ['setCompany-setCultureIdentifier', companyId],
    queryFn: async () => {
      const model = await BaseVatGroupsService.getApiBaseVatGroupsGetCompany(companyId);

      return model;
    },
  });

  useEffect(() => {
    if (company) {
      setCultureIdentifier(company.importSettings!.cultureIdentifier!);
      setImportSettingsId(company.importSettingsId!);
    }
  }, [company]);

  const putImportSettings = async (culture: string) => {
    const response = await ImportSettingsService.putApiImportSettings(importSettingsId, culture);
    return response;
  };

  const { mutate } = useMutation({
    mutationFn: putImportSettings,
    onSuccess: (importSettings) => {
      setCultureIdentifier(importSettings.cultureIdentifier!);
    },
    mutationKey: ['putImportSettings', companyId],
  });

  if (isFetching || !company) {
    return (
      <Box sx={{ margin: 'auto' }}>
        <Loader />
      </Box>
    );
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: t('id'), type: 'string', width: 150 },
    {
      field: 'source',
      headerName: t('companySettings:source'),
      type: 'string',
      editable: true,
      width: 250,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value.length <= 0;
        return { ...params.props, error: hasError };
      },
    },
    { field: 'target', headerName: t('companySettings:target'), type: 'string', width: 150 },
    {
      field: 'defaultValue',
      headerName: t('companySettings:defaultValue'),
      type: 'string',
      width: 150,
    },
    {
      field: 'isRequired',
      headerName: t('companySettings:isRequired'),
      type: 'boolean',
      width: 100,
    },
    {
      field: 'percentageFormat',
      headerName: t('companySettings:percentage-format'),
      type: 'singleSelect',
      editable: true,
      width: 200,
      valueOptions: formatOptions,
      valueSetter: (params) => ({
        ...params.row,
        percentageFormat: params.value,
      }),
      groupingValueGetter: ({ value }) => getFormatLabel(value),
    },
  ];

  const processRowUpdate = async (row: DataGridRow): Promise<DataGridRow> => {
    // Field mappings can't be added.
    const resultRow = await FieldMappingsService.putApiFieldMappingsUpdateRow(row as CompanyModel);

    return resultRow as DataGridRow;
  };

  const processRowDelete = async (row: DataGridRow): Promise<boolean> =>
    FieldMappingsService.deleteApiFieldMappingsDeleteRow(row as FieldMappingModel);

  const getRows = (pagination: GridPaginationModel) =>
    FieldMappingsService.getApiFieldMappingsGetRows(
      company.importSettingsId!,
      pagination.page,
      pagination.pageSize,
    );

  const getRowCount = () =>
    FieldMappingsService.getApiFieldMappingsGetRowCount(company.importSettingsId!);

  const handleCultureIdentifierChange = async (event: SelectChangeEvent) => {
    const culture = event.target.value as string;
    if (!culture || culture.length === 0) {
      return;
    }

    mutate(culture);
  };

  return (
    <div>
      <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
        {company.name} - (
        {company.isControllingCompany
          ? t('common:controllingCompany')
          : t('common:subsidiaryCompany')}
        )
      </Typography>

      <Box sx={{ p: '1rem' }}>
        <CardWrapper>
          <SummaryCardHeader header={t('companySettings:company-masterdata')} />
          <br />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="subtitle2">{t('companyFields:name')}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="subtitle1">
                      {company.name} - (
                      {company.isControllingCompany
                        ? t('common:controllingCompany')
                        : t('common:subsidiaryCompany')}
                      )
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left">
                    <Typography variant="subtitle2">{t('companyFields:legalForm')}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="subtitle1">{company.legalForm || '-'}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="subtitle2">{t('companyFields:taxNumber')}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="subtitle1">{company.taxNumber}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="subtitle2">{t('companyFields:vatTaxNumber')}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="subtitle1">{company.vatTaxNumber || '-'}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="subtitle2">{t('companyFields:address')}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <AddressView address={company.address} showHeader={false} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="subtitle2">{t('companyFields:contactInfo')}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <ContactInfoView contactInfo={company.contactInfo} showHeader={false} />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left">
                    <Typography variant="subtitle2">{t('companyFields:taxOffice')}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="subtitle1" component="div">
                      {company.taxOffice?.name} ({company.taxOffice?.taxOfficeNumber})
                      <div>{company.taxOffice?.notes}</div>
                      <AddressView address={company.taxOffice?.address} showHeader={false} />
                      <ContactInfoView
                        contactInfo={company.taxOffice?.contactInfo}
                        showHeader={false}
                      />
                      <ContactView contact={company.taxOffice?.contact} showHeader={false} />
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left">
                    <Typography variant="subtitle2">{t('companyFields:auditorOffice')}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="subtitle1" component="div">
                      <div>{company.auditorOffice?.name}</div>
                      <div>{company.auditorOffice?.notes}</div>
                      <AddressView address={company.auditorOffice?.address} showHeader={false} />
                      <ContactInfoView
                        contactInfo={company.auditorOffice?.contactInfo}
                        showHeader={false}
                      />
                      <ContactView contact={company.auditorOffice?.contact} showHeader={false} />
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardWrapper>

        <CardWrapper>
          <SummaryCardHeader header={t('companySettings:import-settings')} />
          <br />
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="culture-identifier-select-label">
                {t('companySettings:cultureIdentifier')}
              </InputLabel>
              <Select
                labelId="culture-identifier-select-label"
                id="culture-identifier-select"
                value={cultureIdentifier}
                label={t(':ompanySettings:cultureIdentifier')}
                onChange={handleCultureIdentifierChange}
              >
                <MenuItem value="de-DE">{t('companySettings:cultureIdentifierGerman')}</MenuItem>
                <MenuItem value="en-US">{t('companySettings:cultureIdentifierAmerican')}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <br />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="subtitle2">{t('companySettings:date-format')}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="subtitle1">
                      {new Date(Date.now()).toLocaleString(cultureIdentifier)}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left">
                    <Typography variant="subtitle2">
                      {t('companySettings:number-format')}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="subtitle1">
                      {(10234.56).toLocaleString(cultureIdentifier)} €
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <BdoDataGrid
            columns={columns}
            processRowUpdate={processRowUpdate}
            processRowDelete={processRowDelete}
            getRows={getRows}
            getRowCount={getRowCount}
            exportFileName={t('companySettings:exportFileName')}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              sorting: {
                sortModel: [
                  { field: 'isRequired', sort: 'desc' },
                  { field: 'name', sort: 'desc' },
                ],
              },
            }}
          />{' '}
        </CardWrapper>
      </Box>
      <br />
    </div>
  );
}

export default withTranslation(['common', 'companyFields', 'companySettings'])(CompanySettings);
