/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdvanceVatReturnModel } from '../models/AdvanceVatReturnModel';
import type { InvoiceModel } from '../models/InvoiceModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdvanceVatReturnsService {
  /**
   * Retrieves the advance VAT return details for a specified ID.
   * @param advanceVatReturnId The unique identifier for the advance VAT return.
   * @returns AdvanceVatReturnModel Success
   * @throws ApiError
   */
  public static getApiAdvanceVatReturns(
    advanceVatReturnId?: string,
  ): CancelablePromise<AdvanceVatReturnModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/AdvanceVatReturns',
      query: {
        advanceVatReturnId: advanceVatReturnId,
      },
    });
  }

  /**
   * Retrieves a list of invoices used in the VAT return calculation for a given tax form entry.
   * @param taxFormEntryId The ID of the tax form entry for which to retrieve the invoices.
   * @returns InvoiceModel Success
   * @throws ApiError
   */
  public static getApiAdvanceVatReturnsGetUsedInvoicesForCalculation(
    taxFormEntryId?: string,
  ): CancelablePromise<Array<InvoiceModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/AdvanceVatReturns/GetUsedInvoicesForCalculation',
      query: {
        taxFormEntryId: taxFormEntryId,
      },
    });
  }
}
