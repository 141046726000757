import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { FunctionComponentElement } from 'react';
import { get, useFormContext } from 'react-hook-form';
import { withTranslation } from 'react-i18next';

import FormHeader from 'components/Layouts/FormHeader/FormHeader';

import FieldWidth from 'enums/FieldWidth';

import AddressFields from '../AddressFields/AddressFields';
import ContactFields from '../ContactFields/ContactFields';
import ContactInfoFields from '../ContactInfoFields/ContactInfoFields';

import { AuditorOfficeFieldsProps } from './AuditorOfficeFields.definitions';

function AuditorOfficeFields({
  nameField,
  notesField,
  addressField,
  contactInfoField,
  contactField,
  showHeader,
  t,
}: AuditorOfficeFieldsProps): FunctionComponentElement<AuditorOfficeFieldsProps> {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Box component="span">
      {showHeader && <FormHeader header={t('auditorOfficeHeader')} />}
      <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
        <TextField
          id="name"
          label={t('name')}
          variant="outlined"
          required
          {...register(nameField, {
            required: t('fieldsValidation:requiredHelperText', { fieldName: t('name') }),
          })}
          error={!!(errors && get(errors, nameField))}
          sx={{ width: FieldWidth.Full }}
          helperText={get(errors, nameField) && get(errors, nameField).message}
          inputProps={{ 'data-testid': 'input_name' }}
        />
      </Stack>

      <ContactInfoFields
        showHeader={false}
        phoneNumberField={`${contactInfoField}.phoneNumber`}
        emailField={`${contactInfoField}.email`}
      />

      <AddressFields
        streetField={`${addressField}.street`}
        numberField={`${addressField}.number`}
        postalField={`${addressField}.postal`}
        cityField={`${addressField}.city`}
        regionField={`${addressField}.region`}
        countryField={`${addressField}.country`}
        notesField={`${addressField}.notes`}
      />

      <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
        <TextField
          id="notes"
          label={t('notes')}
          variant="outlined"
          multiline
          rows={3}
          sx={{ width: 815 }}
          {...register(notesField)}
          inputProps={{ 'data-testid': 'input_notes_address' }}
        />
      </Stack>

      <ContactFields
        firstNameField={`${contactField}.firstName`}
        lastNameField={`${contactField}.lastName`}
        contactInfoField={`${contactField}.contactInfo`}
        notesField={`${contactField}.notes`}
        addressField={`${contactField}.address`}
        showHeader
      />
    </Box>
  );
}

export default withTranslation(['auditorOfficeFields', 'fieldsValidation'])(AuditorOfficeFields);
