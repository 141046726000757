import ClearIcon from '@mui/icons-material/Clear';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import Link from '@mui/material/Link';
import { FunctionComponentElement, useState } from 'react';
import { withTranslation } from 'react-i18next';

import theme from 'themes/theme';

import { getDateTime } from 'shared/utils/date.helper';
import { getNotificationColor } from 'shared/utils/notification.helper';

import { NotificationListItemProps } from './NotificationListItem.definitions';

function NotificationListItem({
  notification,
  icon,
  linkText,
  href,
  onDeleteClick,
  t,
}: NotificationListItemProps): FunctionComponentElement<NotificationListItemProps> {
  const { severity, title, content, timestamp, parameters } = notification;
  const [showParameters, setShowParameters] = useState(false);

  const toggleParameters = () => {
    setShowParameters(!showParameters);
  };

  return (
    <Card sx={{ bgcolor: theme.palette.grey[100] }}>
      <CardHeader
        sx={{ p: 1 }}
        avatar={<Avatar sx={{ bgcolor: getNotificationColor(severity) }}>{icon}</Avatar>}
        action={
          <IconButton aria-label="delete" onClick={() => onDeleteClick(notification)}>
            <ClearIcon fontSize="small" />
          </IconButton>
        }
        title={
          <Typography variant="body1" fontWeight="medium" component="div">
            {t(title)}
          </Typography>
        }
        subheader={
          <Typography variant="caption" sx={{ color: theme.palette.grey[500] }} component="div">
            {getDateTime(timestamp)}
          </Typography>
        }
      />
      <CardContent sx={{ px: 2, pt: 0, pb: 1, '&:last-child': { pb: 2 } }}>
        {content && <Typography variant="body2">{t(content)}</Typography>}
        {parameters && Object.entries(parameters).length > 0 && (
          <div>
            {showParameters && (
              <List dense>
                {Object.entries(parameters).map(([key, value]) => (
                  <div key={key}>
                    <ListItem key={key} sx={{ pl: 0 }}>
                      <Typography variant="body2">
                        <b>{t(`parameters:${key}`)}</b>: {t(`parameters:${value.toString()}`)}
                      </Typography>
                    </ListItem>
                    <Divider />
                  </div>
                ))}
              </List>
            )}
            <Box display="flex" justifyContent="flex-end">
              <Button component="button" variant="text" size="small" onClick={toggleParameters}>
                {showParameters ? t('parameters:show-less') : t('parameters:show-more')}
              </Button>
            </Box>
          </div>
        )}
        {linkText && (
          <Link href={href} variant="body2">
            {linkText}
          </Link>
        )}
      </CardContent>
    </Card>
  );
}

export default withTranslation(['notifications', 'parameters'])(NotificationListItem);
