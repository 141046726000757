import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { IconButton, Tooltip } from '@mui/material';

import { ModificationStateCellProps } from './ModificationState.definitions';

function ModificationStateCell({
  modificationState,
  originalInvoice,
  currentInvoice,
  tooltipTitle,
  handleOpenModal,
}: ModificationStateCellProps) {
  if (modificationState === 1) {
    if (originalInvoice && currentInvoice) {
      return (
        <IconButton
          aria-label="show-original-invoice-modal"
          size="small"
          sx={{
            color: (theme) => theme.yellow.main,
          }}
          onClick={() => handleOpenModal(originalInvoice, currentInvoice)}
        >
          <ManageHistoryIcon fontSize="small" />
        </IconButton>
      );
    }

    return (
      <Tooltip title={tooltipTitle}>
        <ManageHistoryIcon fontSize="small" />
      </Tooltip>
    );
  }

  return (
    <Tooltip title={tooltipTitle}>
      <PublishedWithChangesIcon fontSize="small" />
    </Tooltip>
  );
}

export default ModificationStateCell;
