import { Box, Typography } from '@mui/material';
import {
  GridColDef,
  GridPaginationModel,
  GridRowModel,
  ValueOptions,
} from '@mui/x-data-grid-premium';
import { useQuery } from '@tanstack/react-query';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { TaxCodeModel, TaxCodesService } from 'api/client';
import { TaxIssuesService } from 'api/taxRule';

import GridFieldsValidator from 'shared/utils/validators/GridFieldsValidator';

import BdoDataGrid from 'components/BdoDataGrid/BdoDataGrid';
import RenderBasicEditCell from 'components/BdoDataGrid/Cells/BasicEditCell';
import { DataGridRow } from 'components/BdoDataGrid/Types/DataGridRow.definitions';
import FallbackComponent from 'components/FallbackComponent/FallbackComponent';

import { TaxCodesProps } from './TaxCodes.definitions';

function TaxCodes({ t }: TaxCodesProps): FunctionComponentElement<TaxCodesProps> {
  const { isError: isErrorTaxIssues, data: taxIssues } = useQuery({
    queryKey: ['setTaxIssues'],
    queryFn: async () => {
      const taxIssuesData = await TaxIssuesService.getApiTaxIssues();
      return taxIssuesData;
    },
  });

  const getValueOptions = (): ValueOptions[] => {
    if (!taxIssues) return [];

    const mappedTaxIssues = taxIssues.map(
      (taxIssue): ValueOptions => ({
        value: taxIssue.id,
        label: taxIssue.name,
      }),
    );

    const result: ValueOptions[] = [
      { value: 'none', label: t('common:noSelection') },
      ...mappedTaxIssues,
    ];

    return result;
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: t('taxCodeFields:id'), type: 'string', width: 150 },
    {
      field: 'code',
      headerName: `${t('taxCodeFields:code')} *`,
      type: 'string',
      editable: true,
      width: 150,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'description',
      headerName: `${t('taxCodeFields:description')} *`,
      type: 'string',
      editable: true,
      width: 300,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'invoiceType',
      headerName: `${t('taxCodeFields:invoiceType')} *`,
      type: 'singleSelect',
      editable: true,
      width: 150,
      valueOptions: [
        { value: 1, label: t('taxCodeFields:incoming') },
        { value: 2, label: t('taxCodeFields:outgoing') },
      ],
      valueSetter: (params) => ({
        ...params.row,
        invoiceType: params.value,
      }),
      groupingValueGetter: ({ value }) =>
        value === 1 ? t('taxCodeFields:incoming') : t('taxCodeFields:outgoing'),
    },
    {
      field: 'taxIssueId',
      headerName: t('taxCodeFields:taxIssue'),
      type: 'singleSelect',
      editable: true,
      width: 300,
      valueOptions: getValueOptions(),
      valueGetter: (params) => {
        const taxIssue = taxIssues?.find((x) => x.id === params.value);

        if (taxIssue === undefined) {
          return 'none';
        }

        return taxIssue.id;
      },
      renderCell: (params) => {
        if (isErrorTaxIssues) {
          return <FallbackComponent errorMessage={t('common:errorOccured')} />;
        }

        if (params.rowNode.type === 'group' && params.field === params.rowNode.groupingField) {
          return '';
        }

        if (params.value === 'none') return t('common:noSelection');

        const taxIssueId = params?.row?.taxIssueId;
        if (taxIssueId) {
          return taxIssues?.find((x) => x.id === taxIssueId)?.name;
        }

        return params.value || t('common:noSelection');
      },
      valueSetter: (params) => {
        const { value, row } = params;
        const taxIssue = taxIssues?.find((x) => x.id === value);

        return { ...row, taxIssueId: taxIssue?.id || '' };
      },
      groupingValueGetter: (params) => {
        const name = taxIssues?.find((x) => x.id === params.value)?.name;

        if (name === undefined) {
          return t('common:noSelection');
        }

        return name;
      },
    },
    {
      field: 'isValueNegation',
      headerName: `${t('taxCodeFields:valueNegation')} *`,
      type: 'boolean',
      editable: true,
      width: 150,
    },
  ];

  const addDefaultRecord = (id: string): GridRowModel => {
    const taxCode: TaxCodeModel = {
      id,
      code: '',
      description: '',
      invoiceType: 1,
      taxIssueId: '',
    };

    return taxCode;
  };

  const processRowUpdate = async (row: DataGridRow): Promise<DataGridRow> => {
    let resultRow;

    if (row.isNew) {
      resultRow = await TaxCodesService.postApiTaxCodesAddRow(row as TaxCodeModel);
    } else {
      resultRow = await TaxCodesService.putApiTaxCodesUpdateRow(row as TaxCodeModel);
    }

    return resultRow as DataGridRow;
  };

  const processRowDelete = async (row: DataGridRow): Promise<boolean> =>
    TaxCodesService.deleteApiTaxCodesDeleteRow(row as TaxCodeModel);

  const getRows = (pagination: GridPaginationModel) =>
    TaxCodesService.getApiTaxCodesGetRows(pagination.page, pagination.pageSize);

  const getRowCount = () => TaxCodesService.getApiTaxCodesGetRowCount();

  return (
    <div>
      <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
        {t('navigation:tax-codes')}
      </Typography>
      <Box sx={{ p: '1rem' }}>
        <BdoDataGrid
          columns={columns}
          getRows={getRows}
          getRowCount={getRowCount}
          processRowUpdate={processRowUpdate}
          processRowDelete={processRowDelete}
          addDefaultRecord={addDefaultRecord}
          exportFileName={t('taxCodeFields:exportFileName')}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
            sorting: {
              sortModel: [{ field: 'taxOfficeNumber', sort: 'asc' }],
            },
          }}
        />
      </Box>
    </div>
  );
}

export default withTranslation(['gridTooltips', 'navigation', 'taxCodeFields'])(TaxCodes);
