/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExchangeRateImportModel } from '../models/ExchangeRateImportModel';
import type { InvoiceImportModel } from '../models/InvoiceImportModel';
import type { SheetModel } from '../models/SheetModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ImportService {
  /**
   * Retrieves the sheets from the provided Excel file based on its identifier.
   * @param fileId The identifier of the Excel file to retrieve sheets from.
   * @returns SheetModel Success
   * @throws ApiError
   */
  public static getApiImportGetSheets(fileId?: string): CancelablePromise<Array<SheetModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Import/GetSheets',
      query: {
        fileId: fileId,
      },
    });
  }

  /**
   * Enqueues a new job for execution of an invoice import.
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static postApiImportEnqueue(requestBody?: InvoiceImportModel): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Import/Enqueue',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Enqueues a new job for execution of an exchange rate import.
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static postApiImportEnqueueExchangeRate(
    requestBody?: ExchangeRateImportModel,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Import/EnqueueExchangeRate',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Checks if there is an unfinished Excel import job associated with the specified company ID.
   * @param companyId The unique identifier of the company to check for an unfinished import job.
   * @returns boolean Success
   * @throws ApiError
   */
  public static getApiImportDoesUnfinishedImportExist(
    companyId?: string,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Import/DoesUnfinishedImportExist',
      query: {
        companyId: companyId,
      },
    });
  }
}
