import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import LoadingButton from 'components/Buttons/LoadingButton/LoadingButton';
import CardWrapper from 'components/CardWrapper/CardWrapper';
import FormFooter from 'components/Layouts/FormFooter/FormFooter';
import SummaryCardHeader from 'components/SummaryCardHeader/SummaryCardHeader';

import { SummaryStepProps } from './SummaryStep.definitions';

function SummaryStep({
  clientFormInfo,
  isSaving = false,
  goToNextStep,
  goToPrevStep,
  t,
}: SummaryStepProps): FunctionComponentElement<SummaryStepProps> {
  const handleNextClick = async () => {
    goToNextStep();
  };

  const handlePrevClick = () => {
    goToPrevStep();
  };

  return (
    <form className="formContent">
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <CardWrapper>
          <SummaryCardHeader header={t('clientFields:clientHeader')} />
          {clientFormInfo.client?.name}
        </CardWrapper>

        <CardWrapper>
          <SummaryCardHeader header={t('companyFields:companyHeader')} />
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t('companyFields:name')}</TableCell>
                  <TableCell>{t('common:companyType')}</TableCell>
                  <TableCell>{t('companyFields:legalForm')}</TableCell>
                  <TableCell>{t('companyFields:taxNumber')}</TableCell>
                  <TableCell>{t('companyFields:vatTaxNumber')}</TableCell>
                  <TableCell>{t('companyFields:address')}</TableCell>
                  <TableCell>{t('companyFields:contactInfo')}</TableCell>
                  <TableCell>{t('companyFields:taxOffice')}</TableCell>
                  <TableCell>{t('companyFields:auditorOffice')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientFormInfo.valueAddedTaxGroup?.map((item) => (
                  <TableRow
                    key={item.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      {t(
                        item.isControllingCompany
                          ? 'common:controllingCompany'
                          : 'common:subsidiaryCompany',
                      )}
                    </TableCell>
                    <TableCell>{item.legalForm}</TableCell>
                    <TableCell>{item.taxNumber}</TableCell>
                    <TableCell>{item.vatTaxNumber}</TableCell>

                    <TableCell>
                      <div>
                        {item.address?.street} {item.address?.number}
                      </div>
                      <div>
                        {item.address?.postal} {item.address?.city}
                      </div>

                      <div>
                        {item.address?.country}
                        {item.address?.region}
                      </div>
                      <div>{item.address?.notes}</div>
                    </TableCell>
                    <TableCell>
                      <div>{item.contactInfo?.email}</div>
                      {item.contactInfo?.phoneNumber}
                    </TableCell>

                    <TableCell>
                      <div>{item.taxOffice?.name}</div>
                      <div>{item.taxOffice?.taxOfficeNumber}</div>
                      <div>{item.taxOffice?.notes}</div>
                      <br />
                      <div>
                        {item.taxOffice?.address?.street} {item.taxOffice?.address?.number}
                      </div>
                      <div>
                        {item.taxOffice?.address?.postal} {item.taxOffice?.address?.city}
                      </div>

                      <div>
                        {item.taxOffice?.address?.region} {item.taxOffice?.address?.country}
                      </div>

                      <div>{item.taxOffice?.address?.notes}</div>
                      <br />
                      <div>{item.taxOffice?.contactInfo?.email}</div>
                      <div>{item.taxOffice?.contactInfo?.phoneNumber}</div>
                      <br />
                      <div>
                        {item.taxOffice?.contact?.firstName} {item.taxOffice?.contact?.lastName}
                      </div>
                      <div>{item.taxOffice?.contact?.contactInfo?.email}</div>
                      <div>{item.taxOffice?.contact?.contactInfo?.phoneNumber}</div>
                      <div>{item.taxOffice?.contact?.notes}</div>
                      <div>
                        {item.taxOffice?.contact?.address?.street}{' '}
                        {item.taxOffice?.contact?.address?.number}
                      </div>
                      <div>
                        {item.taxOffice?.contact?.address?.postal}{' '}
                        {item.taxOffice?.contact?.address?.city}
                      </div>

                      <div>
                        {item.taxOffice?.contact?.address?.region}{' '}
                        {item.taxOffice?.contact?.address?.country}
                      </div>

                      <div>{item.taxOffice?.contact?.address?.notes}</div>
                    </TableCell>
                    <TableCell>
                      <div>{item.auditorOffice?.name}</div>

                      <div>{item.auditorOffice?.notes}</div>
                      <br />
                      <div>
                        {item.auditorOffice?.address?.street} {item.auditorOffice?.address?.number}
                      </div>
                      <div>
                        {item.auditorOffice?.address?.postal} {item.auditorOffice?.address?.city}
                      </div>

                      <div>
                        {item.auditorOffice?.address?.region} {item.auditorOffice?.address?.country}
                      </div>

                      <div>{item.auditorOffice?.address?.notes}</div>
                      <br />
                      <div>{item.auditorOffice?.contactInfo?.email}</div>
                      <div>{item.auditorOffice?.contactInfo?.phoneNumber}</div>
                      <br />
                      <div>
                        {item.auditorOffice?.contact?.firstName}{' '}
                        {item.auditorOffice?.contact?.lastName}
                      </div>
                      <div>{item.auditorOffice?.contact?.contactInfo?.email}</div>
                      <div>{item.auditorOffice?.contact?.contactInfo?.phoneNumber}</div>
                      <div>{item.auditorOffice?.contact?.notes}</div>
                      <div>
                        {item.auditorOffice?.contact?.address?.street}{' '}
                        {item.taxOffice?.contact?.address?.number}
                      </div>
                      <div>
                        {item.auditorOffice?.contact?.address?.postal}{' '}
                        {item.auditorOffice?.contact?.address?.city}
                      </div>

                      <div>
                        {item.auditorOffice?.contact?.address?.region}{' '}
                        {item.auditorOffice?.contact?.address?.country}
                      </div>

                      <div>{item.auditorOffice?.contact?.address?.notes}</div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardWrapper>
        <CardWrapper>
          <SummaryCardHeader header={t('common:newUser')} />
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t('userFields:name')}</TableCell>
                  <TableCell>{t('userFields:email')}</TableCell>
                  <TableCell>{t('userFields:username')}</TableCell>
                  <TableCell>{t('userFields:roles')}</TableCell>
                  <TableCell>{t('common:auditorUser')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientFormInfo.clientUsers?.map((user) => (
                  <TableRow
                    key={user.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      {user.firstName} {user.lastName}
                    </TableCell>

                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>
                      {user.roles &&
                        user.roles.map((role) => <p key={role}>{t(`keycloakRoles:${role}`)}</p>)}
                    </TableCell>
                    <TableCell>{t(user.isAuditorUser ? 'common:true' : 'common:false')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardWrapper>
      </Box>
      <FormFooter>
        <Stack direction="row" spacing={3}>
          <Button variant="contained" disabled={isSaving} onClick={handlePrevClick}>
            {t('common:back')}
          </Button>
          <LoadingButton title={t('common:save')} isLoading={isSaving} onClick={handleNextClick} />
        </Stack>
      </FormFooter>
    </form>
  );
}

export default withTranslation([
  'userFields',
  'clientFields',
  'companyFields',
  'common',
  'keycloakRoles',
])(SummaryStep);
