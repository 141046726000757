import { Box, Typography } from '@mui/material';
import {
  GridColDef,
  GridGroupingValueGetterParams,
  GridPaginationModel,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { ImportLogsService } from 'api/import';

import { dateFormat } from 'shared/utils/date.helper';
import { isGroupTypeColumn } from 'shared/utils/grid.helper';

import BdoDataGrid from 'components/BdoDataGrid/BdoDataGrid';

import { ImportLogsProps } from './ImportLogs.definitions';

function ImportLogs({ t }: ImportLogsProps): FunctionComponentElement<ImportLogsProps> {
  const columns: GridColDef[] = [
    { field: 'id', headerName: t('importLogs:id'), type: 'string', width: 150 },
    {
      field: 'created',
      headerName: t('importLogs:created'),
      type: 'dateTime',
      width: 150,
      valueGetter: ({ value }) => (value && new Date(value)) || '',
      renderCell: ({ value }) => {
        const date = new Date(value || '');

        return Number.isNaN(date.getTime()) ? '' : date.toLocaleTimeString('de-DE', dateFormat);
      },
      valueFormatter: ({ value }) => {
        const date = new Date(value || '');

        return Number.isNaN(date.getTime()) ? '' : date.toLocaleTimeString('de-DE', dateFormat);
      },
    },
    {
      field: 'createdBy',
      headerName: t('importLogs:createdBy'),
      type: 'string',
      width: 150,
    },
    { field: 'fileId', headerName: t('importLogs:fileId'), type: 'string', width: 150 },
    {
      field: 'fileName',
      headerName: t('importLogs:fileName'),
      type: 'string',
      width: 200,
    },

    { field: 'companyId', headerName: t('importLogs:companyId'), type: 'string', width: 150 },
    {
      field: 'companyName',
      headerName: t('importLogs:companyName'),
      type: 'string',
      width: 150,
      valueGetter: (params: GridValueGetterParams) =>
        (params?.row?.companyName?.length ?? 0) > 0
          ? params.row.companyName
          : t('importLogs:notFound'),
    },
    { field: 'jobId', headerName: t('importLogs:jobId'), type: 'string', width: 150 },
    {
      field: 'severity',
      headerName: t('importLogs:severity'),
      type: 'string',
      width: 100,
      valueFormatter: (params: GridValueFormatterParams) => t(`severityLevels:${params.value}`),
      valueGetter: (params: GridValueGetterParams) => {
        if (isGroupTypeColumn(params)) {
          return '';
        }

        return params.value;
      },
      groupingValueGetter: (params: GridGroupingValueGetterParams) =>
        t(`severityLevels:${params.value}`),
    },
    {
      field: 'message',
      headerName: t('importLogs:message'),
      type: 'string',
      width: 400,
      valueFormatter: (params: GridValueFormatterParams) => t(`importMessages:${params.value}`),
      valueGetter: (params: GridValueGetterParams) => {
        if (isGroupTypeColumn(params)) {
          return '';
        }

        return params.value;
      },
      groupingValueGetter: (params: GridGroupingValueGetterParams) =>
        t(`importMessages:${params.value}`),
    },
    {
      field: 'workbook',
      headerName: t('importLogs:workbook'),
      type: 'string',
      width: 200,
    },
    { field: 'worksheet', headerName: t('importLogs:worksheet'), type: 'string', width: 150 },
    { field: 'row', headerName: t('importLogs:row'), type: 'string', width: 100 },
    {
      field: 'column',
      headerName: t('importLogs:column'),
      type: 'string',
      width: 100,
      groupingValueGetter: (params) => params.value ?? t('importLogs:withoutValue'),
    },
    {
      field: 'cell',
      headerName: t('importLogs:cell'),
      type: 'string',
      width: 100,
      groupingValueGetter: (params) => params.value ?? t('importLogs:withoutValue'),
    },
    { field: 'source', headerName: t('importLogs:source'), type: 'string', width: 200 },
    { field: 'target', headerName: t('importLogs:target'), type: 'string', width: 200 },
  ];

  const getRows = (pagination: GridPaginationModel) =>
    ImportLogsService.getApiImportLogsGetRows(pagination.page, pagination.pageSize);

  const getRowCount = () => ImportLogsService.getApiImportLogsGetRowCount();

  return (
    <div>
      <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
        {t('importLogs:importLogs')}
      </Typography>
      <Box sx={{ p: '1rem' }}>
        <BdoDataGrid
          columns={columns}
          getRows={getRows}
          getRowCount={getRowCount}
          processRowUpdate={async (row) => row}
          exportFileName={t('importLogs:exportFileName')}
          initialState={{
            sorting: {
              sortModel: [
                { field: 'created', sort: 'desc' },
                { field: 'fileName', sort: 'desc' },
              ],
            },
            columns: {
              columnVisibilityModel: {
                id: false,
                createdBy: false,
                companyId: false,
                jobId: false,
                fileId: false,
                actions: false,
              },
            },
          }}
        />
      </Box>
    </div>
  );
}

export default withTranslation(['importLogs', 'importMessages', 'severityLevels'])(ImportLogs);
