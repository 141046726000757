/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyModel } from '../models/CompanyModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BaseVatGroupsService {
  /**
   * Retrieves a paginated list of company rows.
   * @param page The page number of the data set to retrieve.
   * @param pageSize The size of the page to retrieve.
   * @returns CompanyModel Success
   * @throws ApiError
   */
  public static getApiBaseVatGroupsGetRows(
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<CompanyModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/BaseVatGroups/GetRows',
      query: {
        page: page,
        pageSize: pageSize,
      },
    });
  }

  /**
   * Retrieves the total count of company rows.
   * @returns number Success
   * @throws ApiError
   */
  public static getApiBaseVatGroupsGetRowCount(): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/BaseVatGroups/GetRowCount',
    });
  }

  /**
   * Adds a new company row.
   * @param requestBody The company model to add.
   * @returns CompanyModel Success
   * @throws ApiError
   */
  public static postApiBaseVatGroupsAddRow(
    requestBody?: CompanyModel,
  ): CancelablePromise<CompanyModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/BaseVatGroups/AddRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Updates an existing company row.
   * @param requestBody The company model with updated information.
   * @returns CompanyModel Success
   * @throws ApiError
   */
  public static putApiBaseVatGroupsUpdateRow(
    requestBody?: CompanyModel,
  ): CancelablePromise<CompanyModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/BaseVatGroups/UpdateRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Retrieves a single company by its unique identifier.
   * @param companyId The unique identifier of the company to retrieve.
   * @returns CompanyModel Success
   * @throws ApiError
   */
  public static getApiBaseVatGroupsGetCompany(companyId?: string): CancelablePromise<CompanyModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/BaseVatGroups/GetCompany',
      query: {
        companyId: companyId,
      },
    });
  }

  /**
   * Deletes a company if it's not a base company.
   * @param requestBody The company model to delete.
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteApiBaseVatGroupsDeleteRow(
    requestBody?: CompanyModel,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/BaseVatGroups/DeleteRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
