import { Typography } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { CultureSettingsProps } from './CultureSettings.definitions';

function CultureSettings({
  t,
}: CultureSettingsProps): FunctionComponentElement<CultureSettingsProps> {
  return (
    <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
      {t('culture-settings')}
    </Typography>
  );
}

export default withTranslation('navigation')(CultureSettings);
