import ClientStore from '../ClientStore/ClientStore';
import { IClientStore } from '../ClientStore/IClientStore';
import HubStore from '../HubStore/HubStore';
import { IHubStore } from '../HubStore/IHubStore';
import { INavigationStore } from '../NavigationStore/INavigationStore';
import NavigationStore from '../NavigationStore/NavigationStore';
import { INotificationStore } from '../NotificationStore/INotificationStore';
import NotificationStore from '../NotificationStore/NotificationStore';
import { IRightDrawerStore } from '../RightDrawerStore/IRightDrawerStore';
import RightDrawerStore from '../RightDrawerStore/RightDrawerStore';
import { IToastStore } from '../ToastStore/IToastStore';
import ToastStore from '../ToastStore/ToastStore';

import { IRootStore } from './IRootstore';

export interface WithRootStore {
  rootStore: IRootStore;
}

class RootStore implements IRootStore {
  public clientStore: IClientStore;

  public navigationStore: INavigationStore;

  public rightDrawerStore: IRightDrawerStore;

  public notificationStore: INotificationStore;

  public toastStore: IToastStore;

  public hubStore: IHubStore;

  constructor() {
    this.clientStore = new ClientStore();
    this.navigationStore = new NavigationStore();
    this.rightDrawerStore = new RightDrawerStore();
    this.toastStore = new ToastStore();
    this.notificationStore = new NotificationStore(this.toastStore);
    this.hubStore = new HubStore(this.notificationStore);
  }
}

export default RootStore;
