import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import NotificationType from 'enums/NotificationType';
import Severity from 'enums/Severity';
import { Notification } from 'models/Notification';

import { IToastStore } from '../ToastStore/IToastStore';

import { INotificationStore } from './INotificationStore';

class NotificationStore implements INotificationStore {
  notifications: Notification[] = [];

  constructor(private toastStore: IToastStore) {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'NotificationStore',
      properties: ['notifications'],
      storage: window.localStorage,
    });
  }

  get notificationsCount() {
    return this.notifications.length;
  }

  // method to send notifications from client to itself (FE). This also sends a toast.
  sendNotification = (
    title: string,
    severity: Severity,
    content?: string,
    type?: NotificationType,
    parameters?: { [key: string]: string },
  ) => {
    const notification: Notification = {
      id: uuidv4(),
      timestamp: moment().utc().toString(),
      type,
      severity,
      title,
      content,
      parameters,
    };
    this.addNotification(notification);
  };

  // method that is invoked when notifications come from BE
  addNotification = (notification: Notification): void => {
    if (notification.type === NotificationType.SignalRConnectionFailed) {
      if (
        this.notifications.some((item) => item.type === NotificationType.SignalRConnectionFailed)
      ) {
        return;
      }
    }
    this.notifications.unshift(notification);
    const { severity, title, type } = notification;
    this.toastStore.addToast({ id: uuidv4(), title, severity, type });
  };

  deleteNotification = (notification: Notification): void => {
    const indexToDelete = this.notifications.findIndex((not) => not.id === notification.id);

    if (indexToDelete === -1) {
      this.toastStore.addToast({
        id: uuidv4(),
        title: 'Unable to delete notification',
        severity: Severity.Error,
      });
    }
    this.notifications.splice(indexToDelete, 1);
  };
}

export default NotificationStore;
