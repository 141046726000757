/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyModel } from '../models/CompanyModel';
import type { CompanySelectionModel } from '../models/CompanySelectionModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VatGroupsService {
  /**
   * Gets the VAT group associated with a specific filling period.
   * @param fillingPeriodId The unique identifier for the filling period.
   * @returns CompanyModel Success
   * @throws ApiError
   */
  public static getApiVatGroupsGetVatGroupByFillingPeriod(
    fillingPeriodId?: string,
  ): CancelablePromise<Array<CompanyModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/VatGroups/GetVatGroupByFillingPeriod',
      query: {
        fillingPeriodId: fillingPeriodId,
      },
    });
  }

  /**
   * Gets the VAT group selection for a specific filling period.
   * @param fillingPeriodId The unique identifier for the filling period.
   * @returns CompanySelectionModel Success
   * @throws ApiError
   */
  public static getApiVatGroupsGetVatGroupSelection(
    fillingPeriodId?: string,
  ): CancelablePromise<Array<CompanySelectionModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/VatGroups/GetVatGroupSelection',
      query: {
        fillingPeriodId: fillingPeriodId,
      },
    });
  }

  /**
   * Creates a VAT group for the specified filling period based on selected companies.
   * This action first verifies if the provided filling period exists. If not, it returns a 'Not Found' status.
   * It then checks if a VAT group already exists for the filling period. If it does, it returns a 'Bad Request' status.
   * It also ensures that a controlling company is selected among the provided companies and that all selected companies exist in the database.
   * If all checks pass, it delegates the task of creating the VAT group to the '_vatGroupService' and then returns the newly created companies.
   * @param fillingPeriodId The identifier for the filling period for which the VAT group needs to be created.
   * @param requestBody List of selected companies to be included in the VAT group.
   * @returns CompanyModel Success
   * @throws ApiError
   */
  public static postApiVatGroups(
    fillingPeriodId?: string,
    requestBody?: Array<CompanySelectionModel>,
  ): CancelablePromise<Array<CompanyModel>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/VatGroups',
      query: {
        fillingPeriodId: fillingPeriodId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
