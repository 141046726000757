import { Box, Button, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import CardWrapper from 'components/CardWrapper/CardWrapper';
import FallbackComponent from 'components/FallbackComponent/FallbackComponent';
import FormFooter from 'components/Layouts/FormFooter/FormFooter';
import Loader from 'components/Loader/Loader';

import { SelectTaxYearStepProps } from './SelectTaxYearStep.definitions';

function SelectTaxYearStep({
  isFetchingTaxYears,
  error,
  taxYears,
  selectedTaxYear,
  updateTaxYear,
  goToPrevStep,
  goToNextStep,
  t,
}: SelectTaxYearStepProps): FunctionComponentElement<SelectTaxYearStepProps> {
  if (isFetchingTaxYears) {
    return (
      <Box sx={{ margin: 'auto' }}>
        <Loader />
      </Box>
    );
  }

  if (error) {
    return <FallbackComponent error={error} />;
  }

  const handlePrevClick = () => {
    goToPrevStep();
  };

  const handleNextClick = () => {
    goToNextStep();
  };

  const handleChange = (event: SelectChangeEvent) => {
    const selection = taxYears.find((x) => x.year === Number(event.target.value));

    if (selection) {
      updateTaxYear(selection);
    }
  };

  return (
    <form onSubmit={handleNextClick} className="formContent">
      <Box sx={{ flexGrow: 1, p: 2, width: '40%', margin: '0 auto' }}>
        <CardWrapper>
          <Stack sx={{ marginBottom: 2 }}>
            <Select
              value={selectedTaxYear ? `${selectedTaxYear.year}` : ''}
              onChange={handleChange}
            >
              {taxYears.map((item) => (
                <MenuItem value={`${item.year}`} key={item.id}>{`${item.year}`}</MenuItem>
              ))}
            </Select>
          </Stack>
        </CardWrapper>
      </Box>
      <FormFooter>
        <Stack direction="row" spacing={3}>
          <Button variant="contained" onClick={handlePrevClick}>
            {t('common:back')}
          </Button>
          <Button type="submit" variant="contained">
            {t('common:next')}
          </Button>
        </Stack>
      </FormFooter>
    </form>
  );
}

export default withTranslation()(SelectTaxYearStep);
