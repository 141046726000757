/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImportLogModel } from '../models/ImportLogModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ImportLogsService {
  /**
   * Retrieves a paginated list of import log entries.
   * @param page The page number of entries to retrieve.
   * @param pageSize The number of entries per page.
   * @returns ImportLogModel Success
   * @throws ApiError
   */
  public static getApiImportLogsGetRows(
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<ImportLogModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/ImportLogs/GetRows',
      query: {
        page: page,
        pageSize: pageSize,
      },
    });
  }

  /**
   * Gets the total count of import log entries in the database.
   * @returns number Success
   * @throws ApiError
   */
  public static getApiImportLogsGetRowCount(): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/ImportLogs/GetRowCount',
    });
  }
}
