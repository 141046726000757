import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FunctionComponentElement, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { CompanySelectionModel, VatGroupsService } from 'api/invoices';

import Loader from 'components/Loader/Loader';
import ShowInfo from 'components/ShowInfo/ShowInfo';

import SummaryStep from '../SummaryStep/SummaryStep';
import VatGroupsSelectionStep from '../VatGroupsSelectionStep/VatGroupsSelectionStep';

import { CreateVatGroupsStepperProps } from './CreateVatGroupsStepper.definitions';

function CreateVatGroupsStepper({
  t,
}: CreateVatGroupsStepperProps): FunctionComponentElement<CreateVatGroupsStepperProps> {
  const [vatGroupsSelection, setVatGroupsSelection] = useState<CompanySelectionModel[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [isMutating, setIsMutating] = useState(false);
  const { countryId, taxYearId, fillingPeriodId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const getVatGroupSelectionQuery = async () => {
    const response = await VatGroupsService.getApiVatGroupsGetVatGroupSelection(fillingPeriodId);
    return response;
  };

  const postVatGroupsQuery = async () => {
    const response = await VatGroupsService.postApiVatGroups(fillingPeriodId, vatGroupsSelection);
    return response;
  };

  const { isFetching, data: vatGroups } = useQuery({
    queryKey: ['getVatGroupSelection', fillingPeriodId],
    queryFn: getVatGroupSelectionQuery,
  });

  const { mutate } = useMutation({
    mutationFn: postVatGroupsQuery,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['navigation'] });
      await queryClient.invalidateQueries({ queryKey: ['getVatGroups', fillingPeriodId] });
      await queryClient.invalidateQueries({ queryKey: ['getCompanies', fillingPeriodId] });
      navigate(
        `/country/${countryId}/tax-year/${taxYearId}/filling-period/${fillingPeriodId}/value-added-tax-group`,
      );
    },
    onMutate: () => setIsMutating(true),
    onSettled: () => setIsMutating(false),
    mutationKey: ['postVatGroupSelection', fillingPeriodId],
  });

  useEffect(() => {
    if (vatGroups) {
      setVatGroupsSelection(vatGroups);
    }
  }, [vatGroups]);

  if (isFetching) {
    return (
      <Box sx={{ margin: 'auto' }}>
        <Loader />
      </Box>
    );
  }

  const steps = [t('selectVatGroups'), t('common:reviewAndSave')];
  const isLastStep = activeStep === steps.length - 1;

  const handleNextClick = () => {
    if (isLastStep) {
      mutate();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handlePrevClick = () => {
    setActiveStep(activeStep - 1);
  };

  const renderStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <VatGroupsSelectionStep
            vatGroupsSelection={vatGroupsSelection}
            updateVatGroupsSelection={setVatGroupsSelection}
            goToNextStep={handleNextClick}
          />
        );
      case 1:
        return (
          <SummaryStep
            vatGroupsSelection={vatGroupsSelection}
            isSaving={isMutating}
            goToNextStep={handleNextClick}
            goToPrevStep={handlePrevClick}
          />
        );
      default:
        return <div>Not Found</div>;
    }
  };

  return (
    <>
      <Typography component="h1" variant="h6">
        {t('common:createVatGroups')}
      </Typography>
      {vatGroupsSelection.length === 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <ShowInfo text={t('common:noExistingVatGroups')} />
        </Box>
      ) : (
        <>
          <Stepper activeStep={activeStep} sx={{ py: 2 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {renderStepContent(activeStep)}
        </>
      )}
    </>
  );
}

export default withTranslation(['createVatGroupsStepper', 'common'])(CreateVatGroupsStepper);
