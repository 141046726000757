import { Box, Typography } from '@mui/material';
import {
  GridColDef,
  GridEditBooleanCell,
  GridEditBooleanCellProps,
  GridPaginationModel,
  GridRowModel,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { useKeycloak } from '@react-keycloak/web';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import Roles from 'configs/keycloak-roles';

import { UserModel, UsersService } from 'api/client';

import useStore from 'context/Store/Store.hooks';
import GridFieldsValidator from 'shared/utils/validators/GridFieldsValidator';

import BdoDataGrid from 'components/BdoDataGrid/BdoDataGrid';
import RenderBasicEditCell from 'components/BdoDataGrid/Cells/BasicEditCell';
import { DataGridRow } from 'components/BdoDataGrid/Types/DataGridRow.definitions';

import Severity from 'enums/Severity';

import { UserManagementProps } from './UserManagement.definitions';

function IsAuditorUserBooleanEditComponent(props: GridEditBooleanCellProps) {
  const apiRef = useGridApiContext();

  const handleValueChange = async () => {
    await apiRef.current.setEditCellValue({
      ...props,
      field: 'roles',
      value: '',
    });
  };

  return <GridEditBooleanCell onValueChange={handleValueChange} {...props} />;
}

function UserManagement({ t }: UserManagementProps): FunctionComponentElement<UserManagementProps> {
  const { toastStore } = useStore();
  const { keycloak } = useKeycloak();

  const columns: GridColDef[] = [
    { field: 'id', headerName: t('userManagement:id'), type: 'string', width: 150 },
    {
      field: 'firstName',
      headerName: `${t('userManagement:firstName')} *`,
      type: 'string',
      editable: true,
      width: 200,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'lastName',
      headerName: `${t('userManagement:lastName')} *`,
      type: 'string',
      editable: true,
      width: 200,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'username',
      headerName: `${t('userManagement:username')} *`,
      type: 'string',
      editable: true,
      width: 200,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'email',
      headerName: `${t('userManagement:email')} *`,
      type: 'string',
      editable: true,
      width: 200,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'isAuditorUser',
      headerName: t('userManagement:isAuditorUser'),
      type: 'boolean',
      editable: true,
      width: 150,
      renderEditCell: (params) => <IsAuditorUserBooleanEditComponent {...params} />,
    },
    {
      field: 'roles',
      headerName: `${t('userManagement:roles')} *`,
      type: 'singleSelect',
      editable: true,
      width: 250,
      valueOptions: ({ row }) => {
        const auditorRoles = [
          {
            value: Roles.AuditorAdministrator,
            label: t(`keycloakRoles:${Roles.AuditorAdministrator}`),
          },
          {
            value: Roles.AuditorSupervisor,
            label: t(`keycloakRoles:${Roles.AuditorSupervisor}`),
          },
          { value: Roles.AuditorEmployee, label: t(`keycloakRoles:${Roles.AuditorEmployee}`) },
        ];

        const clientRoles = [
          { value: Roles.ClientSupervisor, label: t(`keycloakRoles:${Roles.ClientSupervisor}`) },
          { value: Roles.ClientEmployee, label: t(`keycloakRoles:${Roles.ClientEmployee}`) },
        ];

        if (!row) {
          return auditorRoles.concat(clientRoles);
        }

        if (row.isAuditorUser) {
          return auditorRoles;
        }

        return clientRoles;
      },
      valueGetter: ({ value }) => (value && value.length > 0 ? value[0] : ''),
      valueSetter: (params) => ({
        ...params.row,
        roles: [params.value],
      }),
      groupingValueGetter: ({ value }) => t(`keycloakRoles:${value}`),
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
  ];

  const addDefaultRecord = (id: string): GridRowModel => {
    const user: UserModel = {
      id,
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      isAuditorUser: true,
      roles: [Roles.AuditorSupervisor],
    };

    return user;
  };

  const processRowUpdate = async (row: DataGridRow): Promise<DataGridRow> => {
    let resultRow;

    if (row.isNew) {
      resultRow = await UsersService.postApiUsersAddRow(row as UserModel);
    } else {
      resultRow = await UsersService.putApiUsersUpdateRow(row as UserModel);
    }

    return resultRow as DataGridRow;
  };

  const processRowDelete = async (row: DataGridRow): Promise<boolean> => {
    if (keycloak.subject === row.id) {
      toastStore.addToast({
        id: v4(),
        title: t(`notifications:error-user-deletes-itself`),
        severity: Severity.Error,
      });
      return false;
    }
    return UsersService.deleteApiUsersDeleteRow(row as UserModel);
  };

  const getRows = (pagination: GridPaginationModel) =>
    UsersService.getApiUsersGetRows(pagination.page, pagination.pageSize);

  const getRowCount = () => UsersService.getApiUsersGetRowCount();

  return (
    <div>
      <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
        {t('navigation:user-management')}
      </Typography>
      <Box sx={{ p: '1rem' }}>
        <BdoDataGrid
          columns={columns}
          getRows={getRows}
          getRowCount={getRowCount}
          processRowUpdate={processRowUpdate}
          processRowDelete={processRowDelete}
          addDefaultRecord={addDefaultRecord}
          exportFileName={t('userManagement:exportFileName')}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
            sorting: {
              sortModel: [
                { field: 'isAuditorUser', sort: 'desc' },
                { field: 'firstName', sort: 'asc' },
              ],
            },
          }}
        />
      </Box>
    </div>
  );
}

export default withTranslation([
  'userManagement',
  'gridTooltips',
  'keycloakRoles',
  'navigation',
  'notifications',
])(UserManagement);
