import CommentIcon from '@mui/icons-material/Comment';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Badge, BadgeProps, Divider, List } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import useStore from 'context/Store/Store.hooks';

import ActionMenuButton from 'components/Buttons/ActionMenuButton/ActionMenuButton';
import RightDrawer from 'components/Layouts/RightDrawer/RightDrawer';

import RightDrawerContentType from 'enums/RightDrawerContentType';

import { ActionMenuProps } from './ActionMenu.definitions';

const drawerWidth = 300;

const Menu = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  ...(open && {
    transition: theme.transitions.create(
      ['transform', 'margin', 'height', 'width', 'top', 'left', 'right'],
      {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      },
    ),
    marginRight: `${drawerWidth}px`,
  }),
}));

function ActionMenu({ t }: ActionMenuProps): FunctionComponentElement<ActionMenuProps> {
  const {
    notificationStore: { notificationsCount },
    rightDrawerStore: {
      open,
      activeContent,
      isActionsDrawer,
      isCommentsDrawer,
      isNotificationsDrawer,
      setOpen,
      setActiveContent,
    },
  } = useStore();

  const close = () => {
    setOpen(false);
    setActiveContent(RightDrawerContentType.None);
  };

  const handleToggleWithDrawerContent = (type: RightDrawerContentType) => {
    if (!open) {
      setOpen(true);
      setActiveContent(type);
    } else if (activeContent === type) {
      close();
    } else {
      setActiveContent(type);
    }
  };

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      border: `1px solid ${theme.palette.background.paper}`,
    },
  }));

  return (
    <Box
      component="nav"
      sx={{
        display: { xs: 'none', sm: 'flex' },
        position: 'absolute',
        right: 0,
        alignItems: 'center',
      }}
    >
      <CssBaseline />

      <RightDrawer open={open} drawerWidth={drawerWidth} handleCloseClick={close} />
      <Menu open={open}>
        <Paper
          sx={{
            borderRadius: '0px',
            backgroundColor: (theme) => theme.palette.secondary.main,
          }}
        >
          <List
            sx={{
              py: 0,
              borderRadius: '0px',
            }}
          >
            <ActionMenuButton
              key={RightDrawerContentType.Actions}
              icon={<MenuIcon sx={{ color: (theme) => theme.palette.common.white }} />}
              tooltipText={t(RightDrawerContentType.Actions)}
              selected={open && isActionsDrawer}
              handleClick={() => handleToggleWithDrawerContent(RightDrawerContentType.Actions)}
            />
            <Divider
              sx={{
                borderColor: (theme) => theme.palette.grey[500],
              }}
            />
            <ActionMenuButton
              key={RightDrawerContentType.Comments}
              icon={<CommentIcon sx={{ color: (theme) => theme.palette.common.white }} />}
              tooltipText={t(RightDrawerContentType.Comments)}
              selected={open && isCommentsDrawer}
              handleClick={() => handleToggleWithDrawerContent(RightDrawerContentType.Comments)}
            />
            <Divider
              sx={{
                borderColor: (theme) => theme.palette.grey[500],
              }}
            />
            <ActionMenuButton
              key={RightDrawerContentType.Notifications}
              icon={
                <StyledBadge badgeContent={notificationsCount} color="primary">
                  <NotificationsActiveIcon sx={{ color: (theme) => theme.palette.common.white }} />
                </StyledBadge>
              }
              tooltipText={t(RightDrawerContentType.Notifications)}
              selected={open && isNotificationsDrawer}
              handleClick={() =>
                handleToggleWithDrawerContent(RightDrawerContentType.Notifications)
              }
            />
          </List>
        </Paper>
      </Menu>
    </Box>
  );
}
export default withTranslation('actionMenu')(observer(ActionMenu));
