import { observer } from 'mobx-react-lite';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useStore from 'context/Store/Store.hooks';
import useFillingPeriod from 'shared/api-hooks/useFillingPeriod';

import FillingPeriodActions from 'features/FillingPeriodActions/FillingPeriodActions';

import NoDrawerContent from 'components/NoDrawerContent/NoDrawerContent';

import { TaxGroupDrawerProps } from './TaxGroupDrawer.definitions';

function TaxGroupDrawer({ t }: TaxGroupDrawerProps): FunctionComponentElement<TaxGroupDrawerProps> {
  const {
    rightDrawerStore: { isActionsDrawer, isCommentsDrawer },
  } = useStore();
  const { fillingPeriodId } = useParams();
  const { isReadonly } = useFillingPeriod(fillingPeriodId);

  return (
    <>
      {isActionsDrawer && <FillingPeriodActions isDisabled={isReadonly} />}
      {isCommentsDrawer && <NoDrawerContent text={t('noCommentsAvailable')} />}
    </>
  );
}

export default withTranslation('common')(observer(TaxGroupDrawer));
