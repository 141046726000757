import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import FolderOffOutlinedIcon from '@mui/icons-material/FolderOffOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import { Tooltip } from '@mui/material';

import InvoiceOriginState from 'enums/InvoiceOriginState';

import { InvoiceOriginCellProps } from './InvoiceOriginCell.definitions';

function InvoiceOriginCell({ title, state }: InvoiceOriginCellProps) {
  if (state === InvoiceOriginState.ShiftedOut) {
    return (
      <Tooltip title={title}>
        <FolderOffOutlinedIcon />
      </Tooltip>
    );
  }

  return (
    <Tooltip title={title}>
      {state === InvoiceOriginState.ShiftedIn ? (
        <DriveFileMoveOutlinedIcon />
      ) : (
        <FolderOpenOutlinedIcon />
      )}
    </Tooltip>
  );
}

export default InvoiceOriginCell;
