import AddIcon from '@mui/icons-material/Add';
import {
  Fab,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FunctionComponentElement, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Controller, get, useFormContext } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { AuditorOfficeModel, TaxOfficeModel } from 'api/client';
import { AuditorOfficesService, TaxOfficesService } from 'api/masterdata';

import { logError } from 'shared/utils/error.helper';
import { getNewAuditorOffice, getNewTaxOffice } from 'shared/utils/formFields.helper';

import AuditorOfficeFormDialog from 'components/AuditorOfficeFormDialog/AuditorOfficeFormDialog';
import FallbackComponent from 'components/FallbackComponent/FallbackComponent';
import Loader from 'components/Loader/Loader';
import TaxOfficeFormDialog from 'components/TaxOfficeFormDialog/TaxOfficeFormDialog';

import { TaxAuditorOfficeFieldsProps } from './TaxAuditorOfficeFields.definitions';

function TaxAuditorOfficeFields({
  index,
  taxOfficeField,
  auditorOfficeField,
  t,
}: TaxAuditorOfficeFieldsProps): FunctionComponentElement<TaxAuditorOfficeFieldsProps> {
  const [taxOfficeList, setTaxOfficeList] = useState<TaxOfficeModel[] | undefined>(undefined);
  const [auditorOfficeList, setAuditorOfficeList] = useState<AuditorOfficeModel[] | undefined>(
    undefined,
  );
  const [openTaxOfficeDialog, setOpenTaxOfficeDialog] = useState(false);
  const [openAuditorOfficeDialog, setOpenAuditorOfficeDialog] = useState(false);

  const { isFetching: isFetchingTaxOffice, data: taxOfficeListData } = useQuery({
    queryKey: ['getApiTaxOffices'],
    queryFn: async () => TaxOfficesService.getApiTaxOffices(),
  });

  useEffect(() => {
    if (taxOfficeListData) {
      setTaxOfficeList(
        taxOfficeListData.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? '')),
      );
    }
  }, [taxOfficeListData]);

  const { isFetching: isFetchingAuditorOffice, data: auditorfficeListData } = useQuery({
    queryKey: ['getApiAuditorOffices'],
    queryFn: async () => AuditorOfficesService.getApiAuditorOffices(),
  });

  useEffect(() => {
    if (auditorfficeListData) {
      setAuditorOfficeList(
        auditorfficeListData.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? '')),
      );
    }
  }, [auditorfficeListData]);

  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  const handleTaxOfficeChange = (event: SelectChangeEvent<string>, indexTaxOffice: number) => {
    if (!taxOfficeList) return;
    const selectedTaxOffice = taxOfficeList.find((ofc) => ofc.id === event.target.value);
    if (selectedTaxOffice) {
      setValue(taxOfficeField, selectedTaxOffice, {
        shouldValidate: true,
      });
    } else {
      setValue(taxOfficeField, getNewTaxOffice(), {
        shouldValidate: true,
      });
    }
  };

  const handleAuditorOfficeChange = (
    event: SelectChangeEvent<string>,
    indexAuditorOffice: number,
  ) => {
    if (!auditorOfficeList) return;
    const selectedAuditorOffice = auditorOfficeList.find((ofc) => ofc.id === event.target.value);
    if (selectedAuditorOffice) {
      setValue(auditorOfficeField, selectedAuditorOffice, {
        shouldValidate: true,
      });
    } else {
      setValue(auditorOfficeField, getNewAuditorOffice(), {
        shouldValidate: true,
      });
    }
  };

  return (
    <>
      {isFetchingTaxOffice || !taxOfficeList ? (
        <Loader />
      ) : (
        <Stack direction="row" spacing={3} sx={{ marginBottom: 2, alignItems: 'center' }}>
          <FormControl required sx={{ width: 815 }}>
            <Controller
              name={taxOfficeField}
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { value } }) => (
                <>
                  <InputLabel id="taxOffice_label">
                    {t('taxOfficeFields:taxOfficeHeader')}
                  </InputLabel>
                  <Select
                    id="taxOffice_select"
                    labelId="taxOffice_label"
                    value={value?.id || ''}
                    label={t('taxOfficeFields:taxOfficeHeader')}
                    onChange={(event) => handleTaxOfficeChange(event, index)}
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                  >
                    {taxOfficeList.map((taxOffice) =>
                      taxOffice.id ? (
                        <MenuItem key={taxOffice.id} value={taxOffice.id}>
                          {taxOffice.name}
                        </MenuItem>
                      ) : null,
                    )}
                  </Select>
                </>
              )}
            />
          </FormControl>
          <Fab
            color="primary"
            size="small"
            aria-label="add"
            onClick={() => setOpenTaxOfficeDialog(true)}
          >
            <AddIcon data-testid="AddIconTaxOffice" />
          </Fab>
        </Stack>
      )}
      {isFetchingAuditorOffice || !auditorOfficeList ? (
        <Loader />
      ) : (
        <Stack direction="row" spacing={3} sx={{ marginBottom: 2, alignItems: 'center' }}>
          <FormControl sx={{ width: 815 }} required>
            <Controller
              name={auditorOfficeField}
              control={control}
              render={({ field: { value } }) => (
                <>
                  <InputLabel id="auditorOffice_label" required>
                    {t('auditorOfficeFields:auditorOfficeHeader')}
                  </InputLabel>
                  <Select
                    id="auditorOffice_select"
                    labelId="auditorOffice_label"
                    value={value?.id || ''}
                    label={t('auditorOfficeFields:auditorOfficeHeader')}
                    onChange={(event) => handleAuditorOfficeChange(event, index)}
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    required
                  >
                    {auditorOfficeList.map((auditorOffice) =>
                      auditorOffice.id ? (
                        <MenuItem key={auditorOffice.id} value={auditorOffice.id}>
                          {auditorOffice.name}
                        </MenuItem>
                      ) : null,
                    )}
                  </Select>
                </>
              )}
            />
            {errors && get(errors, auditorOfficeField) && (
              <FormHelperText>
                {get(errors, auditorOfficeField) && get(errors, auditorOfficeField).message}
              </FormHelperText>
            )}
          </FormControl>
          <Fab
            color="primary"
            size="small"
            aria-label="add"
            onClick={() => setOpenAuditorOfficeDialog(true)}
          >
            <AddIcon data-testid="AddIconAuditorOffice" />
          </Fab>
        </Stack>
      )}
      <ErrorBoundary onError={logError} FallbackComponent={FallbackComponent}>
        <TaxOfficeFormDialog
          key={uuidv4()}
          open={openTaxOfficeDialog}
          onClose={() => setOpenTaxOfficeDialog(false)}
        />
      </ErrorBoundary>
      <ErrorBoundary onError={logError} FallbackComponent={FallbackComponent}>
        <AuditorOfficeFormDialog
          key={uuidv4()}
          open={openAuditorOfficeDialog}
          onClose={() => setOpenAuditorOfficeDialog(false)}
        />
      </ErrorBoundary>
    </>
  );
}
export default withTranslation(['taxOfficeFields', 'auditorOfficeFields'])(TaxAuditorOfficeFields);
