import { Box, Typography } from '@mui/material';
import { GridColDef, GridPaginationModel } from '@mui/x-data-grid-premium';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { JobModel, JobsService } from 'api/system';

import BdoDataGrid from 'components/BdoDataGrid/BdoDataGrid';
import { DataGridRow } from 'components/BdoDataGrid/Types/DataGridRow.definitions';

import { JobsProps } from './Jobs.definitions';

function Jobs({ t }: JobsProps): FunctionComponentElement<JobsProps> {
  const columns: GridColDef[] = [
    { field: 'id', headerName: t('jobFields:id'), type: 'string', width: 150 },
    {
      field: 'jobType',
      headerName: t('jobsFields:jobType'),
      type: 'string',
      width: 150,
      valueFormatter: ({ value }) => (value ? t(`jobsFields:${value}`) : ''),
      groupingValueGetter: ({ value }) => t(`jobsFields:${value}`),
    },
    {
      field: 'companyName',
      headerName: t('jobsFields:companyName'),
      type: 'string',
      width: 200,
    },
    {
      field: 'status',
      headerName: t('jobsFields:status'),
      type: 'string',
      width: 150,
      valueFormatter: ({ value }) => (value ? t(`importMessages:${value}`) : ''),
      groupingValueGetter: ({ value }) => t(`importMessages:${value}`),
    },
    {
      field: 'statusMessage',
      headerName: t('jobsFields:statusMessage'),
      type: 'string',
      width: 400,
      valueFormatter: ({ value }) => {
        const translation = t(`importMessages:${value}`);
        if (translation.startsWith('importMessages:')) {
          return value;
        }

        return translation;
      },
      groupingValueGetter: ({ value }) => {
        const translation = t(`importMessages:${value}`);
        if (translation.startsWith('importMessages:')) {
          return value;
        }

        return translation;
      },
    },
    {
      field: 'importedFileName',
      headerName: t('jobsFields:importedFileName'),
      type: 'string',
      width: 200,
    },
    {
      field: 'concurrencyGroup',
      headerName: t('jobsFields:concurrencyGroup'),
      type: 'string',
      width: 150,
    },

    {
      field: 'parameter',
      headerName: t('jobsFields:parameter'),
      type: 'string',
      width: 150,
    },
    {
      field: 'assemblyQualifiedJobName',
      headerName: t('jobsFields:assemblyQualifiedJobName'),
      type: 'string',
      width: 150,
    },
  ];

  const processRowUpdate = async (row: DataGridRow): Promise<DataGridRow> => {
    let resultRow;

    if (row.isNew) {
      resultRow = await JobsService.postApiJobsAddRow(row as JobModel);
    } else {
      resultRow = await JobsService.putApiJobsUpdateRow(row as JobModel);
    }

    return resultRow as DataGridRow;
  };

  const getRows = (pagination: GridPaginationModel) =>
    JobsService.getApiJobsGetRows(pagination.page, pagination.pageSize);

  const getRowCount = () => JobsService.getApiJobsGetRowCount();

  return (
    <div>
      <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
        {t('navigation:jobs')}
      </Typography>
      <Box sx={{ p: '1rem' }}>
        <BdoDataGrid
          columns={columns}
          getRows={getRows}
          getRowCount={getRowCount}
          processRowUpdate={processRowUpdate}
          exportFileName={t('jobsFields:exportFileName')}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                concurrencyGroup: false,
                parameter: false,
                assemblyQualifiedJobName: false,
                actions: false,
              },
            },
          }}
        />
      </Box>
    </div>
  );
}

export default withTranslation(['navigation', 'jobsFields', 'gridTooltips', 'importMessages'])(
  Jobs,
);
