import { Typography } from '@mui/material';
import { FunctionComponentElement } from 'react';

import { ShowInfoProps } from './ShowInfo.definitions';

function ShowInfo({ text }: ShowInfoProps): FunctionComponentElement<ShowInfoProps> {
  return (
    <Typography variant="body1" fontStyle="italic">
      {text}
    </Typography>
  );
}

export default ShowInfo;
