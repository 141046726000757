/* eslint-disable react/jsx-props-no-spreading */
import { Logout, Settings } from '@mui/icons-material';
import {
  Avatar,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useQuery } from '@tanstack/react-query';
import React, { FunctionComponentElement, useCallback, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ClientsService } from 'api/client';

import useStore from 'context/Store/Store.hooks';
import useUserRoles from 'shared/api-hooks/useUserRoles';

import { UserMenuProps } from './UserMenu.definitions';

function UserMenu({ t }: UserMenuProps): FunctionComponentElement<UserMenuProps> {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const { clientStore } = useStore();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);

  const { isFetching: isFetchingUserRoles, data: roles } = useUserRoles(keycloak.subject);

  const { isFetching: isFetchingClients, data: availableClients } = useQuery({
    queryKey: ['ClientSelection', keycloak.subject],
    queryFn: async () => ClientsService.getApiClientsGetClientsForUser(keycloak.subject),
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleChangeClient = useCallback(() => {
    clientStore.logout();
    navigate('/client-selection');
  }, [clientStore, navigate]);

  const handleLogout = useCallback(() => {
    clientStore.logout();
    keycloak.logout();
  }, [clientStore, keycloak]);

  const getUserShortcut = () => {
    const name = keycloak?.idTokenParsed?.name || t('unknown-user');
    return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
  };

  const getUserRole = () => {
    if (!roles || roles.length === 0) {
      return t('keycloakRoles:no-role-assigned');
    }

    if (roles.includes('bdo-vat-compliance-admin')) {
      return t('keycloakRoles:bdo-vat-compliance-admin');
    }

    if (roles.includes('bdo-vat-compliance-auditor-admin')) {
      return t('keycloakRoles:bdo-vat-compliance-auditor-admin');
    }

    if (roles.includes('bdo-vat-compliance-auditor-supervisor')) {
      return t('keycloakRoles:bdo-vat-compliance-auditor-supervisor');
    }

    if (roles.includes('bdo-vat-compliance-auditor-employee')) {
      return t('keycloakRoles:bdo-vat-compliance-auditor-employee');
    }

    if (roles.includes('bdo-vat-compliance-client-supervisor')) {
      return t('keycloakRoles:bdo-vat-compliance-client-supervisor');
    }

    if (roles.includes('bdo-vat-compliance-client-employee')) {
      return t('keycloakRoles:bdo-vat-compliance-client-employee');
    }

    return t('keycloakRoles:no-role-assigned');
  };

  if (isFetchingUserRoles || isFetchingClients) {
    return <CircularProgress />;
  }

  return (
    <>
      <Stack
        sx={{
          ml: '0.5rem',
          mr: '0.5rem',
        }}
      >
        <Typography variant="overline" display="block" sx={{ lineHeight: 'inherit' }}>
          {keycloak?.idTokenParsed?.name || t('unknown-user')}
        </Typography>
        <Typography variant="caption" display="block">
          {getUserRole()}
        </Typography>
      </Stack>
      <Tooltip title={t('account-settings')}>
        <IconButton
          onClick={handleClick}
          size="medium"
          sx={{ m: 0, p: 0, ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar
            sx={{
              width: (theme) => theme.mixins.toolbar.minHeight,
              height: (theme) => theme.mixins.toolbar.minHeight,
              bgcolor: '#98002e',
            }}
            variant="square"
          >
            {getUserShortcut()}
          </Avatar>
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorElement}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose} disabled>
          <Avatar /> {t('profile')}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose} disabled>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          {t('settings')}
        </MenuItem>
        <MenuItem
          onClick={handleChangeClient}
          disabled={!isFetchingClients && availableClients && availableClients.length <= 1}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t('changeClient')}
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t('logout')}
        </MenuItem>
      </Menu>
    </>
  );
}

export default withTranslation(['userMenu', 'keycloakRoles'])(UserMenu);
