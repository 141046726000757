import { observer } from 'mobx-react-lite';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import useStore from 'context/Store/Store.hooks';

import NoDrawerContent from 'components/NoDrawerContent/NoDrawerContent';

import { HomeDrawerProps } from './HomeDrawer.definitions';

function HomeDrawer({ t }: HomeDrawerProps): FunctionComponentElement<HomeDrawerProps> {
  const {
    rightDrawerStore: { isActionsDrawer, isCommentsDrawer },
  } = useStore();

  return (
    <>
      {isActionsDrawer && <NoDrawerContent text={t('noActionsAvailable')} />}
      {isCommentsDrawer && <NoDrawerContent text={t('noCommentsAvailable')} />}
    </>
  );
}

export default withTranslation('common')(observer(HomeDrawer));
