enum NavListType {
  Dashboard = 'Dashboard',
  Country = 'Country',
  TaxYear = 'TaxYear',
  AnnualVatReturn = 'AnnualVatReturn',
  FillingPeriod = 'FillingPeriod',
  AdvanceVatReturn = 'AdvanceVatReturn',
  EcSalesList = 'EcSalesList',
  SpecialPrepayment = 'SpecialPrepayment',
  ValueAddedTaxGroup = 'ValueAddedTaxGroup',
  Company = 'Company',
  ClientSettings = 'ClientSettings',
  ClientMasterdata = 'ClientMasterdata',
  TaxYears = 'TaxYears',
  TaxCodes = 'TaxCodes',
  ValueAddedTaxGroupSettings = 'ValueAddedTaxGroupSettings',
  CompanySettings = 'CompanySettings',
  ImportSettings = 'ImportSettings',
  CultureSettings = 'CultureSettings',
  UserManagement = 'UserManagement',
  RoleManagement = 'RoleManagement',
  SystemSettings = 'SystemSettings',
  Clients = 'Clients',
  ValueAddedTaxForms = 'ValueAddedTaxForms',
  ValueAddedTaxFormYear = 'ValueAddedTaxFormYear',
  AnnualVatReturnForm = 'AnnualVatReturnForm',
  AdvanceVatReturnForm = 'AdvanceVatReturnForm',
  EcSalesListForm = 'EcSalesListForm',
  TaxOffices = 'TaxOffices',
  AuditorOffices = 'AuditorOffices',
  Contacts = 'Contacts',
  ExchangeRates = 'ExchangeRates',
  TaxIssues = 'TaxIssues',
  TaxRules = 'TaxRules',
  TaxRuleMappings = 'TaxRuleMappings',
  SystemAdminSettings = 'SystemAdminSettings',
  Jobs = 'Jobs',
  Administrations = 'Administrations',
  AuditLogs = 'AuditLogs',
  ImportLogs = 'ImportLogs',
}

export default NavListType;
