import { burgundy, charcoal, gold, jade, ocean, red } from './variables';

const PRIMARY = {
  main: burgundy,
  contrastText: '#fff',
};

const SECONDARY = {
  main: charcoal,
  contrastText: '#fff',
};

const INFO = {
  main: ocean,
  contrastText: '#fff',
};

const SUCCESS = {
  main: jade,
  contrastText: '#fff',
};

const WARNING = {
  main: gold,
  contrastText: '#fff',
};

const ERROR = {
  main: red,
  contrastText: '#fff',
};

const palette = {
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
};

export default palette;
