/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TaxYearModel } from '../models/TaxYearModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaxYearsService {
  /**
   * Gets the list of Tax Years.
   * @returns TaxYearModel Success
   * @throws ApiError
   */
  public static getApiTaxYears(): CancelablePromise<Array<TaxYearModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxYears',
    });
  }

  /**
   * Creates a new Tax Year.
   * @param requestBody The TaxYearModel containing information for the new Tax Year.
   * @returns TaxYearModel Success
   * @throws ApiError
   */
  public static postApiTaxYearsAddRow(requestBody?: TaxYearModel): CancelablePromise<TaxYearModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/TaxYears/AddRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Retrieves a paginated list of Tax Years.
   * @param page The page number of the data set to retrieve.
   * @param pageSize The size of the page to retrieve.
   * @returns TaxYearModel Success
   * @throws ApiError
   */
  public static getApiTaxYearsGetRows(
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<TaxYearModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxYears/GetRows',
      query: {
        page: page,
        pageSize: pageSize,
      },
    });
  }

  /**
   * Retrieves the total count of Tax Years.
   * @returns number Success
   * @throws ApiError
   */
  public static getApiTaxYearsGetRowCount(): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxYears/GetRowCount',
    });
  }

  /**
   * Updates an existing Tax Year.
   * @param requestBody The TaxYearModel with updated information.
   * @returns TaxYearModel Success
   * @throws ApiError
   */
  public static putApiTaxYearsUpdateRow(
    requestBody?: TaxYearModel,
  ): CancelablePromise<TaxYearModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/TaxYears/UpdateRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Deletes a tax year if it's not referenced anymore.
   * @param requestBody The tax year model to delete.
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteApiTaxYearsDeleteRow(requestBody?: TaxYearModel): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/TaxYears/DeleteRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
