import { RouteObject } from 'react-router-dom';

import PathConstants from 'pages/PathConstants';

import HomeDrawer from '../HomeDrawer/HomeDrawer';
import ExchangeRateDrawer from '../SystemDrawer/ExchangeRateDrawer/ExchangeRateDrawer';
import SystemSettingsDrawer from '../SystemDrawer/SystemSettingsDrawer/SystemSettingsDrawer';

const SystemRouterConfig: RouteObject[] = [
  {
    path: PathConstants.SYSTEM_SETTINGS,
    children: [
      {
        index: true,
        element: <SystemSettingsDrawer />,
      },
      {
        path: PathConstants.VAT_FORMS,
        children: [
          {
            index: true,
            element: <HomeDrawer />,
          },
          {
            path: PathConstants.VAT_FORM_YEAR,
            children: [
              {
                index: true,
                element: <HomeDrawer />,
              },
              {
                path: PathConstants.ANNUAL_VAT_RETURN_FORM,
                element: <HomeDrawer />,
              },
              {
                path: PathConstants.ADVANCE_VAT_RETURN_FORM,
                element: <HomeDrawer />,
              },
              {
                path: PathConstants.EC_SALES_LIST_FORM,
                element: <HomeDrawer />,
              },
            ],
          },
        ],
      },
      {
        path: PathConstants.TAX_OFFICES,
        element: <HomeDrawer />,
      },
      {
        path: PathConstants.AUDITOR_OFFICES,
        element: <HomeDrawer />,
      },
      {
        path: PathConstants.TAX_YEARS,
        element: <HomeDrawer />,
      },
      {
        path: PathConstants.CONTACTS,
        element: <HomeDrawer />,
      },
      {
        path: PathConstants.EXCHANGE_RATES,
        children: [
          {
            index: true,
            element: <ExchangeRateDrawer />,
          },
          {
            path: PathConstants.IMPORT_EXCHANGE_RATES,
            element: <HomeDrawer />,
          },
        ],
      },
      {
        path: PathConstants.COUNTRIES,
        element: <HomeDrawer />,
      },
      {
        path: PathConstants.TAX_ISSUES,
        element: <HomeDrawer />,
      },
      {
        path: PathConstants.TAX_RULES,
        element: <HomeDrawer />,
      },
      {
        path: PathConstants.TAX_RULE_MAPPINGS,
        element: <HomeDrawer />,
      },
    ],
  },
];

export default SystemRouterConfig;
