import { RouteObject } from 'react-router-dom';

import PathConstants from 'pages/PathConstants';

import CreateClientStepper from 'features/CreateClientStepper/CreateClientStepper/CreateClientStepper';

import Administrations from '../Admin/Administrations/Administrations';
import AuditLogs from '../Admin/AuditLogs/AuditLogs';
import Clients from '../Admin/Clients/Clients';
import SystemAdminSettings from '../Admin/SystemAdminSettings/SystemAdminSettings';

const AdminRouterConfig: RouteObject[] = [
  {
    path: PathConstants.ADMINISTRATIONS,
    children: [
      {
        index: true,
        element: <Administrations />,
      },
      {
        path: PathConstants.CLIENTS,
        children: [
          {
            index: true,
            element: <Clients />,
          },
          {
            path: PathConstants.ADD,
            element: <CreateClientStepper />,
          },
        ],
      },
      {
        path: PathConstants.SYSTEM_ADMIN_SETTINGS,
        element: <SystemAdminSettings />,
      },
      {
        path: 'audit-logs',
        element: <AuditLogs />,
      },
    ],
  },
];

export default AdminRouterConfig;
