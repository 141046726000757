/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReviewState } from '../models/ReviewState';
import type { TaxFormEntryModel } from '../models/TaxFormEntryModel';
import type { TaxFormEntryValueModel } from '../models/TaxFormEntryValueModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaxFormsService {
  /**
   * Changes the review state of a tax form entry.
   * @param taxFormEntryId The ID of the tax form entry to update.
   * @param reviewState The new review state to be applied.
   * @returns TaxFormEntryModel Success
   * @throws ApiError
   */
  public static postApiTaxFormsChangeReviewState(
    taxFormEntryId?: string,
    reviewState?: ReviewState,
  ): CancelablePromise<TaxFormEntryModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/TaxForms/ChangeReviewState',
      query: {
        taxFormEntryId: taxFormEntryId,
        reviewState: reviewState,
      },
    });
  }

  /**
   * Applies overwriting values to a tax form entry.
   * @param taxFormEntryId The ID of the tax form entry to overwrite.
   * @param requestBody The overwriting values to apply.
   * @returns TaxFormEntryModel Success
   * @throws ApiError
   */
  public static postApiTaxFormsApplyOverwriting(
    taxFormEntryId?: string,
    requestBody?: TaxFormEntryValueModel,
  ): CancelablePromise<TaxFormEntryModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/TaxForms/ApplyOverwriting',
      query: {
        taxFormEntryId: taxFormEntryId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Reverses any overwriting applied to a tax form entry, restoring its original values.
   * @param taxFormEntryId The ID of the tax form entry to reverse overwriting for.
   * @returns TaxFormEntryModel Success
   * @throws ApiError
   */
  public static postApiTaxFormsReverseOverwriting(
    taxFormEntryId?: string,
  ): CancelablePromise<TaxFormEntryModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/TaxForms/ReverseOverwriting',
      query: {
        taxFormEntryId: taxFormEntryId,
      },
    });
  }

  /**
   * Enforces the recalculation of form entries for a given submission by removing existing calculation and overwriting values.
   * This method finds a submission by its ID, then removes all associated calculation and overwriting values for GeneralFormEntries,
   * EntrepreneurFormEntries, and TaxFormEntries. After the associated entries are removed, the changes are saved to the database.
   * @param submissionId The unique identifier of the submission for which recalculation is to be enforced.
   * @returns any Success
   * @throws ApiError
   */
  public static postApiTaxFormsEnforceRecalculation(submissionId?: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/TaxForms/EnforceRecalculation',
      query: {
        submissionId: submissionId,
      },
    });
  }
}
