import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import NamedApprovalStatus from 'enums/NamedApprovalStatus';
import { TaxFormData } from 'models/TaxFormData';

const useSubmissionFormData = (submissionId: string | undefined) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const queryClient = useQueryClient();

  const data = queryClient.getQueryData<TaxFormData>(['BdoFormDataGrid', submissionId]);

  useEffect(() => {
    if (data) {
      setIsDisabled(Number(data.approvalStatus) !== NamedApprovalStatus.InEditing);
    }
  }, [data]);

  return { data, isDisabled };
};

export default useSubmissionFormData;
