import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FunctionComponentElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { withTranslation } from 'react-i18next';

import theme from 'themes/theme';

import { CommentModel, CommentsService } from 'api/system';

import { getDateTime } from 'shared/utils/date.helper';

import Loader from 'components/Loader/Loader';

import { CommentSectionProps } from './CommentSection.definitions';

function CommentSection({
  entityType,
  referenceId,
  t,
}: CommentSectionProps): FunctionComponentElement<CommentSectionProps> {
  const [isMutating, setIsMutating] = useState(false);
  const queryClient = useQueryClient();

  const methods = useForm<CommentModel>({
    defaultValues: {
      entityType,
      referenceId,
      value: '',
    },
    mode: 'onChange',
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid },
  } = methods;

  const { isFetching, data: comments } = useQuery({
    queryKey: ['getComments', entityType, referenceId],
    queryFn: async () => {
      const response = await CommentsService.getApiCommentsGetComments(entityType, referenceId);
      return response;
    },
  });

  const { mutate: postMutate } = useMutation({
    mutationFn: async (newComment: CommentModel) => {
      const response = await CommentsService.postApiCommentsCreateComment(newComment);
      return response;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['getComments', entityType, referenceId],
        (oldComments: CommentModel[]) => {
          const updatedComments = [data, ...oldComments];
          return updatedComments;
        },
      );
      reset();
    },
    onMutate: () => setIsMutating(true),
    onSettled: () => setIsMutating(false),
    mutationKey: ['postComment', entityType, referenceId],
  });

  const { mutate: deleteMutate } = useMutation({
    mutationFn: async (commentId: string) => {
      const response = await CommentsService.deleteApiCommentsDeleteComment(commentId);
      return response;
    },
    onSuccess: (isDeleteSuccess, commentId) => {
      if (isDeleteSuccess) {
        queryClient.setQueryData(
          ['getComments', entityType, referenceId],
          (oldComments: CommentModel[]) => {
            const updatedComments = oldComments.filter((comment) => comment.id !== commentId);
            return updatedComments;
          },
        );
      }
    },
    mutationKey: ['deleteComment', entityType, referenceId],
  });

  const handleAddClick = (data: CommentModel) => {
    postMutate(data);
  };

  const handleDeleteClick = (commentId: string) => {
    deleteMutate(commentId);
  };

  if (isFetching) {
    return <Loader />;
  }

  const getTranslatedComment = (commentValue?: string | null): string => {
    if (!commentValue) {
      return '';
    }
    const approvalStatusChangedComment = commentValue.startsWith('approval-status-changed');
    if (approvalStatusChangedComment) {
      const [, oldStatus, newStatus] = commentValue.split(' ');
      const translated = t('approval-status-changed', {
        oldStatus: t(`submissions:${oldStatus}`),
        newStatus: t(`submissions:${newStatus}`),
      });
      return translated;
    }
    return commentValue;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          flexGrow: '1',
          mb: 2,
          overflowY: 'auto',
        }}
      >
        <Stack spacing={1} sx={{ p: 1 }}>
          {comments?.map((comment) => (
            <Card key={comment.id} sx={{ bgcolor: theme.palette.grey[100] }}>
              <CardHeader
                sx={{ p: 1 }}
                action={
                  !comment.isReadOnly ? (
                    <IconButton aria-label="delete" onClick={() => handleDeleteClick(comment.id!)}>
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    ''
                  )
                }
                title={
                  <Typography variant="body1" fontWeight="medium" component="div">
                    {comment.createdBy}
                  </Typography>
                }
                subheader={
                  <Typography
                    variant="caption"
                    sx={{ color: theme.palette.grey[500] }}
                    component="div"
                  >
                    {getDateTime(comment.created || '')}
                  </Typography>
                }
              />
              <CardContent sx={{ px: 1, pt: 0, pb: 1, '&:last-child': { pb: 2 } }}>
                <Typography variant="body2">{getTranslatedComment(comment.value)}</Typography>
              </CardContent>
            </Card>
          ))}
        </Stack>
      </Box>
      <form
        onSubmit={handleSubmit(handleAddClick)}
        className="comment"
        autoComplete="off"
        autoCorrect="off"
      >
        <FormControl variant="outlined" fullWidth>
          <Controller
            name="value"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { value, onChange } }) => (
              <>
                <InputLabel htmlFor="input-comment">{t('addComment')}</InputLabel>
                <OutlinedInput
                  id="input-comment"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={t('addComment')}
                        type="submit"
                        edge="end"
                        disabled={!isValid}
                        color="primary"
                        sx={{ height: '50px', width: '50px' }}
                      >
                        {isMutating ? <CircularProgress /> : <SendIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t('addComment')}
                  value={value}
                  onChange={onChange}
                  multiline
                  minRows={1}
                  maxRows={2}
                  autoComplete="off"
                  autoCorrect="off"
                />
              </>
            )}
          />
        </FormControl>
      </form>
    </Box>
  );
}

export default withTranslation('common')(CommentSection);
