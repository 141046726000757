import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, Fab, Stack } from '@mui/material';
import React, { FunctionComponentElement, useEffect, useState } from 'react';
import { FormProvider, Path, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { withTranslation } from 'react-i18next';

import { CompanyModel } from 'api/client';

import { getNewCompany } from 'shared/utils/formFields.helper';

import AccordionTitle from 'components/AccordionTitle/AccordionTitle';
import ControlledAccordion from 'components/ControlledAccordion/ControlledAccordion';
import CompanyFields from 'components/FormFields/CompanyFields/CompanyFields';
import FormFooter from 'components/Layouts/FormFooter/FormFooter';

import { ValueAddedTaxStepProps, ValueAddedTaxStepValues } from './ValueAddedTaxStep.definitions';

const newControllingCompany = { ...getNewCompany(), isControllingCompany: true };
function ValueAddedTaxStep({
  clientFormInfo,
  updateClientFormInfo,
  goToNextStep,
  goToPrevStep,
  t,
}: ValueAddedTaxStepProps): FunctionComponentElement<ValueAddedTaxStepProps> {
  const getDefaultFormValues = (): CompanyModel[] => {
    if (clientFormInfo.valueAddedTaxGroup && clientFormInfo.valueAddedTaxGroup.length > 0) {
      return clientFormInfo.valueAddedTaxGroup;
    }
    return [newControllingCompany];
  };

  const getActiveCompanyFormId = (): string => {
    const defaultValues = getDefaultFormValues();
    if (defaultValues.length > 0) {
      return defaultValues[0].id || '';
    }
    return '';
  };

  const [activeCompanyFormId, setActiveCompanyFormId] = useState<string>(getActiveCompanyFormId());

  const methods = useForm<ValueAddedTaxStepValues>({
    defaultValues: {
      valueAddedTaxGroup: getDefaultFormValues(),
    },
    mode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    clearErrors,
    watch,
    formState: { isValid },
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'valueAddedTaxGroup',
    keyName: 'key',
  });

  const getNameField = (index: number): Path<ValueAddedTaxStepValues> =>
    `valueAddedTaxGroup.${index}.name`;
  const getLegalFormField = (index: number): Path<ValueAddedTaxStepValues> =>
    `valueAddedTaxGroup.${index}.legalForm`;
  const getTaxNumberField = (index: number): Path<ValueAddedTaxStepValues> =>
    `valueAddedTaxGroup.${index}.taxNumber`;
  const getVatTaxNumberField = (index: number): Path<ValueAddedTaxStepValues> =>
    `valueAddedTaxGroup.${index}.vatTaxNumber`;
  const getAddressField = (index: number): Path<ValueAddedTaxStepValues> =>
    `valueAddedTaxGroup.${index}.address`;
  const getContactInfoField = (index: number): Path<ValueAddedTaxStepValues> =>
    `valueAddedTaxGroup.${index}.contactInfo`;
  const getAuditorOfficeField = (index: number): Path<ValueAddedTaxStepValues> =>
    `valueAddedTaxGroup.${index}.auditorOffice`;
  const getTaxOfficeField = (index: number): Path<ValueAddedTaxStepValues> =>
    `valueAddedTaxGroup.${index}.taxOffice`;

  const handleNextClick = (data: ValueAddedTaxStepValues) => {
    const updatedInfo = { ...clientFormInfo, valueAddedTaxGroup: data.valueAddedTaxGroup };
    updateClientFormInfo(updatedInfo);
    goToNextStep();
  };

  const handlePrevClick = () => {
    goToPrevStep();
  };

  const handleAddClick = () => {
    const newCompany = getNewCompany();
    append(newCompany, {
      shouldFocus: false,
    });
    if (newCompany.id) {
      setActiveCompanyFormId(newCompany.id);
    }
  };

  const handleRemoveClick = (itemIndex: number) => {
    remove(itemIndex);
  };

  const formValues = useWatch({
    control,
    name: 'valueAddedTaxGroup',
  });

  const areAllFormFieldsEntered = (): boolean => {
    if (!formValues) {
      return false;
    }

    return formValues.every(
      (companyForm) =>
        companyForm.name !== '' &&
        companyForm.taxNumber !== '' &&
        companyForm.address?.street !== '' &&
        companyForm.address?.number !== '' &&
        companyForm.address?.city !== '' &&
        companyForm.address?.postal !== '' &&
        companyForm.taxOffice?.id !== '' &&
        companyForm.auditorOffice?.id !== '',
    );
  };

  useEffect(() => {
    if (isValid) {
      clearErrors();
    }
  }, [clearErrors, isValid]);

  return (
    <form onSubmit={handleSubmit(handleNextClick)} className="formContent">
      <Box sx={{ flexGrow: 1, p: 2 }}>
        {fields.map((item, index) => (
          <ControlledAccordion
            key={item.id}
            accordionId={item.id!}
            activeAccordionId={activeCompanyFormId}
            expanded={item.id !== undefined && activeCompanyFormId === item.id}
            accordionSummary={
              <AccordionTitle
                title={`${t(
                  item.isControllingCompany
                    ? 'common:controllingCompany'
                    : 'common:subsidiaryCompany',
                )} ${watch(`valueAddedTaxGroup.${index}.name`)}`}
              />
            }
            accordionDetails={
              <>
                <FormProvider {...methods}>
                  <CompanyFields
                    index={index}
                    nameField={getNameField(index)}
                    legalFormField={getLegalFormField(index)}
                    taxNumberField={getTaxNumberField(index)}
                    vatTaxNumberField={getVatTaxNumberField(index)}
                    addressField={getAddressField(index)}
                    contactInfoField={getContactInfoField(index)}
                    auditorOfficeField={getAuditorOfficeField(index)}
                    taxOfficeField={getTaxOfficeField(index)}
                  />
                </FormProvider>
                <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
                  <Fab
                    color="primary"
                    size="small"
                    aria-label="add"
                    onClick={() => handleRemoveClick(index)}
                    disabled={item.isControllingCompany}
                  >
                    <RemoveIcon />
                  </Fab>
                </Box>
              </>
            }
          />
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'end', m: 2 }}>
          <Fab
            color="primary"
            size="small"
            aria-label="add"
            onClick={handleAddClick}
            disabled={!(areAllFormFieldsEntered() && isValid)}
          >
            <AddIcon />
          </Fab>
        </Box>
      </Box>
      <FormFooter>
        <Stack direction="row" spacing={3}>
          <Button variant="contained" onClick={handlePrevClick}>
            {t('common:back')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={!(areAllFormFieldsEntered() && isValid)}
          >
            {t('common:next')}
          </Button>
        </Stack>
      </FormFooter>
    </form>
  );
}

export default withTranslation(['taxOfficeFields', 'auditorOfficeFields', 'common'])(
  ValueAddedTaxStep,
);
