import { Card, CardContent } from '@mui/material';
import { FunctionComponentElement } from 'react';

import { CardWrapperProps } from './CardWrapper.definitions';

function CardWrapper({ children }: CardWrapperProps): FunctionComponentElement<CardWrapperProps> {
  return (
    <Card
      sx={{
        mb: 2,
      }}
    >
      <CardContent>{children}</CardContent>
    </Card>
  );
}

export default CardWrapper;
