import { IconButton, Tooltip } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { PopupDataDisplayIconProps } from './PopupDataDisplayIcon.definition';

function PopupDataDisplayIcon({
  hasData,
  activeTitle,
  inactiveTitle,
  handleClick,
  children,
  t,
}: PopupDataDisplayIconProps): FunctionComponentElement<PopupDataDisplayIconProps> {
  if (!hasData) {
    return (
      <Tooltip title={inactiveTitle}>
        <IconButton disableFocusRipple disableRipple color="default">
          {children}
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={activeTitle}>
      <IconButton disableFocusRipple disableRipple color="info" onClick={handleClick}>
        {children}
      </IconButton>
    </Tooltip>
  );
}

export default withTranslation('submissionLog')(PopupDataDisplayIcon);
