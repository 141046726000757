import { Box, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { TaxValidationsService } from 'api/taxRule';

import useStore from 'context/Store/Store.hooks';

import { FillingPeriodActionsProps } from './FillingPeriodActions.definitions';

function FillingPeriodActions({
  isDisabled = false,
  t,
}: FillingPeriodActionsProps): FunctionComponentElement<FillingPeriodActionsProps> {
  const { fillingPeriodId } = useParams();
  const {
    rightDrawerStore: { setOpen },
  } = useStore();

  const handleTriggerValidation = async () => {
    setOpen(false);
    await TaxValidationsService.getApiTaxValidationsEnqueueFillingPeriodValidations(
      fillingPeriodId,
    );
  };

  return (
    <Box sx={{ p: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Button
        fullWidth
        variant="contained"
        sx={{ mb: '10px', textTransform: 'none' }}
        onClick={handleTriggerValidation}
        disabled={isDisabled}
      >
        {t('triggerValidation')}
      </Button>
    </Box>
  );
}

export default withTranslation('common')(observer(FillingPeriodActions));
