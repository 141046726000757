import { Typography } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { CompanyHeaderProps } from './CompanyHeader.definitions';

function CompanyHeader({
  name,
  isControllingCompany,
  t,
}: CompanyHeaderProps): FunctionComponentElement<CompanyHeaderProps> {
  return (
    <Typography
      variant="h6"
      component="div"
      sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}
    >
      {name}
      <Typography
        variant="subtitle2"
        sx={{ display: 'flex', px: 1, py: 0.5, fontWeight: 'medium' }}
      >
        (
        {isControllingCompany
          ? t('companyFields:controllingCompany')
          : t('companyFields:controlledCompany')}
        )
      </Typography>
    </Typography>
  );
}

export default withTranslation('companyFields')(CompanyHeader);
