import { Typography } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { ValueAddedTaxFormYearProps } from './ValueAddedTaxFormYear.definitions';

function ValueAddedTaxFormYear({
  t,
}: ValueAddedTaxFormYearProps): FunctionComponentElement<ValueAddedTaxFormYearProps> {
  return (
    <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
      {t('value-added-tax-form-year-prefix')}
    </Typography>
  );
}

export default withTranslation('navigation')(ValueAddedTaxFormYear);
