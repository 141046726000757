import { Box, Typography } from '@mui/material';
import {
  GridColDef,
  GridEditDateCell,
  GridPaginationModel,
  GridRowModel,
  ValueOptions,
} from '@mui/x-data-grid-premium';
import { useQuery } from '@tanstack/react-query';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { CountriesService } from 'api/masterdata';
import {
  TaxIssuesService,
  TaxRuleMappingModel,
  TaxRuleMappingsService,
  TaxRulesService,
} from 'api/taxRule';

import BdoDataGrid from 'components/BdoDataGrid/BdoDataGrid';
import { DataGridRow } from 'components/BdoDataGrid/Types/DataGridRow.definitions';
import CenteredLoader from 'components/CenteredLoader/CenteredLoader';
import FallbackComponent from 'components/FallbackComponent/FallbackComponent';

import { TaxRuleMappingsProps } from './TaxRuleMappings.definitions';

function TaxRuleMappings({
  t,
}: TaxRuleMappingsProps): FunctionComponentElement<TaxRuleMappingsProps> {
  const {
    data: taxRules,
    isFetching: isFetchingTaxRules,
    isError: isErrorTaxRules,
  } = useQuery({
    queryKey: ['setTaxRules'],
    queryFn: async () => {
      const taxRulesData = await TaxRulesService.getApiTaxRules();

      return taxRulesData;
    },
  });

  const {
    data: taxIssues,
    isFetching: isFetchingTaxIssues,
    isError: isErrorTaxIssues,
  } = useQuery({
    queryKey: ['setTaxIssues'],
    queryFn: async () => {
      const taxIssuesData = await TaxIssuesService.getApiTaxIssues();

      return taxIssuesData;
    },
  });

  const {
    data: countries,
    isFetching: isFetchingCountries,
    isError: isErrorCountries,
  } = useQuery({
    queryKey: ['setCountries'],
    queryFn: async () => {
      const countriesData = await CountriesService.getApiCountries();

      return countriesData;
    },
  });

  const getTaxRulesValueOptions = (): ValueOptions[] => {
    if (!taxRules) return [];

    const mappedTaxRules = taxRules.map(
      (taxRule): ValueOptions => ({
        value: taxRule.id,
        label: taxRule.name,
      }),
    );

    const result: ValueOptions[] = [
      { value: 'none', label: t('common:noSelection') },
      ...mappedTaxRules,
    ];

    return result;
  };

  const getTaxIssuesValueOptions = (): ValueOptions[] => {
    if (!taxIssues) return [];

    const mappedTaxIssues = taxIssues.map(
      (taxIssue): ValueOptions => ({
        value: taxIssue.id,
        label: taxIssue.name,
      }),
    );

    const result: ValueOptions[] = [
      { value: 'none', label: t('common:noSelection') },
      ...mappedTaxIssues,
    ];

    return result;
  };

  const getCountriesValueOptions = (): ValueOptions[] => {
    if (!countries) return [];

    const mappedCountries = countries.map(
      (country): ValueOptions => ({
        value: country.id,
        label: country.name,
      }),
    );

    const result: ValueOptions[] = [
      { value: 'none', label: t('common:noSelection') },
      ...mappedCountries,
    ];

    return result;
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: t('taxRuleMappingFields:id'), type: 'string', width: 150 },
    {
      field: 'validFrom',
      headerName: t('taxRuleMappingFields:validFrom'),
      type: 'date',
      width: 150,
      editable: true,
      valueGetter: ({ value }) => (value && new Date(value)) || '',
      renderEditCell: (params) => <GridEditDateCell {...params} />,
      groupingValueGetter: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
      field: 'validTo',
      headerName: t('taxRuleMappingFields:validTo'),
      type: 'date',
      width: 150,
      editable: true,
      valueGetter: ({ value }) => (value && new Date(value)) || '',
      renderEditCell: (params) => <GridEditDateCell {...params} />,
      groupingValueGetter: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
      field: 'taxRuleId',
      headerName: t('taxRuleMappingFields:taxRule'),
      type: 'singleSelect',
      editable: true,
      width: 300,
      valueOptions: getTaxRulesValueOptions(),
      valueGetter: (params) => {
        const taxRule = taxRules?.find((x) => x.id === params.value);

        if (taxRule === undefined) {
          return 'none';
        }

        return taxRule.id;
      },
      renderCell: (params) => {
        if (isErrorTaxRules) {
          return <FallbackComponent errorMessage={t('common:errorOccured')} />;
        }

        if (params.rowNode.type === 'group' && params.field === params.rowNode.groupingField) {
          return '';
        }

        if (params.value === 'none') return t('common:noSelection');

        const taxRuleId = params?.row?.taxRuleId;

        if (taxRuleId) {
          return taxRules?.find((x) => x.id === taxRuleId)?.name;
        }

        return params.value || t('common:noSelection');
      },
      valueSetter: (params) => {
        const { value, row } = params;
        const taxRule = taxRules?.find((x) => x.id === value);

        return { ...row, taxRuleId: taxRule?.id };
      },
      groupingValueGetter: (params) => {
        const name = taxRules?.find((x) => x.id === params.value)?.name;

        if (name === undefined) {
          return t('common:noSelection');
        }

        return name;
      },
    },
    {
      field: 'taxIssueId',
      headerName: t('taxRuleMappingFields:taxIssue'),
      type: 'singleSelect',
      editable: true,
      width: 300,
      valueOptions: getTaxIssuesValueOptions(),
      valueGetter: (params) => {
        const taxIssue = taxIssues?.find((x) => x.id === params.value);

        if (taxIssue === undefined) {
          return 'none';
        }

        return taxIssue.id;
      },
      renderCell: (params) => {
        if (isErrorTaxIssues) {
          return <FallbackComponent errorMessage={t('common:errorOccured')} />;
        }

        if (params.rowNode.type === 'group' && params.field === params.rowNode.groupingField) {
          return '';
        }

        if (params.value === 'none') return t('common:noSelection');

        const taxIssueId = params?.row?.taxIssueId;
        if (taxIssueId) {
          return taxIssues?.find((x) => x.id === taxIssueId)?.name;
        }

        return params.value || t('common:noSelection');
      },
      valueSetter: (params) => {
        const { value, row } = params;
        const taxIssue = taxIssues?.find((x) => x.id === value);

        return { ...row, taxIssueId: taxIssue?.id };
      },
      groupingValueGetter: (params) => {
        const name = taxIssues?.find((x) => x.id === params.value)?.name;

        if (name === undefined) {
          return t('common:noSelection');
        }

        return name;
      },
    },
    {
      field: 'country',
      headerName: t('taxRuleMappingFields:country'),
      type: 'singleSelect',
      editable: true,
      width: 150,
      valueOptions: getCountriesValueOptions(),
      valueGetter: (params) => {
        const country = countries?.find((x) => x.id === params.value?.id);

        if (country === undefined) {
          return 'none';
        }

        return country.id;
      },
      renderCell: (params) => {
        if (isErrorCountries) {
          return <FallbackComponent errorMessage={t('common:errorOccured')} />;
        }

        if (params.rowNode.type === 'group' && params.field === params.rowNode.groupingField) {
          return '';
        }

        if (params.value === 'none') return t('common:noSelection');

        const country = params?.row?.country;

        if (country) {
          return country.name;
        }

        return params.value || t('common:noSelection');
      },
      valueSetter: (params) => {
        const { value, row } = params;
        const country = countries?.find((x) => x.id === value);

        return { ...row, country };
      },
      groupingValueGetter: (params) => {
        const country = countries?.find((x) => x.id === params.value?.id);

        if (country === undefined) {
          return t('common:noSelection');
        }

        return country.name;
      },
    },
  ];

  const addDefaultRecord = (id: string): GridRowModel => {
    const taxRuleMapping: TaxRuleMappingModel = {
      id,
      validFrom: new Date().toDateString(),
      validTo: new Date().toDateString(),
    };

    return taxRuleMapping;
  };

  const processRowUpdate = async (row: DataGridRow): Promise<DataGridRow> => {
    let resultRow;

    if (row.isNew) {
      resultRow = await TaxRuleMappingsService.postApiTaxRuleMappingsAddRow(
        row as TaxRuleMappingModel,
      );
    } else {
      resultRow = await TaxRuleMappingsService.putApiTaxRuleMappingsUpdateRow(
        row as TaxRuleMappingModel,
      );
    }

    return resultRow as DataGridRow;
  };

  const processRowDelete = async (row: DataGridRow): Promise<boolean> =>
    TaxRuleMappingsService.deleteApiTaxRuleMappingsDeleteRow(row as TaxRuleMappingModel);

  const getRows = (pagination: GridPaginationModel) =>
    TaxRuleMappingsService.getApiTaxRuleMappingsGetRows(pagination.page, pagination.pageSize);

  const getRowCount = () => TaxRuleMappingsService.getApiTaxRuleMappingsGetRowCount();

  if (isFetchingTaxRules || isFetchingTaxIssues || isFetchingCountries) {
    return <CenteredLoader />;
  }

  return (
    <div>
      <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
        {t('navigation:tax-rule-mappings')}
      </Typography>
      <Box sx={{ p: '1rem' }}>
        <BdoDataGrid
          columns={columns}
          getRows={getRows}
          getRowCount={getRowCount}
          processRowUpdate={processRowUpdate}
          processRowDelete={processRowDelete}
          addDefaultRecord={addDefaultRecord}
          exportFileName={t('taxRuleMappingFields:exportFileName')}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
          }}
        />
      </Box>
    </div>
  );
}

export default withTranslation(['gridTooltips', 'navigation', 'taxRuleMappingFields'])(
  TaxRuleMappings,
);
