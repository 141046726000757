import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { EcSalesListSubmissionService } from 'api/submission';
import { EcSalesListsService } from 'api/taxCalculation';

import BdoTaxFormDataGrid from 'components/BdoTaxFormDataGrid/BdoTaxFormDataGrid';

import { EcSalesListProps } from './EcSalesList.definitions';

function EcSalesList({ t }: EcSalesListProps): FunctionComponentElement<EcSalesListProps> {
  const { ecSalesListId, fillingPeriodId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const basePath = location.pathname.substring(0, location.pathname.indexOf('filling-period') - 1);

  const { mutate: createCorrection } = useMutation({
    mutationFn: (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      return EcSalesListSubmissionService.postApiEcSalesListSubmissionCreateCorrection(
        fillingPeriodId,
      );
    },
    onSuccess: (copiedPeriod) => {
      if (copiedPeriod) {
        queryClient.invalidateQueries({ queryKey: ['navigation'] });
        navigate(`${basePath}/filling-period/${copiedPeriod.id}`);
      }
    },
  });

  return ecSalesListId ? (
    <BdoTaxFormDataGrid
      title={t('ec-sales-list')}
      dataId={ecSalesListId}
      getData={() => EcSalesListsService.getApiEcSalesLists(ecSalesListId)}
      validateTaxForm={() =>
        EcSalesListSubmissionService.postApiEcSalesListSubmissionValidate(ecSalesListId)
      }
      submitTaxForm={() =>
        EcSalesListSubmissionService.postApiEcSalesListSubmissionValidate(ecSalesListId)
      }
      createCorrection={createCorrection}
      getInvoices={(taxFormEntryId) =>
        EcSalesListsService.getApiEcSalesListsGetUsedInvoicesForCalculation(taxFormEntryId)
      }
    />
  ) : (
    <div />
  );
}

export default withTranslation('navigation')(EcSalesList);
