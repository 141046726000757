/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommentModel } from '../models/CommentModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CommentsService {
  /**
   * Gets the list of all comments.
   * @param entityType The entity type of the comment to retrieve.
   * @param referenceId The identifier associated with the comment with a particular entity.
   * @returns CommentModel Success
   * @throws ApiError
   */
  public static getApiCommentsGetComments(
    entityType?: string,
    referenceId?: string,
  ): CancelablePromise<Array<CommentModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Comments/GetComments',
      query: {
        entityType: entityType,
        referenceId: referenceId,
      },
    });
  }

  /**
   * Adds a new comment.
   * @param requestBody The comment model to add.
   * @returns CommentModel Success
   * @throws ApiError
   */
  public static postApiCommentsCreateComment(
    requestBody?: CommentModel,
  ): CancelablePromise<CommentModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Comments/CreateComment',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Deletes a comment.
   * @param id The comment id to be deleted.
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteApiCommentsDeleteComment(id?: string): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/Comments/DeleteComment',
      query: {
        id: id,
      },
    });
  }
}
