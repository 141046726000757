/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TaxFormEntryType {
  '_0' = 0,
  '_1' = 1,
  '_2' = 2,
  '_3' = 3,
  '_4' = 4,
  '_5' = 5,
  '_6' = 6,
  '_7' = 7,
  '_8' = 8,
  '_9' = 9,
  '_10' = 10,
  '_11' = 11,
  '_12' = 12,
  '_13' = 13,
  '_14' = 14,
  '_15' = 15,
  '_16' = 16,
  '_17' = 17,
  '_18' = 18,
  '_19' = 19,
  '_20' = 20,
}
