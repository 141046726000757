/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TaxFolderCreationForm } from '../models/TaxFolderCreationForm';
import type { TaxFolderModel } from '../models/TaxFolderModel';
import type { TaxYearModel } from '../models/TaxYearModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaxFoldersService {
  /**
   * Retrieves a list of tax years that are not already associated with a tax folder for the specified country.
   * @param countryId The unique identifier of the country.
   * @returns TaxYearModel Success
   * @throws ApiError
   */
  public static getApiTaxFoldersGetOpenTaxYearsByCountry(
    countryId?: string,
  ): CancelablePromise<Array<TaxYearModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxFolders/GetOpenTaxYearsByCountry',
      query: {
        countryId: countryId,
      },
    });
  }

  /**
   * Retrieves a TaxFolder by its identifier.
   * @param taxFolderId The unique identifier of the TaxFolder to retrieve.
   * @returns TaxFolderModel Success
   * @throws ApiError
   */
  public static getApiTaxFolders(taxFolderId?: string): CancelablePromise<TaxFolderModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxFolders',
      query: {
        taxFolderId: taxFolderId,
      },
    });
  }

  /**
   * Creates a new tax folder based on the provided form data.
   * @param requestBody The form containing the details for the new tax folder.
   * @returns TaxFolderModel Success
   * @throws ApiError
   */
  public static postApiTaxFolders(
    requestBody?: TaxFolderCreationForm,
  ): CancelablePromise<TaxFolderModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/TaxFolders',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Deletes a tax folder including all filling periods, submissions, companies and invoices.
   * @param yearId The id of tax folders tax year.
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteApiTaxFoldersDeleteTaxFolder(yearId?: string): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/TaxFolders/DeleteTaxFolder',
      query: {
        yearId: yearId,
      },
    });
  }
}
