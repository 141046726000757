import { Box, Typography } from '@mui/material';
import { GridColDef, GridPaginationModel, GridRowModel } from '@mui/x-data-grid-premium';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { TaxRuleModel, TaxRulesService } from 'api/taxRule';

import GridFieldsValidator from 'shared/utils/validators/GridFieldsValidator';

import BdoDataGrid from 'components/BdoDataGrid/BdoDataGrid';
import RenderBasicEditCell from 'components/BdoDataGrid/Cells/BasicEditCell';
import { DataGridRow } from 'components/BdoDataGrid/Types/DataGridRow.definitions';

import { TaxRulesProps } from './TaxRules.definitions';

function TaxRules({ t }: TaxRulesProps): FunctionComponentElement<TaxRulesProps> {
  const columns: GridColDef[] = [
    { field: 'id', headerName: t('taxRuleFields:id'), type: 'string', width: 150 },
    {
      field: 'name',
      headerName: `${t('taxRuleFields:name')} *`,
      type: 'string',
      editable: true,
      width: 300,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'description',
      headerName: `${t('taxRuleFields:description')}`,
      type: 'string',
      editable: true,
      width: 300,
      renderEditCell: RenderBasicEditCell,
    },
    {
      field: 'isGeneral',
      headerName: t('taxRuleFields:isGeneral'),
      type: 'boolean',
      editable: true,
      width: 300,
    },
    {
      field: 'technicalRuleName',
      headerName: `${t('taxRuleFields:technicalRuleName')}`,
      editable: true,
      width: 300,
      renderEditCell: RenderBasicEditCell,
    },
    {
      field: 'targetFields',
      headerName: `${t('taxRuleFields:targetFields')}`,
      editable: true,
      width: 300,
      valueGetter: (params) => (params.value ? params.value.join(';') : ''),
      valueSetter: (params) => {
        const newValue = (params?.value ?? '').split(';').map((x: string) => x.trim());
        return { ...params.row, targetFields: newValue };
      },
    },
  ];

  const addDefaultRecord = (id: string): GridRowModel => {
    const taxRule: TaxRuleModel = {
      id,
      name: '',
      isGeneral: false,
    };

    return taxRule;
  };

  const processRowUpdate = async (row: DataGridRow): Promise<DataGridRow> => {
    let resultRow;

    if (row.isNew) {
      resultRow = await TaxRulesService.postApiTaxRulesAddRow(row as TaxRuleModel);
    } else {
      resultRow = await TaxRulesService.putApiTaxRulesGetRow(row as TaxRuleModel);
    }

    return resultRow as DataGridRow;
  };

  const processRowDelete = async (row: DataGridRow): Promise<boolean> =>
    TaxRulesService.deleteApiTaxRulesDeleteRow(row as TaxRuleModel);

  const getRows = (pagination: GridPaginationModel) =>
    TaxRulesService.getApiTaxRulesGetRows(pagination.page, pagination.pageSize);

  const getRowCount = () => TaxRulesService.getApiTaxRulesGetRowCount();

  return (
    <div>
      <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
        {t('navigation:tax-rules')}
      </Typography>
      <Box sx={{ p: '1rem' }}>
        <BdoDataGrid
          columns={columns}
          getRows={getRows}
          getRowCount={getRowCount}
          processRowUpdate={processRowUpdate}
          processRowDelete={processRowDelete}
          addDefaultRecord={addDefaultRecord}
          exportFileName={t('taxRuleFields:exportFileName')}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                technicalRuleName: false,
                targetFields: false,
              },
            },
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
          }}
        />
      </Box>
    </div>
  );
}

export default withTranslation(['gridTooltips', 'navigation', 'taxRuleFields'])(TaxRules);
