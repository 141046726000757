/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CountryModel } from '../models/CountryModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CountriesService {
  /**
   * Gets the list of all countries.
   * @returns CountryModel Success
   * @throws ApiError
   */
  public static getApiCountries(): CancelablePromise<Array<CountryModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Countries',
    });
  }

  /**
   * Creates a new country row using the supplied data.
   * @param requestBody The model containing the data for the new country.
   * @returns CountryModel Success
   * @throws ApiError
   */
  public static postApiCountries(requestBody?: CountryModel): CancelablePromise<CountryModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Countries',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Retrieves a paginated list of country rows.
   * @param page The page number of the data set to retrieve.
   * @param pageSize The size of the page to retrieve.
   * @returns CountryModel Success
   * @throws ApiError
   */
  public static getApiCountriesGetRows(
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<CountryModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Countries/GetRows',
      query: {
        page: page,
        pageSize: pageSize,
      },
    });
  }

  /**
   * Retrieves the total count of country rows.
   * @returns number Success
   * @throws ApiError
   */
  public static getApiCountriesGetRowCount(): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Countries/GetRowCount',
    });
  }

  /**
   * Updates an existing country row.
   * @param requestBody The country model with updated information.
   * @returns CountryModel Success
   * @throws ApiError
   */
  public static putApiCountriesUpdateRow(
    requestBody?: CountryModel,
  ): CancelablePromise<CountryModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Countries/UpdateRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Deletes a country if it's not referenced anymore.
   * @param requestBody The country model to delete.
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteApiCountriesDeleteRow(
    requestBody?: CountryModel,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/Countries/DeleteRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
