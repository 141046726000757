import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Fragment, FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import LoadingButton from 'components/Buttons/LoadingButton/LoadingButton';
import CardWrapper from 'components/CardWrapper/CardWrapper';
import FormFooter from 'components/Layouts/FormFooter/FormFooter';
import SummaryCardHeader from 'components/SummaryCardHeader/SummaryCardHeader';

import { SummaryStepProps } from './SummaryStep.definitions';

function SummaryStep({
  vatGroupsSelection,
  isSaving = false,
  goToNextStep,
  goToPrevStep,
  t,
}: SummaryStepProps): FunctionComponentElement<SummaryStepProps> {
  const handleSaveClick = async () => {
    goToNextStep();
  };

  const handlePrevClick = () => {
    goToPrevStep();
  };

  return (
    <div className="formContent">
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <CardWrapper>
          <SummaryCardHeader header={t('companyFields:companyHeader')} />
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t('companyFields:name')}</TableCell>
                  <TableCell>{t('common:companyType')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vatGroupsSelection.map((vatGroup) => (
                  <Fragment key={vatGroup.id}>
                    {vatGroup.isSelected && (
                      <TableRow
                        key={vatGroup.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell>{vatGroup.name}</TableCell>
                        <TableCell>
                          {t(
                            vatGroup.isControllingCompany
                              ? 'common:controllingCompany'
                              : 'common:subsidiaryCompany',
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardWrapper>
      </Box>
      <FormFooter>
        <Stack direction="row" spacing={3}>
          <Button variant="contained" disabled={isSaving} onClick={handlePrevClick}>
            {t('common:back')}
          </Button>
          <LoadingButton title={t('common:save')} isLoading={isSaving} onClick={handleSaveClick} />
        </Stack>
      </FormFooter>
    </div>
  );
}

export default withTranslation(['common', 'companyFields'])(SummaryStep);
