import { Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { FunctionComponentElement, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FillingPeriodDeadlines } from 'api/invoices';

import FillingPeriodDeadlinesStep from '../FillingPeriodDeadlinesStep/FillingPeriodDeadlinesStep';
import SummaryStep from '../SummaryStep/SummaryStep';
import TaxFolderStep from '../TaxFolderStep/TaxFolderStep';

import {
  CreateTaxFolderStepperProps,
  TaxFolderStepperForm,
} from './CreateTaxFolderStepper.definitions';
import { postTaxFolderQuery } from './CreateTaxFolderStepper.helper';

function CreateTaxFolderStepper({
  t,
}: CreateTaxFolderStepperProps): FunctionComponentElement<CreateTaxFolderStepperProps> {
  const [taxFolderStepperForm, setTaxFolderStepperForm] = useState<TaxFolderStepperForm>({
    allCountries: undefined,
    allOpenTaxYears: undefined,
    selectedCountry: undefined,
    selectedOpenTaxYear: undefined,
    selectedInterval: '12',
    permanentPeriodExtension: false,
    calculatedDeadlines: {} as FillingPeriodDeadlines,
  });
  const [activeStep, setActiveStep] = useState(0);
  const [isMutating, setIsMutating] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: () => postTaxFolderQuery(taxFolderStepperForm),
    onSuccess: async (taxFolder) => {
      await queryClient.invalidateQueries({ queryKey: ['navigation'] });
      navigate(`/country/${taxFolder.country?.id}/tax-year/${taxFolder.taxYear?.id}`);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    },
    onMutate: () => setIsMutating(true),
    onSettled: () => setIsMutating(false),
    mutationKey: ['postTaxFolderCreationForm'],
  });

  const steps = [
    t('addTaxFolderDetails'),
    t('showFillingPeriodDeadlines'),
    t('common:reviewAndSave'),
  ];

  const isLastStep = activeStep === steps.length - 1;

  const handleNextClick = () => {
    if (isLastStep) {
      mutate();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handlePrevClick = () => {
    setActiveStep(activeStep - 1);
  };

  const renderStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <TaxFolderStep
            taxFolderStepperForm={taxFolderStepperForm}
            updateTaxFolderStepperForm={setTaxFolderStepperForm}
            goToNextStep={handleNextClick}
          />
        );
      case 1:
        return (
          <FillingPeriodDeadlinesStep
            taxFolderStepperForm={taxFolderStepperForm}
            updateTaxFolderStepperForm={setTaxFolderStepperForm}
            goToNextStep={handleNextClick}
            goToPrevStep={handlePrevClick}
          />
        );
      case 2:
        return (
          <SummaryStep
            taxFolderStepperForm={taxFolderStepperForm}
            isSaving={isMutating}
            goToNextStep={handleNextClick}
            goToPrevStep={handlePrevClick}
          />
        );

      default:
        return <div>Not Found</div>;
    }
  };

  return (
    <>
      <Typography component="h1" variant="h6">
        {t('common:createTaxFolder')}
      </Typography>
      <Stepper activeStep={activeStep} sx={{ py: 2 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {renderStepContent(activeStep)}
    </>
  );
}

export default withTranslation(['createTaxFolderStepper', 'common'])(
  observer(CreateTaxFolderStepper),
);
