import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, Fab, Stack } from '@mui/material';
import React, { FunctionComponentElement, useEffect, useState } from 'react';
import { FormProvider, Path, useFieldArray, useForm } from 'react-hook-form';
import { withTranslation } from 'react-i18next';

import { ClientCreationForm, UserModel } from 'api/client';

import { getNewUser } from 'shared/utils/formFields.helper';

import AccordionTitle from 'components/AccordionTitle/AccordionTitle';
import ControlledAccordion from 'components/ControlledAccordion/ControlledAccordion';
import UserFields from 'components/FormFields/UserFields/UserFields';
import FormFooter from 'components/Layouts/FormFooter/FormFooter';

import { ClientUsersStepProps, ClientUsersStepValues } from './ClientUsersStep.definitions';

const defaultUser = getNewUser();
function ClientUsersStep({
  clientFormInfo,
  updateClientFormInfo,
  goToNextStep,
  goToPrevStep,
  t,
}: ClientUsersStepProps): FunctionComponentElement<ClientUsersStepProps> {
  const getDefaultFormValues = (): UserModel[] => {
    if (clientFormInfo.clientUsers && clientFormInfo.clientUsers.length > 0) {
      return clientFormInfo.clientUsers;
    }
    return [defaultUser];
  };

  const getActiveUserForId = (): string => {
    const defaultValues = getDefaultFormValues();
    if (defaultValues.length > 0) {
      return defaultValues[0].id || '';
    }
    return '';
  };

  const [activeUserFormId, setActiveUserFormId] = useState<string>(getActiveUserForId());

  const methods = useForm<ClientUsersStepValues>({
    defaultValues: {
      clientUsers: getDefaultFormValues(),
    },
    mode: 'all',
  });

  const {
    control,
    watch,
    handleSubmit,
    getValues,
    clearErrors,
    formState: { isValid },
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'clientUsers',
    keyName: 'key',
  });

  const getFirstNameField = (index: number): Path<ClientUsersStepValues> =>
    `clientUsers.${index}.firstName`;
  const getLastNameField = (index: number): Path<ClientUsersStepValues> =>
    `clientUsers.${index}.lastName`;
  const getUsernameField = (index: number): Path<ClientUsersStepValues> =>
    `clientUsers.${index}.username`;
  const getEmailField = (index: number): Path<ClientUsersStepValues> =>
    `clientUsers.${index}.email`;
  const getRolesField = (index: number): Path<ClientUsersStepValues> =>
    `clientUsers.${index}.roles`;
  const getIsAuditorUserField = (index: number): Path<ClientUsersStepValues> =>
    `clientUsers.${index}.isAuditorUser`;

  const handleNextClick = (data: ClientUsersStepValues) => {
    let updatedInfo: ClientCreationForm;
    if (!data.clientUsers) {
      return;
    }
    if (data.clientUsers.length === 1) {
      const { firstName, lastName, email, username, roles } = data.clientUsers[0];
      if (
        firstName === '' &&
        lastName === '' &&
        email === '' &&
        username === '' &&
        roles?.length === 0
      ) {
        updatedInfo = { ...clientFormInfo, clientUsers: [] };
      } else {
        updatedInfo = { ...clientFormInfo, clientUsers: data.clientUsers };
      }
    } else {
      updatedInfo = { ...clientFormInfo, clientUsers: data.clientUsers };
    }

    updateClientFormInfo(updatedInfo);
    goToNextStep();
  };

  const handlePrevClick = () => {
    goToPrevStep();
  };

  const handleAddClick = () => {
    const newUser = getNewUser();
    append(newUser, {
      shouldFocus: false,
    });
    if (newUser.id) {
      setActiveUserFormId(newUser.id);
    }
  };

  const handleRemoveClick = (itemIndex: number) => {
    remove(itemIndex);
  };

  const areAllFormFieldsEntered = (): boolean => {
    const formValues = getValues();
    if (!formValues.clientUsers) return false;
    return formValues.clientUsers.every(
      (userForm) =>
        userForm.firstName !== '' &&
        userForm.lastName !== '' &&
        userForm.email !== '' &&
        userForm.username !== '' &&
        userForm.roles?.length !== 0,
    );
  };

  const isNextButtonDisabled = (): boolean => {
    // if only one user form; validate fields
    if (fields.length === 1) {
      return !isValid;
    }
    // if many user forms; check if all fields are entered and validate them
    return !(areAllFormFieldsEntered() && isValid);
  };

  useEffect(() => {
    if (isValid) {
      clearErrors();
    }
  }, [clearErrors, isValid]);

  return (
    <form noValidate onSubmit={handleSubmit(handleNextClick)} className="formContent">
      <Box sx={{ flexGrow: 1, p: 2 }}>
        {fields.map((item, index) => (
          <ControlledAccordion
            key={item.id}
            accordionId={item.id}
            activeAccordionId={activeUserFormId}
            expanded={item.id !== undefined && activeUserFormId === item.id}
            accordionSummary={
              <AccordionTitle
                title={`${t('userFields:user')} ${watch(`clientUsers.${index}.firstName`)} ${watch(
                  `clientUsers.${index}.lastName`,
                )}`}
              />
            }
            accordionDetails={
              <>
                <FormProvider {...methods}>
                  <UserFields
                    firstNameField={getFirstNameField(index)}
                    lastNameField={getLastNameField(index)}
                    usernameField={getUsernameField(index)}
                    emailField={getEmailField(index)}
                    rolesField={getRolesField(index)}
                    isAuditorUserField={getIsAuditorUserField(index)}
                  />
                </FormProvider>
                <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
                  <Fab
                    color="primary"
                    size="small"
                    aria-label="add"
                    onClick={() => handleRemoveClick(index)}
                  >
                    <RemoveIcon />
                  </Fab>
                </Box>
              </>
            }
          />
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'end', m: 2 }}>
          <Fab
            color="primary"
            size="small"
            aria-label="add"
            onClick={handleAddClick}
            disabled={!(areAllFormFieldsEntered() && isValid)}
          >
            <AddIcon />
          </Fab>
        </Box>
      </Box>
      <FormFooter>
        <Stack direction="row" spacing={3}>
          <Button variant="contained" onClick={handlePrevClick}>
            {t('common:back')}
          </Button>
          <Button type="submit" variant="contained" disabled={isNextButtonDisabled()}>
            {t('common:next')}
          </Button>
        </Stack>
      </FormFooter>
    </form>
  );
}

export default withTranslation(['createClientStepper', 'userFields'])(ClientUsersStep);
