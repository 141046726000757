import { Typography } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { RoleManagementProps } from './RoleManagement.definitions';

function RoleManagement({ t }: RoleManagementProps): FunctionComponentElement<RoleManagementProps> {
  return (
    <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
      {t('role-management')}
    </Typography>
  );
}

export default withTranslation('navigation')(RoleManagement);
