import { ListItemButton, ListItemText } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { getDisplayText } from '../Navigation.helper';

import { NormalNavListItemProps } from './NormalNavListItem.definitions';

function NormalNavListItem({
  item,
  path,
  paddingLeft,
  t,
}: NormalNavListItemProps): FunctionComponentElement<NormalNavListItemProps> {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ListItemButton
      onClick={() => navigate(path)}
      selected={location.pathname.includes(path)}
      sx={{ pl: paddingLeft }}
    >
      <ListItemText primary={getDisplayText(item, t)} />
    </ListItemButton>
  );
}

export default withTranslation('navigation')(NormalNavListItem);
