import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useQuery } from '@tanstack/react-query';
import { FunctionComponentElement, startTransition, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import theme from 'themes/theme';

import { ClientModel, ClientsService } from 'api/client';

import useStore from 'context/Store/Store.hooks';

import { ClientSelectionProps } from './ClientSelection.definitions';

function ClientSelection({
  t,
}: ClientSelectionProps): FunctionComponentElement<ClientSelectionProps> {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const { clientStore } = useStore();

  const { isFetching, data: availableClients } = useQuery({
    queryKey: ['ClientSelection'],
    queryFn: async () => ClientsService.getApiClientsGetClientsForUser(keycloak.subject),
  });

  useEffect(() => {
    if (!isFetching && availableClients && availableClients.length === 1) {
      startTransition(() => {
        clientStore.setClient(availableClients[0]);
        navigate('/dashboard');
      });
    }
  }, [isFetching, availableClients, clientStore, navigate]);

  const handleClientSelected = (selectedClient: ClientModel) => {
    startTransition(() => {
      clientStore.setClient(selectedClient);
      navigate('/dashboard');
    });
  };

  if (isFetching) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          height: '100vh',
          margin: 0,
          padding: 0,
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if ((!isFetching && availableClients && availableClients.length === 0) || !availableClients) {
    return (
      <ThemeProvider theme={theme}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
            margin: 0,
          }}
        >
          <Card
            sx={{
              width: '100%',
              maxWidth: 360,
            }}
          >
            <CardHeader
              title={`${t('hello')} ${keycloak?.idTokenParsed?.given_name}!`}
              sx={{ mr: 2, mt: 2, ml: 2, mb: -1 }}
            />
            <CardContent>
              <Typography
                variant="subtitle2"
                sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}
              >
                {t('noClientsAvailable')}
              </Typography>
            </CardContent>
          </Card>
        </div>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          height: '100vh',
          margin: 0,
        }}
      >
        <Card
          sx={{
            width: '100%',
            maxWidth: 360,
          }}
        >
          <CardHeader
            title={`${t('hello')} ${keycloak?.idTokenParsed?.given_name}!`}
            sx={{ mr: 2, mt: 2, ml: 2, mb: -1 }}
          />
          <CardContent>
            <Typography
              variant="subtitle2"
              sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}
            >
              {t('selectClientDescription')}
            </Typography>
            <List sx={{ maxHeight: '25em', overflow: 'auto' }}>
              {availableClients!.map((availableClient) => (
                <ListItem key={availableClient.id}>
                  <ListItemButton onClick={() => handleClientSelected(availableClient)}>
                    <ListItemText
                      primary={availableClient.name}
                      secondary={`Id: ${availableClient.id}`}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </div>
    </ThemeProvider>
  );
}

export default withTranslation('clientSelection')(ClientSelection);
