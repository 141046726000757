import { Box, CircularProgress } from '@mui/material';
import { FunctionComponentElement } from 'react';

function AppLoader(): FunctionComponentElement<unknown> {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        position: 'absolute',
        width: '100%',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
    >
      <CircularProgress />
    </Box>
  );
}

export default AppLoader;
