import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import * as React from 'react';
import { FunctionComponentElement } from 'react';

import { ControlledAccordionProps } from './ControlledAccordion.definitions';

function ControlledAccordion({
  accordionId,
  expanded = false,
  activeAccordionId,
  accordionSummary,
  accordionDetails,
}: ControlledAccordionProps): FunctionComponentElement<ControlledAccordionProps> {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(expanded);

  // when controlled from outside, sync with internal state
  React.useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  // when only one active accordion is set from outside, sync with internal state
  React.useEffect(() => {
    if (accordionId) setIsExpanded(activeAccordionId === accordionId);
  }, [activeAccordionId, accordionId]);

  return (
    <Accordion
      expanded={isExpanded}
      onChange={() => {
        setIsExpanded(!isExpanded);
      }}
      sx={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.25), -1px -1px 4px rgba(0, 0, 0, 0.25)' }}
    >
      <AccordionSummary
        id="panel1bh-header"
        aria-controls="panel1bh-content"
        expandIcon={<ExpandLessIcon />}
        sx={{ mr: 1 }}
      >
        {accordionSummary}
      </AccordionSummary>
      <AccordionDetails>{accordionDetails}</AccordionDetails>
    </Accordion>
  );
}

export default ControlledAccordion;
