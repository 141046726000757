import { makeAutoObservable } from 'mobx';

import RightDrawerContentType from 'enums/RightDrawerContentType';
import { CommentsDrawerGridContext } from 'models/CommentsDrawerGridContext';

import { IRightDrawerStore } from './IRightDrawerStore';

class RightDrawerStore implements IRightDrawerStore {
  open = false;

  activeContent = RightDrawerContentType.None;

  commentsDrawerGridContext: CommentsDrawerGridContext = {} as CommentsDrawerGridContext;

  get isActionsDrawer() {
    return this.activeContent === RightDrawerContentType.Actions;
  }

  get isCommentsDrawer() {
    return this.activeContent === RightDrawerContentType.Comments;
  }

  get isNotificationsDrawer() {
    return this.activeContent === RightDrawerContentType.Notifications;
  }

  constructor() {
    makeAutoObservable(this);
  }

  setOpen = (isOpen: boolean) => {
    this.open = isOpen;
  };

  setActiveContent = (selectedContent: RightDrawerContentType) => {
    this.activeContent = selectedContent;
  };

  setCommentsDrawerGridRowContext = (context: CommentsDrawerGridContext) => {
    this.commentsDrawerGridContext = context;
  };
}

export default RightDrawerStore;
