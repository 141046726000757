enum Roles {
  /**
   * Represents the Administrator role for BDO VAT compliance.
   */
  Administrator = 'bdo-vat-compliance-admin',

  /**
   * Represents the Auditor Administrator role for BDO VAT compliance.
   */
  AuditorAdministrator = 'bdo-vat-compliance-auditor-admin',

  /**
   * Represents the Auditor Supervisor role for BDO VAT compliance.
   */
  AuditorSupervisor = 'bdo-vat-compliance-auditor-supervisor',

  /**
   * Represents the Auditor Employee role for BDO VAT compliance.
   */
  AuditorEmployee = 'bdo-vat-compliance-auditor-employee',

  /**
   * Represents the Client Supervisor role for BDO VAT compliance.
   */
  ClientSupervisor = 'bdo-vat-compliance-client-supervisor',

  /**
   * Represents the Client Employee role for BDO VAT compliance.
   */
  ClientEmployee = 'bdo-vat-compliance-client-employee',
}

export default Roles;
