/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserModel } from '../models/UserModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {
  /**
   * Retrieves the roles of a specific user based on their user ID.
   * @param userId The unique identifier of the user.
   * @returns string Success
   * @throws ApiError
   */
  public static getApiUsersGetUserRoles(userId?: string): CancelablePromise<Array<string>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Users/GetUserRoles',
      query: {
        userId: userId,
      },
    });
  }

  /**
   * Retrieves a list of users with auditor roles.
   * @returns UserModel Success
   * @throws ApiError
   */
  public static getApiUsersAuditor(): CancelablePromise<Array<UserModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Users/Auditor',
    });
  }

  /**
   * Retrieves a paginated list of user models.
   * @param page The page number of the user list.
   * @param pageSize The number of users per page.
   * @returns UserModel Success
   * @throws ApiError
   */
  public static getApiUsersGetRows(
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<UserModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Users/GetRows',
      query: {
        page: page,
        pageSize: pageSize,
      },
    });
  }

  /**
   * Retrieves the total count of users.
   * @returns number Success
   * @throws ApiError
   */
  public static getApiUsersGetRowCount(): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Users/GetRowCount',
    });
  }

  /**
   * Adds a new user row.
   * @param requestBody The user model to add.
   * @returns UserModel Success
   * @throws ApiError
   */
  public static postApiUsersAddRow(requestBody?: UserModel): CancelablePromise<UserModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Users/AddRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Updates an existing user row.
   * @param requestBody The user model with updated information.
   * @returns UserModel Success
   * @throws ApiError
   */
  public static putApiUsersUpdateRow(requestBody?: UserModel): CancelablePromise<UserModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Users/UpdateRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Deletes a user from the system.
   * @param requestBody The user model to delete.
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteApiUsersDeleteRow(requestBody?: UserModel): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/Users/DeleteRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
