import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FunctionComponentElement, ReactNode, useCallback } from 'react';
import { withTranslation } from 'react-i18next';

import theme from 'themes/theme';

import { InvoiceModel } from 'api/invoices';

import { currencyFormatter, formatDate, formatPercentage } from '../../shared/utils/invoice.helper';

import { InvoiceTableProps } from './InvoiceTable.definitions';

function InvoiceTable({
  invoices,
  comparisonInvoice,
  t,
}: InvoiceTableProps): FunctionComponentElement<InvoiceTableProps> {
  const renderTableHeader = useCallback(
    (): ReactNode => (
      <TableHead>
        <TableRow>
          <TableCell>{t('invoice:taxCode')}</TableCell>
          <TableCell align="right">{t('invoice:invoiceType')}</TableCell>
          <TableCell align="right">{t('invoice:invoiceDate')}</TableCell>
          <TableCell align="right">{t('invoice:invoiceNumber')}</TableCell>
          <TableCell align="right">{t('invoice:reportingMonth')}</TableCell>
          <TableCell align="right">{t('invoice:reportingYear')}</TableCell>
          <TableCell align="right">{t('invoice:accountingArea')}</TableCell>
          <TableCell align="right">{t('invoice:bookingNumber')}</TableCell>
          <TableCell align="right">{t('invoice:bookingDate')}</TableCell>
          <TableCell align="right">{t('invoice:dateOfSupply')}</TableCell>
          <TableCell align="right">{t('businessPartner:businessPartnerType')}</TableCell>
          <TableCell align="right">{t('businessPartner:name')}</TableCell>
          <TableCell align="right">{t('businessPartner:vatTaxNumber')}</TableCell>
          <TableCell align="right">{t('businessPartner:street')}</TableCell>
          <TableCell align="right">{t('businessPartner:number')}</TableCell>
          <TableCell align="right">{t('businessPartner:postal')}</TableCell>
          <TableCell align="right">{t('businessPartner:city')}</TableCell>
          <TableCell align="right">{t('businessPartner:region')}</TableCell>
          <TableCell align="right">{t('businessPartner:country')}</TableCell>
          <TableCell align="right">{t('invoice:foreignCurrency')}</TableCell>
          <TableCell align="right">{t('invoice:foreignNetAmount')}</TableCell>
          <TableCell align="right">{t('invoice:foreignVatAmount')}</TableCell>
          <TableCell align="right">{t('invoice:exchangeRate')}</TableCell>
          <TableCell align="right">{t('invoice:vatRate')}</TableCell>
          <TableCell align="right">{t('invoice:targetCurrency')}</TableCell>
          <TableCell align="right">{t('invoice:targetNetAmount')}</TableCell>
          <TableCell align="right">{t('invoice:targetVatAmount')}</TableCell>
        </TableRow>
      </TableHead>
    ),
    [t],
  );

  const renderComparisonTable = useCallback(
    (currentInvoice: InvoiceModel): ReactNode => {
      const invoice = invoices[0];

      const getBackgroundColor = (comparison: boolean): string =>
        comparison ? theme.yellow.main : 'inherit';

      return (
        <TableBody>
          <TableRow key={invoice.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell
              component="th"
              scope="row"
              sx={{
                backgroundColor: getBackgroundColor(currentInvoice.taxCodeId !== invoice.taxCodeId),
              }}
            >
              {invoice.taxCode?.code}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor: getBackgroundColor(
                  currentInvoice.invoiceType !== invoice.invoiceType,
                ),
              }}
            >
              {invoice.invoiceType === 1 ? t('invoice:incoming') : t('invoice:outgoing')}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor: getBackgroundColor(
                  formatDate(currentInvoice.invoiceDate) !== formatDate(invoice.invoiceDate),
                ),
              }}
            >
              {formatDate(invoice.invoiceDate)}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor: getBackgroundColor(
                  currentInvoice.invoiceNumber !== invoice.invoiceNumber,
                ),
              }}
            >
              {invoice.invoiceNumber}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor: getBackgroundColor(
                  currentInvoice.reportingMonth !== invoice.reportingMonth,
                ),
              }}
            >
              {invoice.reportingMonth}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor: getBackgroundColor(
                  currentInvoice.reportingYear !== invoice.reportingYear,
                ),
              }}
            >
              {invoice.reportingYear}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor: getBackgroundColor(
                  currentInvoice.accountingArea !== invoice.accountingArea,
                ),
              }}
            >
              {invoice.accountingArea}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor: getBackgroundColor(
                  currentInvoice.bookingNumber !== invoice.bookingNumber,
                ),
              }}
            >
              {invoice.bookingNumber}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor: getBackgroundColor(
                  formatDate(currentInvoice.bookingDate) !== formatDate(invoice.bookingDate),
                ),
              }}
            >
              {formatDate(invoice.bookingDate)}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor: getBackgroundColor(
                  formatDate(currentInvoice.dateOfSupply) !== formatDate(invoice.dateOfSupply),
                ),
              }}
            >
              {formatDate(invoice.dateOfSupply)}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  currentInvoice.businessPartner?.businessPartnerType &&
                  invoice.businessPartner?.businessPartnerType &&
                  getBackgroundColor(
                    currentInvoice.businessPartner.businessPartnerType !==
                      invoice.businessPartner.businessPartnerType,
                  ),
              }}
            >
              {invoice.businessPartner &&
                (invoice.businessPartner.businessPartnerType === 1
                  ? t('invoice:supplier')
                  : invoice.businessPartner.businessPartnerType === 2 && t('invoice:customer'))}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  currentInvoice.businessPartner &&
                  invoice.businessPartner &&
                  getBackgroundColor(
                    currentInvoice.businessPartner.name !== invoice.businessPartner.name,
                  ),
              }}
            >
              {invoice.businessPartner?.name}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  currentInvoice.businessPartner &&
                  invoice.businessPartner &&
                  getBackgroundColor(
                    currentInvoice.businessPartner.vatTaxNumber !==
                      invoice.businessPartner.vatTaxNumber,
                  ),
              }}
            >
              {invoice.businessPartner?.vatTaxNumber}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  currentInvoice.businessPartner &&
                  invoice.businessPartner &&
                  getBackgroundColor(
                    currentInvoice.businessPartner.street !== invoice.businessPartner.street,
                  ),
              }}
            >
              {invoice.businessPartner?.street}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  currentInvoice.businessPartner &&
                  invoice.businessPartner &&
                  getBackgroundColor(
                    currentInvoice.businessPartner.number !== invoice.businessPartner.number,
                  ),
              }}
            >
              {invoice.businessPartner?.number}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  currentInvoice.businessPartner &&
                  invoice.businessPartner &&
                  getBackgroundColor(
                    currentInvoice.businessPartner.postal !== invoice.businessPartner.postal,
                  ),
              }}
            >
              {invoice.businessPartner?.postal}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  currentInvoice.businessPartner &&
                  invoice.businessPartner &&
                  getBackgroundColor(
                    currentInvoice.businessPartner.city !== invoice.businessPartner.city,
                  ),
              }}
            >
              {invoice.businessPartner?.city}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  currentInvoice.businessPartner &&
                  invoice.businessPartner &&
                  getBackgroundColor(
                    currentInvoice.businessPartner.region !== invoice.businessPartner.region,
                  ),
              }}
            >
              {invoice.businessPartner?.region}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  currentInvoice.businessPartner &&
                  invoice.businessPartner &&
                  getBackgroundColor(
                    currentInvoice.businessPartner.country !== invoice.businessPartner.country,
                  ),
              }}
            >
              {invoice.businessPartner?.country}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor: getBackgroundColor(
                  currentInvoice.foreignCurrency !== invoice.foreignCurrency,
                ),
              }}
            >
              {invoice.foreignCurrency}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor: getBackgroundColor(
                  currentInvoice.foreignNetAmount !== invoice.foreignNetAmount,
                ),
              }}
            >
              {currencyFormatter.format(Number(invoice.foreignNetAmount))}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor: getBackgroundColor(
                  currentInvoice.foreignVatAmount !== invoice.foreignVatAmount,
                ),
              }}
            >
              {currencyFormatter.format(Number(invoice.foreignVatAmount))}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor: getBackgroundColor(
                  currentInvoice.exchangeRate !== invoice.exchangeRate,
                ),
              }}
            >
              {formatPercentage(Number(invoice.exchangeRate))}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor: getBackgroundColor(currentInvoice.vatRate !== invoice.vatRate),
              }}
            >
              {formatPercentage(Number(invoice.vatRate))}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor: getBackgroundColor(
                  currentInvoice.targetCurrency !== invoice.targetCurrency,
                ),
              }}
            >
              {invoice.targetCurrency}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor: getBackgroundColor(
                  currentInvoice.targetNetAmount !== invoice.targetNetAmount,
                ),
              }}
            >
              {currencyFormatter.format(Number(invoice.targetNetAmount))}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor: getBackgroundColor(
                  currentInvoice.targetVatAmount !== invoice.targetVatAmount,
                ),
              }}
            >
              {currencyFormatter.format(Number(invoice.targetVatAmount))}
            </TableCell>
          </TableRow>
        </TableBody>
      );
    },
    [invoices, t],
  );

  const header = renderTableHeader();

  if (invoices.length === 1 && comparisonInvoice) {
    const tableBody = renderComparisonTable(comparisonInvoice);

    return (
      <TableContainer sx={{ height: '100%', overflow: 'unset' }}>
        <Table sx={{ minWidth: 650 }} size="medium">
          {header}
          {tableBody}
        </Table>
      </TableContainer>
    );
  }

  return (
    <TableContainer sx={{ height: '100%', overflow: 'unset' }}>
      <Table sx={{ minWidth: 650 }} size="medium">
        {header}
        <TableBody>
          {invoices.map((invoice) => (
            <TableRow key={invoice.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {invoice.taxCode?.code}
              </TableCell>
              <TableCell align="right">
                {invoice.invoiceType === 1 ? t('invoice:incoming') : t('invoice:outgoing')}
              </TableCell>
              <TableCell align="right">{formatDate(invoice.invoiceDate)}</TableCell>
              <TableCell align="right">{invoice.invoiceNumber}</TableCell>
              <TableCell align="right">{invoice.reportingMonth}</TableCell>
              <TableCell align="right">{invoice.reportingYear}</TableCell>
              <TableCell align="right">{invoice.accountingArea}</TableCell>
              <TableCell align="right">{invoice.bookingNumber}</TableCell>
              <TableCell align="right">{formatDate(invoice.bookingDate)}</TableCell>
              <TableCell align="right">{formatDate(invoice.dateOfSupply)}</TableCell>
              <TableCell align="right">
                {invoice.businessPartner &&
                  (invoice.businessPartner.businessPartnerType === 1
                    ? t('invoice:supplier')
                    : invoice.businessPartner.businessPartnerType === 2 && t('invoice:customer'))}
              </TableCell>
              <TableCell align="right">{invoice.businessPartner?.name}</TableCell>
              <TableCell align="right">{invoice.businessPartner?.vatTaxNumber}</TableCell>
              <TableCell align="right">{invoice.businessPartner?.street}</TableCell>
              <TableCell align="right">{invoice.businessPartner?.number}</TableCell>
              <TableCell align="right">{invoice.businessPartner?.postal}</TableCell>
              <TableCell align="right">{invoice.businessPartner?.city}</TableCell>
              <TableCell align="right">{invoice.businessPartner?.region}</TableCell>
              <TableCell align="right">{invoice.businessPartner?.country}</TableCell>
              <TableCell align="right">{invoice.foreignCurrency}</TableCell>
              <TableCell align="right">
                {currencyFormatter.format(Number(invoice.foreignNetAmount))}
              </TableCell>
              <TableCell align="right">
                {currencyFormatter.format(Number(invoice.foreignVatAmount))}
              </TableCell>
              <TableCell align="right">{formatPercentage(Number(invoice.exchangeRate))}</TableCell>
              <TableCell align="right">{formatPercentage(Number(invoice.vatRate))}</TableCell>
              <TableCell align="right">{invoice.targetCurrency}</TableCell>
              <TableCell align="right">
                {currencyFormatter.format(Number(invoice.targetNetAmount))}
              </TableCell>
              <TableCell align="right">
                {currencyFormatter.format(Number(invoice.targetVatAmount))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default withTranslation(['invoice', 'businessPartner'])(InvoiceTable);
