import Typography from '@mui/material/Typography';
import { FunctionComponentElement } from 'react';

import { FormHeaderProps } from './FormHeader.definitions';

function FormHeader({ header }: FormHeaderProps): FunctionComponentElement<FormHeaderProps> {
  return (
    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2 }}>
      {header}
    </Typography>
  );
}

export default FormHeader;
