import { observer } from 'mobx-react-lite';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useStore from 'context/Store/Store.hooks';
import useFillingPeriod from 'shared/api-hooks/useFillingPeriod';

import FillingPeriodActions from 'features/FillingPeriodActions/FillingPeriodActions';

import CommentSection from 'components/CommentSection/CommentSection';
import NoDrawerContent from 'components/NoDrawerContent/NoDrawerContent';

import CommentEntityType from 'enums/CommentEntityType';

import { FillingPeriodDrawerProps } from './FillingPeriodDrawer.definitions';

function FillingPeriodDrawer({
  t,
}: FillingPeriodDrawerProps): FunctionComponentElement<FillingPeriodDrawerProps> {
  const {
    rightDrawerStore: { isActionsDrawer, isCommentsDrawer },
  } = useStore();
  const { fillingPeriodId } = useParams();
  const { isReadonly } = useFillingPeriod(fillingPeriodId);

  return (
    <>
      {isActionsDrawer && <FillingPeriodActions isDisabled={isReadonly} />}
      {isCommentsDrawer &&
        (fillingPeriodId ? (
          <CommentSection
            entityType={CommentEntityType.FillingPeriod}
            referenceId={fillingPeriodId}
          />
        ) : (
          <NoDrawerContent text={t('noCommentsAvailable')} />
        ))}
    </>
  );
}

export default withTranslation('common')(observer(FillingPeriodDrawer));
