import { useQuery } from '@tanstack/react-query';

import { UsersService } from 'api/client';

const useUserRoles = (userId?: string) =>
  useQuery({
    queryKey: ['getUserRoles', userId],
    queryFn: async () => UsersService.getApiUsersGetUserRoles(userId),
    staleTime: Infinity,
  });

export default useUserRoles;
