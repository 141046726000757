import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { LinearProgress, Stack, Typography } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import formatFileSize from 'shared/utils/file.helper';

import { FileViewProps } from './FileView.definitions';

function FileView({
  fileName,
  fileSize,
  progress,
  t,
}: FileViewProps): FunctionComponentElement<FileViewProps> {
  return (
    <Stack direction="row" spacing={1}>
      <InsertDriveFileOutlinedIcon sx={{ fontSize: 45 }} />
      {progress ? (
        <Stack sx={{ flex: 1, justifyContent: 'space-evenly' }}>
          <Typography variant="body2">{fileName}</Typography>
          <LinearProgress variant="determinate" value={progress} />
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Typography variant="body2">{t('common:isUploading')}</Typography>
            <Typography variant="body2">{progress}%</Typography>
          </Stack>
        </Stack>
      ) : (
        <Stack sx={{ justifyContent: 'space-evenly' }}>
          <Typography variant="body2">{fileName}</Typography>
          {fileSize && <Typography variant="body2">{formatFileSize(fileSize)}</Typography>}
        </Stack>
      )}
    </Stack>
  );
}

export default withTranslation()(FileView);
