import { FunctionComponentElement } from 'react';

import { StoreContextProvider } from './Store.context';
import { StoreProviderProps } from './Store.definitions';

function StoreProvider({
  rootStore,
  children,
}: StoreProviderProps): FunctionComponentElement<StoreProviderProps> {
  return <StoreContextProvider value={rootStore}>{children}</StoreContextProvider>;
}

export default StoreProvider;
