import { TextField } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { get, useFormContext } from 'react-hook-form';
import { withTranslation } from 'react-i18next';

import FormHeader from 'components/Layouts/FormHeader/FormHeader';

import FieldWidth from 'enums/FieldWidth';

import { ClientFieldsProps } from './ClientFields.definitions';

function ClientFields({
  nameField,
  t,
}: ClientFieldsProps): FunctionComponentElement<ClientFieldsProps> {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <FormHeader header={t('clientHeader')} />{' '}
      <TextField
        data-testid="textfield-element"
        id="clientName"
        label={t('name')}
        variant="outlined"
        {...register(nameField, {
          required: t('fieldsValidation:requiredHelperText', { fieldName: t('name') }),
        })}
        required
        sx={{ width: FieldWidth.Half }}
        error={!!(errors && get(errors, nameField))}
        helperText={get(errors, nameField) && get(errors, nameField).message}
      />
    </>
  );
}

export default withTranslation(['clientFields', 'fieldsValidation'])(ClientFields);
