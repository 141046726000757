import { IObservableArray, action, makeAutoObservable, observable, runInAction } from 'mobx';

import { Toast } from 'models/Toast';

import { IToastStore } from './IToastStore';

class ToastStore implements IToastStore {
  toasts: IObservableArray<Toast> = observable<Toast>([]);

  displayedIds: IObservableArray<string> = observable<string>([]);

  constructor() {
    makeAutoObservable(this);
  }

  @action
  addToDisplayedIds = (snackbarId: string): void => {
    runInAction(() => {
      this.displayedIds.push(snackbarId);
    });
  };

  @action
  addToast = (toast: Toast): void => {
    this.toasts.push(toast);
  };

  @action
  deleteToast = (toast: Toast): void => {
    runInAction(() => {
      this.toasts.remove(toast);
    });
  };
}

export default ToastStore;
