import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AnnualVatReturnSubmissionService } from 'api/submission';
import { AnnualVatReturnsService } from 'api/taxCalculation';

import BdoTaxFormDataGrid from 'components/BdoTaxFormDataGrid/BdoTaxFormDataGrid';

import { AnnualVatReturnProps } from './AnnualVatReturn.definitions';

function AnnualVatReturn({
  t,
}: AnnualVatReturnProps): FunctionComponentElement<AnnualVatReturnProps> {
  const { annualVatReturnId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const basePath = location.pathname.split('/');
  basePath.pop();

  const { mutate: createCorrection } = useMutation({
    mutationFn: (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      return AnnualVatReturnSubmissionService.postApiAnnualVatReturnSubmissionCreateCorrection(
        annualVatReturnId,
      );
    },
    onSuccess: (copiedSubmission) => {
      if (copiedSubmission) {
        queryClient.invalidateQueries({ queryKey: ['navigation'] });
        basePath.push(copiedSubmission.id || '');
        navigate(basePath.join('/'));
      }
    },
  });

  return annualVatReturnId ? (
    <BdoTaxFormDataGrid
      title={t('navigation:annual-vat-return')}
      dataId={annualVatReturnId}
      getData={() => AnnualVatReturnsService.getApiAnnualVatReturns(annualVatReturnId)}
      getInvoices={(taxFormEntryId) =>
        AnnualVatReturnsService.getApiAnnualVatReturnsGetUsedInvoicesForCalculation(taxFormEntryId)
      }
      exportFileName={t('gridForms:exportFileAnnualVatReturn')}
      validateTaxForm={() =>
        AnnualVatReturnSubmissionService.postApiAnnualVatReturnSubmissionValidate(annualVatReturnId)
      }
      submitTaxForm={() =>
        AnnualVatReturnSubmissionService.postApiAnnualVatReturnSubmissionSubmit(annualVatReturnId)
      }
      createCorrection={createCorrection}
    />
  ) : (
    <div />
  );
}

export default withTranslation(['navigation', 'gridForms'])(AnnualVatReturn);
