import { Block, TrafficOutlined } from '@mui/icons-material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import TrafficLightTooltip from '../Tooltips/TrafficLightTooltip';

import { ValidationStateIconCellProps } from './ValidationStateIconCell.definitions';

function ValidationStateIconCell({ props, messages, state }: ValidationStateIconCellProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(['validationStates', 'validationMessages']);

  const iconMap: { [key: string]: JSX.Element } = {
    '0': <TrafficOutlined color="success" />,
    '1': <TrafficOutlined color="warning" />,
    '2': <TrafficOutlined color="error" />,
    ignored: <Block color="action" />,
    default: <TrafficOutlined color="info" />,
  };

  const colorMap: { [key: string]: string } = {
    '0': 'success',
    '1': 'warning',
    '2': 'error',
    ignored: 'action',
    default: 'info',
  };

  const staticContent = (
    <div>
      {t('validationStates:state')}: {t(`validationStates:${state}`)} <br />
    </div>
  );

  let formattedMessages;
  if (messages.length > 1) {
    formattedMessages = (
      <>
        {staticContent}
        {t('validationMessages:results')}:
        {messages.map((message) => (
          <div key={v4()}>{t(`validationMessages:${message}`, message)}</div>
        ))}
      </>
    );
  } else {
    formattedMessages = (
      <>
        {staticContent}
        {t('validationMessages:result')}:{' '}
        {t(`validationMessages:${messages[0] || ''}`, messages[0] || '')}
      </>
    );
  }

  const icon = iconMap[state] || iconMap.default;
  const color = colorMap[state] || colorMap.default;

  return (
    <TrafficLightTooltip
      color={color}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      open={isOpen}
      title={formattedMessages}
      PopperProps={{
        disablePortal: true,
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              enabled: false,
              options: {
                altBoundary: false,
                rootBoundary: 'document',
                padding: 8,
              },
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: false,
                altBoundary: false,
                tether: true,
                rootBoundary: 'document',
                padding: 8,
              },
            },
          ],
        },
      }}
    >
      {icon}
    </TrafficLightTooltip>
  );
}

export default function RenderValidationStateIcon(
  params: GridRenderCellParams,
  messages: string[],
  color: string,
) {
  return <ValidationStateIconCell props={params} messages={messages} state={color} />;
}
