import { Switch } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { ChangeEvent, FunctionComponentElement, useState } from 'react';
import { get, useFormContext } from 'react-hook-form';
import { withTranslation } from 'react-i18next';

import FormHeader from 'components/Layouts/FormHeader/FormHeader';

import FieldWidth from 'enums/FieldWidth';

import AddressFields from '../AddressFields/AddressFields';
import ContactInfoFields from '../ContactInfoFields/ContactInfoFields';

import { ContactFieldsProps } from './ContactFields.definitions';

function ContactFields({
  lastNameField,
  firstNameField,
  addressField,
  contactInfoField,
  showHeader,
  notesField,
  t,
}: ContactFieldsProps): FunctionComponentElement<ContactFieldsProps> {
  const [showContactFields, setShowContactFields] = useState(false);
  const {
    register,
    trigger,
    resetField,
    formState: { errors },
  } = useFormContext();

  const handleSwitch = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setShowContactFields((prev) => !prev);
    if (trigger && checked) {
      trigger([firstNameField, lastNameField]);
    }
    if (trigger && resetField && !checked) {
      trigger();
      resetField(firstNameField);
      resetField(lastNameField);
    }
  };
  return (
    <Box component="span">
      {showHeader && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormHeader header={t('contact')} />
          <Switch
            sx={{ mb: 2 }}
            inputProps={{ 'aria-label': 'Switch contactInfo' }}
            checked={showContactFields}
            onChange={handleSwitch}
          />
        </Box>
      )}

      {showContactFields && (
        <Box>
          <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
            <TextField
              data-testid="textfield-element"
              id="firstName"
              label={t('firstName')}
              variant="outlined"
              required
              {...register(firstNameField, {
                disabled: !showContactFields,
                required: t('fieldsValidation:requiredHelperText', { fieldName: t('firstName') }),
              })}
              sx={{ width: FieldWidth.Half }}
              error={!!(errors && get(errors, firstNameField))}
              helperText={get(errors, firstNameField) && get(errors, firstNameField).message}
              inputProps={{ 'data-testid': 'input_firstName' }}
            />
            <TextField
              data-testid="textfield-element"
              id="lastName"
              label={t('lastName')}
              variant="outlined"
              required
              {...register(lastNameField, {
                disabled: !showContactFields,
                required: t('fieldsValidation:requiredHelperText', { fieldName: t('lastName') }),
              })}
              sx={{ width: FieldWidth.Half }}
              error={!!(errors && get(errors, lastNameField))}
              helperText={get(errors, lastNameField) && get(errors, lastNameField).message}
              inputProps={{ 'data-testid': 'input_lastName' }}
            />
          </Stack>
          <ContactInfoFields
            showHeader={false}
            phoneNumberField={`${contactInfoField}.phoneNumber`}
            emailField={`${contactInfoField}.email`}
          />
          <AddressFields
            showHeader
            streetField={`${addressField}.street`}
            numberField={`${addressField}.number`}
            postalField={`${addressField}.postal`}
            cityField={`${addressField}.city`}
            regionField={`${addressField}.region`}
            countryField={`${addressField}.country`}
            notesField={`${addressField}.notes`}
            isContactAddress
          />
          <Stack direction="row" spacing={2}>
            <TextField
              data-testid="textfield-element"
              id="notes"
              label={t('notes')}
              variant="outlined"
              multiline
              rows={3}
              {...register(notesField, {
                disabled: !showContactFields,
              })}
              sx={{ width: FieldWidth.Full }}
              inputProps={{ 'data-testid': 'input_contact_notes' }}
            />
          </Stack>
        </Box>
      )}
    </Box>
  );
}

export default withTranslation('contactFields')(ContactFields);
