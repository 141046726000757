import LaunchIcon from '@mui/icons-material/Launch';
import UndoIcon from '@mui/icons-material/Undo';
import { Button, ButtonGroup, CircularProgress, Tooltip } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { FunctionComponentElement, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import useStore from 'context/Store/Store.hooks';

import Severity from 'enums/Severity';

import { GridRevertAndInvoicesButtonGroupProps } from './GridRevertAndInvoicesButtonGroup.definitions';

function GridRevertAndInvoicesButtonGroup({
  params,
  rows,
  updateRows,
  handleShowInvoicesClick,
  revertOverwritingAsync,
  isDisabled = false,
  t,
}: GridRevertAndInvoicesButtonGroupProps): FunctionComponentElement<GridRevertAndInvoicesButtonGroupProps> {
  const [isMutating, setIsMutating] = useState(false);
  const { toastStore } = useStore();

  const { mutate } = useMutation({
    mutationFn: async (entryId: string) => {
      const response = await revertOverwritingAsync(entryId);
      return response;
    },
    onSuccess: (updatedRow) => {
      toastStore.addToast({
        id: uuidv4(),
        title: t('notifications:notification-row-entry-updated'),
        severity: Severity.Info,
      });
      const updatedRows = rows.map((row) => (row.id === updatedRow.id ? updatedRow : row));
      updateRows(updatedRows);
    },
    onMutate: () => setIsMutating(true),
    onSettled: () => setIsMutating(false),
    mutationKey: ['revertOverwriting'],
  });

  return (
    <ButtonGroup variant="contained" aria-label="Revert and show invoices button group">
      <Tooltip title={t('gridForms:revertToOriginal')} placement="bottom">
        <span>
          <Button
            variant="outlined"
            disabled={!params.row?.overwritingId || isDisabled}
            onClick={() => {
              if (params.row?.id) mutate(params.row.id);
            }}
            sx={{
              padding: 0.5,
              height: '100%',
            }}
          >
            {isMutating ? (
              <CircularProgress
                sx={{ ml: 1, color: (theme) => theme.palette.primary.main }}
                size="1rem"
              />
            ) : (
              <UndoIcon />
            )}
          </Button>
        </span>
      </Tooltip>
      <Tooltip title={t('gridForms:showInvoices')} placement="bottom">
        <span>
          <Button
            variant="outlined"
            disabled={
              (!params.row?.calculation || !params.row.calculation.numberOfCalculatedInvoices) &&
              !isDisabled
            }
            onClick={() => {
              if (params.row?.id) handleShowInvoicesClick(params.row.id);
            }}
            sx={{
              padding: 0.5,
            }}
          >
            <LaunchIcon />
          </Button>
        </span>
      </Tooltip>
    </ButtonGroup>
  );
}

export default withTranslation('gridForms')(GridRevertAndInvoicesButtonGroup);
