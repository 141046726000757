import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { FunctionComponentElement } from 'react';
import { get, useFormContext } from 'react-hook-form';
import { withTranslation } from 'react-i18next';

import FormHeader from 'components/Layouts/FormHeader/FormHeader';

import FieldWidth from 'enums/FieldWidth';

import { ContactInfoFieldsProps } from './ContactInfoFields.definitions';

function ContactInfoFields({
  phoneNumberField,
  emailField,
  showHeader = false,
  t,
}: ContactInfoFieldsProps): FunctionComponentElement<ContactInfoFieldsProps> {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      {showHeader && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormHeader header={t('contactInfo')} />
        </Box>
      )}
      <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
        <TextField
          data-testid="textfield-element"
          id="phoneNumber"
          label={t('phoneNumber')}
          variant="outlined"
          {...register(phoneNumberField, {
            pattern: {
              // eslint-disable-next-line no-useless-escape
              value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
              message: t('fieldsValidation:phoneNumberHelperText'),
            },
          })}
          sx={{ width: FieldWidth.Half }}
          error={!!(errors && get(errors, phoneNumberField))}
          helperText={get(errors, phoneNumberField) && get(errors, phoneNumberField).message}
          inputProps={{ 'data-testid': 'input_phoneNumber' }}
        />
        <TextField
          data-testid="textfield-element"
          id="email"
          label={t('email')}
          variant="outlined"
          {...register(emailField, {
            pattern: {
              value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: t('fieldsValidation:emailHelperText'),
            },
          })}
          sx={{ width: FieldWidth.Half }}
          error={!!(errors && get(errors, emailField))}
          helperText={get(errors, emailField) && get(errors, emailField).message}
          inputProps={{ 'data-testid': 'input_contactInfo_email' }}
        />
      </Stack>
    </>
  );
}

export default withTranslation(['contactInfoFields', 'fieldsValidation'])(ContactInfoFields);
