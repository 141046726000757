import { Box, CircularProgress } from '@mui/material';
import { FunctionComponentElement } from 'react';

function Loader(): FunctionComponentElement<unknown> {
  return (
    <Box
      sx={{
        margin: 'auto',
      }}
    >
      <CircularProgress />
    </Box>
  );
}

export default Loader;
