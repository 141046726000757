import { Alert, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { AxiosError } from 'axios';
import { FunctionComponentElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiError } from 'api/client';

import { FallbackComponentProps } from './FallbackComponent.definitions';
import styles from './FallbackComponent.module.scss';

function FallbackComponent({
  error,
  errorMessage,
  resetErrorBoundary,
}: FallbackComponentProps): FunctionComponentElement<FallbackComponentProps> {
  const { t } = useTranslation('common');

  const displayError = (err: Error): ReactNode => {
    if (err instanceof AxiosError) {
      if (err.code === 'ECONNABORTED' || err.code === 'ERR_NETWORK') {
        return <Alert severity="error">{t('noServiceMessage')}</Alert>;
      }
    }
    if (err instanceof ApiError) {
      if (err.status) {
        if (err.status >= 500) {
          return <Alert severity="error">{t('noServiceMessage')}</Alert>;
        }
      }
    }

    return <Alert severity="error">{err.message || t('errorMessage')}</Alert>;
  };

  if (errorMessage) {
    return (
      <div className={styles.root}>
        <Typography component="h6" variant="body1">
          <Alert severity="error">{errorMessage}</Alert>
        </Typography>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.root}>
        <Typography component="h6" variant="body1">
          {displayError(error)}
        </Typography>
        <Button sx={{ mt: 2 }} onClick={resetErrorBoundary}>
          {t('tryAgain')}
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <Typography component="h6" variant="body1">
        <Alert severity="error">{t('errorMessage')}</Alert>
      </Typography>
    </div>
  );
}

export default FallbackComponent;
