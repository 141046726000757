import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, Divider, Stack, Typography, styled } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useMutation } from '@tanstack/react-query';
import { AxiosProgressEvent } from 'axios';
import { ChangeEvent, FunctionComponentElement, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import theme from 'themes/theme';

import { FileModel, FilesService } from 'api/files';

import FileView from 'components/FileView/FileView';
import FormFooter from 'components/Layouts/FormFooter/FormFooter';

import { PostFileModel, SelectFilesStepProps } from './SelectFilesStep.definitions';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function SelectFilesStep({
  selectedFile,
  updateSelectedFile,
  goToNextStep,
  t,
}: SelectFilesStepProps): FunctionComponentElement<SelectFilesStepProps> {
  const [currentFileSelected, setCurrentFileSelected] = useState<File | undefined>(undefined);
  const [uploadedFile, setUploadedFile] = useState<FileModel | undefined>(selectedFile);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const onUploadProgress = (progressEvent: AxiosProgressEvent): void => {
    if (!progressEvent.progress) {
      return;
    }
    const progressResult = Math.trunc(progressEvent.progress * 100);
    setProgress(progressResult);
  };

  const { mutate, error } = useMutation({
    mutationFn: async ({ referenceId, formData }: PostFileModel) => {
      const uploaded = await FilesService.postApiFiles(referenceId, formData, onUploadProgress);
      return uploaded;
    },
    onSuccess: (data) => {
      setUploadedFile(data);
    },
    onMutate: () => setIsUploading(true),
    onSettled: () => {
      setIsUploading(false);
      setProgress(0);
    },
    mutationKey: ['createClient'],
  });

  const uploadFile = (file: File) => {
    setCurrentFileSelected(file);
    const postFile: PostFileModel = {
      referenceId: uuidv4(),
      formData: {
        file,
      },
    };
    mutate(postFile);
  };

  const handleUploadClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    uploadFile(event.target.files[0]);
  };

  const handleDrop = (files: File[]) => {
    if (files.length > 1) {
      return;
    }
    uploadFile(files[0]);
  };

  const { getRootProps, getInputProps, open, isDragAccept } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDropAccepted: handleDrop,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
  });

  const handleNextClick = () => {
    if (!uploadedFile) {
      return;
    }
    updateSelectedFile(uploadedFile);
    goToNextStep();
  };

  return (
    <div className="formContent">
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          m: 'auto',
        }}
      >
        <Stack spacing={2}>
          <Box
            {...getRootProps()}
            sx={{
              border: '1px dashed grey',
              width: '500px',
              m: 'auto',
              backgroundColor: isDragAccept
                ? alpha(theme.palette.success.light, 0.25)
                : theme.palette.common.white,
            }}
          >
            <input {...getInputProps()} />
            <Stack sx={{ p: 2, alignItems: 'center' }} spacing={2}>
              <CloudUploadIcon color="primary" fontSize="large" />
              <Typography variant="body1">{t('dragAndDropFile')}</Typography>
              <Divider sx={{ width: '300px' }}>{t('or')}</Divider>
              <Button
                sx={{ width: 'fit-content' }}
                variant="contained"
                startIcon={<CloudUploadIcon />}
                onClick={open}
              >
                {t('uploadFile')}
                <VisuallyHiddenInput type="file" onChange={handleUploadClick} />
              </Button>
              <Stack sx={{ alignItems: 'center' }}>
                <Typography variant="body2" color={theme.palette.grey[500]}>
                  {t('maxFiles')}
                </Typography>
                <Typography variant="body2" color={theme.palette.grey[500]}>
                  {t('supportedFormat')}
                </Typography>
              </Stack>
            </Stack>
          </Box>

          {currentFileSelected && isUploading && (
            <FileView fileName={currentFileSelected.name} progress={progress} />
          )}

          {!isUploading && uploadedFile && (
            <FileView fileName={uploadedFile.name} fileSize={uploadedFile.size} />
          )}

          {error && <Typography variant="body2">{t('uploadErrorMsg')}</Typography>}
        </Stack>
      </Box>
      <FormFooter>
        <Button
          type="submit"
          variant="contained"
          onClick={handleNextClick}
          disabled={!uploadedFile}
        >
          {t('common:next')}
        </Button>
      </FormFooter>
    </div>
  );
}

export default withTranslation('importInvoicesStepper')(SelectFilesStep);
