import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { DashboardProps } from './Dashboard.definitions';

function Dashboard({ t }: DashboardProps): FunctionComponentElement<DashboardProps> {
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
        {t('dashboard')}
      </Typography>
    </Box>
  );
}

export default withTranslation('navigation')(observer(Dashboard));
