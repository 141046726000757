import moment from 'moment';

import DateTimeFormat from '../../enums/DateTimeFormat';

export const dateFormat: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

export const formatDateTimeString = (
  dateTimeString: string,
  dateTimeFormat: DateTimeFormat,
): string => moment(dateTimeString).format(dateTimeFormat);

export const getDateTime = (timestampInUtc: string): string => {
  const localDateTimeString = new Date(timestampInUtc).toUTCString();
  return formatDateTimeString(localDateTimeString, DateTimeFormat.DDMMYYYHHmmss);
};

// date formatter used in DataGrids
export const dateReplacer = (key: string, value: string) => {
  const dateValue = new Date(value);
  if (!Number.isNaN(dateValue.getTime())) {
    return dateValue.toISOString();
  }
  return value;
};
