import { useQuery } from '@tanstack/react-query';

import { InvoicesService } from 'api/invoices';

const getInvoicesAlreadyExist = async (companyId?: string) => {
  const response = await InvoicesService.getApiInvoicesDoesInvoicesAlreadyExist(companyId);
  return response;
};

const useInvoicesExist = (companyId?: string) =>
  useQuery({
    queryKey: ['getInvoicesExist', companyId],
    queryFn: () => getInvoicesAlreadyExist(companyId),
  });

export default useInvoicesExist;
