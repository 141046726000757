import { dateFormat } from './date.helper';

export const currencyFormatter = new Intl.NumberFormat('de-EU', {
  style: 'currency',
  currency: 'EUR',
});

export const numberFormatter = new Intl.NumberFormat('de-DE', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatPercentage = (value: number): string => `${(value * 100).toFixed(2)} %`;

export const formatDate = (value?: string | null): string => {
  if (value) {
    return new Date(value).toLocaleDateString('de-DE', dateFormat);
  }

  return '';
};
