import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

interface TrafficLightTooltipProps extends TooltipProps {
  color: string;
}

const TrafficLightTooltip = styled(({ className, ...props }: TrafficLightTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, color }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: color,
    color: theme.palette.error.contrastText,
    maxWidth: 'none',
  },
}));

export default TrafficLightTooltip;
