import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { FunctionComponentElement } from 'react';
import { get, useFormContext } from 'react-hook-form';
import { withTranslation } from 'react-i18next';

import FormHeader from 'components/Layouts/FormHeader/FormHeader';

import FieldWidth from 'enums/FieldWidth';

import AddressFields from '../AddressFields/AddressFields';
import ContactInfoFields from '../ContactInfoFields/ContactInfoFields';
import TaxAuditorOfficeFields from '../TaxAuditorOfficeFields/TaxAuditorOfficeFields';

import { CompanyFieldsProps } from './CompanyFields.definitions';

function CompanyFields({
  index,
  nameField,
  taxNumberField,
  vatTaxNumberField,
  addressField,
  contactInfoField,
  showHeader,
  legalFormField,
  taxOfficeField,
  auditorOfficeField,
  t,
}: CompanyFieldsProps): FunctionComponentElement<CompanyFieldsProps> {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      {showHeader && <FormHeader header={t('companyHeader')} />}
      <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
        <TextField
          data-testid="textfield-element"
          id="companytName"
          label={t('name')}
          variant="outlined"
          {...register(nameField, {
            required: t('fieldsValidation:requiredHelperText', { fieldName: t('name') }),
          })}
          required
          sx={{ width: FieldWidth.Full }}
          error={!!(errors && get(errors, nameField))}
          helperText={get(errors, nameField) && get(errors, nameField).message}
        />
      </Stack>
      <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
        <TextField
          data-testid="textfield-element"
          id="legalForm"
          label={t('legalForm')}
          variant="outlined"
          {...register(legalFormField)}
          sx={{ width: FieldWidth.Full }}
        />
      </Stack>
      <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
        <TextField
          data-testid="textfield-element"
          id="taxNumber"
          label={t('taxNumber')}
          variant="outlined"
          {...register(taxNumberField, {
            required: t('fieldsValidation:requiredHelperText', { fieldName: t('taxNumber') }),
          })}
          required
          sx={{ width: FieldWidth.Half }}
          error={!!(errors && get(errors, taxNumberField))}
          helperText={get(errors, taxNumberField) && get(errors, taxNumberField).message}
        />
        <TextField
          data-testid="textfield-element"
          id="vatTaxNumber"
          label={t('vatTaxNumber')}
          variant="outlined"
          {...register(vatTaxNumberField)}
          sx={{ width: FieldWidth.Half }}
        />
      </Stack>

      <ContactInfoFields
        showHeader={false}
        phoneNumberField={`${contactInfoField}.phoneNumber`}
        emailField={`${contactInfoField}.email`}
      />
      <TaxAuditorOfficeFields
        index={index}
        taxOfficeField={taxOfficeField}
        auditorOfficeField={auditorOfficeField}
      />
      <AddressFields
        showHeader={false}
        streetField={`${addressField}.street`}
        numberField={`${addressField}.number`}
        postalField={`${addressField}.postal`}
        cityField={`${addressField}.city`}
        regionField={`${addressField}.region`}
        countryField={`${addressField}.country`}
        notesField={`${addressField}.notes`}
      />
    </>
  );
}

export default withTranslation(['companyFields', 'fieldsValidation'])(CompanyFields);
