import { Box, Typography } from '@mui/material';
import { GridColDef, GridPaginationModel, GridRowModel } from '@mui/x-data-grid-premium';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { ContactModel, ContactsService } from 'api/masterdata';

import GridFieldsValidator from 'shared/utils/validators/GridFieldsValidator';

import BdoDataGrid from 'components/BdoDataGrid/BdoDataGrid';
import RenderBasicEditCell from 'components/BdoDataGrid/Cells/BasicEditCell';
import { DataGridRow } from 'components/BdoDataGrid/Types/DataGridRow.definitions';

import { ContactsProps } from './Contacts.definitions';

function Contacts({ t }: ContactsProps): FunctionComponentElement<ContactsProps> {
  const columns: GridColDef[] = [
    { field: 'id', headerName: t('id'), type: 'string', width: 150 },
    {
      field: 'firstName',
      headerName: `${t('contactFields:firstName')} *`,
      type: 'string',
      editable: true,
      width: 150,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'lastName',
      headerName: `${t('contactFields:lastName')} *`,
      type: 'string',
      editable: true,
      width: 150,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'contactNotes',
      headerName: t('contactFields:notes'),
      type: 'string',
      editable: true,
      width: 250,
    },
    {
      field: 'email',
      headerName: t('contactInfoFields:email'),
      type: 'string',
      editable: true,
      width: 200,
      valueGetter: (params) => params?.row?.contactInfo?.email,
      valueSetter: (params) => ({
        ...params.row,
        contactInfo: { ...params.row.contactInfo, email: params.value },
      }),
    },
    {
      field: 'phoneNumber',
      headerName: t('contactInfoFields:phoneNumber'),
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.contactInfo?.phoneNumber,
      valueSetter: (params) => ({
        ...params.row,
        contactInfo: { ...params.row.contactInfo, phoneNumber: params.value },
      }),
    },
    {
      field: 'street',
      headerName: t('addressFields:street'),
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.address?.street,
      valueSetter: (params) => ({
        ...params.row,
        address: { ...params.row.address, street: params.value },
      }),
      groupingValueGetter: (params) => params?.row?.address?.street,
    },
    {
      field: 'number',
      headerName: t('addressFields:number'),
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.address?.number,
      valueSetter: (params) => ({
        ...params.row,
        address: { ...params.row.address, number: params.value },
      }),
      groupingValueGetter: (params) => params?.row?.address?.number,
    },
    {
      field: 'postal',
      headerName: t('addressFields:postal'),
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.address?.postal,
      valueSetter: (params) => ({
        ...params.row,
        address: { ...params.row.address, postal: params.value },
      }),
      groupingValueGetter: (params) => params?.row?.address?.postal,
    },
    {
      field: 'city',
      headerName: t('addressFields:city'),
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.address?.city,
      valueSetter: (params) => ({
        ...params.row,
        address: { ...params.row.address, city: params.value },
      }),
      groupingValueGetter: (params) => params?.row?.address?.city,
    },
    {
      field: 'region',
      headerName: t('addressFields:region'),
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.address?.region,
      valueSetter: (params) => ({
        ...params.row,
        address: { ...params.row.address, region: params.value },
      }),
      groupingValueGetter: (params) => params?.row?.address?.region,
    },
    {
      field: 'country',
      headerName: t('addressFields:country'),
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.address?.country,
      valueSetter: (params) => ({
        ...params.row,
        address: { ...params.row.address, country: params.value },
      }),
      groupingValueGetter: (params) => params?.row?.address?.country,
    },
    {
      field: 'addressNotes',
      headerName: t('addressFields:notes'),
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.address?.addressNotes,
      valueSetter: (params) => ({
        ...params.row,
        address: { ...params.row.address, addressNotes: params.value },
      }),
    },
  ];

  const addDefaultRecord = (id: string): GridRowModel => {
    const contact: ContactModel = {
      id,
      firstName: '',
      lastName: '',
    };

    return contact;
  };

  const processRowUpdate = async (row: DataGridRow): Promise<DataGridRow> => {
    let resultRow;

    if (row.isNew) {
      resultRow = await ContactsService.postApiContacts(row as ContactModel);
    } else {
      resultRow = await ContactsService.putApiContactsUpdateRow(row as ContactModel);
    }

    return resultRow as DataGridRow;
  };

  const processRowDelete = async (row: DataGridRow): Promise<boolean> =>
    ContactsService.deleteApiContactsDeleteRow(row as ContactModel);

  const getRows = (pagination: GridPaginationModel) =>
    ContactsService.getApiContactsGetRows(pagination.page, pagination.pageSize);

  const getRowCount = () => ContactsService.getApiContactsGetRowCount();

  return (
    <div>
      <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
        {t('navigation:contacts')}
      </Typography>
      <Box sx={{ p: '1rem' }}>
        <BdoDataGrid
          columns={columns}
          processRowUpdate={processRowUpdate}
          processRowDelete={processRowDelete}
          getRows={getRows}
          getRowCount={getRowCount}
          addDefaultRecord={addDefaultRecord}
          exportFileName={t('contactFields:exportFileName')}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
            sorting: {
              sortModel: [{ field: 'lastName', sort: 'asc' }],
            },
          }}
        />
      </Box>
    </div>
  );
}

export default withTranslation([
  'addressFields',
  'contactFields',
  'contactInfoFields',
  'gridTooltips',
  'navigation',
])(Contacts);
