import { Box, Button } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { FormProvider, Path, useForm } from 'react-hook-form';
import { withTranslation } from 'react-i18next';

import { ClientModel } from 'api/client/models/ClientModel';

import CardWrapper from 'components/CardWrapper/CardWrapper';
import ClientFields from 'components/FormFields/ClientFields/ClientFields';
import FormFooter from 'components/Layouts/FormFooter/FormFooter';

import { ClientFormProps } from './ClientStep.definitions';

function ClientStep({
  clientFormInfo,
  updateClientFormInfo,
  goToNextStep,
  t,
}: ClientFormProps): FunctionComponentElement<ClientFormProps> {
  const methods = useForm({
    defaultValues: clientFormInfo.client,
    mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const nameField: Path<ClientModel> = 'name';

  const handleNextClick = (data: ClientModel) => {
    const updatedInfo = { ...clientFormInfo, client: data };
    updateClientFormInfo(updatedInfo);
    goToNextStep();
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)} className="formContent">
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <CardWrapper>
          <FormProvider {...methods}>
            <ClientFields nameField={nameField} />
          </FormProvider>
        </CardWrapper>
      </Box>
      <FormFooter>
        <Button type="submit" variant="contained" disabled={!isValid}>
          {t('common:next')}
        </Button>
      </FormFooter>
    </form>
  );
}

export default withTranslation(['createClientStepper', 'common'])(ClientStep);
