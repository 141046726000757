import { RouteObject } from 'react-router-dom';

import PathConstants from 'pages/PathConstants';

import Dashboard from '../Dashboard/Dashboard';

import AdminRouterConfig from './AdminRouterConfig';
import ClientRouterConfig from './ClientRouterConfig';
import SystemRouterConfig from './SystemRouterConfig';
import TaxRouterConfig from './TaxRouterConfig';

const HomeRouterConfig: RouteObject[] = [
  {
    path: PathConstants.DASHBOARD,
    element: <Dashboard />,
  },
  ...TaxRouterConfig,
  ...ClientRouterConfig,
  ...SystemRouterConfig,
  ...AdminRouterConfig,
];

export default HomeRouterConfig;
