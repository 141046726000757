import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';

import i18n from 'configs/i18n';

import { ApiError } from 'api/client';

import { INotificationStore } from 'shared/stores/NotificationStore/INotificationStore';
import {
  handleErrorCode,
  handleErrorMessage,
  isServiceUnavailableError,
} from 'shared/utils/error.helper';

import Severity from 'enums/Severity';

import { isApiError } from '../shared/utils/error.helper';

const handleError = (error: Error, notificationStore: INotificationStore): void => {
  const errorCode = handleErrorCode(error);
  const errorMessage = handleErrorMessage(error);

  // eslint-disable-next-line no-console
  console.error(error);
  // eslint-disable-next-line no-console
  console.error(errorMessage);

  switch (errorCode) {
    case 400:
      if (isApiError(error)) {
        const apiError = error as ApiError;

        if (apiError.body.length > 0) {
          const translationKey = `notifications:${apiError.body}`;
          const translatedMessage = i18n.t(translationKey);
          const notificationMessage =
            translatedMessage !== translationKey ? translatedMessage : apiError.body;

          notificationStore.sendNotification(
            notificationMessage,
            Severity.Error,
            notificationMessage,
          );
        }
      } else {
        notificationStore.sendNotification(
          i18n.t('common:errorOccured'),
          Severity.Error,
          i18n.t('common:badRequestMessage'),
        );
      }
      break;
    case 401:
      notificationStore.sendNotification(
        i18n.t('common:errorOccured'),
        Severity.Error,
        i18n.t('common:badRequestMessage'),
      );
      break;
    case 404:
      notificationStore.sendNotification(
        i18n.t('common:errorOccured'),
        Severity.Error,
        i18n.t('common:badRequestMessage'),
      );
      break;
    case 500:
      notificationStore.sendNotification(
        i18n.t('common:serverError'),
        Severity.Error,
        i18n.t('common:noServiceMessage'),
      );
      break;
    case 503:
      notificationStore.sendNotification(
        i18n.t('common:serverError'),
        Severity.Error,
        i18n.t('common:noServiceMessage'),
      );
      break;
    default:
      notificationStore.sendNotification(
        i18n.t('common:errorOccured'),
        Severity.Error,
        i18n.t('common:badRequestMessage'),
      );
      break;
  }
};

const initializeQueryClient = (notificationStore: INotificationStore): QueryClient => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        throwOnError: (error) => isServiceUnavailableError(error),
        suspense: true,
        retry: 5,
      },
    },
    mutationCache: new MutationCache({
      onError: (error) => handleError(error, notificationStore),
    }),
    queryCache: new QueryCache({
      onError: (error) => handleError(error, notificationStore),
    }),
  });
  return queryClient;
};

export default initializeQueryClient;
