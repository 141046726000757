import { Box, Typography } from '@mui/material';
import { GridColDef, GridPaginationModel } from '@mui/x-data-grid-premium';
import React, { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { AuditLogsService } from 'api/system';

import { dateFormat } from 'shared/utils/date.helper';

import BdoDataGrid from 'components/BdoDataGrid/BdoDataGrid';

import { AuditLogsProps } from './AuditLogs.definitions';

function AuditLogs({ t }: AuditLogsProps): FunctionComponentElement<AuditLogsProps> {
  const columns: GridColDef[] = [
    { field: 'id', headerName: t('id'), type: 'string', width: 150 },
    {
      field: 'tenantId',
      headerName: t('tenantId'),
      type: 'string',
      width: 200,
    },
    {
      field: 'clientName',
      headerName: t('clientName'),
      type: 'string',
      width: 200,
    },
    {
      field: 'entityType',
      headerName: t('entityType'),
      type: 'string',
      width: 150,
    },
    {
      field: 'action',
      headerName: `${t('action')}`,
      type: 'singleSelect',
      width: 150,
      valueOptions: [
        { value: 0, label: t('added') },
        { value: 1, label: t('modified') },
        { value: 2, label: t('deleted') },
      ],
      groupingValueGetter: ({ value }) => {
        switch (value) {
          case 0:
            return t('added');
          case 1:
            return t('modified');
          case 2:
            return t('deleted');
          default:
            return '';
        }
      },
    },
    {
      field: 'created',
      headerName: t('created'),
      type: 'date',
      width: 200,
      valueGetter: ({ value }) => (value && new Date(value)) || '',
      valueFormatter: ({ value }) => {
        const date = new Date(value || '');

        return Number.isNaN(date.getTime()) ? '' : date.toLocaleTimeString('de-DE', dateFormat);
      },
    },
    {
      field: 'createdBy',
      headerName: t('createdBy'),
      type: 'string',
      width: 200,
    },
    {
      field: 'entityId',
      headerName: t('entityId'),
      type: 'string',
      width: 150,
    },
    {
      field: 'oldValue',
      headerName: t('oldValue'),
      type: 'string',
      width: 150,
    },
    {
      field: 'newValue',
      headerName: t('newValue'),
      type: 'string',
      width: 150,
    },

    {
      field: 'difference',
      headerName: t('difference'),
      type: 'string',
      width: 500,
    },
  ];

  const getRows = (pagination: GridPaginationModel) =>
    AuditLogsService.getApiAuditLogsGetRows(pagination.page, pagination.pageSize);

  const getRowCount = () => AuditLogsService.getApiAuditLogsGetRowCount();

  return (
    <div>
      <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
        {t('auditLogs')}
      </Typography>
      <Box sx={{ p: '1rem' }}>
        <BdoDataGrid
          columns={columns}
          getRows={getRows}
          getRowCount={getRowCount}
          processRowUpdate={async (row) => row}
          exportFileName={t('exportFileName')}
          initialState={{
            rowGrouping: {
              model: ['clientName', 'entityType'],
            },
            sorting: {
              sortModel: [
                { field: 'entityId', sort: 'asc' },
                { field: 'created', sort: 'asc' },
              ],
            },
            columns: {
              columnVisibilityModel: {
                id: false,
                tenantId: false,
                clientName: false,
                entityId: false,
                entityType: false,
                actions: false,
                oldValue: false,
                newValue: false,
              },
            },
          }}
        />
      </Box>
    </div>
  );
}

export default withTranslation('auditLogs')(AuditLogs);
