import { GridRenderCellParams } from '@mui/x-data-grid-premium';

export const isAutoGeneratedColumn = (params: GridRenderCellParams) =>
  params &&
  params.rowNode &&
  (params.rowNode as { isAutoGenerated: boolean }).isAutoGenerated === true;

export const isGroupTypeColumn = (params: GridRenderCellParams) =>
  params && params.rowNode && (params.rowNode as { type: string }).type === 'group';

export const isGroupingColumn = (params: GridRenderCellParams) =>
  params && params.field && params.field === '__row_group_by_columns_group__';

export const getGroupingKey = (params: GridRenderCellParams): string => {
  if (isAutoGeneratedColumn(params) && isGroupTypeColumn(params)) {
    return (params.rowNode as { groupingKey: string }).groupingKey;
  }

  return '';
};
