/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExchangeRateModel } from '../models/ExchangeRateModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExchangeRatesService {
  /**
   * Retrieves paginated rows of exchange rates.
   * @param page The page number for the data set to retrieve.
   * @param pageSize The size of the page to retrieve.
   * @returns ExchangeRateModel Success
   * @throws ApiError
   */
  public static getApiExchangeRatesGetRows(
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<ExchangeRateModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/ExchangeRates/GetRows',
      query: {
        page: page,
        pageSize: pageSize,
      },
    });
  }

  /**
   * Retrieves the total count of exchange rates.
   * @returns number Success
   * @throws ApiError
   */
  public static getApiExchangeRatesGetRowCount(): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/ExchangeRates/GetRowCount',
    });
  }

  /**
   * Adds a new exchange rate row.
   * @param requestBody The ExchangeRateModel object representing the new exchange rate to add.
   * @returns ExchangeRateModel Success
   * @throws ApiError
   */
  public static postApiExchangeRatesAddRow(
    requestBody?: ExchangeRateModel,
  ): CancelablePromise<ExchangeRateModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/ExchangeRates/AddRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Updates an existing exchange rate row.
   * @param requestBody The ExchangeRateModel object with updated information.
   * @returns ExchangeRateModel Success
   * @throws ApiError
   */
  public static putApiExchangeRatesUpdateRow(
    requestBody?: ExchangeRateModel,
  ): CancelablePromise<ExchangeRateModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/ExchangeRates/UpdateRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Deletes an exchange rate if it's not referenced anymore.
   * @param requestBody The exchange rate model to delete.
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteApiExchangeRatesDeleteRow(
    requestBody?: ExchangeRateModel,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/ExchangeRates/DeleteRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
