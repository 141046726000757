/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EcSalesListModel } from '../models/EcSalesListModel';
import type { FillingPeriodModel } from '../models/FillingPeriodModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EcSalesListSubmissionService {
  /**
   * Validates the specified annual VAT return.
   * @param ecSalesListId The ID of the annual VAT return.
   * @returns EcSalesListModel Success
   * @throws ApiError
   */
  public static postApiEcSalesListSubmissionValidate(
    ecSalesListId?: string,
  ): CancelablePromise<EcSalesListModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/EcSalesListSubmission/Validate',
      query: {
        ecSalesListId: ecSalesListId,
      },
    });
  }

  /**
   * Submits the specified annual VAT return.
   * @param ecSalesListId The ID of the annual VAT return.
   * @returns EcSalesListModel Success
   * @throws ApiError
   */
  public static postApiEcSalesListSubmissionSubmit(
    ecSalesListId?: string,
  ): CancelablePromise<EcSalesListModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/EcSalesListSubmission/Submit',
      query: {
        ecSalesListId: ecSalesListId,
      },
    });
  }

  /**
   * Creates a correction for the specified filling period.
   * @param fillingPeriodId The ID of the filling period to create a correction for.
   * @returns FillingPeriodModel Success
   * @throws ApiError
   */
  public static postApiEcSalesListSubmissionCreateCorrection(
    fillingPeriodId?: string,
  ): CancelablePromise<FillingPeriodModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/EcSalesListSubmission/CreateCorrection',
      query: {
        fillingPeriodId: fillingPeriodId,
      },
    });
  }
}
