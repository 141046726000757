const PathConstants = {
  DASHBOARD: '/dashboard',
  // Tax
  COUNTRY: '/country/:countryId',
  TAX_YEAR: 'tax-year/:taxYearId',
  ANNUAL_VAT_RETURN: 'annual-vat-return/:annualVatReturnId',
  FILLING_PERIOD: 'filling-period/:fillingPeriodId',
  ADVANCE_VAT_RETURN: 'advance-vat-return/:advanceVatReturnId',
  EC_SALES_LIST: 'ec-sales-list/:ecSalesListId',
  SPECIAL_PREPAYMENT: 'special-prepayment/:specialPrepaymentId',
  VAT_GROUP: 'value-added-tax-group',
  COMPANY: 'company/:companyId',
  IMPORT_INVOICES: 'import-invoices',
  // System
  SYSTEM_SETTINGS: '/system-settings',
  VAT_FORMS: 'value-added-tax-forms',
  VAT_FORM_YEAR: ':taxYearId',
  ANNUAL_VAT_RETURN_FORM: 'annual-vat-return-form',
  ADVANCE_VAT_RETURN_FORM: 'advance-vat-return-form',
  EC_SALES_LIST_FORM: 'ec-sales-list-form',
  TAX_OFFICES: 'tax-offices',
  AUDITOR_OFFICES: 'auditor-offices',
  TAX_YEARS: 'tax-years',
  CONTACTS: 'contacts',
  EXCHANGE_RATES: 'exchange-rates',
  COUNTRIES: 'country',
  TAX_ISSUES: 'tax-issues',
  TAX_RULES: 'tax-rules',
  TAX_RULE_MAPPINGS: 'tax-rule-mappings',
  IMPORT_EXCHANGE_RATES: 'import-exchange-rates',
  // Client
  CLIENT_SETTINGS: '/client-settings',
  CLIENT_MASTERDATA: 'client-masterdata',
  TAX_CODES: 'tax-codes',
  JOBS: 'jobs',
  VAT_GROUP_SETTINGS: 'value-added-tax-group-settings',
  COMPANY_SETTINGS: 'company-settings/:companyId',
  IMPORT_SETTINGS: 'import-settings',
  CULTURE_SETTINGS: 'culture-settings',
  USER_MANAGEMENT: 'user-management',
  ROLE_MANAGEMENT: 'role-management',
  IMPORT_LOGS: 'import-logs',
  // Admin
  ADMINISTRATIONS: '/administrations',
  CLIENTS: 'clients',
  SYSTEM_ADMIN_SETTINGS: 'system-admin-settings',
  // common
  ADD: 'add',
};

export default PathConstants;
