import { RouteObject } from 'react-router-dom';

import PathConstants from 'pages/PathConstants';

import CreateTaxFolderStepper from 'features/CreateTaxFolderStepper/CreateTaxFolderStepper/CreateTaxFolderStepper';
import CreateVatGroupsStepper from 'features/CreateVatGroupsStepper/CreateVatGroupsStepper/CreateVatGroupsStepper';
import ImportInvoicesStepper from 'features/ImportInvoicesStepper/ImportInvoicesStepper/ImportInvoicesStepper';

import AdvanceVatReturn from '../Tax/AdvanceVatReturn/AdvanceVatReturn';
import AnnualVatReturn from '../Tax/AnnualVatReturn/AnnualVatReturn';
import Company from '../Tax/Company/Company';
import Country from '../Tax/Country/Country';
import EcSalesList from '../Tax/EcSalesList/EcSalesList';
import FillingPeriod from '../Tax/FillingPeriod/FillingPeriod';
import SpecialPrepayment from '../Tax/SpecialPrepayment/SpecialPrepayment';
import TaxGroup from '../Tax/TaxGroup/TaxGroup';
import TaxYear from '../Tax/TaxYear/TaxYear';

const TaxRouterConfig: RouteObject[] = [
  {
    path: PathConstants.COUNTRY,
    children: [
      {
        index: true,
        element: <Country />,
      },
      {
        path: PathConstants.TAX_YEAR,
        children: [
          {
            index: true,
            element: <TaxYear />,
          },
          {
            path: PathConstants.ANNUAL_VAT_RETURN,
            element: <AnnualVatReturn />,
          },
          {
            path: PathConstants.FILLING_PERIOD,
            children: [
              {
                index: true,
                element: <FillingPeriod />,
              },
              {
                path: PathConstants.ADVANCE_VAT_RETURN,
                element: <AdvanceVatReturn />,
              },
              {
                path: PathConstants.EC_SALES_LIST,
                element: <EcSalesList />,
              },
              {
                path: PathConstants.SPECIAL_PREPAYMENT,
                element: <SpecialPrepayment />,
              },
              {
                path: PathConstants.VAT_GROUP,
                children: [
                  {
                    index: true,
                    element: <TaxGroup />,
                  },
                  {
                    path: PathConstants.ADD,
                    element: <CreateVatGroupsStepper />,
                  },
                  {
                    path: PathConstants.COMPANY,
                    children: [
                      {
                        index: true,
                        element: <Company />,
                      },
                      {
                        path: PathConstants.IMPORT_INVOICES,
                        element: <ImportInvoicesStepper />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/create-tax-folder/',
    element: <CreateTaxFolderStepper />,
  },
];

export default TaxRouterConfig;
