import { FunctionComponentElement, Suspense } from 'react';

import Loader from 'components/Loader/Loader';
import Router from 'components/Router/Router';

import DrawerRouterConfig from '../RouterConfigs/DrawerRouterConfig';

import { MainDrawerProps } from './MainDrawer.definitions';

function MainDrawer(): FunctionComponentElement<MainDrawerProps> {
  return (
    <Suspense fallback={<Loader />}>
      <Router routes={DrawerRouterConfig} />
    </Suspense>
  );
}

export default MainDrawer;
