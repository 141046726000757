import { Box, Button, Stack } from '@mui/material';
import { FunctionComponentElement, useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { withTranslation } from 'react-i18next';

import CardWrapper from 'components/CardWrapper/CardWrapper';
import FallbackComponent from 'components/FallbackComponent/FallbackComponent';
import FormCheckbox from 'components/FormCheckbox/FormCheckbox';
import FormFooter from 'components/Layouts/FormFooter/FormFooter';
import Loader from 'components/Loader/Loader';

import getSheetDescription from '../ImportInvoicesStepper.helper';

import {
  SelectWorksheetsFormValues,
  SelectWorksheetsStepProps,
} from './SelectWorksheetsStep.definitions';

function SelectWorksheetsStep({
  isFetchingSheets,
  error,
  sheets,
  updateSheets,
  goToNextStep,
  goToPrevStep,
  t,
}: SelectWorksheetsStepProps): FunctionComponentElement<SelectWorksheetsStepProps> {
  const methods = useForm<SelectWorksheetsFormValues>({
    defaultValues: { worksheets: sheets },
  });

  const { control, handleSubmit, reset, watch } = methods;

  const { fields } = useFieldArray({
    control,
    name: 'worksheets',
    keyName: 'key',
  });

  useEffect(() => {
    reset({ worksheets: sheets });
  }, [sheets, reset]);

  if (isFetchingSheets) {
    return (
      <Box sx={{ margin: 'auto' }}>
        <Loader />
      </Box>
    );
  }

  if (error) {
    return <FallbackComponent error={error} />;
  }

  const handlePrevClick = () => {
    goToPrevStep();
  };

  const handleNextClick = (data: SelectWorksheetsFormValues) => {
    updateSheets(data.worksheets);
    goToNextStep();
  };

  const isAtleastOneSheetSelected = (): boolean =>
    watch('worksheets').some((sheet) => sheet.isSelected);

  return (
    <form onSubmit={handleSubmit(handleNextClick)} className="formContent">
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <CardWrapper>
          <Stack sx={{ marginBottom: 2 }}>
            <FormProvider {...methods}>
              {fields.map((item, index) => (
                <FormCheckbox<SelectWorksheetsFormValues>
                  key={item.key}
                  name={`worksheets.${index}.isSelected`}
                  label={getSheetDescription(item)}
                  isDisabled={item.usedRows ? item.usedRows < 2 : true}
                />
              ))}
            </FormProvider>
          </Stack>
        </CardWrapper>
      </Box>
      <FormFooter>
        <Stack direction="row" spacing={3}>
          <Button variant="contained" onClick={handlePrevClick}>
            {t('common:back')}
          </Button>
          <Button type="submit" variant="contained" disabled={!isAtleastOneSheetSelected()}>
            {t('common:next')}
          </Button>
        </Stack>
      </FormFooter>
    </form>
  );
}

export default withTranslation()(SelectWorksheetsStep);
