import { Box, Button, Stack } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { withTranslation } from 'react-i18next';

import CardWrapper from 'components/CardWrapper/CardWrapper';
import FormCheckbox from 'components/FormCheckbox/FormCheckbox';
import FormFooter from 'components/Layouts/FormFooter/FormFooter';

import {
  VatGroupsFormValues,
  VatGroupsSelectionStepProps,
} from './VatGroupsSelectionStep.definitions';

function VatGroupsSelectionStep({
  vatGroupsSelection,
  updateVatGroupsSelection,
  goToNextStep,
  t,
}: VatGroupsSelectionStepProps): FunctionComponentElement<VatGroupsSelectionStepProps> {
  const methods = useForm<VatGroupsFormValues>({
    defaultValues: { vatGroups: vatGroupsSelection },
  });

  const { control, handleSubmit } = methods;

  const { fields } = useFieldArray({
    control,
    name: 'vatGroups',
    keyName: 'key',
  });

  const handleNextClick = (data: VatGroupsFormValues) => {
    updateVatGroupsSelection(data.vatGroups);
    goToNextStep();
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)} className="formContent">
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <CardWrapper>
          <Stack sx={{ marginBottom: 2 }}>
            <FormProvider {...methods}>
              {fields.map((item, index) => (
                <FormCheckbox<VatGroupsFormValues>
                  key={item.id}
                  name={`vatGroups.${index}.isSelected`}
                  label={`${item.name || ''} ${t(
                    item.isControllingCompany
                      ? 'navigation:controlling-company-postfix'
                      : 'navigation:subsidiary-company-postfix',
                  )}`}
                  isDisabled={item.isControllingCompany}
                />
              ))}
            </FormProvider>
          </Stack>
        </CardWrapper>
      </Box>
      <FormFooter>
        <Button type="submit" variant="contained">
          {t('common:next')}
        </Button>
      </FormFooter>
    </form>
  );
}

export default withTranslation(['common', 'navigation'])(VatGroupsSelectionStep);
