import { Button, MenuItem } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FunctionComponentElement, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { TaxFormsService } from 'api/taxCalculation';

import useStore from 'context/Store/Store.hooks';

import DropdownMenu from 'components/DropdownMenu/DropdownMenu';

import NamedApprovalStatus from 'enums/NamedApprovalStatus';
import Severity from 'enums/Severity';

import { RecalculationDropdownButtonProps } from './RecalculationDropdownButton.definitons';

function RecalculationDropdownButton({
  submissionId,
  approvalStatus,
  t,
}: RecalculationDropdownButtonProps): FunctionComponentElement<RecalculationDropdownButtonProps> {
  const [isMutating, setIsMutating] = useState(false);
  const queryClient = useQueryClient();
  const { toastStore } = useStore();

  const isDisabled = isMutating || approvalStatus !== NamedApprovalStatus.InEditing;

  const menuItemStyle = { fontSize: '0.8rem ' };

  const recalculate = (): void => {
    queryClient.invalidateQueries({ queryKey: ['BdoFormDataGrid', submissionId] });
  };

  const { mutate: enforceRecalculation } = useMutation({
    mutationFn: async () => {
      const response = await TaxFormsService.postApiTaxFormsEnforceRecalculation(submissionId);
      return response;
    },
    onSuccess: (data) => {
      recalculate();
      toastStore.addToast({
        id: uuidv4(),
        title: t('enforce-recalculation-success'),
        severity: Severity.Info,
      });
    },
    onMutate: () => setIsMutating(true),
    onSettled: () => setIsMutating(false),
    mutationKey: ['enforceRecalculation', submissionId],
  });

  const defaultButton = (
    <Button onClick={() => recalculate()} sx={menuItemStyle} disabled={isDisabled}>
      {t('btn-recalculate')}
    </Button>
  );

  const menuItems = [
    <MenuItem key={uuidv4()} onClick={() => recalculate()} disabled={isDisabled}>
      {t('btn-recalculate')}
    </MenuItem>,
    <MenuItem key={uuidv4()} onClick={() => enforceRecalculation()} disabled={isDisabled}>
      {t('btn-enforce-recalculation')}
    </MenuItem>,
  ];

  return (
    <DropdownMenu
      menuDisabled={isDisabled}
      defaultButton={defaultButton}
      menuItems={menuItems}
      isLoading={isMutating}
    />
  );
}

export default withTranslation('submissions')(RecalculationDropdownButton);
