import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useStore from 'context/Store/Store.hooks';

type ClientSelectionWrapperProps = {
  children: React.ReactNode;
};

function ClientSelectionWrapper({ children }: ClientSelectionWrapperProps): React.ReactNode {
  const navigate = useNavigate();
  const { clientStore } = useStore();

  useEffect(() => {
    if (!clientStore.getClient()) {
      navigate('/client-selection');
    }
  }, [clientStore, navigate]);

  return children;
}

export default ClientSelectionWrapper;
