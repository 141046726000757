import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { SubmissionModel } from 'api/taxCalculation';

import { TaxFormData } from 'models/TaxFormData';

const useValidateTaxForm = (
  validateTaxForm: () => Promise<SubmissionModel>,
  submissionId: string,
) => {
  const [isMutating, setIsMutating] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async () => {
      const response = await validateTaxForm();
      return response;
    },
    onSuccess: (data: TaxFormData) => {
      queryClient.setQueryData(['BdoFormDataGrid', submissionId], () => {
        const updatedData = { ...data };
        return updatedData;
      });

      setIsValidated(data.validationState === 0);
      return data;
    },
    onMutate: () => setIsMutating(true),
    onSettled: () => setIsMutating(false),
    mutationKey: ['validateTaxForm', submissionId],
  });

  return { ...mutation, isMutating, isValidated };
};

export default useValidateTaxForm;
