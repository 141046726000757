import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { GridRowModes } from '@mui/x-data-grid-premium';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { AddRecordToolbarEntryProps } from './AddRecordToolbarEntryProps.definitions';

function AddRecordToolbarEntry({
  addDefaultRecord,
  setRows,
  setRowModesModel,
  t,
}: AddRecordToolbarEntryProps): FunctionComponentElement<AddRecordToolbarEntryProps> {
  const handleClick = () => {
    if (!addDefaultRecord) return;

    const id = uuidv4();
    setRows((oldRows) => [...oldRows, { isNew: true, isValid: false, ...addDefaultRecord!(id) }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <Button color="primary" startIcon={<AddIcon />} onClick={handleClick} size="small">
      {t('AddRecord')}
    </Button>
  );
}

export default withTranslation('bdoDataGrid')(AddRecordToolbarEntry);
