import { createTheme } from '@mui/material';

import components from './components';
import palette from './palette';
import typography from './typography';
import { paleCharcoal } from './variables';

// module augmentation
declare module '@mui/material/styles' {
  interface Theme {
    sidebarColor: {
      main: string;
      dark: string;
    };
    yellow: {
      main: string;
    };
  }

  interface ThemeOptions {
    sidebarColor: {
      main: string;
      dark: string;
    };
    yellow: {
      main: string;
    };
  }
}

const theme = createTheme({
  palette,
  typography,
  sidebarColor: {
    main: paleCharcoal,
    dark: '#d9d9d9',
  },
  yellow: {
    main: '#FFC000',
  },
  components,
});

export default theme;
