const MuiButton = {
  styleOverrides: {
    root: {
      minWidth: '110px',
    },
  },
};

const MuiButtonGroup = {
  styleOverrides: {
    firstButton: {
      borderColor: 'rgba(152, 0, 46, 0.7)',
    },
    middleButton: {
      borderColor: 'rgba(152, 0, 46, 0.7)',
    },
    lastButton: {
      borderColor: 'rgba(152, 0, 46, 0.7)',
    },
  },
};

const MuiCard = {
  styleOverrides: {
    root: {
      boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.25), -1px -1px 4px rgba(0, 0, 0, 0.25)',
    },
  },
};

const MuiFab = {
  styleOverrides: {
    root: {
      zIndex: '0',
    },
  },
};

const components = {
  MuiButton,
  MuiCard,
  MuiFab,
  MuiButtonGroup,
};

export default components;
