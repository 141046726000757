import { Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FunctionComponentElement, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FileModel } from 'api/files';
import { ExchangeRateImportModel, ImportService } from 'api/import';
import { TaxYearModel } from 'api/invoices';
import { TaxYearsService } from 'api/masterdata';

import SelectFilesStep from 'components/StepperComponents/SelectFilesStep/SelectFilesStep';

import ExchangeRatesSummaryStep from '../ExchangeRatesSummaryStep/ExchangeRatesSummaryStep';
import SelectTaxYearStep from '../SelectTaxYearStep/SelectTaxYearStep';

import { ImportExchangeRatesStepperProps } from './ImportExchangeRatesStepper.definitions';

function ImportExchangeRatesStepper({
  t,
}: ImportExchangeRatesStepperProps): FunctionComponentElement<ImportExchangeRatesStepperProps> {
  const [selectedFile, setSelectedFile] = useState<FileModel | undefined>(undefined);
  const [taxYears, setTaxYears] = useState<TaxYearModel[]>([]);
  const [selectedTaxYear, setSelectedTaxYear] = useState<TaxYearModel>();
  const [isMutating, setIsMutating] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();

  const getTaxYearsQuery = async () => {
    const response = await TaxYearsService.getApiTaxYears();
    setTaxYears(response);
    setSelectedTaxYear(response.find((x) => Number(x.year) === new Date().getFullYear()));

    return response;
  };

  const postImportQuery = async () => {
    const exchangeRateImport: ExchangeRateImportModel = {
      fileId: selectedFile?.id,
      year: Number(selectedTaxYear?.year),
    };
    const response = await ImportService.postApiImportEnqueueExchangeRate(exchangeRateImport);

    return response;
  };

  const {
    isFetching: isFetchingTaxYears,
    error,
    refetch,
  } = useQuery({
    queryKey: ['getTaxYears'],
    queryFn: getTaxYearsQuery,
  });

  const { mutate } = useMutation({
    mutationFn: postImportQuery,
    onSuccess: async () => {
      navigate(`/system-settings/exchange-rates`);
    },
    onMutate: () => setIsMutating(true),
    onSettled: () => setIsMutating(false),
    mutationKey: ['postExchangeRateImport'],
  });

  useEffect(() => {
    if (!selectedFile) return;
    refetch();
  }, [refetch, selectedFile]);

  const steps = [t('selectFiles'), t('selectTaxYear'), t('summaryAndImportStart')];

  const isLastStep = activeStep === steps.length - 1;
  const handleNextClick = () => {
    if (isLastStep) {
      mutate();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handlePrevClick = () => {
    setActiveStep(activeStep - 1);
  };

  const renderStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <SelectFilesStep
            selectedFile={selectedFile}
            updateSelectedFile={setSelectedFile}
            goToNextStep={handleNextClick}
          />
        );
      case 1:
        return (
          <SelectTaxYearStep
            error={error}
            goToPrevStep={handlePrevClick}
            goToNextStep={handleNextClick}
            updateTaxYear={setSelectedTaxYear}
            isFetchingTaxYears={isFetchingTaxYears}
            taxYears={taxYears}
            selectedTaxYear={selectedTaxYear}
          />
        );
      case 2:
        return (
          <ExchangeRatesSummaryStep
            selectedFile={selectedFile}
            goToNextStep={handleNextClick}
            goToPrevStep={handlePrevClick}
            isSaving={isMutating}
            taxYear={selectedTaxYear}
          />
        );
      default:
        return <div>Not Found</div>;
    }
  };

  return (
    <>
      <Typography component="h1" variant="h6">
        {t('common:startInvoiceImport')}{' '}
      </Typography>
      <Stepper activeStep={activeStep} sx={{ py: 2 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {renderStepContent(activeStep)}
    </>
  );
}

export default withTranslation(['importExchangeRatesStepper', 'common', 'notifications'])(
  ImportExchangeRatesStepper,
);
