/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImportSettingsModel } from '../models/ImportSettingsModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ImportSettingsService {
  /**
   * Updates the culture identifier of an import setting.
   * @param id The unique identifier of the import setting.
   * @param cultureIdentifier The new culture identifier to be set.
   * @returns ImportSettingsModel Success
   * @throws ApiError
   */
  public static putApiImportSettings(
    id?: string,
    cultureIdentifier?: string,
  ): CancelablePromise<ImportSettingsModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/ImportSettings',
      query: {
        id: id,
        cultureIdentifier: cultureIdentifier,
      },
    });
  }
}
