import { Box, Typography } from '@mui/material';
import { GridColDef, GridPaginationModel } from '@mui/x-data-grid-premium';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { ClientsService } from 'api/client';

import BdoDataGrid from 'components/BdoDataGrid/BdoDataGrid';

import { ClientsProps } from './Clients.definitions';

function Clients({ t }: ClientsProps): FunctionComponentElement<ClientsProps> {
  const columns: GridColDef[] = [
    { field: 'id', headerName: t('clientFields:id'), type: 'string', width: 300 },
    { field: 'name', headerName: t('clientFields:clientHeader'), type: 'string', width: 450 },
  ];

  const getRows = (pagination: GridPaginationModel) =>
    ClientsService.getApiClientsGetRows(pagination.page, pagination.pageSize);

  const getRowCount = () => ClientsService.getApiClientsGetRowCount();

  return (
    <div>
      <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
        {t('navigation:clients')}
      </Typography>
      <Box sx={{ p: '1rem' }}>
        <BdoDataGrid
          columns={columns}
          getRows={getRows}
          getRowCount={getRowCount}
          processRowUpdate={async (row) => row}
          exportFileName={t('clients:exportFileName')}
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
            columns: {
              columnVisibilityModel: {
                actions: false,
              },
            },
          }}
        />
      </Box>
    </div>
  );
}

export default withTranslation(['navigation', 'clientFields'])(Clients);
