import { Button, CircularProgress } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import useValidateTaxForm from 'shared/api-hooks/useValidateTaxForm';

import NamedApprovalStatus from 'enums/NamedApprovalStatus';

import { ValidationWorkflowButtonProps } from './ValidationWorkflowButton.definitons';

function ValidationWorkflowButton({
  submissionId,
  approvalStatus,
  validateTaxForm,
  t,
}: ValidationWorkflowButtonProps): FunctionComponentElement<ValidationWorkflowButtonProps> {
  const { mutate: validate, isMutating } = useValidateTaxForm(validateTaxForm, submissionId);

  return (
    <Button
      variant="contained"
      onClick={() => validate()}
      disabled={isMutating || approvalStatus !== NamedApprovalStatus.InEditing}
      sx={{ fontSize: '0.8rem' }}
    >
      {isMutating ? <CircularProgress size={25} /> : t('btn-validation-validate')}
    </Button>
  );
}

export default withTranslation('submissions')(ValidationWorkflowButton);
