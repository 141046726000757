import { Typography } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { ImportSettingsProps } from './ImportSettings.definitions';

function ImportSettings({ t }: ImportSettingsProps): FunctionComponentElement<ImportSettingsProps> {
  return (
    <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
      {t('import-settings')}
    </Typography>
  );
}

export default withTranslation('navigation')(ImportSettings);
