enum CommentEntityType {
  Country = 'Country',
  TaxYear = 'TaxYear',
  AnnualVatReturn = 'AnnualVatReturn',
  FillingPeriod = 'FillingPeriod',
  AdvanceVatReturn = 'AdvanceVatReturn',
  EcSalesList = 'EcSalesList',
  Company = 'Company',
  Invoice = 'Invoice',
  Submission = 'Submission',
}

export default CommentEntityType;
