/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SpecialPrepaymentModel } from '../models/SpecialPrepaymentModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SpecialPrepaymentsService {
  /**
   * Asynchronously retrieves and calculates the special prepayment for a given identifier.
   * @param specialPrepaymentId The unique identifier of the special prepayment to calculate and retrieve.
   * @returns SpecialPrepaymentModel Success
   * @throws ApiError
   */
  public static getApiSpecialPrepayments(
    specialPrepaymentId?: string,
  ): CancelablePromise<SpecialPrepaymentModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/SpecialPrepayments',
      query: {
        specialPrepaymentId: specialPrepaymentId,
      },
    });
  }
}
