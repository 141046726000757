import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FunctionComponentElement, useState } from 'react';
import { FormProvider, Path, useForm } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4, v4 } from 'uuid';

import { AuditorOfficeModel } from 'api/client';
import { AuditorOfficesService } from 'api/masterdata';

import useStore from 'context/Store/Store.hooks';
import { getNewAuditorOffice } from 'shared/utils/formFields.helper';

import LoadingButton from 'components/Buttons/LoadingButton/LoadingButton';
import AuditorOfficeFields from 'components/FormFields/AuditorOfficeFields/AuditorOfficeFields';
import FormFooter from 'components/Layouts/FormFooter/FormFooter';

import Severity from 'enums/Severity';

import { AuditorOfficeFormDialogProps } from './AuditorOfficeFormDialog.definitions';

function AuditorOfficeFormDialog({
  open = false,
  onClose,
  t,
}: AuditorOfficeFormDialogProps): FunctionComponentElement<AuditorOfficeFormDialogProps> {
  const [isMutating, setIsMutating] = useState(false);
  const queryClient = useQueryClient();
  const { toastStore } = useStore();
  const nameField: Path<AuditorOfficeModel> = 'name';
  const addressField: Path<AuditorOfficeModel> = 'address';
  const contactInfoField: Path<AuditorOfficeModel> = 'contactInfo';
  const contactField: Path<AuditorOfficeModel> = 'contact';
  const notesField: Path<AuditorOfficeModel> = 'notes';

  const methods = useForm<AuditorOfficeModel>({
    defaultValues: { ...getNewAuditorOffice(), id: uuidv4() },
    mode: 'onChange',
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods;

  const postAuditorOfficeQuery = async (newData: AuditorOfficeModel) => {
    const response = await AuditorOfficesService.postApiAuditorOffices(newData);
    return response;
  };

  const { mutate } = useMutation({
    mutationFn: postAuditorOfficeQuery,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['getApiAuditorOffices'] });
      reset();
      onClose();
      toastStore.addToast({
        id: v4(),
        title: t('notifications:auditor-office-created'),
        severity: Severity.Info,
      });
    },
    onMutate: () => setIsMutating(true),
    onSettled: () => setIsMutating(false),
    mutationKey: ['postAuditorOffice'],
  });

  const handleSaveClick = async (data: AuditorOfficeModel) => {
    let newData = { ...data };
    if (!data.contactInfo?.phoneNumber) {
      newData = { ...newData, contactInfo: undefined };
    }

    if (!data.contact?.firstName) {
      newData = { ...newData, contact: undefined };
    } else {
      let newContact = { ...data.contact };
      if (!data.contact.contactInfo?.phoneNumber) {
        newContact = { ...newContact, contactInfo: undefined };
        newData = { ...newData, contact: newContact };
      }
      if (!data.contact.address?.street) {
        newContact = { ...newContact, address: undefined };
        newData = { ...newData, contact: newContact };
      }
    }

    mutate(newData);
  };

  const handleCancelClick = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{t('common:createAuditorOffice')}</DialogTitle>
      <form
        onSubmit={(event) => {
          event.stopPropagation();
          handleSubmit(handleSaveClick)(event);
        }}
      >
        <DialogContent dividers>
          <FormProvider {...methods}>
            <AuditorOfficeFields
              nameField={nameField}
              addressField={addressField}
              contactInfoField={contactInfoField}
              contactField={contactField}
              notesField={notesField}
            />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <FormFooter>
            <Stack direction="row" spacing={3}>
              <Button variant="contained" onClick={handleCancelClick}>
                {t('common:cancel')}
              </Button>
              <LoadingButton title={t('common:save')} isLoading={isMutating} disabled={!isValid} />
            </Stack>
          </FormFooter>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default withTranslation(['auditorOfficeFields', 'common', 'notifications'])(
  AuditorOfficeFormDialog,
);
