import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { FillingPeriodsService } from 'api/taxCalculation';

import NamedEditStatus from 'enums/NamedEditStatus';

const useFillingPeriod = (fillingPeriodId: string | undefined) => {
  const [isReadonly, setIsReadonly] = useState(false);

  const { isFetching, data: fillingPeriod } = useQuery({
    queryKey: ['getFillingPeriod', fillingPeriodId],
    queryFn: async () => {
      const fillingPeriodData =
        await FillingPeriodsService.getApiFillingPeriodsGetFillingPeriod(fillingPeriodId);
      return fillingPeriodData;
    },
    enabled: !!fillingPeriodId,
  });

  useEffect(() => {
    if (fillingPeriod) {
      setIsReadonly(Number(fillingPeriod?.editStatus) !== NamedEditStatus.Open);
    }
  }, [fillingPeriod]);

  return { fillingPeriod, isFetching, isReadonly };
};

export default useFillingPeriod;
