import { Box, Drawer, Toolbar } from '@mui/material';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { FunctionComponentElement, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { logError } from 'shared/utils/error.helper';

import FallbackComponent from 'components/FallbackComponent/FallbackComponent';
import Loader from 'components/Loader/Loader';
import NavigationList from 'components/Navigation/NavigationList/NavigationList';

import { SidebarProps } from './Sidebar.definitions';

function Sidebar({
  width,
  isOpen,
  handleDrawerToggle,
}: SidebarProps): FunctionComponentElement<SidebarProps> {
  const container = window !== undefined ? () => window.document.body : undefined;
  return (
    <Box
      component="nav"
      sx={{
        display: { xs: 'none', sm: 'block' },
        width: { sm: width },
        flexShrink: { sm: 0 },
      }}
    >
      {/* hidden drawer for screens < 600px */}
      <Drawer
        container={container}
        variant="temporary"
        open={isOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          width,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width,
            boxSizing: 'border-box',
            background: (theme) => theme.sidebarColor.main,
          },
        }}
      >
        <Toolbar />
        <QueryErrorResetBoundary>
          {({ reset }) => (
            <ErrorBoundary onReset={reset} onError={logError} FallbackComponent={FallbackComponent}>
              <Suspense fallback={<Loader />}>
                <NavigationList />
              </Suspense>
            </ErrorBoundary>
          )}
        </QueryErrorResetBoundary>
      </Drawer>

      {/* visible drawer for screens > 600px */}
      <Drawer
        variant="permanent"
        open
        sx={{
          width,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width,
            boxSizing: 'border-box',
            background: (theme) => theme.sidebarColor.main,
          },
        }}
      >
        <Toolbar />
        <QueryErrorResetBoundary>
          {({ reset }) => (
            <ErrorBoundary onReset={reset} onError={logError} FallbackComponent={FallbackComponent}>
              <Suspense fallback={<Loader />}>
                <NavigationList />
              </Suspense>
            </ErrorBoundary>
          )}
        </QueryErrorResetBoundary>
      </Drawer>
    </Box>
  );
}

export default Sidebar;
