import { Alert, Grid } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FunctionComponentElement, useCallback, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import { CompaniesService, CompanySummaryModel } from 'api/invoices';

import useStore from 'context/Store/Store.hooks';
import useFillingPeriod from 'shared/api-hooks/useFillingPeriod';

import CenteredLoader from 'components/CenteredLoader/CenteredLoader';
import DeletionApprovalDialog from 'components/DeletionApprovalDialog/DeletionApprovalDialog';
import InformationCard from 'components/InformationCard/InformationCard';

import Severity from 'enums/Severity';

import { CompanyInformationCardsProps } from './CompanyInformationCards.definitions';

function CompanyInformationCards({
  companies,
  navigationBasePath,
  hasDelete,
  t,
}: CompanyInformationCardsProps): FunctionComponentElement<CompanyInformationCardsProps> {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<string>('');
  const [isMutating, setIsMutating] = useState<boolean>(false);
  const [companiesData, setCompaniesData] = useState<CompanySummaryModel[]>(companies);

  const { fillingPeriodId } = useParams();
  const { toastStore } = useStore();

  const queryClient = useQueryClient();

  const { isFetching, isReadonly } = useFillingPeriod(fillingPeriodId);

  const handleOpen = (companyId: string) => {
    setSelectedCompany(companyId);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const sendMessage = useCallback(() => {
    toastStore.addToast({
      id: v4(),
      title: t('notifications:notification-row-entry-deleted'),
      severity: Severity.Info,
    });
  }, [t, toastStore]);

  const { mutate: deleteCompany } = useMutation({
    mutationFn: async (companyId: string) => {
      const response = await CompaniesService.deleteApiCompaniesDeleteCompany(companyId);
      return response;
    },
    onSuccess: async () => {
      const filteredCompaniesData = companiesData.filter(
        (company) => company.id !== selectedCompany,
      );
      setCompaniesData(filteredCompaniesData);
      setSelectedCompany('');
      sendMessage();
      await queryClient.invalidateQueries({ queryKey: ['navigation'] });
      if (filteredCompaniesData.length === 0) {
        await queryClient.invalidateQueries({ queryKey: ['getVatGroups'] });
      }
    },
    onMutate: () => setIsMutating(true),
    onSettled: () => setIsMutating(false),
    mutationKey: ['deleteCompany', fillingPeriodId],
  });

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    deleteCompany(selectedCompany);
    setOpen(false);
  };

  if (isMutating || isFetching) {
    return <CenteredLoader />;
  }

  return (
    <>
      {companiesData.map((company: CompanySummaryModel) => {
        const hasInvoices = company?.invoicesCount && company.invoicesCount > 0;
        const isControllingCompany = company.isControllingCompany ?? false;
        const isDeletable = isControllingCompany && companiesData.length === 1;

        let deleteEntryFunction;

        if (isControllingCompany) {
          if (isDeletable && hasDelete) {
            deleteEntryFunction = () => handleOpen(company.id ?? '');
          }
        } else if (hasDelete) {
          deleteEntryFunction = () => handleOpen(company.id ?? '');
        }

        return (
          <Grid item xs={12} lg={4} key={company.id}>
            <InformationCard
              subtitle={
                isControllingCompany
                  ? t('fillingPeriod:company-subtitle-controlling-company')
                  : t('fillingPeriod:company-subtitle-controlled-company')
              }
              title={company?.name || ''}
              bodyText={
                hasInvoices ? (
                  t('fillingPeriod:company-body-with-invoices', {
                    invoicesCount: company.invoicesCount,
                  })
                ) : (
                  <Alert
                    variant="outlined"
                    severity="warning"
                    sx={{ border: 'none', padding: '0' }}
                  >
                    {t('fillingPeriod:company-body-without-invoices')}
                  </Alert>
                )
              }
              buttonText={
                hasInvoices
                  ? t('fillingPeriod:cta-button-company')
                  : t('fillingPeriod:cta-button-import')
              }
              navigatePath={`${navigationBasePath}/${company.id}`}
              deleteEntity={deleteEntryFunction}
              isDeletable={isReadonly}
            />
          </Grid>
        );
      })}
      <DeletionApprovalDialog
        open={open}
        title={t('companyFields:deletion-dialog-delete-title')}
        message={t('companyFields:dialog-delete-warning')}
        switchLabel={t('companyFields:dialog-switch-label')}
        handleClose={handleClose}
        handleFormSubmit={handleFormSubmit}
      />
    </>
  );
}

export default withTranslation(['companyFields', 'dialogs'])(CompanyInformationCards);
