import { RouteObject } from 'react-router-dom';

import ClientSelection from 'pages/main/Client/ClientSelection/ClientSelection';
import Home from 'pages/main/Home/Home';

const AppRouterConfig: RouteObject[] = [
  {
    path: '/*',
    element: <Home />,
  },
  {
    path: '/client-selection',
    element: <ClientSelection />,
  },
];

export default AppRouterConfig;
