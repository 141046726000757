import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import useStore from 'context/Store/Store.hooks';

import CardWrapper from 'components/CardWrapper/CardWrapper';
import SummaryCardHeader from 'components/SummaryCardHeader/SummaryCardHeader';

import { ClientMasterdataProps } from './ClientMasterdata.definitions';

function ClientMasterdata({
  t,
}: ClientMasterdataProps): FunctionComponentElement<ClientMasterdataProps> {
  const { clientStore } = useStore();

  return (
    <Box sx={{ p: '1rem' }}>
      <CardWrapper>
        <SummaryCardHeader header={t('navigation:client-masterdata')} />
        <br />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="subtitle2">{t('name')}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle1">{clientStore.getClient()?.name}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Typography variant="caption" display="block" gutterBottom>
          {t('clientMasterdata:clientMasterdataCanOnlyBeEditedByAdmin')}
        </Typography>
      </CardWrapper>
    </Box>
  );
}

export default withTranslation('clientMasterdata')(ClientMasterdata);
