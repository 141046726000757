import { Box, Typography } from '@mui/material';
import { FunctionComponentElement } from 'react';

import { AccordionTitleProps } from './AccordionTitle.definitions';

function AccordionTitle({
  title,
}: AccordionTitleProps): FunctionComponentElement<AccordionTitleProps> {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Typography sx={{ fontWeight: 'bold' }} variant="body1">
        {title}
      </Typography>
    </Box>
  );
}

export default AccordionTitle;
