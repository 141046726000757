/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnnualVatReturnModel } from '../models/AnnualVatReturnModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AnnualVatReturnSubmissionService {
  /**
   * Validates the specified annual VAT return.
   * @param annualVatReturnId The ID of the annual VAT return.
   * @returns AnnualVatReturnModel Success
   * @throws ApiError
   */
  public static postApiAnnualVatReturnSubmissionValidate(
    annualVatReturnId?: string,
  ): CancelablePromise<AnnualVatReturnModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/AnnualVatReturnSubmission/Validate',
      query: {
        annualVatReturnId: annualVatReturnId,
      },
    });
  }

  /**
   * Submits the specified annual VAT return.
   * @param annualVatReturnId The ID of the annual VAT return.
   * @returns AnnualVatReturnModel Success
   * @throws ApiError
   */
  public static postApiAnnualVatReturnSubmissionSubmit(
    annualVatReturnId?: string,
  ): CancelablePromise<AnnualVatReturnModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/AnnualVatReturnSubmission/Submit',
      query: {
        annualVatReturnId: annualVatReturnId,
      },
    });
  }

  /**
   * Creates a correction for the specified annual VAT return.
   * @param annualVatReturnId The ID of the annual VAT return to create a correction for.
   * @returns AnnualVatReturnModel Success
   * @throws ApiError
   */
  public static postApiAnnualVatReturnSubmissionCreateCorrection(
    annualVatReturnId?: string,
  ): CancelablePromise<AnnualVatReturnModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/AnnualVatReturnSubmission/CreateCorrection',
      query: {
        annualVatReturnId: annualVatReturnId,
      },
    });
  }
}
