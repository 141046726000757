/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnnualVatReturnModel } from '../models/AnnualVatReturnModel';
import type { InvoiceModel } from '../models/InvoiceModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AnnualVatReturnsService {
  /**
   * Retrieves the Annual VAT Return for the specified ID.
   * @param annualVatReturnId The unique identifier of the annual VAT return.
   * @returns AnnualVatReturnModel Success
   * @throws ApiError
   */
  public static getApiAnnualVatReturns(
    annualVatReturnId?: string,
  ): CancelablePromise<AnnualVatReturnModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/AnnualVatReturns',
      query: {
        annualVatReturnId: annualVatReturnId,
      },
    });
  }

  /**
   * Retrieves a list of invoices used in the VAT return calculation for a given tax form entry.
   * @param taxFormEntryId The ID of the tax form entry for which to retrieve the invoices.
   * @returns InvoiceModel Success
   * @throws ApiError
   */
  public static getApiAnnualVatReturnsGetUsedInvoicesForCalculation(
    taxFormEntryId?: string,
  ): CancelablePromise<Array<InvoiceModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/AnnualVatReturns/GetUsedInvoicesForCalculation',
      query: {
        taxFormEntryId: taxFormEntryId,
      },
    });
  }

  /**
   * Deletes an annual VAT return submission.
   * @param id The unique identifier of the filling period.
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteApiAnnualVatReturnsDelete(id?: string): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/AnnualVatReturns/Delete',
      query: {
        id: id,
      },
    });
  }
}
