import { ClientCreationForm, ClientsService, UsersService } from 'api/client';

export const postClientFormQuery = async (clientFormInfo: ClientCreationForm) => {
  const createdClient = await ClientsService.postApiClients(clientFormInfo);
  return createdClient;
};

export const getAuditorUsersQuery = async () => {
  const createdClient = await UsersService.getApiUsersAuditor();
  return createdClient;
};
