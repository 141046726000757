import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FunctionComponentElement } from 'react';
import { v4 as uuidv4 } from 'uuid';

import useStore from 'context/Store/Store.hooks';
import { getNotificationIcon } from 'shared/utils/notification.helper';

import { Notification } from 'models/Notification';

import NotificationListItem from '../NotificationListItem/NotificationListItem';

import { NotificationListProps } from './NotificationList.definitions';

function NotificationList(): FunctionComponentElement<NotificationListProps> {
  const {
    notificationStore: { notifications, deleteNotification },
  } = useStore();

  const handleDeleteClick = (notification: Notification): void => {
    deleteNotification(notification);
  };

  return (
    <Stack spacing={1} sx={{ p: 1 }}>
      {notifications.map((notification) => (
        <div key={uuidv4()}>
          <NotificationListItem
            notification={notification}
            onDeleteClick={handleDeleteClick}
            icon={
              notification.type ? (
                getNotificationIcon(notification.type)
              ) : (
                <NotificationsOutlinedIcon />
              )
            }
          />
        </div>
      ))}
    </Stack>
  );
}

export default observer(NotificationList);
