import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useState } from 'react';

import { SubmissionDialogProps } from './SubmissionDialog.definitions';

function SubmissionDialog({
  open,
  showWarning = false,
  warningText,
  dialogTitle,
  switchLabel,
  cancelButtonText,
  submitButtonText,
  onClose,
  onFormSubmit,
}: SubmissionDialogProps) {
  const [approved, setApproved] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: 'form',
        onSubmit: onFormSubmit,
      }}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        {showWarning && warningText && <DialogContentText>{warningText}</DialogContentText>}
        <FormControlLabel
          control={
            <Switch checked={approved} onChange={(event) => setApproved(event.target.checked)} />
          }
          label={switchLabel}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{cancelButtonText}</Button>
        <Button disabled={!approved} type="submit">
          {submitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SubmissionDialog;
