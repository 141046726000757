import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';
import { makeAutoObservable } from 'mobx';

import i18n from 'configs/i18n';

import NotificationType from 'enums/NotificationType';
import Severity from 'enums/Severity';

import { INotificationStore } from '../NotificationStore/INotificationStore';

import { IHubStore } from './IHubStore';

class HubStore implements IHubStore {
  private hubConnections: HubConnection[] = [];

  private endpoint = '/notify';

  private connectionStatus = HubConnectionState.Disconnected;

  constructor(private notificationStore: INotificationStore) {
    makeAutoObservable(this);
  }

  get isConnected() {
    return this.connectionStatus === HubConnectionState.Connected;
  }

  get isConnecting() {
    return this.connectionStatus === HubConnectionState.Connecting;
  }

  get isDisconnected() {
    return this.connectionStatus === HubConnectionState.Disconnected;
  }

  get isDisconnecting() {
    return this.connectionStatus === HubConnectionState.Disconnecting;
  }

  startHubConnections(resolveToken: () => Promise<string>): void {
    this.connectionStatus = HubConnectionState.Connecting;

    this.buildAllHubConnections(resolveToken);

    if (this.hubConnections.length > 0) {
      this.hubConnections.forEach((connection) => {
        connection
          .start()
          .then(this.handleSuccessfulConnection)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => this.handleFailureConnection(error));
        connection.onclose(this.handleCloseConnection);
      });
    } else {
      this.notificationStore.sendNotification(
        i18n.t('common:notificationServerError'),
        Severity.Error,
        i18n.t('common:noNotificationServiceMessage'),
        NotificationType.SignalRConnectionFailed,
      );
    }
  }

  private buildAllHubConnections = (resolveToken: () => Promise<string>) => {
    const masterDataConnection = this.buildHubConnection(
      process.env.REACT_APP_MASTERDATA_API_URL || '',
      resolveToken,
    );

    const systemConnection = this.buildHubConnection(
      process.env.REACT_APP_SYSTEM_API_URL || '',
      resolveToken,
    );

    const clientConnection = this.buildHubConnection(
      process.env.REACT_APP_CLIENT_API_URL || '',
      resolveToken,
    );

    const invoicesConnection = this.buildHubConnection(
      process.env.REACT_APP_INVOICES_API_URL || '',
      resolveToken,
    );

    const taxCalculationConnection = this.buildHubConnection(
      process.env.REACT_APP_TAXCALCULATION_API_URL || '',
      resolveToken,
    );

    const filesConnection = this.buildHubConnection(
      process.env.REACT_APP_FILES_API_URL || '',
      resolveToken,
    );

    const importConnection = this.buildHubConnection(
      process.env.REACT_APP_IMPORT_API_URL || '',
      resolveToken,
    );

    const taxRuleConnection = this.buildHubConnection(
      process.env.REACT_APP_TAXRULE_API_URL || '',
      resolveToken,
    );

    const submissionConnection = this.buildHubConnection(
      process.env.REACT_APP_SUBMISSION_API_URL || '',
      resolveToken,
    );

    this.hubConnections = [
      masterDataConnection,
      systemConnection,
      clientConnection,
      invoicesConnection,
      taxCalculationConnection,
      filesConnection,
      importConnection,
      taxRuleConnection,
      submissionConnection,
    ];
  };

  private buildHubConnection = (
    apiUrl: string,
    resolveToken: () => Promise<string>,
  ): HubConnection =>
    new HubConnectionBuilder()
      .withUrl(apiUrl + this.endpoint, {
        // This only works with long polling; need to verify
        transport: HttpTransportType.LongPolling,
        accessTokenFactory: resolveToken,
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Error)
      .build();

  private handleSuccessfulConnection = (): void => {
    this.connectionStatus = HubConnectionState.Connected;
    this.subscribe();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleFailureConnection = (error: any): void => {
    this.connectionStatus = HubConnectionState.Disconnected;
    this.notificationStore.sendNotification(
      i18n.t('common:notificationServerError'),
      Severity.Error,
      i18n.t('common:noNotificationServiceMessage'),
      NotificationType.SignalRConnectionFailed,
    );
  };

  private handleCloseConnection = (): void => {
    this.connectionStatus = HubConnectionState.Disconnected;
  };

  private subscribe(): void {
    this.hubConnections.forEach((connection) => {
      connection.on('ReceiveNotification', this.notificationStore.addNotification);
    });
  }
}

export default HubStore;
