/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SystemSettingModel } from '../models/SystemSettingModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SystemSettingsService {
  /**
   * Retrieves all possible system setting keys
   * @returns string Success
   * @throws ApiError
   */
  public static getApiSystemSettingsGetAllKeys(): CancelablePromise<Array<string>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/SystemSettings/GetAllKeys',
    });
  }

  /**
   * Retrieves paginated rows of system settings.
   * @param page The page number for the data set to retrieve.
   * @param pageSize The size of the page to retrieve.
   * @returns SystemSettingModel Success
   * @throws ApiError
   */
  public static getApiSystemSettingsGetRows(
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<SystemSettingModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/SystemSettings/GetRows',
      query: {
        page: page,
        pageSize: pageSize,
      },
    });
  }

  /**
   * Retrieves the total count of system settings.
   * @returns number Success
   * @throws ApiError
   */
  public static getApiSystemSettingsGetRowCount(): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/SystemSettings/GetRowCount',
    });
  }

  /**
   * Adds a new system setting row.
   * @param requestBody The SystemSettingModel object representing the new system setting to add.
   * @returns SystemSettingModel Success
   * @throws ApiError
   */
  public static postApiSystemSettingsAddRow(
    requestBody?: SystemSettingModel,
  ): CancelablePromise<SystemSettingModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/SystemSettings/AddRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Updates an existing system setting row.
   * @param requestBody The SystemSettingModel object with updated information.
   * @returns SystemSettingModel Success
   * @throws ApiError
   */
  public static putApiSystemSettingsUpdateRow(
    requestBody?: SystemSettingModel,
  ): CancelablePromise<SystemSettingModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/SystemSettings/UpdateRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Deletes a system setting row.
   * @param requestBody The system settings model to delete.
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteApiSystemSettingsDeleteRow(
    requestBody?: SystemSettingModel,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/SystemSettings/DeleteRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
