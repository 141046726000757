import { GridEditCellProps, GridPreProcessEditCellProps } from '@mui/x-data-grid-premium';
import { t } from 'i18next';

export default class GridFieldsValidator {
  public static validateRequiredField(
    params: GridPreProcessEditCellProps,
    minLength: number = 0,
  ): GridEditCellProps {
    const hasError = !params.props.value || params.props.value.length <= minLength;
    let errorMessage = '';

    if (minLength === 0) {
      errorMessage = t('gridTooltips:requiredField');
    } else {
      errorMessage = t('gridTooltips:minLengthRequired', { length: minLength + 1 });
    }

    return { ...params.props, error: hasError, errormessage: errorMessage };
  }

  public static validateExactLength(
    params: GridPreProcessEditCellProps,
    length: number,
  ): GridEditCellProps {
    const hasError = params.props.value.length !== length;
    const errorMessage = t('gridTooltips:exactLengthRequired', { length });

    return { ...params.props, error: hasError, errormessage: errorMessage };
  }

  public static validateMaxLength(
    params: GridPreProcessEditCellProps,
    maxLength: number,
  ): GridEditCellProps {
    const hasError = params.props.value.length > maxLength;
    const errorMessage = t('gridTooltips:maxLengthAllowed', { length: maxLength });

    return { ...params.props, error: hasError, errormessage: errorMessage };
  }

  public static validateNumberRange(
    params: GridPreProcessEditCellProps,
    min: number,
    max: number,
  ): GridEditCellProps {
    const hasError =
      params.props.value === undefined || params.props.value < min || params.props.value > max;
    const errorMessage = t('gridTooltips:numberRange', { min, max });

    return { ...params.props, error: hasError, errormessage: errorMessage };
  }

  public static validateNumber(params: GridPreProcessEditCellProps): GridEditCellProps {
    const hasError = params.props.value === null || Number.isNaN(Number(params.props.value));
    const errorMessage = t('gridTooltips:number');

    return { ...params.props, error: hasError, errormessage: errorMessage };
  }

  public static validatePositiveNumber(params: GridPreProcessEditCellProps): GridEditCellProps {
    const hasError =
      params.props.value === null ||
      Number.isNaN(Number(params.props.value)) ||
      Number(params.props.value) < 0 ||
      1 / Number(params.props.value) === Number.NEGATIVE_INFINITY;
    const errorMessage = t('gridTooltips:positiveNumber');

    return { ...params.props, error: hasError, errormessage: errorMessage };
  }
}
