import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { FunctionComponentElement } from 'react';
import { Controller, get, useFormContext } from 'react-hook-form';
import { withTranslation } from 'react-i18next';

import { auditorRoles, clientRoles } from 'shared/utils/roles.helper';

import UserRoleSelection from 'components/UserRoleSelection/UserRoleSelection';

import FieldWidth from 'enums/FieldWidth';

import { getNewUser } from '../../../shared/utils/formFields.helper';

import { UserFieldsProps } from './UserFields.definitions';

function UserFields({
  firstNameField,
  lastNameField,
  usernameField,
  emailField,
  rolesField,
  isAuditorUserField,
  t,
}: UserFieldsProps): FunctionComponentElement<UserFieldsProps> {
  const {
    register,
    watch,
    control,
    resetField,
    formState: { errors },
  } = useFormContext();

  const isAtleastOneFieldFilled =
    watch(firstNameField) !== '' ||
    watch(lastNameField) !== '' ||
    watch(emailField) !== '' ||
    watch(usernameField) !== '' ||
    watch(rolesField).length !== 0;

  const defaultRoles = getNewUser().roles;
  return (
    <Grid container sx={{ justifyContent: 'space-evenly' }}>
      <Grid item xs={7}>
        <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
          <TextField
            data-testid="input_firstName"
            id="firstName"
            label={t('firstName')}
            variant="outlined"
            required
            {...register(firstNameField, {
              required: {
                value: isAtleastOneFieldFilled,
                message: t('fieldsValidation:requiredHelperText', { fieldName: t('firstName') }),
              },
            })}
            sx={{ width: FieldWidth.Half }}
            error={!!(errors && get(errors, firstNameField))}
            helperText={get(errors, firstNameField) && get(errors, firstNameField).message}
          />
          <TextField
            data-testid="input_lastName"
            id="lastName"
            label={t('lastName')}
            variant="outlined"
            required
            {...register(lastNameField, {
              required: {
                value: isAtleastOneFieldFilled,
                message: t('fieldsValidation:requiredHelperText', { fieldName: t('lastName') }),
              },
            })}
            sx={{ width: FieldWidth.Half }}
            error={!!(errors && get(errors, lastNameField))}
            helperText={get(errors, lastNameField) && get(errors, lastNameField).message}
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
          <TextField
            data-testid="input_email"
            id="email"
            label={t('email')}
            variant="outlined"
            required
            {...register(emailField, {
              required: {
                value: isAtleastOneFieldFilled,
                message: t('fieldsValidation:requiredHelperText', { fieldName: t('email') }),
              },
              pattern: {
                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: t('fieldsValidation:emailHelperText'),
              },
            })}
            sx={{ width: FieldWidth.Full }}
            error={!!(errors && get(errors, emailField))}
            helperText={get(errors, emailField) && get(errors, emailField).message}
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
          <TextField
            data-testid="input_username"
            id="username"
            label={t('username')}
            variant="outlined"
            required
            {...register(usernameField, {
              required: {
                value: isAtleastOneFieldFilled,
                message: t('fieldsValidation:requiredHelperText', { fieldName: t('username') }),
              },
            })}
            sx={{ width: FieldWidth.Half }}
            error={!!(errors && get(errors, usernameField))}
            helperText={get(errors, usernameField) && get(errors, usernameField).message}
          />
          <FormControl
            sx={{
              width: FieldWidth.Half,
              border: '1px solid #ccc',
              borderRadius: '4px',
              px: 2,
              justifyContent: 'center',
            }}
          >
            <FormGroup>
              <FormControlLabel
                label={t('common:auditorUser')}
                labelPlacement="end"
                control={
                  <Controller
                    name={isAuditorUserField}
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(event) => {
                          field.onChange(event);
                          resetField(rolesField, {
                            defaultValue: defaultRoles,
                          });
                        }}
                        inputProps={{
                          'aria-label': 'isAuditorUser',
                        }}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                      />
                    )}
                  />
                }
              />
            </FormGroup>
          </FormControl>
        </Stack>
      </Grid>
      <Grid item xs={4} sx={{ ml: 1 }}>
        <UserRoleSelection
          fieldName={rolesField}
          availableRoles={watch(isAuditorUserField) ? auditorRoles : clientRoles}
          isRequired={isAtleastOneFieldFilled}
          isDisabled={false}
        />
      </Grid>
    </Grid>
  );
}

export default withTranslation(['userFields', 'fieldsValidation', 'common'])(UserFields);
