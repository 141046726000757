/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TaxIssueModel } from '../models/TaxIssueModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaxIssuesService {
  /**
   * Gets the list of all tax issues.
   * @returns TaxIssueModel Success
   * @throws ApiError
   */
  public static getApiTaxIssues(): CancelablePromise<Array<TaxIssueModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxIssues',
    });
  }

  /**
   * Retrieves paginated rows of tax issues.
   * @param page The page number for the data set to retrieve.
   * @param pageSize The size of the page to retrieve.
   * @returns TaxIssueModel Success
   * @throws ApiError
   */
  public static getApiTaxIssuesGetRows(
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<TaxIssueModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxIssues/GetRows',
      query: {
        page: page,
        pageSize: pageSize,
      },
    });
  }

  /**
   * Retrieves the total count of tax issues.
   * @returns number Success
   * @throws ApiError
   */
  public static getApiTaxIssuesGetRowCount(): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/TaxIssues/GetRowCount',
    });
  }

  /**
   * Adds a new tax issue row.
   * @param requestBody The TaxIssueModel object representing the new tax issue to add.
   * @returns TaxIssueModel Success
   * @throws ApiError
   */
  public static postApiTaxIssuesAddRow(
    requestBody?: TaxIssueModel,
  ): CancelablePromise<TaxIssueModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/TaxIssues/AddRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Updates an existing tax issue row.
   * @param requestBody The TaxIssueModel object with updated information.
   * @returns TaxIssueModel Success
   * @throws ApiError
   */
  public static putApiTaxIssuesUpdateRow(
    requestBody?: TaxIssueModel,
  ): CancelablePromise<TaxIssueModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/TaxIssues/UpdateRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Deletes a tax issue if it's not referenced anymore.
   * @param requestBody The tax issue model to delete.
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteApiTaxIssuesDeleteRow(
    requestBody?: TaxIssueModel,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/TaxIssues/DeleteRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
