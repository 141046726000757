enum NavListTypeToRoute {
  Dashboard = '/dashboard',
  Country = '/country',
  TaxYear = '/tax-year',
  AnnualVatReturn = '/annual-vat-return',
  FillingPeriod = '/filling-period',
  AdvanceVatReturn = '/advance-vat-return',
  EcSalesList = '/ec-sales-list',
  SpecialPrepayment = '/special-prepayment',
  ValueAddedTaxGroup = '/value-added-tax-group',
  Company = '/company',
  ClientSettings = '/client-settings',
  ClientMasterdata = '/client-masterdata',
  TaxYears = '/tax-years',
  TaxCodes = '/tax-codes',
  ValueAddedTaxGroupSettings = '/value-added-tax-group-settings',
  CompanySettings = '/company-settings',
  ImportSettings = '/import-settings',
  CultureSettings = '/culture-settings',
  UserManagement = '/user-management',
  RoleManagement = '/role-management',
  SystemSettings = '/system-settings',
  Clients = '/clients',
  ValueAddedTaxForms = '/value-added-tax-forms',
  ValueAddedTaxFormYear = '',
  AnnualVatReturnForm = '/annual-vat-return-form',
  AdvanceVatReturnForm = '/advance-vat-return-form',
  EcSalesListForm = '/ec-sales-list-form',
  TaxOffices = '/tax-offices',
  AuditorOffices = '/auditor-offices',
  Contacts = '/contacts',
  ExchangeRates = '/exchange-rates',
  TaxIssues = '/tax-issues',
  TaxRules = '/tax-rules',
  TaxRuleMappings = '/tax-rule-mappings',
  SystemAdminSettings = '/system-admin-settings',
  Jobs = '/jobs',
  Administrations = '/administrations',
  AuditLogs = '/audit-logs',
  ImportLogs = '/import-logs',
}

export default NavListTypeToRoute;
