import { Typography } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { EcSalesListFormProps } from './EcSalesListForm.definitions';

function EcSalesListForm({
  t,
}: EcSalesListFormProps): FunctionComponentElement<EcSalesListFormProps> {
  return (
    <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
      {t('ec-sales-list-form')}
    </Typography>
  );
}

export default withTranslation('navigation')(EcSalesListForm);
