import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';

import theme from 'themes/theme';

import NotificationType from 'enums/NotificationType';
import Severity from 'enums/Severity';

export const getNotificationColor = (severity: Severity): string => {
  switch (severity) {
    case Severity.Trace || Severity.Info:
      return theme.palette.grey[400];
    case Severity.Warning:
      return theme.palette.warning.main;
    case Severity.Error || Severity.Critical:
      return theme.palette.error.main;
    default:
      return theme.palette.grey[400];
  }
};

export const getSnackbarColor = (severity: Severity): string => {
  switch (severity) {
    case Severity.Trace || Severity.Info:
      return theme.palette.grey[800];
    case Severity.Warning:
      return theme.palette.warning.main;
    case Severity.Error || Severity.Critical:
      return theme.palette.error.main;
    default:
      return theme.palette.grey[800];
  }
};

export const getNotificationIcon = (type?: NotificationType) => {
  switch (type) {
    case NotificationType.BackgroundJobPending: {
      return <InsertDriveFileOutlinedIcon />;
    }
    case NotificationType.BackgroundJobRunning: {
      return <InsertDriveFileOutlinedIcon />;
    }
    case NotificationType.BackgroundJobCompleted: {
      return <InsertDriveFileOutlinedIcon />;
    }
    case NotificationType.BackgroundJobFailed: {
      return <InsertDriveFileOutlinedIcon />;
    }
    default:
      return <NotificationsOutlinedIcon />;
  }
};
