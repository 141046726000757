/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContactModel } from '../models/ContactModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContactsService {
  /**
   * Gets the list of all contacts.
   * @returns ContactModel Success
   * @throws ApiError
   */
  public static getApiContacts(): CancelablePromise<Array<ContactModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Contacts',
    });
  }

  /**
   * Creates a new contact.
   * @param requestBody The contact model containing the details of the contact to be created.
   * @returns ContactModel Success
   * @throws ApiError
   */
  public static postApiContacts(requestBody?: ContactModel): CancelablePromise<ContactModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Contacts',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Retrieves a paginated list of contact rows.
   * @param page The page number of the data set to retrieve.
   * @param pageSize The size of the page to retrieve.
   * @returns ContactModel Success
   * @throws ApiError
   */
  public static getApiContactsGetRows(
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<ContactModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Contacts/GetRows',
      query: {
        page: page,
        pageSize: pageSize,
      },
    });
  }

  /**
   * Retrieves the total count of contact rows.
   * @returns number Success
   * @throws ApiError
   */
  public static getApiContactsGetRowCount(): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Contacts/GetRowCount',
    });
  }

  /**
   * Updates an existing contact row.
   * @param requestBody The contact model with updated information.
   * @returns ContactModel Success
   * @throws ApiError
   */
  public static putApiContactsUpdateRow(
    requestBody?: ContactModel,
  ): CancelablePromise<ContactModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Contacts/UpdateRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Deletes a contact if it's not referenced anymore.
   * @param requestBody The contact model to delete.
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteApiContactsDeleteRow(requestBody?: ContactModel): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/Contacts/DeleteRow',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
