import { Box, Typography } from '@mui/material';
import {
  GridColDef,
  GridPaginationModel,
  GridRowModel,
  ValueOptions,
} from '@mui/x-data-grid-premium';
import { useQuery } from '@tanstack/react-query';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { ContactsService, TaxOfficeModel, TaxOfficesService } from 'api/masterdata';

import GridFieldsValidator from 'shared/utils/validators/GridFieldsValidator';

import BdoDataGrid from 'components/BdoDataGrid/BdoDataGrid';
import RenderBasicEditCell from 'components/BdoDataGrid/Cells/BasicEditCell';
import { DataGridRow } from 'components/BdoDataGrid/Types/DataGridRow.definitions';
import FallbackComponent from 'components/FallbackComponent/FallbackComponent';

import { TaxOfficesProps } from './TaxOffices.definitions';

function TaxOffices({ t }: TaxOfficesProps): FunctionComponentElement<TaxOfficesProps> {
  const { isError: isErrorContacts, data: contacts } = useQuery({
    queryKey: ['setContacts'],
    queryFn: async () => {
      const contactsData = await ContactsService.getApiContacts();
      return contactsData;
    },
  });

  const getValueOptions = (): ValueOptions[] => {
    if (!contacts) return [];

    const mappedContacts = contacts.map(
      (contact): ValueOptions => ({
        value: contact.id,
        label: `${contact.lastName}, ${contact.firstName}`,
      }),
    );

    const result: ValueOptions[] = [
      { value: 'none', label: t('common:noSelection') },
      ...mappedContacts,
    ];

    return result;
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: t('id'), type: 'string', width: 150 },
    {
      field: 'name',
      headerName: `${t('taxOfficeFields:name')} *`,
      type: 'string',
      editable: true,
      width: 150,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'taxOfficeNumber',
      headerName: `${t('taxOfficeFields:taxOfficeNumber')} *`,
      type: 'string',
      editable: true,
      width: 150,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'email',
      headerName: t('contactInfoFields:email'),
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.contactInfo?.email,
      valueSetter: (params) => ({
        ...params.row,
        contactInfo: { ...params.row.contactInfo, email: params.value },
      }),
      groupingValueGetter: (params) => params?.row?.contactInfo?.email,
    },
    {
      field: 'phoneNumber',
      headerName: t('contactInfoFields:phoneNumber'),
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.contactInfo?.phoneNumber,
      valueSetter: (params) => ({
        ...params.row,
        contactInfo: { ...params.row.contactInfo, phoneNumber: params.value },
      }),
      groupingValueGetter: (params) => params?.row?.contactInfo?.phoneNumber,
    },
    {
      field: 'street',
      headerName: `${t('addressFields:street')} *`,
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.address?.street,
      valueSetter: (params) => ({
        ...params.row,
        address: { ...params.row.address, street: params.value },
      }),
      groupingValueGetter: (params) => params?.row?.address?.street,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'number',
      headerName: `${t('addressFields:number')} *`,
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.address?.number,
      valueSetter: (params) => ({
        ...params.row,
        address: { ...params.row.address, number: params.value },
      }),
      groupingValueGetter: (params) => params?.row?.address?.number,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'postal',
      headerName: `${t('addressFields:postal')} *`,
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.address?.postal,
      valueSetter: (params) => ({
        ...params.row,
        address: { ...params.row.address, postal: params.value },
      }),
      groupingValueGetter: (params) => params?.row?.address?.postal,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: (params) => GridFieldsValidator.validateRequiredField(params, 3),
    },
    {
      field: 'city',
      headerName: `${t('addressFields:city')} *`,
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.address?.city,
      valueSetter: (params) => ({
        ...params.row,
        address: { ...params.row.address, city: params.value },
      }),
      groupingValueGetter: (params) => params?.row?.address?.city,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'region',
      headerName: `${t('addressFields:region')} *`,
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.address?.region,
      valueSetter: (params) => ({
        ...params.row,
        address: { ...params.row.address, region: params.value },
      }),
      groupingValueGetter: (params) => params?.row?.address?.region,
    },
    {
      field: 'country',
      headerName: `${t('addressFields:country')} *`,
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.address?.country,
      valueSetter: (params) => ({
        ...params.row,
        address: { ...params.row.address, country: params.value },
      }),
      groupingValueGetter: (params) => params?.row?.address?.country,
    },
    {
      field: 'addressNotes',
      headerName: `${t('addressFields:notes')} `,
      type: 'string',
      editable: true,
      width: 150,
      valueGetter: (params) => params?.row?.address?.notes,
      valueSetter: (params) => ({
        ...params.row,
        address: { ...params.row.address, notes: params.value },
      }),
    },
    {
      field: 'contact',
      headerName: t('taxOfficeFields:contact'),
      type: 'singleSelect',
      editable: true,
      width: 150,
      valueOptions: getValueOptions(),
      valueGetter: (params) => {
        const contact = contacts?.find((x) => x.id === params.value?.id);

        if (contact === undefined) {
          return 'none';
        }

        return contact.id;
      },
      renderCell: (params) => {
        if (isErrorContacts) {
          return <FallbackComponent errorMessage={t('common:errorOccured')} />;
        }

        if (params.rowNode.type === 'group' && params.field === params.rowNode.groupingField) {
          return '';
        }

        if (params.value === 'none') return t('common:noSelection');

        const data = params?.row?.contact;

        if (data) {
          return `${data.lastName}, ${data.firstName}`;
        }

        return params.value || '';
      },
      valueSetter: (params) => {
        const { value, row } = params;
        const contact = contacts?.find((x) => x.id === value);

        return { ...row, contact };
      },
      groupingValueGetter: (params) => {
        const contact = contacts?.find((x) => x.id === params.value?.id);

        if (contact === undefined) {
          return t('common:noSelection');
        }

        return `${contact.lastName}, ${contact.firstName}`;
      },
    },
    {
      field: 'notes',
      headerName: t('taxOfficeFields:notes'),
      type: 'string',
      editable: true,
      width: 250,
    },
  ];

  const addDefaultRecord = (id: string): GridRowModel => {
    const taxOffice: TaxOfficeModel = {
      id,
      name: '',
      address: {
        street: undefined,
        number: undefined,
        postal: undefined,
        city: undefined,
        region: undefined,
        country: undefined,
        notes: undefined,
      },
      taxOfficeNumber: 0,
    };

    return taxOffice;
  };

  const processRowUpdate = async (row: DataGridRow): Promise<DataGridRow> => {
    let resultRow;

    if (row.isNew) {
      resultRow = await TaxOfficesService.postApiTaxOfficesAddRow(row as TaxOfficeModel);
    } else {
      resultRow = await TaxOfficesService.putApiTaxOfficesUpdateRow(row as TaxOfficeModel);
    }

    return resultRow as DataGridRow;
  };

  const processRowDelete = async (row: DataGridRow): Promise<boolean> =>
    TaxOfficesService.deleteApiTaxOfficesDeleteRow(row as TaxOfficeModel);

  const getRows = (pagination: GridPaginationModel) =>
    TaxOfficesService.getApiTaxOfficesGetRows(pagination.page, pagination.pageSize);

  const getRowCount = () => TaxOfficesService.getApiTaxOfficesGetRowCount();

  return (
    <div>
      <Typography variant="h6" sx={{ display: 'flex', px: 2, py: 1, fontWeight: 'medium' }}>
        {t('navigation:tax-offices')}
      </Typography>
      <Box sx={{ p: '1rem' }}>
        <BdoDataGrid
          columns={columns}
          processRowUpdate={processRowUpdate}
          processRowDelete={processRowDelete}
          getRows={getRows}
          getRowCount={getRowCount}
          addDefaultRecord={addDefaultRecord}
          exportFileName={t('taxOfficeFields:exportFileName')}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                addressNotes: false,
              },
            },
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
          }}
        />
      </Box>
    </div>
  );
}

export default withTranslation([
  'addressFields',
  'contactInfoFields',
  'gridTooltips',
  'navigation',
  'taxOfficeFields',
  'common',
])(TaxOffices);
