/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileModel } from '../models/FileModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import axios, { AxiosProgressEvent } from 'axios';

export class FilesService {
  /**
   * Uploads a file.
   * @param referenceId The reference identifier.
   * @param formData
   * @returns FileModel Success
   * @throws ApiError
   */
  public static postApiFiles(
    referenceId?: string,
    formData?: {
      file?: Blob;
    },
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  ): CancelablePromise<FileModel> {
    return __request(
      OpenAPI,
      {
        method: 'POST',
        url: '/api/Files',
        query: {
          referenceId: referenceId,
        },
        formData: formData,
        mediaType: 'multipart/form-data',
      },
      axios,
      onUploadProgress,
    );
  }

  /**
   * Gets the file by its identifier.
   * The file content is not returned for performance reasons.
   * To get the file content please use the method M:BDO.VAT.Compliance.Files.Api.Controllers.FilesController.GetFileContent(System.Guid).
   * @param fileId The file's identifier.
   * @returns FileModel Success
   * @throws ApiError
   */
  public static getApiFilesGetFile(fileId?: string): CancelablePromise<FileModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Files/GetFile',
      query: {
        fileId: fileId,
      },
    });
  }

  /**
   * Gets files associated with a specific reference identifier.
   * The file content is not returned for performance reasons.
   * To get the file content please use the method M:BDO.VAT.Compliance.Files.Api.Controllers.FilesController.GetFileContent(System.Guid).
   * @param referenceId The reference identifier.
   * @returns FileModel Success
   * @throws ApiError
   */
  public static getApiFilesGetFilesByReference(
    referenceId?: string,
  ): CancelablePromise<Array<FileModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Files/GetFilesByReference',
      query: {
        referenceId: referenceId,
      },
    });
  }

  /**
   * Gets the content of a file by its identifier.
   * @param fileId The file's identifier.
   * @returns FileModel Success
   * @throws ApiError
   */
  public static getApiFilesGetFileContent(fileId?: string): CancelablePromise<FileModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Files/GetFileContent',
      query: {
        fileId: fileId,
      },
    });
  }
}
